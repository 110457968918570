import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuthInterceptor } from "./util";

export const logsApi = createApi({
  reducerPath: "logsApi",
  baseQuery: baseQueryWithAuthInterceptor({ baseUrl: "/api/" }),
  tagTypes: ["activityLog"],
  endpoints: (builder) => ({
    getLogs: builder.query({
      query: (val) => ({
        url: "activityLog",
        method: "GET",
      }),
    }),
    getLogsByDay: builder.query({
      query: (val) => ({
        url: `activityLog/${val}`,
        method: "GET",
      }),
    }),
    getOrderLogs: builder.query({
      query: (val) => ({
        url: `orderLog/${val}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useGetLogsQuery, useGetLogsByDayQuery, useGetOrderLogsQuery } =
  logsApi;
