import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuthInterceptor } from "./util";

export const slackMessageApi = createApi({
  reducerPath: "slackMessageApi",
  baseQuery: baseQueryWithAuthInterceptor({ baseUrl: "/api/" }),
  tagTypes: ["Slack_Message"],
  endpoints: (builder) => ({
    sendSlackMessage: builder.mutation({
      query: (body) => {
        return {
          url: "sendSlackMessage",
          method: "POST",
          body,
        };
      },
      invalidatesTags: (result, error, arg) => !error && ["Slack_Message"],
    }),
  }),
});

export const { useSendSlackMessageMutation } = slackMessageApi;
