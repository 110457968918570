import React from 'react'
import EditIcon from "@mui/icons-material/Edit";
import Days from "../../WeekSchedule";
import { actions } from '../../../../redux/store/store';


function EditSchedule(props) {
    const driver = props && props?.driver
    const role = props && props.currentUser && props?.currentUser?.role;
    const id = props && props?.id && props?.id;
    return (
        <>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <Days {...driver} />
                {role === "root" &&
                    <span
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                            actions.modal.openSchedule({
                                driver,
                                id,
                            })
                        }
                    >
                        <EditIcon style={{ width: "20px", height: "20px" }} />
                    </span>
                }
            </div>
        </>
    )
}

export default EditSchedule
