import React from "react";

import moment from "moment";
import cn from "classnames";

import "./style.scss";

window.moment = moment;

const weekdays = Array.apply(null, Array(7)).map(function (_, i) {
  return moment(i, "e")
    .startOf("week")
    .isoWeekday(i + 1)
    .format("ddd");
});

const WeekSchedule = ({ availableDays, preferredDays }) => {
  return (
    <div className="week-schedule">
      {weekdays.map((day, index) => (
        <div
          className={cn("week-schedule__day", {
            "week-schedule__day_active": availableDays?.includes(index),
            // "week-schedule__day_favorite": preferredDays?.includes(index),
          })}
          key={index}
        >
          {day}
        </div>
      ))}
    </div>
  );
};

export default WeekSchedule;
