import _ from "lodash";
import React, { useState, useCallback, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Modal, Select, MenuItem, CircularProgress } from "@mui/material";
import { FaCheck, FaBan } from "react-icons/fa";
import { InputItem, BaseInputItem, ErrorMessage } from "../../../shared/Form";
import { MdCancel } from "react-icons/md";
import cn from "classnames";

import {
  useCreateOrUpdateCustomerMutation,
  useCreateOrUpdateFundingSourceMutation,
  useCreateAccountMutation,
  useGetSingleDirectPaymentQuery,
} from "../../../../api/drivers";

import { actions } from "../../../../redux/store/store";

import "../style.scss";
import "./style.directPayment.scss";
import "../massPayments/style.massPayments.scss";

const requiredMessage = "This field is required";
const validationSchema = Yup.object().shape({
  depositHolderName: Yup.string().required(requiredMessage),
  depositHolderSurname: Yup.string().required(requiredMessage),
  depositHolderEmail: Yup.string().email().required(requiredMessage),
  depositAccountNumber: Yup.string().required(requiredMessage),
  depositRoutingNumber: Yup.string().required(requiredMessage),
});

const CustomModal = ({ id }) => {
  const title = `Direct Payment`;

  const [isLoaded, setIsLoaded] = useState(false);

  const [create, { error: createError, isLoading: isCreating }] =
    useCreateAccountMutation();
  const [createOrUpdateCustomer, createOrUpdateCustomerReq] =
    useCreateOrUpdateCustomerMutation();
  const [createOrUpdateFundingSource, createOrUpdateFundingSourceReq] =
    useCreateOrUpdateFundingSourceMutation();

  const { data: paymentInfo, status: paymentLoadingStatus } =
    useGetSingleDirectPaymentQuery(id, {
      refetchOnMountOrArgChange: true,
      skip: !id,
    });
    const [generalError, setGeneralError] = useState(null)

  useEffect(() => {
    if (!id) setIsLoaded(true);

    if (paymentInfo != undefined) setIsLoaded(true);
  }, [paymentLoadingStatus]);

  // const customerForm = useForm()
  const customerForm = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onCancel = useCallback(() => {
    if (
      !customerForm.formState.isDirty ||
      window.confirm("Do you want to close the dialog? Changes will be lost.")
    ) {
      actions.modal.closeDirectpayment();
    }
  }, [customerForm.formState.isDirty]);

  const onSubmit = useCallback(async (data) => {
    const result = await create(data);

    if(result.data.message)
      setGeneralError({message: result.data.message})

    if(result.data.status !== 500)
        actions.modal.closeDirectpayment();
  
  }, []);

  return (
    <Modal
      open
      onClose={onCancel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="modal" style={{height: '60%'}}>
        <div className="modal__header">
          <h2 className="modal__title">{title}</h2>
        </div>
        <div className="modal__body">
          {isLoaded ? (
            <React.Fragment>
              <div className="form__col form__col_full-width deposit">
                <div className="section section_group">
                  <h3 className="section__title">Deposit:</h3>
                  <div className="form__row">
                    <div className="form__col form__col_height-flex">
                      <div className="section">
                        <div className="section__title">Customer:</div>

                        <form
                          onSubmit={customerForm.handleSubmit(
                            createOrUpdateCustomer
                          )}
                        >
                          <InputItem
                            name="depositHolderName"
                            title="First Name"
                            initialValue={
                              paymentInfo && paymentInfo.depositHolderName
                            }
                            form={customerForm}
                          />
                          <InputItem
                            name="depositHolderSurname"
                            title="Last Name"
                            initialValue={
                              paymentInfo && paymentInfo.depositHolderSurname
                            }
                            form={customerForm}
                          />
                          <InputItem
                            name="depositHolderEmail"
                            title="Email"
                            initialValue={
                              paymentInfo && paymentInfo.depositHolderEmail
                            }
                            form={customerForm}
                          />

                          <div>
                            {JSON.stringify(createOrUpdateCustomerReq.data)}
                          </div>
                       
                        </form>
                      </div>
                    </div>

                    <div className="form__col form__col_height-flex">
                      <div className="section">
                        <div className="section__title">Funding source:</div>

                      
                        <InputItem
                          name="depositRoutingNumber"
                          title="Routing number"
                          initialValue={
                            paymentInfo && paymentInfo.depositRoutingNumber
                          }
                          form={customerForm}
                        />
                          <InputItem
                          name="depositAccountNumber"
                          title="Account number"
                          initialValue={
                            paymentInfo && paymentInfo.depositAccountNumber
                          }
                          form={customerForm}
                        />

                        <div className="form__item">
                          <label className="form__label">Type:</label>

                          <div className="form__input-place">
                            <div className="form__inline-items form__inline-items_deposit">
                              <label
                                htmlFor="deposit_type_checking"
                                className="form__check-label"
                              >
                                <input
                                  type="radio"
                                  name="deposit_type"
                                  id="deposit_type_checking"
                                  value="checking"
                                  // {...customerForm.register("depositType")}
                                  {...customerForm.register(
                                    "depositType",
                                    paymentInfo && {
                                      value: paymentInfo.depositType,
                                    }
                                  )}
                                />
                                Checking
                              </label>
                              <label
                                htmlFor="deposit_type_saving"
                                className="form__check-label"
                              >
                                <input
                                  type="radio"
                                  name="deposit_type"
                                  id="deposit_type_saving"
                                  value="savings"
                                  // {...customerForm.register("depositType")}
                                  {...customerForm.register(
                                    "depositType",
                                    paymentInfo && {
                                      value: paymentInfo.depositType,
                                    }
                                  )}
                                />
                                Saving
                              </label>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                <div className="form__item form__item_summary form__item_half-margin">
                            <ErrorMessage error={generalError} />
                          </div>
              </div>

              {/* table end */}
            </React.Fragment>
          ) : (
            <div className="loading-wrapper">
              <CircularProgress />
            </div>
          )}
        </div>
        <div className="modal__footer">
          {/* <button
          type="submit"
            className="btn btn_accent"
            // disabled={startPay ? true : null}
            onClick={customerForm.handleSubmit(onSubmit)}>
            Create Account
          </button> */}

          <button
            className={cn("btn btn_accent", {
              btn_disabled: isCreating,
            })}
            onClick={customerForm.handleSubmit(onSubmit)}
          >
            {isCreating && <CircularProgress className="btn__progress" />}
            {id && "Update Account"} {!id && "Create Account"}
          </button>

          <button
            className="btn"
            onClick={() => actions.modal.closeDirectpayment()}
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default CustomModal;
