import React, { useEffect, useRef, useState } from "react";
import { Box, Drawer, Typography } from "@mui/material";
import "../driver/style.driver.scss";
import CloseIcon from "@mui/icons-material/Close";
import { BsFillFilterCircleFill } from "react-icons/bs";
import { useGetMetrosQuery } from "../../../../api/metro";
import { MdPlace } from "react-icons/md";
import MultiSelect from "../../../../constants/multiSelectDropdown";
import { payment } from "../../../../constants/menuLists";
import ReactSelect from "react-select";
import { DatePicker } from "../shift";
import { DateRangePicker } from "@mui/lab";
import { addDays } from "date-fns";
import BackspaceIcon from "@mui/icons-material/Backspace";
import CancelIcon from "@mui/icons-material/Cancel";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import moment from "moment";
import { transactionStatusList } from "../../../../constants/variables";
import { useGetTransactionDriversQuery } from "../../../../api/reports";
import { orderReactSelectStyle } from "../../../../constants/multiSelectDropdown/multiSelectStyle";
import { paymentMethodOptions } from "../../../../constants/variables/arrays";

const styles = (width) => {
  return {
    placeholder: (base) => ({
      ...base,
      fontSize: "0.9rem",
      fontWeight: "normal",
    }),
    control: (styles) => ({
      ...styles,
      borderRadius: "2px",
      boxShadow: "none",
      width: width || "12rem",
      height: "2.8rem",
      "&:hover": {
        borderColor: "#e3eaf3",
      },
      borderColor: "#e3eaf3",
      fontSize: "1rem",
    }),
  };
};

let d = new Date();
d.setMonth(d.getMonth() - 3);

function CustomTransactionsDrawer(props) {
  const {
    openDrawer,
    handleCloseDrawer,
    handleApplyFilter,
    handleReset,
    handleCustomRange,
    onResetClick,
    handleTimeRangeSelection,
    onCancelClick,
    onResetClickTransactions,
    handleCustomRangeTransactions,
    handleTimeRangeSelectionTransactions,
    onCancelClickTransactions,
    obj,
  } = props;

  const {
    metros: metro,
    setMetros,
    showDateRangePickerFilter,
    setShowDateRangePickerFilter,
    fromDateFilter,
    setFromDateFilter,
    toDateFilter,
    setToDateFilter,
    stateFilter,
    setStateFilter,
    payments,
    setPayments,
    showDateRangePicker,
    setShowDateRangePicker,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    state,
    setState,
    transactionStatus,
    setTransactionStatus,
    driver,
    setDriver,
    paymentMethod,
    setPaymentMethod
  } = obj;

  const { data: metros } = useGetMetrosQuery(null, {
    refetchOnMountOrArgChange: true,
  });

  const { data: drivers } = useGetTransactionDriversQuery(null, {
    refetchOnMountOrArgChange: true,
  });

  const wrapperRef = useRef(null);

  return (
    <>
      <Drawer
        anchor="right"
        open={openDrawer}
        transitionDuration={{ enter: 1000, exit: 1000 }}
      >
        <Box className="main_contain" role="presentation">
          <div className="drawer_header">
            <div className="d-flex">
              <span
                className="mr-3"
                style={{
                  lineHeight: "2rem",
                  fontSize: "2rem",
                  color: "#4195f5",
                }}
              >
                <BsFillFilterCircleFill />
              </span>
              <Typography style={{ fontSize: "24px", fontWeight: "600" }}>
                {"Advanced Search"}
              </Typography>
            </div>

            <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
              <div
                style={{ cursor: "pointer", color: "#adbfc7" }}
                onClick={() => handleCloseDrawer()}
              >
                <CloseIcon fontSize="medium" />
              </div>
            </div>
          </div>

          <div style={{ margin: "100px 30px" }}>
            <div>
              <div className="mb-3">
                <label style={{ fontWeight: "normal" }} className="m-0 mb-1">
                  <MdPlace /> Metro &nbsp;
                </label>
                <MultiSelect
                  width={"100%"}
                  placeholder="Select Metro"
                  value={metro}
                  itemData={metros?.map((item, i) => {
                    return {
                      value: item?.name ? item?.name : "-",
                      label: `${item?.name ? item?.name : ""}`,
                    };
                  })}
                  onChange={(value) => {
                    setMetros(value);
                  }}
                />
              </div>
            </div>

            <div className="mb-3">
              <label style={{ fontWeight: "normal" }} className=" m-0 mb-1">
                Driver Name
              </label>

              <MultiSelect
                width={"100%"}
                itemData={drivers?.map(function (item) {
                  return {
                    value: item?.name ? item?.name : "-",
                    label: `${item?.name ? item?.name : ""}`,
                  };
                })}
                isClearable={true}
                onChange={(item) => {
                  setDriver(item);
                }}
                value={driver}
                closeMenuOnSelect={true}
                placeholder={`Select Driver Name`}
              />
            </div>

            <div className="mb-3">
              <label style={{ fontWeight: "normal" }} className=" m-0 mb-1">
                Transaction Status
              </label>

              <MultiSelect
                width={"100%"}
                itemData={transactionStatusList?.map(function (item) {
                  return {
                    value: item?.value ? item?.value : "-",
                    label: `${item?.title ? item?.title : ""}`,
                  };
                })}
                isClearable={true}
                onChange={(item) => {
                  setTransactionStatus(item);
                }}
                value={transactionStatus}
                closeMenuOnSelect={true}
                placeholder={`Select Transaction Status`}
              />
            </div>

            <div>
              <div
                className="accordionDetails mt-2"
                style={{ justifyContent: "space-between" }}
              >
                <div style={{ width: "45%" }}>
                  <label
                    className="m-0 mb-1"
                    id="bg_checker"
                    style={{ fontWeight: "normal" }}
                  >
                    {" "}
                    Shift Date Range{" "}
                  </label>
                  <DatePicker
                    handleCustomRange={handleCustomRange}
                    fromDate={fromDateFilter}
                    toDate={toDateFilter}
                    showDateRangePicker={showDateRangePickerFilter}
                    onChange={(item) => {
                      setStateFilter([item.selection]);
                    }}
                    ranges={stateFilter}
                    cancelDatePicker={() => {
                      setShowDateRangePickerFilter(false);
                    }}
                    handleTimeRangeSelection={handleTimeRangeSelection}
                    resetTimeRange={onResetClick}
                    sx={{ position: "relative" }}
                    placeholder="Select Shift Date Range"
                  />
                </div>

                <div style={{ width: "45%" }}>
                  <label style={{ fontWeight: "normal" }} className=" m-0 mb-1">
                    Payment Type
                  </label>

                  <ReactSelect
                    options={payment?.map(function (item) {
                      return {
                        value: item?.value ? item?.value : "-",
                        label: `${item?.title ? item?.title : ""}`,
                      };
                    })}
                    isClearable={true}
                    onChange={(item) => {
                      setPayments(item);
                    }}
                    value={payments}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    styles={styles("100%")}
                    closeMenuOnSelect={true}
                    placeholder={`Select Payment Type`}
                  />
                </div>
              </div>

              <div className="m-0 mb-1">
                <div
                  className="accordionDetails mt-3"
                  style={{ justifyContent: "space-between" }}
                >
                  <div style={{ width: "45%" }}>
                    <label
                      className="m-0 mb-1"
                      id="bg_checker"
                      style={{ fontWeight: "normal" }}
                    >
                      {" "}
                      Transactions Date Range{" "}
                    </label>
                    <DatePicker
                      handleCustomRange={handleCustomRangeTransactions}
                      fromDate={fromDate}
                      toDate={toDate}
                      showDateRangePicker={showDateRangePicker}
                      onChange={(item) => {
                        setState([item.selection]);
                      }}
                      ranges={state}
                      cancelDatePicker={() => {
                        setShowDateRangePicker(false);
                      }}
                      handleTimeRangeSelection={
                        handleTimeRangeSelectionTransactions
                      }
                      resetTimeRange={onResetClickTransactions}
                      sx={{ position: "relative" }}
                      placeholder="Select Transactions Date Range"
                    />
                  </div>
                  <div style={{ width: "45%" }}>
                    <label
                      className="m-0 mb-1"
                      id="bg_checker"
                      style={{ fontWeight: "normal" }}>
                      {" "}
                      Payment Method{" "}
                    </label>
                    <ReactSelect
                      options={paymentMethodOptions}
                      isClearable={true}
                      onChange={(item) => {
                        setPaymentMethod(item);
                      }}
                      value={paymentMethod}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      styles={styles("100%")}
                      closeMenuOnSelect={true}
                      placeholder={`Select Payment Method`}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Box>

        <div className="drawer_footer">
          <button className="btn mr-4 pl-4 pr-4" onClick={() => handleReset()}>
            {" "}
            Reset{" "}
          </button>
          <button
            className="btn btn_accent mr-3 pl-4 pr-4"
            onClick={() => handleApplyFilter()}
          >
            {" "}
            Apply{" "}
          </button>
        </div>
      </Drawer>
    </>
  );
}

export default CustomTransactionsDrawer;
