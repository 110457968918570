import React, { useCallback, useState } from "react";
import { CircularProgress, Modal } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { actions } from "../../../redux/store/store";
import { checkForError } from "../../../constants/extras/errorHandlers";
import { useUpdateDriverMutation } from "../../../api/drivers";
import { sendSuccessMessage } from "../../../constants/extras/sendSuccessMsg";
import { InputItem } from "../Form";
import { zipcode } from "../../../redux/constants/zipcode";

const requiredMessage = "This field is required";
const validationSchema = Yup.object().shape({
    name: Yup.string()
        .test("len", "Please Enter valid metro name", (value) => {
            return !!value.trim();
        })
        .required(requiredMessage),
});

const NotesModal = (props) => {
    const title = props?.id?.title
    const state = props?.id?.state?.state
    const { id } = props;
    const driverId = props && props?.id?.id && props?.id?.id;

    const notes = props && props?.id?.notes && props?.id?.notes;
    const [updateDriverMetro, { isLoading: isUpdatingDriver }] =
        useUpdateDriverMutation();
    const [internalNotes, setInternalNotes] = useState(notes || "");

    const form = useForm({
        defaultValues:
            { notes: notes || "" },
        resolver: yupResolver(validationSchema),
    });

    const zipCodeValidation = (data) => {
        const text = data.replace(/[^0-9]/g, '');

        const stateVal = zipcode.find((e) => e.state === state)

        const endZip = stateVal?.ezip
        const startZip = stateVal?.szip

        if (endZip) {
            if (Number(startZip) <= text && Number(endZip) >= text) {
                return true;
            } else {
                return false;
            }
        } else {
            const num = Number(startZip) === text ? false : true;
            return num;
        }
    }

    const onNotesSubmit = useCallback(
        async () => {
            form.setValue("notes", internalNotes)
            let data = form.getValues();
            let params = {}
            if(title === "First Name") {
                params = {
                    firstName : data?.internalNotes,
                    _id : driverId
                }
            } else if (title === "Last Name"){
                params = {
                    lastName : data?.internalNotes,
                    _id : driverId
                }
            } else if (title === "Address") {
                params = {
                    address : data?.internalNotes,
                    _id : driverId
                }
            } else if (title === "City") {
                params = {
                    city : data?.internalNotes,
                    _id : driverId
                }
            } else if (title === "Zip Code") {
                
                const res = zipCodeValidation(data?.internalNotes)
                if(res) {
                    params = {
                        zipCode : data?.internalNotes,
                        _id : driverId
                    }
                }else {
                    return alert("Please enter valid zip code ")
                }
                
            } else if (title === "Notes") {
                params = {
                    internalNote: data?.internalNotes,
                    _id: driverId,
                }
            } 
            let result = await updateDriverMetro(params);
            if (!result?.error) {
                sendSuccessMessage(`${title} updated successfully`);
                actions.refetch.setIsDataRefetch(true);
            } else {
                checkForError(result);
            }
            actions.modal.closeNotes();
        },
        [
            id,
            driverId,
            updateDriverMetro,
        ]
    );

    const onCancel = useCallback(() => {
        if (
            !form.formState.isDirty ||
            window.confirm("Do you want to close the dialog? Changes will be lost.")
        ) {
            actions.modal.closeNotes();
        }
    }, [form.formState.isDirty]);

    return (
        <Modal
            open
            onClose={onCancel}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className={`modal after-loading`} style={{ maxWidth: "35.875rem" }}>
                <div className="modal__header">
                    <h2 className="modal__title">{`Update ${title}`}</h2>
                </div>
                <div className="modal__body"> 
                     <InputItem
                        title={`${title}`}
                        name="internalNotes"
                        form={form}
                        type="text"
                        initialValue={notes}
                        onInput={(e) => setInternalNotes(e?.target?.value)}
                    />
                </div>
                <div className="modal__footer">
                    <button className="btn" onClick={() => actions.modal.closeNotes()}>
                        Cancel
                    </button>
                    <div style={{ position: 'relative' }}>
                        <button
                            className="btn btn_accent"
                            disabled={isUpdatingDriver || notes === internalNotes}
                            onClick={onNotesSubmit}
                        >
                            OK
                        </button>
                        {isUpdatingDriver && (
                            <CircularProgress size={18}
                            sx={{
                                color: '#fff',
                                position: 'absolute',
                                top: '50%',
                                left: '83%',
                                marginTop: '-8px',
                                marginLeft: '-10px',
                            }} />
                        )}
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default NotesModal;
