export const handledriverName = (firstName, lastName, email) => {
  if (firstName && lastName && email) {
    return  `${firstName}  ${lastName}  (${email}) `
  } else if (firstName && lastName) {
    return  `${firstName}  ${lastName} `
  } else if (firstName && email) {
    return firstName + ` (${email}) `;
  } else if (lastName && email) {
    return lastName + ` (${email}) `;
  } else if (firstName) {
    return firstName;
  } else if (lastName) {
    return lastName;
  } else if (email) {
    return ` (${email}) `;
  } else {
    return "";
  }
};

export const handleCarText = (brand, model) => {
  let carText = "";
  if (brand && model) {
    carText = `${brand}, ${model}`;
  } else if (brand) {
    carText = `${brand}`;
  } else if (model) {
    carText = `${model}`;
  } else {
    carText = "-";
  }
  return carText;
};

export const handleActionee = (item) => {
  if (!item) return;
  const { updatedBy, createdBy } = item || {};
  return updatedBy?.username || createdBy?.username || "";
};
