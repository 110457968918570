import React, { useState, useEffect } from "react";
import { Grid as GridDHX } from "dhx-suite";
import "dhx-suite/codebase/suite.min.css";

const GridData = (props) => {
  // const [gridData, setGriData] = useState("")
  const { columns, height} = props
  let grid;

  useEffect(() => {
    grid = columns.data.length && new GridDHX("grid", columns);

    return (() => {
      grid.destructor()
    })

  }, [])

  return (
    <>
      <div style={{ width: "100%", height: height }} id="grid"></div>
    </>
  )
}
const TableGrid = (props) => {
  const { columns, height } = props

  return (
    <>
      {columns.data.length ? <div style={{ width: "100%",  height: "0%" }}>
        <GridData
          columns={columns}
          height={height}
        />
      </div> : ""}
    </>
  )
}

export default TableGrid