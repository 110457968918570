import React, { useState, useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Modal, Paper, MenuItem, Select } from "@mui/material";
import ReactQuill, { Quill } from "react-quill";
import { TiWarningOutline } from "react-icons/ti";
import moment from "moment";
import { useSelector } from "react-redux";

import "./style.scss";
import MySelect from "../../options/MessageOption";
import {
  Option,
  ValueContainer,
  MultiValue,
  animatedComponents,
  MenuList,
  compare,
} from "../../options/MessageOption";
import { messageListOptionStyles } from "./optionStyles";
import { actions } from "../../../../redux/store/store";
import { emailValidationSchema as validationSchema } from "../../../../constants/validaitonSchema";
import { useGetDriversQuery } from "../../../../api/drivers";
import { InputItem } from "../../Form";
import { useCreateEmailMutation } from "../../../../api/email";
import { checkForError } from "../../../../constants/extras/errorHandlers";
import MetroSelect from "../../metroSelect";
import { sendSuccessMessage } from "../../../../constants/extras/sendSuccessMsg";
import { hubSelectStyle, hoverAndFocusedStyle } from "../../orderHelper/styles";
import EmailsList from "./emailHelper/emailsList";

Quill.register(Quill.import("attributors/style/direction"), true);
Quill.register(Quill.import("attributors/style/align"), true);

//Text indent
const Parchment = Quill.import("parchment");
class IndentAttributor extends Parchment.Attributor.Style {
  add(node, value) {
    if (value === 0) {
      this.remove(node);
      return true;
    } else {
      return super.add(node, `${value}em`);
    }
  }
}

const handleSpace = () => {
  let arr = [];
  for (let i = 1; i <= 70; i++) {
    arr.push(`${i}em`);
  }
  return arr;
};

let IndentStyle = new IndentAttributor("indent", "text-indent", {
  scope: Parchment.Scope.BLOCK,
  whitelist: handleSpace(),
});

Quill.register(IndentStyle, true);

const modules = {
  clipboard: {
    matchVisual: false,
  },
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike", "script"],
    [{ script: "sub" }, { script: "super" }],
    [
      {
        color: [
          "#000000",
          "#e60000",
          "#ff9900",
          "#ffff00",
          "#008a00",
          "#0066cc",
          "#9933ff",
          "#ffffff",
          "#facccc",
          "#ffebcc",
          "#ffffcc",
          "#cce8cc",
          "#cce0f5",
          "#ebd6ff",
          "#bbbbbb",
          "#f06666",
          "#ffc266",
          "#ffff66",
          "#66b966",
          "#66a3e0",
          "#c285ff",
          "#888888",
          "#a10000",
          "#b26b00",
          "#b2b200",
          "#006100",
          "#0047b2",
          "#6b24b2",
          "#444444",
          "#5c0000",
          "#663d00",
          "#666600",
          "#003700",
          "#002966",
          "#3d1466",
          "custom-color",
        ],
      },
    ],
    ["blockquote", "code-block"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ align: [] }],
    ["clean"],
    [{ indent: "-1" }, { indent: "+1" }],
    ["link", "image", "video"],
  ],
};

const EmailModal = (props) => {
  const metro = useSelector((state) => state.metro.value);
  const [status, setStatusFilter] = useState("approved");
  const id = props?.id?.item?._id;
  const data = props?.id?.item;
  const title = id ? `Email #${id} details` : "New Email";
  const { data: allDrivers, isFetching } = useGetDriversQuery(
    { metro: metro && metro, status: status && status !== "all" ? status : "" },
    {
      refetchOnMountOrArgChange: true,
      skip: id || !metro,
    }
  );
  const [create, { isLoading: isCreating }] = useCreateEmailMutation();
  const [isLoadingAllDrivers, setLoadingAllDrivers] = useState(false);
  const [selectedOption, setSelectedOption] = useState([]);
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(data?.body || "");
  const [isError, setIsError] = useState(false);
  const [sub, setSub] = useState("");

  const form = useForm({
    defaultValues: {
      emails: [],
      subject: data?.subject || "",
      body: data?.body || "",
      metro: metro || "",
    },
    resolver: yupResolver(validationSchema),
  });

  const { watch } = form;

  useEffect(() => {
    const subscription = watch((value, { name, type }) =>
      setSub(value?.subject)
    );
    return () => subscription.unsubscribe();
  }, [watch]);

  const onSubmit = useCallback(async () => {
    let data = form.getValues();
    data.emails = [];
    let emails;
    if (selectedOption[0]?.value === "*") {
      data.isSendToAll = true;
    } else {
      data.isSendToAll = false;
      emails = selectedOption?.map((driver) => {
        return driver?.value;
      });
      data.emails = emails;
    }

    let result;
    if (
      (data?.emails?.length > 0 && data?.isSendToAll === false) ||
      (data?.emails?.length === 0 &&
        data?.isSendToAll === true &&
        data?.subject?.length > 0 &&
        data?.body?.length > 0)
    ) {
      data.metro = metro || "";
      if (status !== "all") {
        data.status = status;
      }
      result = await create(data);
    }
    if (!result?.data.error && result?.data?.status === 200) {
      actions.modal.closeEmail();
      actions.metro.selectMetro("");
      sendSuccessMessage("Email send successfully");
    } else {
      checkForError(result?.data?.message);
    }
  }, [create, form, selectedOption, metro, status]);

  const onCancel = useCallback(() => {
    if (
      !form.formState.isDirty ||
      window.confirm("Do you want to close the dialog? Changes will be lost.")
    ) {
      actions.modal.closeEmail();
      actions.metro.selectMetro("");
    }
  }, [form.formState.isDirty]);

  useEffect(() => {
    setLoadingAllDrivers(isFetching);
    if (!allDrivers) return;

    if (metro) {
      let drivers =
        allDrivers &&
        Array.isArray(allDrivers) &&
        allDrivers?.slice().sort(compare);
      setOptions(
        drivers &&
        drivers.map(function (item) {
          return {
            value: item?.username ? item?.username : "-",
            label: `${item?.firstName ? item?.firstName : ""} ${item?.lastName ? item?.lastName : ""
              } (${item?.username ? item?.username : ""})`,
            id: item._id,
            labelByFirstName: item?.username ? item?.username : "-",
          };
        })
      );
    } else {
      setOptions([])
    }
  }, [allDrivers, metro, isFetching]);

  const handleCreate = useCallback(
    (inputValue) => {
      let newValue;
      if (inputValue.match(/^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/)) {
        newValue = {
          value: inputValue,
          label: inputValue,
          labelByFirstName: inputValue,
        };
        setOptions([...options, newValue]);
        setSelectedOption([...selectedOption, newValue]);
        setIsError(false);
      } else {
        setIsError(true);
      }
    },
    [options, selectedOption]
  );

  useEffect(() => {
    if (isError) {
      (function () {
        const input = document.getElementById("error__container");

        input?.classList?.add("bounce");
        setTimeout(function () {
          input?.classList?.remove("bounce");
        }, 2000);
      })();
    }
  }, [isError]);

  return (
    <Modal
      open
      onClose={onCancel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={`modal after-loading`}>
        <div className="modal__header">
          <h2 className="modal__title">{title}</h2>
        </div>

        <div className="modal__body">
          {id && (
            <div className="date__container">
              <dl className="term term_inline">
                <dt className="term__title">Date:</dt>
                <dd className="term__value">
                  {moment(data?.date).format("MMM D, YYYY hh:mm A")}
                </dd>
              </dl>
            </div>
          )}
          {id && data && data?.metro && (
            <>
              <label>
                <strong>Metro</strong>
              </label>
              <Paper
                className="mb-2"
                style={{
                  marginTop: "-0.5rem",
                  padding: "0.5rem",
                  border: "1px solid #dadada",
                }}
              >
                {data?.metro}
              </Paper>
            </>
          )}
          {id && <EmailsList data={data} allDrivers={allDrivers} />}

          {!id && (
            <div className="metro__select__container mb-3">
              <div className="w-75">
                <label style={{ marginBottom: "-0.5rem" }}>Select Metro</label>
                <MetroSelect
                  style={{ width: "100%" }}
                  value={metro || ""}
                  onChange={(event, newValue) => {
                    actions.metro.selectMetro(newValue);
                    setSelectedOption([]);
                  }}
                  name="metro"
                />
              </div>
              <div
                className="w-auto d-flex flex-column"
                style={{ marginTop: "0.1rem" }}
              >
                <label style={{ marginBottom: "-0.1rem" }}>Select Status</label>
                <Select
                  sx={{ ...hubSelectStyle, ...hoverAndFocusedStyle }}
                  style={{ width: "180px" }}
                  value={status}
                  onChange={(e) => {
                    setStatusFilter(e.target.value);
                    setSelectedOption([]);
                  }}
                  defaultValue={status}
                >
                  <MenuItem value={"approved"}>Approved</MenuItem>
                  <MenuItem value={"all"}>All</MenuItem>
                </Select>
              </div>
            </div>
          )}

          {!id && (
            <div className="mb-3">
              <div
                className="d-flex"
                style={{ justifyContent: "space-between" }}
              >
                <label style={{ marginBottom: "-0.2rem" }}>Drivers</label>
                {isError && (
                  <div
                    className={`form__msg form__msg_invalid `}
                    id="error__container"
                  >
                    <TiWarningOutline
                      style={{ fontSize: "16px", marginBottom: "0.2em" }}
                    />
                    Please enter valid email
                  </div>
                )}
              </div>
              <MySelect
                placeholder={
                  isLoadingAllDrivers ? (
                    <div className="loading">
                      <span className="loading__text">Loading Drivers</span>
                      ...
                    </div>
                  ) : (
                    "Select Drivers"
                  )
                }
                options={
                  options && Array.isArray(options) && options?.length > 0
                    ? options
                    : []
                }
                isMulti={true}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                value={selectedOption}
                onChange={(val) => {
                  setSelectedOption(val);
                  setIsError(false);
                  form.setValue("emails", val);
                }}
                allowSelectAll={
                  options && Array.isArray(options) && options?.length > 0
                    ? true
                    : false
                }
                styles={messageListOptionStyles}
                components={{
                  MenuList,
                  Option,
                  ValueContainer,
                  animatedComponents,
                  MultiValue,
                  IndicatorSeparator: () => null,
                  LoadingIndicator: () => null,
                }}
                onCreateOption={handleCreate}
                noOptionsMessage={() => "No Drivers available"}
                isLoading={isLoadingAllDrivers}
                isDisabled={isLoadingAllDrivers || !metro || !options?.length > 0}
              />
            </div>
          )}

          <div>
            <InputItem
              name="subject"
              form={form}
              autoComplete="off"
              disabled={id}
            />
          </div>

          <div className="mb-5">
            <label style={{ marginBottom: "-0.2rem" }}>Message</label>
            <div>
              <ReactQuill
                theme="snow"
                name="body"
                modules={modules}
                placeholder="Enter Message here..."
                onChange={(htmlValue) => {
                  if (htmlValue === "<p><br></p>") {
                    setValue("");
                  } else {
                    setValue(htmlValue);
                    form.setValue("body", htmlValue);
                  }
                }}
                className="react-quill-container"
                value={value}
                bounds={`[data-text-editor="form-editor"]`}
                readOnly={id}
              />
            </div>
          </div>
        </div>
        <div className="modal__footer">
          <button
            className="btn"
            onClick={() => {
              actions.modal.closeEmail();
              actions.metro.selectMetro("");
            }}
          >
            Cancel
          </button>
          {!id && (
            <button
              className="btn btn_accent"
              disabled={
                selectedOption?.length > 0 &&
                  value?.length > 0 &&
                  sub?.length > 0 &&
                  !isCreating
                  ? false
                  : true
              }
              onClick={onSubmit}
            >
              Send
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default EmailModal;
