import React from 'react'
import ReactSelect from 'react-select'
import { multiSelectStyle } from './multiSelectStyle'
import { Option, ValueContainer, animatedComponents } from "../../components/shared/options/MessageOption";


function MultiSelect(props) {
  const { placeholder, value, onChange, itemData, menuPortalTarget, isOptionSelected, styles, width } = props
  return (
    <>
    <ReactSelect
      placeholder={placeholder}
      isClearable
      value={value}
      options={
        itemData && Array.isArray(itemData) && itemData?.length > 0
          ? itemData
          : []
      }
      onChange={onChange}
      isMulti={true}
      allowSelectAll={true}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      styles={styles || multiSelectStyle(width)}
      menuPortalTarget={menuPortalTarget}
      menuShouldBlockScroll={true}
      isOptionSelected={isOptionSelected}
      components={{
        Option,
        ValueContainer,
        animatedComponents,
        IndicatorSeparator: () => null,
      }}
    ></ReactSelect>
    </>
  )
}

export default MultiSelect