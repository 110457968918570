import { handleNumberwithPadding } from "../../../../constants/extras/handleNumbers";
import { scheduleDetails } from "../../../../constants/menuLists";

const TableData = (props) => {
  const {
    scData: scheduleDataForOneDay,
    metro,
    scIdx,
    metroName,
    width,
  } = props;

  let idxOfMetroInScheduleArr = scheduleDataForOneDay?.schedules
    .map((n) => n?.metro)
    .indexOf(metroName);

  return (
    <td
      className="td__layout"
      key={`${metro?._id + scIdx.toString() + metro?.name}`}
      style={{ width: width ? width : "auto" }}
    >
      {scheduleDetails.map((detail, idx) => {
        const { title, value, custom_class } = detail || {};

        let data =
          scheduleDataForOneDay?.schedules[idxOfMetroInScheduleArr]?.[value];
        return (
          <p
            className={`details__cont ${
              idx === scheduleDetails.length - 1 ? "mb-0" : ""
            } ${custom_class}`}
            key={`${title + value.toString() + custom_class + idx}`}
          >
            <span className="details__cont__name">{title}:</span>
            <span className="details__cont__data">
              {!data ? "00" : handleNumberwithPadding(data)}
            </span>
          </p>
        );
      })}
    </td>
  );
};

export default TableData;

export const ActiveAndPipeline = ({ data, title, bgClass }) => {
  return (
    <p className={`details__cont active__blck ${bgClass}`}>
      <span className="details__cont__name">{title}:</span>
      <span className="details__cont__data">
        {data ? handleNumberwithPadding(data) : "00"}
      </span>
    </p>
  );
};

export const BasicTdLayout = ({ children, className }) => {
  return <td className={`${className ? className : ""}`}>{children}</td>;
};

export const DataContainer = (props) => {
  const { type, data, className } = props;
  return (
    <span
      className={`${"driver__table__thead__tr__".concat(
        type
      )} inln_blck name__container ${className ? className : ""}`}
    >
      {data}
    </span>
  );
};
