import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import LogoWithText from "../shared/svgIcons/LogoWithText";
import { useGetConfirmEmailByHashMutation } from "../../api/invitedAdmins";
import "./style.confirmation.scss";
import { TiWarning } from "react-icons/ti";
import bg from "../../images/cover.png";
import { CircularProgress } from "@mui/material";

import { AiFillCheckCircle } from "react-icons/ai";

const Confirmation = (props) => {
  const { onStopUserQuery } = props;
  const [isConfirmError, setIsConfirmError] = useState(false);
  const [username, setUsername] = useState("");
  const { hash } = useParams();

  const [getConfirmEmailByHash] = useGetConfirmEmailByHashMutation();

  useEffect(() => {
    const handleConfirmEmailHash = async () => {
      if (typeof onStopUserQuery === "function") {
        onStopUserQuery(true);
      }
      if (hash) {
        let newUser;
        try {
          newUser = await getConfirmEmailByHash({ hash: hash });
          newUser.data &&
            setUsername(newUser && newUser.data && newUser.data.email);
          newUser.error &&
            setIsConfirmError(
              newUser.error && newUser.error.data && newUser.error.data.message
            );
          // try {
          //   window.open("bettertrucks://", "_blank");
          // } catch (e) {}
        } catch (e) {
          setIsConfirmError(e.status + " " + (e.data && e.data.message));
        }
      }
    };

    handleConfirmEmailHash();
  }, [onStopUserQuery, getConfirmEmailByHash, hash]);

  return (
    <div className="screen screen-flex screen_confirmation">
      <img src={bg} alt="" className="confirmation-bg" />

      <div className="container container_confirmation">
        <div className="confirmation-logo-wrapper">
          <LogoWithText className="confirmation-logo" />
        </div>

        <div className="confirmation">
          {!username && !isConfirmError ? (
            <React.Fragment>
              <div className="confirmation__text">please wait</div>
              <CircularProgress className="loading-progress" />
            </React.Fragment>
          ) : (
            <React.Fragment>
              {isConfirmError ? (
                <React.Fragment>
                  <div className="confirmation__icon-holder">
                    <TiWarning className="confirmation__icon confirmation__icon_error" />
                  </div>
                  <div className="confirmation__text">
                    While confirmation occurred error:
                  </div>
                  <div className="confirmation__text">{isConfirmError}</div>
                  <br />
                  <a href="bettertrucks://">back to shift</a>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className="confirmation__icon-holder">
                    <AiFillCheckCircle className="confirmation__icon" />
                  </div>
                  <div className="confirmation__text">
                    Your email {username} is confirmed
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default Confirmation;
