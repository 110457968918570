import React, { forwardRef } from "react";
import { TiWarningOutline } from "react-icons/ti";

const capitalize = (str) => str[0].toUpperCase() + str.slice(1);

export const BaseInputItem = forwardRef(
  ({ id, title, error, element, autoComplete, ...props }, ref) => {
    const InputElement = element || "input";
    return (
      <div className="form__item">
        <label className="form__label" htmlFor={id}>
          {title}
        </label>
        <InputElement
          className={`form__input ${error ? "form__input_invalid" : ""}`}
          type={"text"}
          id={id}
          {...props}
          ref={ref}
          autoComplete={autoComplete}
        />
        <ErrorMessage error={error} />
      </div>
    );
  }
);

export const InputItem = forwardRef(
  (
    {
      name,
      title = capitalize(name),
      form,
      element,
      autoComplete,
      numeric,
      onInput,
      initialValue,
      ...inputProps
    },
    ref
  ) => {
    const { errors } = form.formState;
    const registerOpts = initialValue ? { value: initialValue } : {};

     // Determine the input type based on the numeric prop
     const inputType = numeric ? "number" : "text";

    return (
      <BaseInputItem
        id={name}
        title={title}
        error={errors[name]}
        element={element}
        onInput={onInput}
        {...inputProps}
        {...form.register(name, registerOpts)}
        autoComplete={autoComplete}
        type={inputType}
      />
    );
  }
);

export const ErrorMessage = ({ error, className }) => {
  return (
    <div
      className={`form__msg form__msg_invalid ${className && className} ${
        error ? "form__msg_visible" : ""
      }`}
    >
      {error && typeof error?.message == "string" && (
        <TiWarningOutline
          className={`${error ? "form__msg_visible mr-1" : ""}`}
          style={{ fontSize: "14px", marginBottom: "0.18em" }}
        />
      )}
      {error && error.message}
    </div>
  );
};

export const Label = ({ component, title, className, htmlFor, style }) => {
  const CustomizeComponent = component || "label";
  return (
    <CustomizeComponent
      style={style || null}
      className={`form__label ${className || ""}`}
      htmlFor={htmlFor || ""}
    >
      {title}
    </CustomizeComponent>
  );
};

export const FormItem = ({ children }) => (
  <div className="form__item">{children}</div>
);
