import { maskingInfo } from "../../components/shared/helper";
import moment from "moment";
const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
const utc = moment().format().slice(19);

export function driversEarningReport(filteredDriversData) {
  const driversEarningReportListData = [];

  if (filteredDriversData && filteredDriversData.length) {
    for (let i = 0; i < filteredDriversData.length; i++) {
      let data = filteredDriversData[i];
      const { name, username, ssn, totalEarning, driver, paymentmode } = data;

      driversEarningReportListData[i] = {
        name: `${name ? name : "-"}`,
        email: `${username ? username : "-"}`,
        ssnNumber: `${ssn ? ssn : driver?.SSN ? driver?.SSN : "-"}`,
        einNumber: `${driver?.EIN ? driver?.EIN : "-"}`,
        phoneNumber: `${driver?.phone ? driver?.phone : "-"}`,
        address: `${driver?.address ? driver?.address : "-"}`,
        city: `${driver?.city ? driver?.city : "-"}`,
        state: `${driver?.state ? driver?.state : "-"}`,
        zipCode: `${driver?.zipCode ? driver?.zipCode : "-"}`,
        paymentmode: `${paymentmode === "BRANCH" ? "BRANCH" : "DWOLLA"}`,
        totalEarnings: `${totalEarning ? "$" + totalEarning.toFixed(2).toString() : "$00"
          }`
      };
    }
  }
  return driversEarningReportListData;
}
