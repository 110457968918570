import { orderBy } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import cn from "classnames";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import {
  arrayOfCaseInSensitivity,
  arrayofInnerObjCaseSensitivity,
} from "../../../constants/extras/sortingArrayDatas";
import { checkArrForMapping } from "../../../constants/validaitonSchema/validation.ts";

const THItem = ({
  prefix,
  label,
  sublabel,
  name,
  sortable,
  sortDir,
  changeSortDir,
  minWidth,
  maxWidth,
  className
}) => {
  return (
    <th
      className={`${prefix}__th ${prefix}__th_${name} ${className}`}
      style={{ minWidth: minWidth, maxWidth: maxWidth }}
    >
      {sortable ? (
        <span
          className={cn("table__th-sorter", {
            [`sort-${sortDir}`]: sortable && sortDir,
          })}
          onClick={changeSortDir}
        >
          <span>
            {label}

            {sublabel && <span className="table__th-sublabel">{sublabel}</span>}
          </span>

          {sortable && (
            <span className="table__sort-dir">
              <IoIosArrowUp className="icon icon-asc" />
              <IoIosArrowDown className="icon icon-desc" />
            </span>
          )}
        </span>
      ) : (
        <React.Fragment>
          {label}

          {sublabel && <span className="table__th-sublabel">{sublabel}</span>}
        </React.Fragment>
      )}
    </th>
  );
};

const oppositeDir = (dir) => (dir === "asc" ? "desc" : "asc");

export default function Table({
  columns,
  items,
  className,
  rowClass,
  activeItem,
  width,
}) {
  const [sortBy, setSortBy] = useState();
  const [type, setType] = useState();
  const [sortDir, setSortDir] = useState();
  const changeSortDirection = (column, type) => {
    setSortDir(sortBy === column ? oppositeDir(sortDir) : "asc");
    setSortBy(column);
    setType(type || null);
  };

  const sorted = (column, type) => {
    return {
      sortDir: sortBy === column ? sortDir : undefined,
      changeSortDir: () => changeSortDirection(column, type),
    };
  };

  const [sortedItems, setSortedItems] = useState(items || []);

  const sortingInInnerObj = useCallback(
    (item) => {
      let splitedArr = sortBy.split("-");
      if (item[splitedArr[0]] && item[splitedArr[0]][splitedArr[1]])
        return type === "number" ? item[splitedArr[0]][splitedArr[1]] : item[splitedArr[0]][splitedArr[1]].toLowerCase();
    },
    [sortBy]
  );

  useEffect(() => {
    setSortedItems(
      sortBy && sortDir
        ? orderBy(
          items,
          [
            (item) => {
              return arrayOfCaseInSensitivity.includes(sortBy)
                ? arrayofInnerObjCaseSensitivity.includes(sortBy)
                  ? sortingInInnerObj(item) // Custom logic for nested properties
                  : item && item[sortBy]?.toLowerCase() // Regular string case insensitive sorting
                : type === "number" ? Number(item[sortBy] || "") : item[sortBy]
            }
          ],
          [sortDir]
        )
        : items
    );
  }, [items, sortBy, sortDir, sortingInInnerObj]);

  return (
    <table
      className={cn(
        "table table_sortable table_thead-sticky table_striped",
        className
      )}
      style={{ width: width || "" }}
    >
      <thead>
        <tr>
          {columns?.map(
            ({ label, sublabel, name, type, sortable, minWidth, maxWidth, className }, index) =>
              !["HIDE"].includes(label) && (
                <THItem
                  key={`${label}__${index}`}
                  label={label}
                  sublabel={sublabel}
                  name={name}
                  prefix={className}
                  className={className}
                  sortable={sortable}
                  {...sorted(name, type)}
                  minWidth={minWidth}
                  maxWidth={maxWidth}
                />
              )
          )}
        </tr>
      </thead>
      <tbody>
        {checkArrForMapping(sortedItems) &&
          sortedItems?.map((item, itemIndex) => (
            <tr
              key={`${item._id}__${itemIndex}`}
              className={`${rowClass && rowClass(item)} ${
                activeItem &&
                (activeItem === item._id ? "table__tr-active" : null)
              }`}
            >
              {columns?.map(
                (column, columnIndex) =>
                  !["HIDE"].includes(column?.label) && (
                    <td
                      key={`${column.label}__${columnIndex + 1}`}
                      {...(column.className
                        ? { className: column?.className }
                        : {})}
                      style={{ minWidth: column.minWidth, maxWidth: column.maxWidth }}
                    >
                      {column.renderer
                        ? column.renderer(item, itemIndex) || "-"
                        : item[column.name] || "-"}
                    </td>
                  )
              )}
            </tr>
          ))}
      </tbody>
    </table>
  );
}
