import React, { useEffect, useState } from "react";
import { CircularProgress, Modal } from "@mui/material";
import { useSelector } from "react-redux";
import { actions } from "../../../../redux/store/store";
import {
  useCreatePaymentCalendarMutation,
  useUpdatePaymentCalendarMutation,
} from "../../../../api/paymentCalendar";
import moment from "moment";
import { sendSuccessMessage } from "../../../../constants/extras/sendSuccessMsg";
import { sendErrorMessage } from "../../../../constants/extras/errorHandlers";
import { DateCalendar } from "../../DatePicker";
import { addDays } from "date-fns";
import { useRef } from "react";
import autosizeTextArea from "../../autosiceTextArea";

function PaymentCalendarModel(props) {
  const paymentCalendar = useSelector((state) => state.modal.calendarPayment);
  const id = paymentCalendar?.id;
  const [createCalendar, { isLoading: loadData }] =
    useCreatePaymentCalendarMutation();
  const [updateCalendar, { isLoading: loadUpdateData }] =
    useUpdatePaymentCalendarMutation();


  const [title, setTitle] = useState(false);
  const [notes, setNotes] = useState();
  const textAreaRef = useRef(null);




  const [showDatePickerFilter, setShowDatePickerFilter] = useState(false);
  const [date, setDate] = useState();
  const [prevDate, setPrevDate] = useState();

  const handleSelectDate = (selection) => {
    setPrevDate(selection);
  };

  const handleCustomDate = () => {
    if (id?.executionPaymentDate)
      return
    setShowDatePickerFilter(!showDatePickerFilter);
    setPrevDate(date)
  };

  const resetCustomDate = () => {
    setDate();
    setPrevDate();
    setShowDatePickerFilter(!showDatePickerFilter);
  };

  const cancelCustomDate = () => {
    setShowDatePickerFilter(false);
    setPrevDate(date)
  };

  const okCustomDate = () => {
    setDate(prevDate);
    setShowDatePickerFilter(false);
  };

  const onSubmit = async () => {
    if (!date) {
      sendErrorMessage("Payment date field is required!");
      return;
    }

    const data = {
      schedulePaymentDate: moment(date).format("yyyy-MM-DD"),
      notes: notes || ''
    };

    const result = id
      ? await updateCalendar({ id, data })
      : await createCalendar(data);

    if (result?.data && !result?.data?.error) {
      resetData();
      sendSuccessMessage(
        id
          ? "Payment Calendar updated successfully!!"
          : "Payment Calendar created successfully!!"
      );
      actions.refetch.setIsDataRefetch(true);
      actions.modal.closeCalendarPayment();
    } else {
      sendErrorMessage(result?.data?.message)
    }
  };

  const onCancel = () => {
    resetData();
    actions.modal.closeCalendarPayment();
  };

  const resetData = () => {
    setTitle(false);
    setDate();
    setPrevDate();
  };

  useEffect(() => {
    if (id) {
      let date = id?.schedulePaymentDate?.split("Z")[0]
      setTitle(true);
      setDate(new Date(date));
      setPrevDate(new Date(date));
      setNotes(id?.notes)
    }

  }, [paymentCalendar, id]);


  useEffect(() => {
    autosizeTextArea(textAreaRef.current, notes)

  }, [textAreaRef, notes]);

  return (
    <>
      <Modal
        open
        onClose={onCancel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableEscapeKeyDown={true}
      >
        <div
          className={`modal after-loading`}
          style={{ maxWidth: "30rem" }}
        >
          <div className="modal__header">
            <h2 className="modal__title">
              {title ? "Update Payment Calendar" : "Create Payment Calendar"}
            </h2>
          </div>
          <div className="modal__body">
            <div style={{ height: showDatePickerFilter ? "40vh" : "" }}>
              <div className="mb-3">
                <label
                  className="m-0 mb-1"
                  id="bg_checker"
                  style={{ fontWeight: "normal" }}
                >
                  Payment Date
                </label>
                <DateCalendar
                  handleCalendarDate={handleCustomDate}
                  date={prevDate}
                  showDate={date}
                  showDatePicker={showDatePickerFilter}
                  onChange={handleSelectDate}
                  submitDate={() => {
                    okCustomDate();
                  }}
                  minDate={addDays(new Date(), 0)}
                  cancelDate={() => {
                    cancelCustomDate();
                  }}
                  placeholder="Select Payment Date"
                  sx={{ position: "relative" }}
                  resetDate={() => {
                    resetCustomDate();
                  }}

                />
              </div>
              <div className="mb-3">
                <label
                  className="m-0 mb-1"
                  id="bg_checker"
                  style={{ fontWeight: "normal" }}
                >
                  Notes
                </label>
                <textarea
                  className="form__input"
                  value={notes}
                  onChange={(e) => {
                    setNotes(e.target.value);
                  }}
                  placeholder="Enter Notes"
                  disabled={id?.executionPaymentDate}
                  ref={textAreaRef}
                  rows={1}
                  style={{ resize: 'none', overflow: 'hidden', height: '50px' }}
                />
              </div>
            </div>
          </div>
          <div className="modal__footer">
            <div>
              <button
                className="btn"
                onClick={onCancel}
                disabled={loadData || loadUpdateData}
              >
                Cancel
              </button>
            </div>
            <div style={{ position: 'relative' }}>
              <button
                className="btn btn_accent"
                style={{ width: "80px" }}
                onClick={() => onSubmit()}
                disabled={loadData || loadUpdateData}
                hidden={id?.executionPaymentDate}
              >
                {title ? "Update" : "Save"}
                {(loadData || loadUpdateData) && <div style={{width: '15px'}}></div> }
              </button>
              {(loadData || loadUpdateData) && (
                  <CircularProgress size={18}
                    sx={{
                      color: '#fff',
                      position: 'absolute',
                      top: '50%',
                      left: '83%',
                      marginTop: '-8px',
                      marginLeft: '-10px',
                    }} />
                )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default PaymentCalendarModel;
