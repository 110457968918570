import moment from "moment";
import { getRoute } from "../../util/time";
import { handleEstimatedShiftPay } from "../extras/handleShiftPayAndTime";
import { handleCurrency } from "../extras/handleNumbers";
import { convertToCapitalize } from "../extras/convertToCapitalize";
import { maskingInfo } from "../../components/shared/helper";
import { handlePaymentStatus } from "../extras/handlePaymentStatus";
import { statusList } from "../variables/arrays";
const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
const utc = moment().format().slice(19);

const commonData = (data, paymentType) => {
    const {
        metro,
        routeCode,
        shiftType,
        dayDeliveryStart,
        from,
        startTime,
        estimatedShiftPayFrom,
        estimatedShiftPayTo,
        price,
        bonusPrice,
        totalPrice,
        driver,
        exceptionNote,
        status,
        date,
        payeeName,
        amount,
        transactionStatus,
        description,
        accountInfo,
        note,
        isRequestedPayments,
        isWeeklyPayments,
        paymentmode
    } = data || {};

    function formatDate(date, format= 'MM-DD-YYYY'){
        return moment(date).format(format)
      }

    return paymentType === 'AdhocPayments' ? {
        'Date': formatDate(date),
        'Payee Name': payeeName || '',
        'Amount': amount ? `$${amount.toFixed(2)}` : '',
        'Transaction Status': `${transactionStatus && transactionStatus === "approved" ? "Waiting for approval" : convertToCapitalize(transactionStatus)} ${description || ''}`,
        "Method": `${paymentmode || "DWOLLA"}`,
        'AccountInfo-depositAccountNumber': maskingInfo(accountInfo.depositAccountNumber),
        'Notes': note
    } : {
        'Metro': metro || '',
        'Route Code': routeCode || '',
        'Shift Type': shiftType || '',
        'Route Date': formatDate(dayDeliveryStart),
        'Starting Delivery Station': from?.name || '',
        'Start Time': getRoute(startTime),
        'Estimated Shift Pay': handleEstimatedShiftPay(estimatedShiftPayFrom, estimatedShiftPayTo),
        'Payout': price ? `${handleCurrency(price)}` : '',
        ...(paymentType === 'Weekly Payment' && { 'Bonus Price': bonusPrice ? handleCurrency(bonusPrice) : '' }),
        ...(paymentType === 'Weekly Payment' && { 'Total Price': totalPrice ? `$${handleCurrency(totalPrice)}` : '' }),
        'Driver Name': `${driver?.firstName || ''} ${driver?.lastName || ''} `,
        'Driver Phone': driver?.phone || '',
        'Company': `${driver?.company?.companyCode ? driver?.company.companyCode : ""} ${driver?.company?.companyCode && driver?.company?.companyName ? "-" : ""} ${driver?.company ? driver?.company.companyName : ""}`,
        "Method": `${paymentmode || "DWOLLA"}`,
        'ExceptionNote': exceptionNote || '',
        'Status': `${status ? `${status === "approved" ? paymentType === "Weekly Payment" ? "Approved" : "Waiting for approval" : status} ${(statusList.includes(status) && price) ? '- ' + handlePaymentStatus(status, price, isRequestedPayments, isWeeklyPayments) : ''}` : "-"}`
    };
}

export function xlsxPaymentsData(filteredPaymentsData, paymentType) {
    const paymentsData = [];

    if (filteredPaymentsData && filteredPaymentsData.length) {
        for (let i = 0; i < filteredPaymentsData.length; i++) {
            const data = filteredPaymentsData[i];

            const allData = commonData(data, paymentType)
            paymentsData[i] = {
                ...allData,
            }
        }
    }
    paymentsData.unshift({ [paymentType === 'AdhocPayments' ? "Date" : "Metro"]: `This report based on "${timeZone}  UTC ${utc}" Timezone ` })
    return paymentsData;
}