import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { MdCancel } from "react-icons/md";
import { CircularProgress, MenuItem, Select } from "@mui/material";
import Table from "../shared/Table";
import {
  useGetInvitationCodeQuery,
  useSetInvitationCodeMutation,
} from "../../api/auth";
import { useGetAdminsQuery, useChangeRoleMutation } from "../../api/admins";
import "./style.scss";
import { changeRoleDatas } from "../../constants/menuLists";
import { convertToCapitalize } from "../../constants/extras/convertToCapitalize";
import { useGetMetrosQuery } from "../../api/metro";
import { checkArrForMapping } from "../../constants/validaitonSchema/validation.ts";
import { sendErrorMessage } from "../../constants/extras/errorHandlers";
import { sendSuccessMessage } from "../../constants/extras/sendSuccessMsg";
import {
  Option,
  ValueContainer,
  animatedComponents,
} from "../../components/shared/options/MessageOption";
import { metroListCurrentStyles } from "./metroListCurrentStyle"
import ReactSelect from "react-select";

const Current = () => {
  const currentUser = useSelector((state) => state.auth.currentUser);

  const [metros, setMetros] = useState([]);
  const [options, setOptions] = useState([]);

  const { data: admins, isFetching } = useGetAdminsQuery({
    refetchOnMountOrArgChange: true,
  });

  const { data: AllMetros, isFetching: fetchMetros } = useGetMetrosQuery(null, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (!AllMetros) return;
    setOptions(
      checkArrForMapping(AllMetros) &&
      AllMetros?.map((item, i) => {
        return {
          value: item?.name ? item?.name : "-",
          label: `${item?.name ? item?.name : ""}`,
          id: item?._id
        };
      })
    );
  }, [AllMetros]);

  const [changeRole, { isLoading }] = useChangeRoleMutation();

  const { data: invitationCode } = useGetInvitationCodeQuery({
    refetchOnMountOrArgChange: true,
  });
  const [setInvitationCode] = useSetInvitationCodeMutation();
  const [isAdminLoading, setLoading] = useState(false);

  const [role, setRole] = useState([]);

  const form = useForm();

  useEffect(() => {
    if (invitationCode) {
      form.setValue("value", invitationCode.value);
    }
  }, [invitationCode, form]);

  useEffect(() => {
    setLoading(isFetching);
    if (!admins) return;
    admins.map((admin) => {
      if (role?.length <= admins?.length) {
        setRole((oldArray) => [...oldArray, admin.role]);
      }
      return admin;
    });
  }, [metros, admins, role.length, isFetching]);

  useEffect(() => {
    if (!admins) return;
    const data = admins?.map((admin) => {
      return admin.metros;
    });
    setMetros(data);
  }, [admins])

  const onSubmit = async (data) => setInvitationCode(data);

  const handleAssignedRole = async (item, index) => {
    const isMetros = metros[index]?.length !== item?.metros?.length
    if (
      (item.role !== role[index] && role[index] !== "metro_manager") ||
      (role[index] === "metro_manager" && isMetros && metros[index]?.length)
    ) {
      const metroData = metros[index]?.map((data) => data.id)
      let response = await changeRole({
        id: item._id,
        role: role[index],
        metros: role[index] === "metro_manager" ? metroData : [],
      });
      if (response && response.data) {
        sendSuccessMessage(`${response.data.role} role assigned successfully`)
      } else {
        sendErrorMessage(`Something went wrong`)
      }
    }
    if ((role[index] === "metro_manager" && !metros[index]?.length)) {
      sendErrorMessage(`Please add Metros`)
    }
    // setMetros([]);
  };

  const columns = [
    { name: "username", label: "Username", sortable: true },
    {
      name: "role",
      label: "Role",
      sortable: true,
      className: "w-25",
      renderer: (item) => (
        <span>{convertToCapitalize(item && item.role ? item.role : "")}</span>
      ),
    },
    {
      name: "change_role",
      className: "table__td_have-btn w-25 align-center",
      renderer: (item, index) => {
        if (item && item?._id === currentUser?._id) {
          return null;
        } else {
          return (
            <React.Fragment>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Select
                  style={{ height: "40px", width: role[index] === "metro_manager" ? "300px" : "240px", fontSize: "15px" }}
                  value={(role && role[index]) || item.role}
                  onChange={(e) => {
                    let newRole = [...role];
                    newRole[index] = e.target.value;
                    setRole(newRole);
                  }}
                  defaultValue={(role && role[index]) || item.role}
                >
                  {changeRoleDatas.map((changeRoleData, idx) => {
                    return (
                      <MenuItem value={changeRoleData.value} key={`${idx}`} style={{ fontSize: "15px" }}>
                        {changeRoleData.title}
                      </MenuItem>
                    );
                  })}
                </Select>
                {role[index] === "metro_manager" && (
                  <div id="scrollContainer" >
                    <ReactSelect
                      placeholder={"Select Metro"}
                      isClearable
                      options={
                        options && Array.isArray(options) && options?.length > 0
                          ? options
                          : []
                      }
                      defaultValue={metros[index]?.map((elem) => {
                        return {
                          value: elem?.name ? elem?.name : "-",
                          label: elem?.name ? elem?.name : "",
                          id: elem._id
                        }
                      })}
                      onChange={(value) => {
                        let newMetros = [...metros];
                        const data = value?.map((data) => {
                          return {
                            value: data?.value ? data?.value : "-",
                            name: data?.label ? data?.label : "",
                            id: data.id
                          }
                        })
                        newMetros[index] = data;
                        setMetros(newMetros)
                      }}
                      isMulti={true}
                      allowSelectAll={true}
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      styles={metroListCurrentStyles}
                      menuPortalTarget={document.body}
                      menuShouldBlockScroll={true}
                      maxMenuHeight={200}
                      menuPlacement='top'
                      components={{
                        Option,
                        ValueContainer,
                        animatedComponents,
                        IndicatorSeparator: () => null,
                      }}
                    ></ReactSelect>
                  </div>
                )}
                <button
                  className="btn btn_accent w-25 ml-auto"
                  type="button"
                  onClick={() => {
                    handleAssignedRole(item, index);
                  }}
                  disabled={isLoading}
                  style={{
                    minHeight: "0rem",
                    height: "2.3rem",
                    display: "block",
                    // margin: "auto",
                    fontSize: "90%",
                    maxWidth: "7rem",
                  }}
                >
                  Save
                </button>
              </div>
            </React.Fragment>
          );
        }
      },
    },
    {
      name: "remove_rights",
      className: "table__td_have-btn align-center",
      renderer: (item, index) =>
        currentUser &&
        currentUser._id !== item._id && (
          <button
            className="btn btn_block customized-details-btn"
            onClick={() => {
              changeRole({ id: item._id, role: "user" })
              setRole((role) => role?.filter((data, i) => i !== index))
            }}
          >
            Remove Admin Rights
            <MdCancel className="btn__icon btn__icon_right btn__icon_danger" />
          </button>
        ),
    },
  ];

  return (
    <React.Fragment>
      <div className="screen-users__form-wrapper" style={{ zIndex: 0 }}>
        <div className="container container_narrow screen-users__container mainTabBackground mainTabWidth">
          <div className="screen-users__form-body">
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <div className="form__item">
                <label className="form__label">Invitation Code</label>
                <div className="form__input-group">
                  <input
                    className={`form__input ${form.formState.errors.password
                      ? "form__input_invalid"
                      : null
                      }`}
                    type="text"
                    {...form.register("value", { required: true })}
                  />
                  <button type="submit" className="btn btn_accent">
                    Save
                  </button>
                </div>
                {form.formState.errors.password && (
                  <div className="form__msg form__msg_invalid">
                    This field is required
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>

      <div
        className="container-fluid"
        style={{
          maxWidth: "91%",
        }}
      >
        <div className="card">
          {isAdminLoading || fetchMetros ? (
            <div className="user_loading">
              <CircularProgress className="loading-progress" />
            </div>
          ) : admins && admins.length ? (
            <div
              className="table-wrapper"
              style={{ height: "60vh", overflow: "auto", zIndex: "1" }}
            >
              <Table
                columns={columns}
                items={admins}
                className="table_users table_users_current"
              />
            </div>
          ) : (
            <div className="no_results">No Results</div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Current;