import React from "react";
import "./style.scss";

const HeaderTextLayout = (props) => {
  const { headerText, data, children, className, dataLength, totalLength, hideBorder,showCustome } = props;
  return (
    <div className="card-header header__helper" style={{ borderBottom: hideBorder && "none" }} >
      <div
        className={className}
        style={{
          width: "10%",
          position: "relative",
        }}
      >
        <h3
          className={`card-title header__text ${dataLength <= 0 || dataLength === "0" ? "mt-2" : ""
            }`}
        >
          {headerText}
        </h3>
        <p className="data__text">
          {
            totalLength ?
              dataLength > 0 && dataLength < 10
                ? `${showCustome ? "".concat(data) : "0".concat(data)}  ${totalLength}`
                : `${data} ${totalLength} ` :
              dataLength > 0 && dataLength < 10 ? "0".concat(data) : data
          }
        </p>
      </div>
      {children}
    </div>
  );
};

export default HeaderTextLayout;
