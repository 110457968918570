import React, { useEffect, useState, Fragment, useCallback } from "react";
import moment from "moment/moment";
import cn from "classnames";
import { orderBy } from "lodash";
import { CircularProgress } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

import { useGetMetrosQuery } from "../../../../api/metro";
import { handleNumberwithPadding } from "../../../../constants/extras/handleNumbers";
import HeaderLayout from "./HeaderLayout";
import TableData, {
  BasicTdLayout,
  ActiveAndPipeline,
  DataContainer,
} from "./TableData";
import { useGetDriversViewQuery } from "../../../../api/schedules";
import { checkArrForMapping } from "../../../../constants/validaitonSchema/validation.ts";
import { oppositeDir } from "../../../../constants/extras/sortingArrayDatas";
import { arrOfTitle } from "../../../../constants/menuLists";
import { useSelector } from "react-redux";
// import { countWeeklySchedule } from "../../../../constants/extras/schedulingDt";

const SchedulingTable = (props) => {
  const {
    isFetching,
    schedulingResponse: scr,
    arr,
    day: fromDate,
    nextDate: toDate,
  } = props || {};
  const [metro, setMetro] = useState("");

  const [schedulesDataForWeek, setSchedulesDataForWeek] = useState([]);
  const [arrayOfActiveDriverForCurrDate, setArrayOfActiveDriverForCurrDate] =
    useState([]);
  const [arrayOfDriversForOneMetro, setArrayOfDriversForOneMetro] = useState(
    []
  );
  const [metroArr, setMetroArr] = useState([]);
  const [sortBy, setSortBy] = useState(undefined);
  const [sortDir, setSortDir] = useState();

  const objForDriversQuery = {
    metro,
    fromDate,
    toDate,
  };

  const { data: metros, metroRequest } = useGetMetrosQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const currentUser = useSelector((state) => state.auth.currentUser);

  const {
    data: driversDataForSelctedMetro,
    isFetching: isDriversDataFetching,
  } = useGetDriversViewQuery(objForDriversQuery, {
    refetchOnMountOrArgChange: true,
    skip: isFetching || !metro,
  });

  const changeSortDirection = (column) => {
    setSortDir(sortBy === column ? oppositeDir(sortDir) : "asc");
    setSortBy(column);
  };

  const sorted = (column) => {
    return {
      sortDir: sortBy === column ? sortDir : undefined,
      changeSortDir: () => changeSortDirection(column),
    };
  };

  useEffect(() => {
    if (isFetching || metroRequest?.isFetching || !scr) return;

    const idxOfCurrDateinSchDataForWeek = scr.result
      ?.map((n) => n?.routeDate)
      .indexOf(moment().format("YYYY-MM-DD").concat("T00:00:00.000Z"));

    const handleSetActDriverForCurrDt = (idxOfCurrDateinSchDataForWeek) => {
      setArrayOfActiveDriverForCurrDate(
        scr?.result[idxOfCurrDateinSchDataForWeek]
      );
    };

    if (
      scr?.status === 200 &&
      checkArrForMapping(scr?.result) &&
      checkArrForMapping(metros)
    ) {
      setSchedulesDataForWeek(scr.result);
      if (currentUser?.role === "metro_manager") {
        let metro_manager_metros = JSON.parse(
          localStorage.getItem("user_metro")
        );
        const metro_manager_metro =
          currentUser &&
          metros?.filter((allmetros) => {
            return metro_manager_metros?.find((metro) => {
              return allmetros.name === metro;
            });
          });
        metro_manager_metro && setMetroArr(metro_manager_metro);
      } else {
        setMetroArr(metros);
      }

      idxOfCurrDateinSchDataForWeek > -1 &&
        handleSetActDriverForCurrDt(idxOfCurrDateinSchDataForWeek);
    } else {
      setSchedulesDataForWeek([]);
      setMetroArr([]);
    }
  }, [scr, isFetching, metros, metroRequest?.isFetching, currentUser]);

  const handleActiveDriverOfLastPossibleDt = (metroName) => {
    /** return true if last date in scheduleDataForWeek comes
     *  after today and false vice versa **/
    const isLastDtComesAfterCurrDt = moment(
      schedulesDataForWeek[schedulesDataForWeek.length - 1 || -1]?.routeDate
    ).isSameOrAfter(new Date());

    const arrayOfLastPossibleDt = isLastDtComesAfterCurrDt
      ? arrayOfActiveDriverForCurrDate
      : schedulesDataForWeek[schedulesDataForWeek.length - 1];

    const idxOfMetroForLastDayOfWeek = arrayOfLastPossibleDt.length && arrayOfLastPossibleDt?.schedules
      ?.map((n) => n?.metro)
      .indexOf(metroName);

    const data = arrayOfLastPossibleDt.length && (arrayOfLastPossibleDt?.schedules[idxOfMetroForLastDayOfWeek] || {});
      
    return { activeDrivers: data?.activeDrivers, pipelineDrivers: data?.pipelineDrivers };
  };

  const getModifiedDriversDataArrForOneMetro = useCallback(
    (dataOfDriversForWeek) => {
      if (!dataOfDriversForWeek) return;
      const arr = [];
      for (let i = 0; i < dataOfDriversForWeek.length; i++) {
        const obj = {};
        const { shifts: shiftsOfOneDriverForWeek, driver: driverDetails } =
          dataOfDriversForWeek[i] || {};
        // const count = countWeeklySchedule(shiftsOfOneDriverForWeek);

        obj.name = driverDetails?.fullName;
        obj.new = driverDetails?.totalShifts <= 3 ? "Yes" : "-";
        obj.ws = driverDetails?.totalShifts;

        const arrOfOneWeekShiftsForDriver = [];
        for (let j = 0; j < schedulesDataForWeek?.length; j++) {
          const { routeDate: routeDateInScData } =
            schedulesDataForWeek[j] || {};
          const idx = shiftsOfOneDriverForWeek
            ?.map((d) => d?.routeDate)
            .indexOf(routeDateInScData);
          arrOfOneWeekShiftsForDriver[j] =
            idx > -1 ? shiftsOfOneDriverForWeek[idx]?.totalShifts : idx;
        }
        obj.weeklyShiftsDataForOneDriver = arrOfOneWeekShiftsForDriver;

        arr.push(obj);
      }

      setArrayOfDriversForOneMetro(arr);
    },
    [schedulesDataForWeek]
  );

  useEffect(() => {
    if (!metro || isDriversDataFetching || !driversDataForSelctedMetro) return;
    getModifiedDriversDataArrForOneMetro(driversDataForSelctedMetro?.result);
  }, [
    driversDataForSelctedMetro,
    metro,
    isDriversDataFetching,
    getModifiedDriversDataArrForOneMetro,
  ]);

  useEffect(() => {
    setArrayOfDriversForOneMetro((arrayOfDriversForOneMetro) => {
      return sortBy && sortDir
        ? orderBy(
          arrayOfDriversForOneMetro,
          [(item) => item[sortBy]],
          [sortDir]
        )
        : arrayOfDriversForOneMetro;
    });
  }, [sortBy, sortDir]);

  const handleMetroWithIdx = (metroName) => {
    setArrayOfDriversForOneMetro([]);
    setSortBy();
    setSortDir();
    if (metro === metroName) return setMetro("");
    else setMetro(metroName);
  };

  return (
    <div className="table-wrapper scheduling_main_div">
      {isFetching ? (
        <div className="loading-holder">
          <CircularProgress className="loading-progress" />
        </div>
      ) : (
        <>
          <table
            className="table table_sortable table__scheduling"
            style={{ height: "2.5rem" }}
          >
            <thead className="table__scheduling__sticky__header">
              <tr className="table__tr">
                <HeaderLayout width={"15%"} className="sticky__name">
                  <p className="th__layout__dt__txt">
                    <span className="th__layout__dt__txt__day">Metro Name</span>
                  </p>
                </HeaderLayout>

                {checkArrForMapping(schedulesDataForWeek)
                  ? schedulesDataForWeek?.map((data, idx) => {
                    return (
                      <HeaderLayout
                        key={idx}
                        width={schedulesDataForWeek?.length < 5 && "15%"}
                      >
                        <p className="th__layout__dt__txt">
                          <span>
                            {moment(data?.routeDate).utc().format("dddd")}
                          </span>
                          <br />
                          <span className="th__layout__dt__txt__day">
                            {moment(data?.routeDate).utc().format("MM/DD/yy")}
                          </span>
                        </p>
                      </HeaderLayout>
                    );
                  })
                  : arr?.map((ar, idx) => {
                    return (
                      <HeaderLayout key={idx}>
                        <p className="th__layout__dt__txt">
                          <span className="th__layout__dt__txt__day">
                            {ar.day}
                          </span>
                          <br />
                          <span>{ar.date}</span>
                        </p>
                      </HeaderLayout>
                    );
                  })}
              </tr>
            </thead>

            {checkArrForMapping(schedulesDataForWeek) && (
              <tbody>
                {metroArr?.map((metroFromApi, metroIdx) => {
                  const { name: metroName } = metroFromApi || {};
                  const { activeDrivers, pipelineDrivers } =
                    handleActiveDriverOfLastPossibleDt(metroName) || {};
                  const isMetroActive = metro === metroName;
                  const isDataFetching = isMetroActive && isDriversDataFetching;
                  const isDriverData = checkArrForMapping(
                    arrayOfDriversForOneMetro
                  );

                  return (
                    <Fragment key={`${metro?.name + metroIdx?.toString()}`}>
                      <tr>
                        <BasicTdLayout className="sticky__name">
                          <p className="th__layout__dt__txt metro__name__blck">
                            <span
                              className={`th__layout__dt__txt metro__name__blck__txt w-100 ${isMetroActive && isDriverData && "text-primary"
                                }`}
                              onClick={() => {
                                handleMetroWithIdx(metroName, metroIdx);
                              }}
                            >
                              {metroName}
                              {isDataFetching && (
                                <span>
                                  <CircularProgress size={14} />
                                </span>
                              )}
                              <button
                                className="arrow___btn"
                                disabled={isDataFetching}
                              >
                                <KeyboardArrowDownIcon
                                  className={`${isMetroActive &&
                                    !isDriversDataFetching &&
                                    checkArrForMapping(
                                      arrayOfDriversForOneMetro
                                    )
                                    ? "icon__up"
                                    : "icon__down"
                                    }`}
                                />
                              </button>
                            </span>
                          </p>
                          <ActiveAndPipeline
                            data={activeDrivers}
                            title="Active"
                            bgClass="bg__new"
                          />
                          <ActiveAndPipeline
                            data={pipelineDrivers}
                            title="Pipeline"
                            bgClass="bg__pipeline"
                          />
                        </BasicTdLayout>
                        {schedulesDataForWeek?.map((scData, scIdx) => {
                          return (
                            <Fragment
                              key={`${metro?._id + metro?.name + scIdx.toString()
                                }`}
                            >
                              <TableData
                                scData={scData}
                                metro={metro}
                                scIdx={scIdx}
                                metroName={metroName}
                                width={
                                  schedulesDataForWeek?.length < 5 && "15%"
                                }
                              />
                            </Fragment>
                          );
                        })}
                      </tr>
                      {!isDataFetching &&
                        isMetroActive &&
                        arrayOfDriversForOneMetro?.map((data, idxOfArr) => {
                          const {
                            weeklyShiftsDataForOneDriver: wsData,
                            name,
                            ws,
                            new: isDriverNew,
                          } = data || {};
                          return (
                            <Fragment key={idxOfArr.toString()}>
                              {idxOfArr === 0 && (
                                <tr className="driver__table__thead">
                                  <BasicTdLayout className="sticky__name data__container">
                                    <p className="driver__table__thead__tr font-weight-bold">
                                      <span className="driver__table__thead__tr__name name__container inln_blck">
                                        {"Name"}
                                      </span>
                                      {arrOfTitle.map((column, colIdx) => {
                                        const { label, name, width } =
                                          column || {};
                                        const sDir = sorted(name)?.sortDir;
                                        const sCd = sorted(name)?.changeSortDir;
                                        return (
                                          <span
                                            key={`${colIdx + label}`}
                                            className={cn("table__th-sorter", {
                                              [`sort-${sDir}`]: sDir && true,
                                            })}
                                            onClick={sCd}
                                            style={{
                                              width: width,
                                              padding: "2px 5px 2px 0",
                                            }}
                                          >
                                            <span>{label}</span>
                                            <span className="table__sort-dir mt-1 pr-1">
                                              <IoIosArrowUp className="icon icon-asc" />
                                              <IoIosArrowDown className="icon icon-desc" />
                                            </span>
                                          </span>
                                        );
                                      })}
                                    </p>
                                  </BasicTdLayout>
                                </tr>
                              )}
                              <tr className="driver__table__thead">
                                <BasicTdLayout className="sticky__name">
                                  <p className="driver__table__thead__tr">
                                    <DataContainer type="name" data={name} />
                                    <DataContainer
                                      className="pl-1"
                                      type="isNew"
                                      data={isDriverNew}
                                    />
                                    <DataContainer
                                      className="text-center"
                                      type="scheduled"
                                      data={handleNumberwithPadding(ws)}
                                    />
                                  </p>
                                </BasicTdLayout>

                                {wsData?.map((wsDForOneDay, idxOfWsData) => {
                                  const wshift =
                                    handleNumberwithPadding(wsDForOneDay);
                                  return wsDForOneDay > -1 ? (
                                    <BasicTdLayout key={idxOfWsData.toString()}>
                                      <p
                                        className={`details__cont table__data bg__shiftCount`}
                                      >
                                        {wshift}
                                      </p>
                                    </BasicTdLayout>
                                  ) : (
                                    <BasicTdLayout
                                      key={idxOfWsData.toString()}
                                    />
                                  );
                                })}
                              </tr>
                            </Fragment>
                          );
                        })}
                    </Fragment>
                  );
                })}
              </tbody>
            )}
          </table>
          {Array.isArray(schedulesDataForWeek) &&
            schedulesDataForWeek.length <= 0 && (
              <div className="no_results"> No Results</div>
            )}
        </>
      )}
    </div>
  );
};

export default SchedulingTable;
