import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuthInterceptor } from "./util";

export const driverCoachingApi = createApi({
  reducerPath: "driverCoachingApi",
  baseQuery: baseQueryWithAuthInterceptor({ baseUrl: "/api/" }),
  tagTypes: ["driverCoaching"],
  endpoints: (builder) => ({
    getCoachings: builder.query({
      query: () => {
        return {
          url: "/get/coachings",
          method: "GET",
        };
      },
      providesTags: ["driverCoaching"],
    }),
    getDebugeCoaching: builder.query({
      query: (id) => {
        return {
          url: `coaching/${id._id}/debugCoaching`,
          method: "GET",
        };
      },
      providesTags: ["driverCoaching"],
    }),
    createCoaching: builder.mutation({
      query: (body) => ({
        url: "coaching",
        method: "POST",
        body,
      }),
      invalidatesTags: (result, error, arg) => !error && ["driverCoaching"],
    }),
    uploadCoaching: builder.mutation({
      query: (body) => ({
        url: `coaching/${body?.id}/uploadCoaching`,
        method: "POST",
        body: uploadCoachingData(body?.upload),
      }),
    }),
    updateCoaching: builder.mutation({
      query: ({ params, id }) => ({
        url: `coaching/${id?._id}`,
        method: "PUT",
        body: params,
      }),
      invalidatesTags: (result, error, arg) => !error && ["driverCoaching"],
    }),

  }),
});

export const {
  useGetCoachingsQuery,
  useGetDebugeCoachingQuery,
  useCreateCoachingMutation,
  useUploadCoachingMutation,
  useUpdateCoachingMutation
} = driverCoachingApi

const uploadCoachingData = (params) => {
  const formData = new FormData();
  formData.append("video", params?.video ? params?.video : "");
  formData.append("document", params?.document ? params?.document : "");
  return formData
}
