export const metroOptionsStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#4195F5" : "white",
    "&:hover": {
      background: "#4195F5",
      color: "#FFFFFF",
      transition: "all 0.9s",
    },
  }),
  control: (styles) => ({
    ...styles,
    border: "2px solid #dadada",
    borderRadius: "0.375rem",
    padding: "3px 0",
    boxShadow: "none",
    "&:hover": {
      borderColor: "#a0a293",
    },
  }),
  menu: (base) => ({
    ...base,
    borderRadius: 5,
    marginTop: "3px",
  }),
  menuList: (base) => ({
    ...base,
    height: "15vh",
    fontSize: "16px",
    fontWeight: "normal",
    color: "#121212",
  }),
  placeholder: (base) => ({
    ...base,
    color: "#000",
    fontSize: "1rem",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";
    return { ...provided, transition, opacity };
  },
};
