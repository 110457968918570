export const metroListCurrentStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#4195F5" : "white",
    paddingTop: "3px",
    paddingBottom: "0px",
    "&:hover": {
      background: "#4195F5",
      color: "#FFFFFF",
      transition: "all 0.8s",
    },
  }),
  control: (styles) => ({
    ...styles,
    borderRadius: "0.375rem",
    padding: "3px 0",
    boxShadow: "none",
    "&:hover": {
      borderColor: "#a0a293",
    },

    width: "15rem",
    height: "2.5rem",
    margin: "0 10px",
    "@media only screen and (min-width: 1200px) and (max-width: 1400px)": {
      height: "3.7rem",
    },
    "@media only screen and (max-width: 1200px)": {
      height: "4rem",
      width: "18rem",
    },
    overflowY: "auto",
    padding: 0
  }),
  menu: (styles) => ({
    ...styles,
    // overflow: "hidden",
    borderRadius: "0.50rem",
    width: "15rem",
    // top: "85%",
    // right:"4%",
    // height: "auto",
    paddingTop: "10px",
    paddingBottom: "10px",
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.24)",
  }),
  multiValue: (styles) => ({
    ...styles,
    maxWidth: "120px"
  }),
};
