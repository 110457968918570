import React, { useCallback, useEffect, useState } from "react";
import { CircularProgress, Modal } from "@mui/material";
import Select from "react-select";
import { actions } from "../../../redux/store/store";
import { checkForError } from "../../../constants/extras/errorHandlers";
import { useUpdateDriverMutation } from "../../../api/drivers";
import { sendSuccessMessage } from "../../../constants/extras/sendSuccessMsg";
import { states } from "../../../constants/variables/states";
import { metroOptionsStyles } from "./metros/metroSelect";
import { DateCalendar } from "../DatePicker";
import moment from "moment";
import { containsEmptyString } from "../helper";


const InsurancePolicyModal = (props) => {
    const title = props?.id?.title
    const driverId = props && props?.id?.id && props?.id?.id;
    const insurancePolicyNo = props && props?.id?.insurancePolicyNumber;
    const [updateDriverMetro, { isLoading: isUpdatingDriver }] =
        useUpdateDriverMutation();
    const [insurancePolicyNumber, setInsurancePolicyNumber] = useState(insurancePolicyNo || "");
    const [options, setOptions] = useState([]);
    const [selectedStateOption, setSelectedStateOption] = useState(props?.id?.insuranceState ? {
        value: props?.id?.insuranceState || '',
        label: props?.id?.insuranceState || ''
    } : null)
    const IDE = props?.id?.insuranceDateExp ? new Date(props?.id?.insuranceDateExp) : '';
    const [insuranceDateExp, setInsuranceDateExp] = useState(IDE)
    const [previnsuranceDateExp, setprevInsuranceDateExp] = useState(insuranceDateExp || '')
    const [showInsuranceDateExp, setShowInsuranceDateExp] = useState(false);

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true)

    const onDriverLicenseSubmit = async () => {
        let params = {
            _id: driverId,
            insuranceState: selectedStateOption.value,
            insuranceDateExp: moment(insuranceDateExp).format('MM/DD/YYYY'),
            insurancePolicyNumber: insurancePolicyNumber
        }

        if(props?.id?.insuranceImage && props?.id?.onboardingInsuranceFillStatus !== 'completed'){
            params.onboardingInsuranceFillStatus = 'completed'
        }

        let result = await updateDriverMetro(params);
        if (!result?.error) {
            sendSuccessMessage(`${title} updated successfully`);
            actions.refetch.setIsDataRefetch(true);
        } else {
            checkForError(result);
        }
        actions.modal.closeInsurancePolicy();
    }

    const onCancel = useCallback(() => {
        actions.modal.closeInsurancePolicy();
    }, []);

    useEffect(() => {
        const data = states?.length > 0 &&
            states.map(function (item) {
                return {
                    value: `${item?.state ? item?.state : ""}`,
                    label: `${item?.title ? item?.title : ""}`,
                }
            })
        setOptions(data)
    }, [])
    
    useEffect(() => {
        if (
            (insurancePolicyNo === insurancePolicyNumber &&
            props?.id?.insuranceState === selectedStateOption?.value &&
            moment(insuranceDateExp).format('MM/DD/YYYY') === moment(IDE).format('MM/DD/YYYY')) || 
            containsEmptyString([insurancePolicyNumber, selectedStateOption?.value || '', insuranceDateExp] ||
            selectedStateOption === null
            )
        ) {
            setIsDisabled(true)
            return
        }
        return setIsDisabled(false);
    }, [insurancePolicyNumber, selectedStateOption, insuranceDateExp])


    function changeDate(e){
        let date = moment(previnsuranceDateExp).date();
        let new_date = moment(e).set('D', date)
        const today = moment()
        const diff =  today.diff(new_date, 'days');
        if(diff >= 0){
            new_date = moment()
        }
        setprevInsuranceDateExp(new_date.toDate())
    }

    return (
        <Modal
            open
            onClose={onCancel}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className={`modal after-loading`} style={{ maxWidth: "35.875rem" }}>
                <div className="modal__header">
                    <h2 className="modal__title">{`${title}`}</h2>
                </div>
                <div className="modal__body">
                    <div className="driver_license" style={{ height: showInsuranceDateExp ? "22vh" : "auto" }}>
                        <span className="fw-600">Date Expiration: </span>
                        <DateCalendar
                            handleCalendarDate={() => {
                                if(showInsuranceDateExp){
                                    setprevInsuranceDateExp(insuranceDateExp)
                                }
                                setShowInsuranceDateExp(!showInsuranceDateExp)
                            }}
                            date={previnsuranceDateExp}
                            showDate={previnsuranceDateExp}
                            showDatePicker={showInsuranceDateExp}
                            minDate={moment().toDate()}
                            onChange={(e) => setprevInsuranceDateExp(e)}
                            onShownDateChange={(e) => changeDate(e)}
                            submitDate={() => {
                                setInsuranceDateExp(previnsuranceDateExp)
                                setShowInsuranceDateExp(false)
                            }}
                            cancelDate={() => {
                                setprevInsuranceDateExp(insuranceDateExp)
                                setShowInsuranceDateExp(false)
                            }}
                            placeholder="Insurance Date Expiration"
                            sx={{ position: "relative" }}
                            resetDate={() => {
                                setInsuranceDateExp(IDE)
                                setprevInsuranceDateExp(IDE)
                                setShowInsuranceDateExp(false)
                            }}
                        />
                    </div>
                    <div className="driver_license">
                        <span className="fw-600">Policy Number: </span>
                        <input
                            className={`form__input `}
                            type={"text"}
                            id={'insurancePolicyNumber'}
                            value={insurancePolicyNumber}
                            onChange={(e) => setInsurancePolicyNumber(e?.target?.value)}
                            autoComplete={false}
                            placeholder="Policy Number"
                        />
                    </div>
                    <div className="driver_license" style={{ height: isMenuOpen ? "20vh" : "auto" }}>
                        <span className="fw-600">State: </span>
                        <Select
                            className="color-black"
                            onChange={(items) => { setSelectedStateOption(items) }}
                            value={selectedStateOption}
                            styles={metroOptionsStyles}
                            closeMenuOnSelect={true}
                            // isClearable={true}
                            components={{
                                IndicatorSeparator: () => null,
                            }}
                            onMenuClose={() => setIsMenuOpen(false)}
                            onMenuOpen={() => setIsMenuOpen(true)}
                            menuIsOpen={isMenuOpen}
                            placeholder={`Select State`}
                            options={options}
                        />
                    </div>
                </div>
                <div className="modal__footer">
                    <button className="btn" onClick={() => actions.modal.closeInsurancePolicy()}>
                        Cancel
                    </button>
                    <div style={{ position: 'relative' }}>
                        <button
                            className="btn btn_accent"
                            disabled={isUpdatingDriver || isDisabled}
                            onClick={onDriverLicenseSubmit}
                        >
                            OK
                        </button>
                        {isUpdatingDriver && (
                            <CircularProgress size={18}
                                sx={{
                                    color: '#fff',
                                    position: 'absolute',
                                    top: '50%',
                                    left: '83%',
                                    marginTop: '-8px',
                                    marginLeft: '-10px',
                                }} />
                        )}
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default InsurancePolicyModal;
