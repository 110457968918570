import { MenuItem, Select } from "@mui/material";
import { cancleShiftScoreReason } from "../../../../constants/menuLists";

const RejectShift = (props) => {
  return (
    <div className="d-flex flex-column w-100">
      <Select
        value={props.reasonForScore}
        onChange={(e) => {
          props.setReasonForScore(e.target.value);
        }}
        defaultValue={props.reasonForScore}
        className="mb-3"
      >
        {cancleShiftScoreReason.map((reasonForScore, idx) => {
          return (
            <MenuItem value={reasonForScore.value} key={`${idx}`}>
              {reasonForScore.title}
            </MenuItem>
          );
        })}
      </Select>
      <div>
        {(props.isRejectingShift || props.isCancellingShift) && (
          <label htmlFor="Reason" className="m-0 pl-1">
            Reason
          </label>
        )}
        <textarea
          value={props.payExceptionNote}
          onChange={(e) => props.setPayExceptionNote(e)}
          name="pay-exception-note"
          style={{
            resize: "none",
            overflow: "auto",
            width: "100%",
            borderRadius: "10px",
            padding: "8px 15px",
          }}
          rows="4"
        />
      </div>
    </div>
  );
};

export default RejectShift;
