import moment from "moment";
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { MdPlace } from "react-icons/md";
import { CircularProgress } from "@mui/material";
import { useLocation } from "react-router-dom";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CancelIcon from "@mui/icons-material/Cancel";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import BackspaceIcon from "@mui/icons-material/Backspace";

import Table from "../../../components/shared/Table";
import { actions } from "../../../redux/store/store";
import "../../../styles/_data.scss";
import "./style.scss";
import { handleBackgroundStatusesBadge, handleStatusesBadge } from "../../../constants/extras/statusesBadge";
import HeaderTextLayout from "../../../components/shared/HeaderTextLayout";
import {
    convertToCapitalize,
    removeDash,
} from "../../../constants/extras/convertToCapitalize";
import { driverReportStatus } from "../../../constants/menuLists";
import CsvExport from "../../../components/shared/CSVExportDriversEarningReport/CsvLink";
import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";
import { getDriversReportList } from "../../../api/driverReport";
import { driverReportData, headers, header, shiftStatusHeader, checkerHeader } from "../../../constants/csv/csvDriverReportData";
import MultiSelect from "../../../constants/multiSelectDropdown";
import { useGetMetrosQuery } from "../../../api/metro";
import { states } from "../../../constants/variables/states"
import { Label } from "../../../components/shared/Form";
import { PAGINATION } from "../../../constants/variables";

const { COMMON__LIMIT } = PAGINATION;

export default function DriverReport() {
    // const metro = useSelector((state) => state.metro.value);
    const isRefetch = useSelector((state) => state.refetch.value);
    const location = useLocation();
    const { pathname } = location;
    const wrapperRef = useRef(null);

    const { data: metros, isFetching } = useGetMetrosQuery(null, {
        refetchOnMountOrArgChange: true,
    });

    const [hasMore, setHasMore] = useState(true);
    const [activeItem, setActiveItem] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(2);
    const [limit] = useState(COMMON__LIMIT);
    const [loadingCsv, setLoading] = useState(false);
    const [disable, setDisable] = useState(false);
    const [firstDriver, setFirstDriver] = useState(false);
    const [shiftStatus, setShiftStatus] = useState(false)
    const [backgroundCheck, setBackgroundCheck] = useState(false)
    const [isFirstDriver, setIsFirstDriver] = useState(false)
    const [isShiftStatus, setIsShiftStatus] = useState(false)
    const [isBackgroundCheck, setIsBackgroundCheck] = useState(false)

    const [filteredDrivers, setFilteredDrivers] = useState([]);
    const [selectState, setSelectState] = useState([])
    const [metro, setMetro] = useState([])
    const [statusFilter, setStatusFilter] = useState([])

    const [showDateRangePicker, setShowDateRangePicker] = useState(false);
    const [fromDate, setFromDate] = useState("");
    const [state, setState] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: "selection",
        },
    ]);
    const [prevDate, setPrevDate] = useState();
    const [toDate, setToDate] = useState("");

    const [fromShiftRange, setFromShiftRange] = useState("");
    const [toShiftRange, setToShiftRange] = useState("");
    const [fromScoreRange, setFromScoreRange] = useState("")
    const [toScoreRange, setToScoreRange] = useState("")

    useEffect(() => {
        actions.metro.selectMetro("");
        actions.refetch.setIsDataRefetch(true);
    }, [pathname]);

    const metroData = metro.map(item => item.value)
    const status = statusFilter.map(item => item.value)
    const stateData = selectState.map(item => item.value)

    useEffect(() => {
        if (isRefetch === false) return;
        setIsLoading(true);
        setHasMore(true);
        setFilteredDrivers([]);
        setPage(1);
        (async () => {
            setDisable(true);
            const data = await getDriversReportList(
                1,
                limit,
                firstDriver,
                shiftStatus,
                fromShiftRange,
                toShiftRange,
                fromScoreRange,
                toScoreRange,
                status,
                metroData ? metroData : "",
                stateData,
                fromDate,
                toDate,
                backgroundCheck
            );
            setFilteredDrivers(data);
            setDisable(false);
            if (data && data?.length < limit) setHasMore(false);
            setPage(2);
        })();
        actions.refetch.setIsDataRefetch(false);
        setIsLoading(false);
    }, [isRefetch]);

    const fetchMoreDrivers = async () => {
        setDisable(true);
        const driverData = await getDriversReportList(
            page,
            limit,
            firstDriver,
            shiftStatus,
            fromShiftRange,
            toShiftRange,
            fromScoreRange,
            toScoreRange,
            status,
            metroData ? metroData : "",
            stateData,
            fromDate,
            toDate,
            backgroundCheck
        );
        setDisable(false);
        setFilteredDrivers([...filteredDrivers, ...driverData]);
        if (driverData?.length === 0 || driverData?.length < limit)
            setHasMore(false);
        setPage(page + 1);
    };

    const handleApply = async () => {
        setFirstDriver(isFirstDriver)
        setShiftStatus(isShiftStatus)
        setBackgroundCheck(isBackgroundCheck)
        actions.refetch.setIsDataRefetch(true);

    }

    const handleReset = async () => {
        setFilteredDrivers([]);
        setMetro([])
        setFromScoreRange("")
        setToScoreRange("")
        setFromShiftRange("")
        setToShiftRange("")
        setIsFirstDriver(false);
        setIsShiftStatus(false);
        setIsBackgroundCheck(false)
        setBackgroundCheck(false)
        setFirstDriver(false)
        setShiftStatus(false)
        setFromDate("");
        setToDate("");
        setSelectState([])
        setStatusFilter([])
        actions.refetch.setIsDataRefetch(true);
    }

    const columns = [
        {
            name: "firstName",
            label: "Name",
            sortable: true,
            className: "w-10",
            renderer: (item) =>
                `${item.firstName ? item.firstName : ""} ${item.lastName ? item.lastName : ""
                }`,
        },
        {
            name: "username", label: "Username", sortable: true,
            className: "w-10",
            maxWidth:"500px",
            renderer: (item) => {
                return (
                    item.username ?
                        firstDriver ?
                            <><span className="dot" style={{ background: item.cancelled ? "red" : "green" }}></span><span>{item.username}</span></>
                            : item.username
                        : ""
                );
            },
        },
        {
            name: "phone",
            label: "Phone",
            sortable: true,
            className: "w-10"
        },
        { name: "metro", label: "Metro", sortable: true, className: "w-10" },
        {
            name: "orderCount",
            label: "Shifts",
            className: "table__td_centered table__td_min-horiz-padding pt-2 pb-2 ",
            sortable: true,
        },
        {
            name: "score",
            label: "Score",
            className: "table__td_centered table__td_min-horiz-padding",
            sortable: true,
            renderer: (item) =>
                item?.score ? <span className="text-danger">{item?.score}</span> : "",
        },
        {
            name: "status",
            label: "Status",
            sortable: true,
            className: "table__td_centered table__td_min-horiz-padding",
            renderer: (item) => {
                return (
                    <span
                        className="textStyleForStatus"
                        style={handleStatusesBadge(
                            item?.isBlocked ? "blocked" : item?.status
                        )}
                    >
                        {convertToCapitalize(
                            removeDash(item?.isBlocked ? "blocked" : item?.status)
                        )}
                    </span>
                );
            },
        },
        backgroundCheck ?
            {
                name: "candidateId",
                label: "Checker Id",
                sortable: true,
                maxWidth: "220px",
                className: "table__td_centered table__td_min-horiz-padding w-11",
            } : { label: "HIDE" },
        backgroundCheck ?
            {
                name: "checkrBackgroundStatus",
                label: "Checker Status",
                sortable: true,
                maxWidth: "220px",
                className: "table__td_centered table__td_min-horiz-padding w-5",
                renderer: (item) => {
                    return (
                        item.checkrBackgroundStatus && <span
                            className="textStyleForStatus"
                            style={handleBackgroundStatusesBadge(item.checkrBackgroundStatus)}
                        >
                            {item.checkrBackgroundStatus}
                        </span>
                    );
                },
            } : { label: "HIDE" },
        shiftStatus ?
            {
                name: "shiftStatus",
                label: "Shift Status",
                sortable: true,
                maxWidth: "220px",
                className: "table__td_centered table__td_min-horiz-padding w-0",
                renderer: (item) => {
                    return (
                        <>
                            <div className="d-flex" style={{ gap: "10px", flexWrap: "wrap" }}>
                                {item?.rejectShiftCount > 0 && <span style={handleStatusesBadge("rejected")}> rejected : {item?.rejectShiftCount}</span>}
                                {item?.assigned && <span style={handleStatusesBadge("assigned")}> assigned : {item?.assigned}</span>}
                                {item?.delivery_started &&
                                    <span style={handleStatusesBadge("delivery_started")}> D.Started : {item?.delivery_started}</span>}
                                {item?.complete && <span style={handleStatusesBadge("complete")}> complete : {item?.complete}</span>}
                                {item?.waiting_for_approval &&
                                    <span style={handleStatusesBadge("approved")}> approval : {item?.waiting_for_approval}</span>}
                                {item?.paid && <span style={handleStatusesBadge("paid")}> paid : {item?.paid}</span>}
                                {item?.cancelled && <span style={handleStatusesBadge("cancelled")}> cancelled : {item?.cancelled}</span>}
                            </div>
                        </>
                    )
                },
            } : { label: "HIDE" },
    ];

    const handleCustomRange = () => {
        setShowDateRangePicker(!showDateRangePicker);
        if (!fromDate.length) {
            setState([
                {
                    startDate: new Date(),
                    endDate: new Date(),
                    key: "selection",
                },
            ]);
        } else {
            setState(prevDate);
        }
    }

    const handleTimeRangeSelection = async () => {
        const firstSelectionOfStartDate = moment(state[0].startDate).format(
            "yyyy-MM-DD"
        );

        const firstSelectionOfToDate = moment(state[0].endDate).format(
            "yyyy-MM-DD"
        );
        setPrevDate(state);
        setFromDate(firstSelectionOfStartDate);
        setToDate(firstSelectionOfToDate);
        setShowDateRangePicker(false);
    }

    const csvHeaders = () => {
        if (!shiftStatus && !backgroundCheck) return header
        if (shiftStatus && backgroundCheck) return headers
        if (shiftStatus && !backgroundCheck) return shiftStatusHeader
        if (!shiftStatus && backgroundCheck) return checkerHeader
    }

    const downloadDriversData = async () => {

        const data = await getDriversReportList(
            undefined,
            undefined,
            firstDriver,
            shiftStatus,
            fromShiftRange,
            toShiftRange,
            fromScoreRange,
            toScoreRange,
            status,
            metroData ? metroData : "",
            stateData,
            fromDate,
            toDate,
            backgroundCheck
        );

        if (data && Array.isArray(data) && data?.length > 0) {
            return data;
        }
        return;
    };

    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="card">
                        <HeaderTextLayout
                            headerText="Driver Report"
                            className="col-lg-2 col-md-2 main__driver"
                            data={
                                filteredDrivers && filteredDrivers.length > 0
                                    ? `${filteredDrivers.length} Drivers Found`
                                    : ""
                            }
                            dataLength={
                                Array.isArray(filteredDrivers) ? filteredDrivers.length : "0"
                            }
                        >
                            <div className="col-lg-10 col-md-10 col-sm-12 filter">
                                <div className="filter__inner p-0 justify-content-end" style={{ gap: "0.5rem" }}>
                                    <div className="toggle_container mr-2">
                                        <label
                                            style={{ fontWeight: "normal", }}
                                            className="m-0"
                                        >
                                            Background Checker :
                                        </label>
                                        <div className="toggle-switch ml-4">
                                            <input
                                                type="checkbox"
                                                className="checkbox"
                                                name="onn"
                                                id="checkId"
                                                checked={isBackgroundCheck}
                                                onChange={(e) => {
                                                    setIsBackgroundCheck(e.target.checked);
                                                }}
                                                disabled={disable}
                                            />
                                            <label className="label" htmlFor="checkId">
                                                <span className="switch_on" />
                                                <span className="switch_off" />
                                            </label>
                                        </div>
                                    </div>
                                    <div className="toggle_container mr-2">
                                        <label
                                            style={{ fontWeight: "normal" }}
                                            className="m-0 "
                                        >
                                            Shift Count :
                                        </label>
                                        <div className="toggle-switch">
                                            <input
                                                type="checkbox"
                                                className="checkbox"
                                                name="onn"
                                                id="shift"
                                                checked={isShiftStatus}
                                                onChange={(e) => {
                                                    setIsShiftStatus(e.target.checked);
                                                }}
                                            />
                                            <label className="label" htmlFor="shift">
                                                <span className="switch_on" />
                                                <span className="switch_off" />
                                            </label>
                                        </div>
                                    </div>
                                    <div className="toggle_container mr-2">
                                        <label
                                            style={{ fontWeight: "normal" }}
                                            className="m-0 "
                                        >
                                            First Drivers :
                                        </label>
                                        <div className="toggle-switch">
                                            <input
                                                type="checkbox"
                                                className="checkbox"
                                                name="onn"
                                                id="on"
                                                checked={isFirstDriver}
                                                onChange={(e) => {
                                                    setIsFirstDriver(e.target.checked);
                                                    setIsShiftStatus(e.target.checked);
                                                }}
                                            />
                                            <label className="label" htmlFor="on">
                                                <span className="switch_on" />
                                                <span className="switch_off" />
                                            </label>
                                        </div>
                                    </div>
                                    <div className="searchbar__container">
                                        <button className="btn mr-3 apply_btn"
                                            style={{
                                                background: "white",
                                                borderColor: "e6e6e6",
                                                width: "100px",
                                                fontSize: "1.1rem"
                                            }}
                                            onClick={handleApply}
                                            disabled={disable}
                                        >
                                            Apply
                                        </button>
                                        <div className="main_exportcvs ">
                                            <CsvExport
                                                asyncExportMethod={async () => {
                                                    setLoading(true);
                                                    const data = await downloadDriversData();
                                                    let obj = {
                                                        headers: csvHeaders(),
                                                        data: driverReportData(data),
                                                        filename: `DriverReport_Information.csv`,
                                                    };
                                                    setLoading(false);
                                                    return obj;
                                                }}
                                                disable={
                                                    loadingCsv || filteredDrivers?.length === 0
                                                        ? true
                                                        : false
                                                }
                                                loadingCsv={loadingCsv}
                                            />
                                        </div>
                                        <button className="btn ml-3 reset_btn"
                                            style={{
                                                background: "white",
                                                borderColor: "e6e6e6",
                                                width: "100px",
                                                fontSize: "1.1rem"
                                            }}
                                            onClick={handleReset}
                                            disabled={disable}
                                        >
                                            Reset
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </HeaderTextLayout>
                        <div className="filter_side">
                            <div className="container_extra-width filter__container">
                                <div className="filter__inner d-flex justify-content-end" style={{ gap: "0.5rem" }}>
                                    <div onClick={() =>  console.log("hhhd")}>
                                        <label
                                            style={{ fontWeight: "normal" }}
                                            className="m-0 "
                                        >
                                            <MdPlace /> Metro: &nbsp;
                                        </label>
                                        <MultiSelect
                                            placeholder="Select Metro"
                                            value={metro}
                                            itemData={metros?.map((item, i) => {
                                                return {
                                                    value: item?.name ? item?.name : "-",
                                                    label: `${item?.name ? item?.name : ""}`,
                                                };
                                            })}
                                            onChange={(value) => {
                                                setMetro(value)
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <label
                                            style={{ fontWeight: "normal" }}
                                            className="m-0 ml-3 "
                                        >
                                            State:
                                        </label>
                                        <MultiSelect
                                            value={selectState}
                                            placeholder="Select State"
                                            itemData={states?.map((item, i) => {
                                                return {
                                                    value: item?.state ? item?.state : "-",
                                                    label: `${item?.title ? item?.title : ""}`,
                                                };
                                            })}
                                            onChange={(value) => {
                                                setSelectState(value)
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <label
                                            style={{ fontWeight: "normal" }}
                                            className=" m-0 ml-3 "
                                        >
                                            Status:
                                        </label>
                                        <MultiSelect
                                            placeholder="Select Status"
                                            itemData={driverReportStatus?.map((item, i) => {
                                                return {
                                                    value: item?.value ? item?.value : "-",
                                                    label: `${item?.title ? item?.title : ""}`,
                                                };
                                            })}
                                            value={statusFilter}
                                            onChange={(value) => {
                                                setStatusFilter(value);
                                            }}
                                        />
                                    </div>
                                    <div ref={wrapperRef} className="inline mr-3 ml-2">
                                        <div onClick={handleCustomRange} className="form__input" style={{ height: "2.8rem" }}>
                                            <span>
                                                {`${fromDate.length
                                                    ? `${moment(fromDate).format(
                                                        "MM-DD-yyyy"
                                                    )} TO ${moment(toDate).format("MM-DD-yyyy")}`
                                                    : "Select Shift Date Range"
                                                    }`}
                                            </span>
                                            <span className="pl-2">
                                                {showDateRangePicker ? (
                                                    <KeyboardArrowUpIcon />
                                                ) : (
                                                    <KeyboardArrowDownIcon />
                                                )}
                                            </span>
                                        </div>
                                        {showDateRangePicker && (
                                            <div className="mainDateRangePicker" style={{ right: "15%" }}>
                                                <DateRangePicker
                                                    onChange={(item) => {
                                                        setState([item.selection]);
                                                    }}
                                                    showSelectionPreview={true}
                                                    moveRangeOnFirstSelection={false}
                                                    months={1}
                                                    ranges={state}
                                                    direction="horizontal"
                                                />

                                                <div className="button-container">
                                                    <button
                                                        onClick={() => {
                                                            setFromDate("");
                                                            setToDate("");
                                                            setShowDateRangePicker(false);
                                                            setState([
                                                                {
                                                                    startDate: new Date(),
                                                                    endDate: addDays(new Date(), 0),
                                                                    key: "selection",
                                                                },
                                                            ]);
                                                        }}
                                                        className="okButton clearButton"
                                                    >
                                                        <span>
                                                            <BackspaceIcon /> &nbsp;
                                                            <strong>CLEAR</strong>
                                                        </span>
                                                    </button>
                                                    <button
                                                        onClick={() => {
                                                            setShowDateRangePicker(false);
                                                        }}
                                                        className="cancelButton"
                                                    >
                                                        <span>
                                                            <CancelIcon
                                                                sx={{ width: "1.5rem", height: "1.5rem" }}
                                                            />{" "}
                                                            <strong>cancel</strong>
                                                        </span>
                                                    </button>
                                                    <button
                                                        onClick={handleTimeRangeSelection}
                                                        className="okButton"
                                                    >
                                                        <span>
                                                            <ThumbUpAltIcon /> &nbsp;
                                                            <strong>Done</strong>
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <div className="mr-3">
                                        <label
                                            style={{ fontWeight: "normal" }}
                                            className="m-0 ml-5 "
                                        >
                                            Shifts Range :
                                        </label>
                                        <div className="d-flex shift_num input num_input" style={{ alignItems: "center" }}>
                                            <input
                                                className={`form__input`}
                                                type="text"
                                                onChange={(e) => {
                                                    const re = /^-?\d*\.{0,1}\d+$/;
                                                    if (e.target.value === '' || re.test(e.target.value)) {
                                                        setFromShiftRange(e.target.value)
                                                    }

                                                }}
                                                value={fromShiftRange}
                                            />
                                            <Label
                                                style={{ padding: "0px 6px" }}
                                                component="span"
                                                title="To"
                                            />
                                            <input
                                                className={`form__input`}
                                                type="text"
                                                onChange={(e) => {
                                                    const re = /^-?\d*\.{0,1}\d+$/;
                                                    if (e.target.value === '' || re.test(e.target.value)) {
                                                        setToShiftRange(e.target.value)
                                                    }
                                                }}
                                                value={toShiftRange}
                                            />
                                        </div>
                                    </div>

                                    <div className="mr-3">
                                        <label
                                            style={{ fontWeight: "normal" }}
                                            className="m-0 ml-5 "
                                        >
                                            Score Range :
                                        </label>
                                        <div className="d-flex shift_num input num_input" style={{ alignItems: "center" }}>
                                            <input
                                                className={`form__input`}
                                                type="number"
                                                onChange={(e) => setFromScoreRange(e.target.value)}
                                                value={fromScoreRange}
                                            />
                                            <Label
                                                style={{ padding: "0px 6px" }}
                                                component="span"
                                                title="To"
                                            />
                                            <input
                                                className={`form__input`}
                                                type="number"
                                                onChange={(e) => setToScoreRange(e.target.value)}
                                                value={toScoreRange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ zIndex: 0 }}>
                            <InfiniteScroll
                                dataLength={filteredDrivers?.length}
                                next={fetchMoreDrivers}
                                hasMore={hasMore}
                                style={{
                                    paddingBottom: !hasMore ? "0px" : "10px",
                                }}
                                loader={
                                    <div
                                        className="d-flex justify-content-center align-items-center"
                                        style={{
                                            height:
                                                filteredDrivers && filteredDrivers?.length <= 0
                                                    ? "60vh"
                                                    : "auto",
                                        }}
                                    >
                                        <CircularProgress className="loading-progress" />
                                    </div>
                                }
                                height={"70vh"}
                                endMessage={
                                    filteredDrivers &&
                                    filteredDrivers?.length <= 0 && (
                                        <div
                                            style={{
                                                height: "60vh",
                                            }}
                                            className="d-flex justify-content-center align-items-center"
                                        >
                                            <p>
                                                <b>No Result Found</b>
                                            </p>
                                        </div>
                                    )
                                }
                            >
                                {isLoading ? (
                                    <div className="loading-holder">
                                        <CircularProgress className="loading-progress" />
                                    </div>
                                ) : (
                                    <div>
                                        <Table
                                            columns={columns}
                                            items={filteredDrivers}
                                            className="table_drivers"
                                            activeItem={activeItem}
                                            width="100%"
                                        />
                                    </div>
                                )}
                            </InfiniteScroll>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
