import { Autocomplete, TextField } from '@mui/material';
import React, { useRef } from 'react'
import { AiFillCloseCircle } from 'react-icons/ai';
import { IoIosAddCircle } from 'react-icons/io'
import { sendErrorMessage } from '../../../../constants/extras/errorHandlers';
import { attachmentType } from '../../../../constants/variables/arrays';
import { ErrorMessage, Label } from '../../Form';
import { autocomplete } from '../../orderHelper/styles';
import video_img from "../../../../images/video.svg"
import document_img from "../../../../images/document.svg"
import { BsFillFileEarmarkPdfFill } from 'react-icons/bs';
import "./style.scss"


function Attechments(props) {
    const { attachment, setAttachment, id, disableField } = props;

    const imageInputRef = useRef(null)

    const handleAddAttachment = () => {
        if(disableField) return;
        const AllOptionSelected = attachment?.every((item) => {
            return item?.type !== "" && item?.file !== "" && !item?.isVideo && !item?.isPdf;
        });

        if (AllOptionSelected) {
            setAttachment([...attachment, { type: "", file: "", prevFile: "", isLimit: false, isFile: false, isPdf: false, isVideo: false }])
        } else {
            sendErrorMessage("Please select type and file")
        }
    }
    const handleChangeType = (data, index) => {
        if(disableField) return;
        setAttachment((prev) => {
            return prev.map((elem, id) => {
                if (index === id) {
                    return { type: data, file: "", prevFile: "", isLimit: false, isFile: false, isPdf: false, isVideo: false }
                } else return { ...elem }
            })
        })
    }

    const handleRemoveAttachment = (i) => {
        if(disableField) return;
        const newData = [...attachment]
        newData.splice(i, 1)
        setAttachment(newData)
    }

    const handleChangeFile = (e, index) => {
        if(disableField) return;
        const file = e.target.files[0]

        setAttachment((prev) => {
            return prev.map((elem, id) => {
                const limit = (file?.size > 30 * 1024 * 1024) && elem?.type === "Video"
                const isPdf = elem?.type === "Document (PDF)" && !file?.name?.includes("pdf")
                const isVideo = elem?.type === "Video" && !file?.name?.includes("mp4")
                const prevData = file ? URL.createObjectURL(file) : ""
                if (index === id) {
                    return {
                        ...elem, file: file, prevFile: prevData, isLimit: limit, isFile: false, isVideo: isVideo, isPdf: isPdf
                    }
                } else return { ...elem }
            })
        })
    }

    function validURL(str) {
        var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        return pattern.test(str);
    }

    return (
        <>
            <div className="attachment__wrapper">
                <div className="attachment">
                    <div>
                        {
                            attachment?.map((elem, index) => {
                                return (
                                    <div key={index} className="mb-3">

                                        <div>
                                            <Label title="Type" />

                                            <div className='d-flex align-items-center' >
                                                <div className='w-100 mr-3'>
                                                    <Autocomplete
                                                        options={attachmentType}
                                                        sx={autocomplete}
                                                        value={elem?.type || null}
                                                        onChange={(_, data) => handleChangeType(data, index)}
                                                        getOptionLabel={(option) => option}
                                                        renderInput={(params) => <TextField {...params} placeholder="Select Type" />}
                                                        isOptionEqualToValue={(option, value) => option === value}
                                                        disabled={disableField}
                                                    />
                                                </div>
                                                <div >

                                                    <div style={{ alignItems: "center", display: "flex", justifyContent: "space-between" }}>

                                                       

                                                        {
                                                            (id && elem?.isSeen !== undefined) && (
                                                                <div className="toggle_container" style={{ marginRight: "10px", marginLeft: "-6px" }}>
                                                                    <div className="toggle-switch">
                                                                        <input
                                                                            type="checkbox"
                                                                            className="checkbox"
                                                                            name="onn"
                                                                            id="on"
                                                                            checked={elem?.isSeen || false}
                                                                        />
                                                                        <label className="label" htmlFor="on">
                                                                            <span className="inner" />
                                                                            <span className="switch" />
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                        <AiFillCloseCircle style={{ color: "red", fontSize: "25px", cursor: "pointer" }} onClick={() => handleRemoveAttachment(index)} />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        {
                                            (elem?.type === "Video" || elem?.type === "Document (PDF)") &&
                                            <div className="d-flex justify-content-center align-items-center flex-column" style={{ marginTop: "20px" }} >
                                                {
                                                    <>
                                                        <div onClick={() => (!elem.file) && imageInputRef.current.click()} >

                                                            <input
                                                                hidden
                                                                ref={imageInputRef}
                                                                type="file"
                                                                accept={elem?.type === "Video" ? "video/mp4" : ".pdf"}
                                                                onChange={(e) => handleChangeFile(e, index)}
                                                            />

                                                            {
                                                                (!elem?.prevFile) && <div className="image-select">
                                                                    <div className="select_img">
                                                                        <img src={elem?.type === "Video" ? video_img : document_img} alt="" />
                                                                        <p className='mt-2'>{elem?.type === "Video" ? "Upload Video" : "Upload Document"}</p>
                                                                    </div>
                                                                </div>
                                                            }

                                                        </div>

                                                        {(elem?.prevFile && elem?.type === "Video") && (
                                                            <video controls width="400" height="250">
                                                                <source src={elem?.prevFile} type="video/mp4" />
                                                            </video>
                                                        )}

                                                        {(elem?.prevFile && elem?.type === "Document (PDF)") && <div className="pdf" >
                                                            <a href={elem?.prevFile} target="_blank" >
                                                                <BsFillFileEarmarkPdfFill className='pdf_icon' />
                                                                <span> {elem.file?.name ? elem.file?.name : elem?.prevFile}</span>
                                                            </a>
                                                        </div>}

                                                        {elem?.type === "Video" && elem?.isLimit && <ErrorMessage error={{ message: "Your video size is more than 30 mb" }} />}
                                                        {elem?.isVideo && <ErrorMessage error={{ message: "Only mp4 file extensions is allowed." }} />}
                                                        {elem?.isPdf && <ErrorMessage error={{ message: "Only pdf file extensions is allowed." }} />}
                                                        {elem?.isFile && <ErrorMessage error={{ message: "This field is required" }} />}
                                                    </>
                                                }
                                            </div>
                                        }

                                        {
                                            elem?.type === "Link" &&
                                            <div className="mt-3 inputData">
                                                <label>Link:</label>
                                                {
                                                    !elem?.prevFile && <input
                                                        type="text"
                                                        value={elem?.file}
                                                        onChange={(e) => {
                                                            const data = e.target.value.trimStart()
                                                            setAttachment((prev) => {
                                                                return prev.map((elem, id) => {
                                                                    if (index === id) {
                                                                        return { ...elem, file: data, prevFile: "", isFile: !validURL(e.target.value) }
                                                                    } else return { ...elem }
                                                                })
                                                            })
                                                        }}
                                                        placeholder="Enter Link"
                                                        className='link_input'
                                                    />
                                                }
                                                {id && elem?.prevFile &&
                                                    <div>

                                                        <a href={elem?.prevFile?.includes("https") ? elem?.prevFile : `https://${elem?.prevFile}`}
                                                            className="mr-2" target="_blank" >
                                                            {elem?.prevFile}
                                                        </a>   
                                                    </div>
                                                }

                                                {elem?.isFile && <ErrorMessage error={{ message: "Please add valid url" }} />}
                                            </div>
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>

                    <div className={`attachment_btn ${attachment?.length && "mt-4"}`} onClick={handleAddAttachment} >
                        <IoIosAddCircle className='opt_icon' />
                        <span>
                            Add Attachment
                        </span>
                    </div>
                </div>
            </div >
        </>
    )
}

export default Attechments
