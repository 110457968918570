import { createSlice } from "@reduxjs/toolkit";

export const metroSlice = createSlice({
  name: "metro",
  initialState: {
    value: undefined,
    metros: []
  },
  reducers: {
    selectMetro: (state, action) => {
      state.value = action.payload;
    },
    selectMetros: (state, action) => {
      state.metros = [...action.payload];
    },
  },
});

export const { selectMetro, selectMetros } = metroSlice.actions;
export default metroSlice.reducer;
