import { statusList } from "../variables/arrays";
import { handlePaymentStatus } from "./handlePaymentStatus";
import { rewordingOrderStatus } from "./rewording";

export const convertToCapitalize = (text) => {
  const words = typeof text === 'string' ? text?.split("_") : [];
  let str = "";
  words?.forEach(
    (s) => (str += s.slice(0, 1).toUpperCase() + s.slice(1) + " ")
  );
  return str;
};

export const removeDash = (str) => str?.replace("_", " ");

export const handlePaymentStatusForDetails = (status, price, isRequestedPayments, isWeeklyPayments, path) => {
  return statusList.includes(status) && price ? `${removeDash(convertToCapitalize(rewordingOrderStatus(status, path)))} | ${handlePaymentStatus(status, price, isRequestedPayments, isWeeklyPayments)}` : handleStatus(status);
}

export const handleStatus = (status) => {
  return removeDash(rewordingOrderStatus(convertToCapitalize(status)));
};
