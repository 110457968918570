import React, { useCallback } from "react";
import { CircularProgress, Modal } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { InputItem } from "../../Form";
import { actions } from "../../../../redux/store/store";
import { sendErrorMessage } from "../../../../constants/extras/errorHandlers";
import { useCreateCompanyMutation, useUpdateCompanyMutation } from "../../../../api/company";
import { sendSuccessMessage } from "../../../../constants/extras/sendSuccessMsg";
import { Button } from "react-bootstrap";

const requiredMessage = "This field is required";

const validationSchema = Yup.object().shape({
  companyName: Yup.string().required(requiredMessage),
  companyCode: Yup.string(),
});

const CompanyMainModal = ({ id }) => {
  const title = id ? `Company #${id?._id}` : "New Company";

  const [update, { error: updateError, isLoading: isUpdating }] =
    useUpdateCompanyMutation();
  const [create, { error: createError, isLoading: isCreating }] =
    useCreateCompanyMutation();

  const form = useForm({
    defaultValues: {
      companyName: id && id?.companyName ? id?.companyName : "",
      companyCode: id && id?.companyCode ? id?.companyCode : ""
    },
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = useCallback(
    async (data) => {
      const result = await (id ? update({
        companyName: data.companyName,
        companyCode: data.companyCode,
        _id: id?._id
      }) : create(data));
      if (!result?.data?.error && result?.data) {
        sendSuccessMessage(`Company ${id ? "updated" : "created"} successfully`);
      } else if (result?.data?.error) {
        if (result?.data?.error) {
          sendErrorMessage(result?.data?.message)
        }
      }
      actions.modal.closeCompanyMain();
      actions.refetch.setIsDataRefetch(true);
    },
    [id, create, update, createError, updateError]
  );

  const onCancel = useCallback(() => {
    if (
      !form.formState.isDirty ||
      window.confirm("Do you want to close the dialog? Changes will be lost.")
    ) {
      actions.modal.closeCompanyMain();
    }
  }, [form.formState.isDirty]);

  return (
    <Modal
      open
      onClose={onCancel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={`modal after-loading`} style={{ maxWidth: "35.875rem" }}>
        <div className="modal__header">
          <h2 className="modal__title">{title}</h2>
        </div>
        <div className="modal__body">
          <form>
            <InputItem
              name="companyName"
              form={form}
              title="Company Name"
              autoComplete="off"
            />
            <InputItem name="companyCode" title="Company Code" form={form} />
          </form>
        </div>
        <div className="modal__footer">
          <button className="btn" onClick={() => actions.modal.closeCompanyMain()}>
            Cancel
          </button>
          <div style={{ position: 'relative' }}>
            <Button
              variant="contained"
              className="btn btn_accent"
              disabled={
                isUpdating ||
                isCreating ||
                (!form.formState.isDirty)
              }
              onClick={form.handleSubmit(onSubmit)}
            >
              OK
            </Button>
            {(isUpdating || isCreating) && (
              <CircularProgress
                size={18}
                sx={{
                  color: '#fff',
                  position: 'absolute',
                  top: '50%',
                  left: '83%',
                  marginTop: '-8px',
                  marginLeft: '-10px',
                }}
              />
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CompanyMainModal;