// import { FetchBaseQueryArgs } from '@reduxjs/toolkit/dist/query/fetchBaseQuery'
import {
  // BaseQueryFn,
  // FetchArgs,
  fetchBaseQuery,
  // FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { setCurrentUser } from "../redux/slices/authSlice";

export const baseQueryWithAuthInterceptor = (args) => {
  const baseQuery = fetchBaseQuery(args);
  return async (args, api, extraOptions) => {
    const result = await baseQuery(args, api, extraOptions);
    if (
      result.error &&
      (result.error.status === 401 || result.error.originalStatus === 401)
    ) {
      api.dispatch(setCurrentUser(null));
    }
    return result;
  };
};
// (args: FetchBaseQueryArgs) => BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> =
