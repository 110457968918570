import React, { useState } from "react";
import { NavItem } from "react-bootstrap";
import { FaAngleDown } from "react-icons/fa";
import { NavLink, useLocation } from "react-router-dom";
import "./menu.scss";

const SidebarMenu = (props) => {
  const { route, setIsOpen, userRole, isOpen } = props;
  const { subRoutes, path } = route || {};
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => {
    // setIsMenuOpen(!isMenuOpen);
    if(path === isOpen){
      setIsOpen("");
    } else {
      setIsOpen(path)
    }
  };
  const location = useLocation()

  return (
    <>
      <div className="menu nav_link " onClick={toggleMenu}>
        <NavItem className="d-flex align-items-center">
          <span className={`nav-item nav__font ${isOpen !== path && route?.subPathArray?.includes(location.pathname) ? 'show' : ''}`} style={{ width: "1.5rem" }}>
            { typeof route?.icon === 'string' ? <img src={route?.icon} alt={route?.path} width="20px" height="20px" /> : route.icon}
          </span>
          <p className="navLink_name">{route.name}</p>
        </NavItem>
        <div
          style={{
            transform: isOpen === path ? "rotate(180deg)" : "rotate(0deg)",
            transition: "all ease-in-out 0.5s",
          }}
        >
          <FaAngleDown />
        </div>
      </div>
      <ul
        style={{
          display: isOpen === path ? "block" : "none",
        }}
        className="nav nav-pills flex-column sideMenu_link"
      >
        {subRoutes.map(({ path, icon, name, role }, i) => (
          <React.Fragment key={i}>
          {
            role?.includes(userRole) &&
            <NavItem key={i} style={{ whiteSpace: "nowrap" }}>
              <NavLink
                to={path}
                className={({ isActive }) =>
                  `side_nav_link ${isActive ? "navLink_active" : ""}`
                }
              >
                <span
                  className="nav-item nav__font"
                  style={{ width: "1.5rem" }}
                >
                  { typeof icon === 'string' ? <img src={icon} alt={path} width="20px" height="20px" /> : icon}
                </span>
                <p className="navLink_name">{name || path}</p>
              </NavLink>
            </NavItem>
          }
          </React.Fragment>
        ))}
      </ul>
    </>
  );
};

export default SidebarMenu;
