import moment from "moment";
const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
const utc = moment().format().slice(19);

export const headers = [
    { label: "Name", key: "name" },
    { label: "Username", key: "username" },
    { label: "Phone", key: "phone" },
    { label: "Created", key: "created" },
    { label: "Last Login At", key: "LastLoginAt" },
];

export function OperatorData(filteredOrdersData) {
    const shiftsData = [];

    if (filteredOrdersData && filteredOrdersData.length) {
        for (let i = 0; i < filteredOrdersData.length; i++) {
            let data = filteredOrdersData[i];

            const {
                name,
                username,
                phone,
                created,
                lastLoginTime: LastLoginAt

            } = data || {};


            shiftsData[i] = {
                name: `${name ? name : "-"}`,
                username: `${username ? username : "-"}`,
                phone: `${phone ? phone : "-"}`,
                created: `${created ? moment(created).format("MMM D, YYYY hh:mm A") : "-"}`,
                LastLoginAt: `${LastLoginAt ? moment(LastLoginAt).format("LLL") : "-"}`

            };
        }
    }
    shiftsData.unshift({ name: `This report based on "${timeZone}  UTC ${utc}" Timezone ` })
    return shiftsData;
}