import React, { forwardRef, useMemo } from "react";
import { DateRangePicker } from "react-date-range";
import CancelIcon from "@mui/icons-material/Cancel";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import BackspaceIcon from "@mui/icons-material/Backspace";

import { DATE, createDate } from "../../../constants/variables/dates";

const { M_D_Y } = DATE;
const handleDateRangeTxt = (fromDate, toDate) => {
  if (!fromDate || !toDate) return "";
  return fromDate || toDate
    ? `${createDate(fromDate, M_D_Y)} TO ${createDate(toDate, M_D_Y)}`
    : "Select Custom Date Range";
};

const SelectDateRange = forwardRef((props, ref) => {
  const {
    showDateRangePicker,
    setState,
    state,
    setShowDateRangePicker,
    fromDate,
    toDate,
    prevDate,
    className,
    handleUpDownIcon,
    onResetClick,
    onCancelClick,
    handleTimeRangeSelection,
    label
  } = props || {};

  const dateRange = useMemo(() => {
    return handleDateRangeTxt(fromDate, toDate);
  }, [fromDate, toDate]);

  const isOpen = useMemo(() => {
    return handleUpDownIcon(showDateRangePicker);
  }, [handleUpDownIcon, showDateRangePicker]);

  const footerProps = {
    onResetClick,
    onCancelClick,
    handleTimeRangeSelection,
  };

  return (
    <div ref={ref}>
      <div
        onClick={() => {
          setShowDateRangePicker(!showDateRangePicker);
          setState(prevDate);
        }}
        className="form__input"
      >
        {
          label && 
          <div style={{position: 'relative'}}>
                      <span className="label-date-picker">{label}</span>
                    </div>
        }
        <span>{dateRange}</span>
        <span className="ml-2">{isOpen}</span>
      </div>
      {showDateRangePicker && (
        <div className={`mainDateRangePicker ${className || ""}`}>
          <DateRangePicker
            onChange={({ selection }) => setState([selection])}
            months={1}
            ranges={state}
          />
          <FooterContainer {...footerProps} />
        </div>
      )}
    </div>
  );
});

export default SelectDateRange;

export const FooterComponent = (props) => {
  const { handleCloseDtPickr } = props;
  return (
    <div className="button-container mt-4">
      <button
        onClick={() => handleCloseDtPickr()}
        className="cancelButton px-2 w-100"
        style={{ height: "2rem" }}
      >
        <span>
          <strong>cancel</strong>
        </span>
      </button>
      <button
        style={{ height: "2rem" }}
        onClick={() => handleCloseDtPickr()}
        className="okButton px-2 w-100"
      >
        <span>
          <strong>Done</strong>
        </span>
      </button>
    </div>
  );
};

const FooterContainer = (props) => {
  const { onResetClick, onCancelClick, handleTimeRangeSelection } = props || {};
  return (
    <div className="button-container">
      <button onClick={() => onResetClick()} className="okButton clearButton">
        <span>
          <BackspaceIcon /> &nbsp;
          <strong>Reset</strong>
        </span>
      </button>
      <button onClick={() => onCancelClick()} className="cancelButton">
        <span>
          <CancelIcon sx={{ width: "1.5rem", height: "1.5rem" }} />
          <strong>cancel</strong>
        </span>
      </button>
      <button onClick={() => handleTimeRangeSelection()} className="okButton">
        <span>
          <ThumbUpAltIcon /> &nbsp;
          <strong>Done</strong>
        </span>
      </button>
    </div>
  );
};
