import React, { useState } from 'react'
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import moment from 'moment'
import { useEffect } from 'react';
import StarIcon from '@mui/icons-material/Star'
import { amber, grey, lightGreen, lime, orange, red } from '@mui/material/colors';
import { Rating, Typography } from '@mui/material';
import _ from 'lodash'
import "./style.scss"

function FeedbackAnswer(props) {
  const { feedbackReview, setFeedbackReview, filterData } = props

  const [searchQuery, setSearchQuery] = useState("")

  const handleSearchQuery = (e) => {
    const value = e.target.value
    setSearchQuery(value)
  }

  useEffect(() => {
    const data = filterData?.answers?.filter((elem) => {
      const { driverId } = elem;
      const { firstName, lastName, username } = driverId;
      return (
        (firstName || lastName) &&
        firstName
          .concat(" ", lastName)
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) || username?.includes(searchQuery.toLowerCase())
      );
    })
    setFeedbackReview(data)

  }, [searchQuery])

  const max = _.max(filterData?.counts?.map(e => e?.count))
  const colors = [lightGreen[300], lime[400], amber[300], orange[300], red[200]]

  let ratings = filterData?.counts?.length ? ([...filterData?.counts].reverse()) : []
  const total = ratings?.length && ratings?.reduce((total, item) => total + item?.count, 0);
  const responseTotal = ratings?.length && ratings?.map((e, i) => e?.count * (e?.answer))?.reduce((total, item) => total + item)
  const ratingAverage = (responseTotal === 0 && responseTotal === 0) ? 0 : responseTotal / total

  return (
    <>

      {
        (filterData?.questionsType === "star" && filterData?.answers?.length) ? (
          <div className="rating_root">
            <div className="card">
              <div className="bigBox">
                <div className='outerAverageBox'>
                  <div className="averageBox">
                    <Typography className="average" style={{ color: grey[500] }}>{ratingAverage?.toFixed(1)}</Typography>
                    <Rating name="half-rating-read" value={ratingAverage} precision={0.5} readOnly />
                    <Typography >{total} total</Typography>
                  </div>
                </div>

                <div className='chart'>
                  <table style={{ width: "100%" }}>
                    <tbody>
                      {
                        filterData && filterData?.counts?.length &&
                        ratings?.map((item, index) => {
                          let style = {
                            backgroundColor: colors[index] || '#f00',
                            width: (item?.count / max) * 100 + '%',
                            height: 'auto',
                          }

                          return (
                            <tr key={index}>
                              <td className='td'>
                                {item?.answer} <StarIcon style={{ height: "15px", color: grey[500] }} />
                              </td>
                              <td>
                                <div style={style}>
                                  <span className='count'>{item?.count}</span>
                                </div>
                              </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        ) : ("")
      }

      {
        (filterData?.questionsType === "options" && filterData?.answers?.length) ? (
          <div className='d-flex mb-4' style={{ gap: "10px", flexWrap: "wrap" }}>
            {
              filterData?.counts?.map((elem, index) => {
                return (
                  <div className='count_badge' key={index}>
                    {elem?.answer} : {elem?.count}
                  </div>
                )
              })
            }
          </div>
        ) : ("")
      }

      <div style={{ position: "relative", zIndex: 0 }}>
        <input
          type="text"
          onChange={handleSearchQuery}
          value={searchQuery}
          name="searchQuery"
          className="search__input"
          placeholder="Search Driver Here..."
          autoComplete="off"
        />
        <HighlightOffIcon
          onClick={() => setSearchQuery("")}
          className="clear__btn"
          style={{ color: "rgb(173, 191, 199)" }}
        />
      </div>


      <div className="available-drivers scroll__view">
        {
          feedbackReview?.length > 0 ?
            feedbackReview?.map((elem, index) => {
              return (
                <div
                  className="driver__table"
                  key={index}
                >
                  <div className="feedback__table__first__cell">
                    <span>{elem?.driverId?.firstName} {elem?.driverId?.lastName}</span> <br />
                    <span style={{ fontSize: "13px", color: "rgb(131, 131, 131)", fontWeight: "600" }}>
                      ({elem?.driverId?.username})
                    </span>
                  </div>

                  <div className="feedback__table__middle__cell">
                    {elem?.answer}
                  </div>

                  <div className="feedback__table__last__cell">
                    {
                      moment(elem?.date).format("MM-DD-YYYY")
                    }
                  </div>
                </div>
              )
            }) : (
              <div className="inner__scroll__view__container">
                No Answer Found
              </div>
            )
        }
      </div>
    </>
  )
}

export default FeedbackAnswer

