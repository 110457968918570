import React, { useCallback, useEffect, useState } from "react";
import { CircularProgress, Modal } from "@mui/material";
import Select from "react-select";
import { actions } from "../../../redux/store/store";
import { checkForError } from "../../../constants/extras/errorHandlers";
import { useUpdateDriverMutation } from "../../../api/drivers";
import { sendSuccessMessage } from "../../../constants/extras/sendSuccessMsg";
import { states } from "../../../constants/variables/states";
import { metroOptionsStyles } from "./metros/metroSelect";
import { DateCalendar } from "../DatePicker";
import moment from "moment";
import { containsEmptyString } from "../helper";


const DriverLicenseModal = (props) => {
    const title = props?.id?.title
    const driverId = props && props?.id?.id && props?.id?.id;
    const driverLicenseNo = props && props?.id?.orginalDriverLicenseNumber;
    const [updateDriverMetro, { isLoading: isUpdatingDriver }] =
        useUpdateDriverMutation();
    const [driverLicenseNumber, setDriverLicenseNumber] = useState(driverLicenseNo || "");
    const [options, setOptions] = useState([]);
    const [selectedStateOption, setSelectedStateOption] = useState(props?.id?.driverLicenseState ? {
        value: props?.id?.driverLicenseState || '',
        label: props?.id?.driverLicenseState || '',
    } : null)
    const LBD = props?.id?.driverLicenseBirthday ? new Date(props?.id?.driverLicenseBirthday) : '';
    const LDE = props?.id?.driverLicenseDateExp ? new Date(props?.id?.driverLicenseDateExp) : '';
    const [licenseBirthDay, setLicenseBirthDay] = useState(LBD)
    const [prevlicenseBirthDay, setprevLicenseBirthDay] = useState(licenseBirthDay || '')
    const [licenseDateExp, setLicenseDateExp] = useState(LDE)
    const [prevlicenseDateExp, setprevLicenseDateExp] = useState(licenseDateExp || '')
    const [showBirthDay, setShowBirthDay] = useState(false);
    const [showDateExp, setShowDateExp] = useState(false);

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true)

    const onDriverLicenseSubmit = async () => {
        let params = {
            _id: driverId,
            driverLicenseState: selectedStateOption?.value,
            driverLicenseBirthday: moment(licenseBirthDay).format('MM/DD/YYYY'),
            driverLicenseDateExp: moment(licenseDateExp).format('MM/DD/YYYY'),
            driverLicenseNumber: driverLicenseNumber
        }

        if(props?.id?.driverLicenseBack && props?.id?.driverLicenseFront && props?.id?.onboardingLicenceFillStatus !== 'completed'){
            params.onboardingLicenceFillStatus = 'completed'
        }

        let result = await updateDriverMetro(params);
        if (!result?.error) {
            sendSuccessMessage(`${title} updated successfully`);
            actions.refetch.setIsDataRefetch(true);
        } else {
            checkForError(result);
        }
        actions.modal.closeDriverLicense();
    }

    const onCancel = useCallback(() => {

        actions.modal.closeDriverLicense();

    }, []);

    useEffect(() => {
        const data = states?.length > 0 &&
            states.map(function (item) {
                return {
                    value: `${item?.state ? item?.state : ""}`,
                    label: `${item?.title ? item?.title : ""}`,
                }
            })
        setOptions(data)
    }, [])

    useEffect(() => {
        if (
            (driverLicenseNo === driverLicenseNumber && 
            props?.id?.driverLicenseState === selectedStateOption?.value &&
            moment(licenseDateExp).format('MM/DD/YYYY') === moment(LDE).format('MM/DD/YYYY') && 
            moment(licenseBirthDay).format('MM/DD/YYYY') === moment(LBD).format('MM/DD/YYYY')) 
            || containsEmptyString([driverLicenseNumber, selectedStateOption?.value || '', licenseDateExp, licenseBirthDay]
            || selectedStateOption === null
            )
        ) {
            setIsDisabled(true)
            return
        }
        return setIsDisabled(false);
    }, [driverLicenseNumber, selectedStateOption, licenseDateExp, licenseBirthDay])

    function changeDate(e, type){
        if(type === 'licenseBirthday'){
            const date21YearsAgo = moment().subtract(21, 'years');
            let date = moment(prevlicenseBirthDay).date();
            let new_date = moment(e).set('D', date)
            const diff =  date21YearsAgo.diff(new_date, 'days');
            if(diff < 0){
                new_date = moment(new_date).subtract(Math.abs(diff), 'days')
            }
            setprevLicenseBirthDay(new_date.toDate())
            return
        }
        let date = moment(prevlicenseDateExp).date();
        let new_date = moment(e).set('D', date)
        const today = moment()
        const diff =  today.diff(new_date, 'days');
        if(diff >= 0){
            new_date = moment()
        }
        setprevLicenseDateExp(new_date.toDate())
    }
    return (
        <Modal
            open
            onClose={onCancel}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className={`modal after-loading`} style={{ maxWidth: "35.875rem" }}>
                <div className="modal__header">
                    <h2 className="modal__title">{`${title}`}</h2>
                </div>
                <div className="modal__body">
                    <div className="driver_license" style={{ height: showBirthDay ? "20vh" : "auto" }}>
                        <span className="fw-600">Birthday: </span>
                        <DateCalendar
                            handleCalendarDate={() => {
                                if(showBirthDay){
                                    setprevLicenseBirthDay(licenseBirthDay)
                                }
                                setShowBirthDay(!showBirthDay)
                            }}
                            date={prevlicenseBirthDay || moment().subtract(21, 'years').toDate()}
                            showDate={prevlicenseBirthDay}
                            showDatePicker={showBirthDay}
                            maxDate={moment().subtract(21, 'years').toDate()}
                            onChange={(e) => setprevLicenseBirthDay(e)}
                            onShownDateChange={(e) => changeDate(e, 'licenseBirthday')}
                            submitDate={() => {
                                setLicenseBirthDay(prevlicenseBirthDay)
                                setShowBirthDay(false)
                            }}
                            cancelDate={() => {
                                setprevLicenseBirthDay(licenseBirthDay)
                                setShowBirthDay(false)
                            }}
                            placeholder="License Birthday"
                            sx={{ position: "relative" }}
                            resetDate={() => {
                                setLicenseBirthDay(LBD)
                                setprevLicenseBirthDay(LBD)
                                setShowBirthDay(false)
                            }}
                        />
                    </div>
                    <div className="driver_license" style={{ height: showDateExp ? "22vh" : "auto" }}>
                        <span className="fw-600">Date Expiration: </span>
                        <DateCalendar
                            handleCalendarDate={() => {
                                if(showDateExp){
                                    setprevLicenseDateExp(licenseDateExp)
                                }
                                setShowDateExp(!showDateExp)
                            }}
                            date={prevlicenseDateExp}
                            showDate={prevlicenseDateExp}
                            showDatePicker={showDateExp}
                            minDate={moment().toDate()}
                            onChange={(e) => setprevLicenseDateExp(e)}
                            onShownDateChange={(e) => changeDate(e)}
                            submitDate={() => {
                                setLicenseDateExp(prevlicenseDateExp)
                                setShowDateExp(false)
                            }}
                            cancelDate={() => {
                                setprevLicenseDateExp(licenseDateExp)
                                setShowDateExp(false)
                            }}
                            placeholder="License Expiration"
                            sx={{ position: "relative" }}
                            resetDate={() => {
                                setprevLicenseDateExp(LDE)
                                setLicenseDateExp(LDE)
                                setShowDateExp(false)
                            }}
                        />
                    </div>
                    <div className="driver_license">
                        <span className="fw-600">Number: </span>
                        <input
                            className={`form__input `}
                            type={"text"}
                            id={'driverlicensenumber'}
                            value={driverLicenseNumber}
                            onChange={(e) => setDriverLicenseNumber(e?.target?.value)}
                            autoComplete={false}
                            placeholder="License Number"
                        />
                    </div>
                    <div className="driver_license" style={{ height: isMenuOpen ? "20vh" : "auto" }}>
                        <span className="fw-600">State: </span>
                        <Select
                            className="color-black"
                            onChange={(items) => { setSelectedStateOption(items) }}
                            options={options}
                            value={selectedStateOption}
                            styles={metroOptionsStyles}
                            components={{
                                IndicatorSeparator: () => null,
                            }}
                            closeMenuOnSelect={true}
                            onMenuClose={() => setIsMenuOpen(false)}
                            onMenuOpen={() => setIsMenuOpen(true)}
                            menuIsOpen={isMenuOpen}
                            placeholder={'Select State'}
                        />
                    </div>
                </div>
                <div className="modal__footer">
                    <button className="btn" onClick={() => actions.modal.closeDriverLicense()}>
                        Cancel
                    </button>
                    <div style={{ position: 'relative' }}>
                        <button
                            className="btn btn_accent"
                            disabled={isUpdatingDriver || isDisabled}
                            onClick={onDriverLicenseSubmit}
                        >
                            OK
                        </button>
                        {isUpdatingDriver && (
                            <CircularProgress size={18}
                                sx={{
                                    color: '#fff',
                                    position: 'absolute',
                                    top: '50%',
                                    left: '83%',
                                    marginTop: '-8px',
                                    marginLeft: '-10px',
                                }} />
                        )}
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default DriverLicenseModal;
