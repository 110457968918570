import axios from "axios";

export const getOperatorReport = async (
    page,
    limit,
    fromDate,
    toDate,
    search
) => {
    try {
        const response = await axios.get(`/api/operators?page=${page}&limit=${limit}&createdFromDate=${fromDate}&createdToDate=${toDate}&search=${search}`)
        if (response.status === 200) return response?.data;
    else return "Something went wrong";
    } catch (error) {
        return error.response.data;
    }
}