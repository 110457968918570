export const MSG = {
  MODAL_CLOSE: "Do you want to close the dialog? Changes will be lost.",
};

export const MODAL_TITLE = {
  SHIFT: {
    REJECTING_SHIFT: "Write a reason for rejecting shift",
    FINAL__PAYMENT: "Write a note for changing Finalized Payment Amount.",
  },
};
