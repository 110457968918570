import React, { useState, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { actions } from "../../../redux/store/store";
import { useForgotPasswordMutation } from "../../../api/auth";
import "../../../styles/_login.scss";
import brandLogo from "../../../images/logo-better-truck.svg";

export default function ForgotPassword() {
  const location = useLocation();
  const [username, setUsername] = useState(
    (location.state && location.state.login) || ""
  );
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [forgotPassword, { isLoading }] = useForgotPasswordMutation();

  const performForgotPassword = useCallback(
    async (e) => {
      e.preventDefault();

      setError("");
      const { data: user } = await forgotPassword({ email: username });

      if (user.status === 200) {
        actions.notifications.add({
          message: "Reset Password Email Sent.",
          status: "success",
        });
        navigate("/login");
      } else {
        if (user.status === 401 || user.originalStatus === 401) {
          setError(user.message || "email not exists.");
        } else {
          setError("Something went wrong");
        }
      }
    },
    [username, forgotPassword, navigate]
  );

  const toLogin = (e) => {
    e.preventDefault();
    navigate("/login");
  };

  return (
    <div className="screen screen_flex">
      <div className="container container_contain-center loginmain">
        <img className="brandlogo" src={brandLogo} alt="" />
        <div className="brandHeading">
          <h5>Forgot Password</h5>
        </div>
        <div className="login" style={{ width: "100%" }}>
          <form>
            <div className="form__item">
              <label className="form__label" htmlFor="login">
                Email
              </label>
              <input
                id="login"
                className="form__input"
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="form__item form__item_min-margin">
              <button
                className="btn btn_block btn_accent"
                disabled={isLoading}
                onClick={performForgotPassword}
              >
                Send
              </button>
            </div>
            {error && (
              <div className="form__item form__item_min-margin">
                <div className="form__msg form__msg_summary form__msg_invalid">
                  {error}
                </div>
              </div>
            )}

            <div className="login__link-holder">
              <a href="/login" onClick={toLogin}>
                Already have an account ? Login
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
