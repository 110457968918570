const muiOutlinedInput = {
  "& .MuiOutlinedInput-root": {
    padding: "4px",
    width: "100%",
    "@media (max-width: 1450px)": {
      marginTop: "2px",
    },
  },
};

export const autocomplete = {
  ...muiOutlinedInput,
  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    border: "2px solid #dadada",
    borderRadius: "0.375rem",
  },
  "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: "#a0a293",
  },
  "& .Mui-focused.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#a0a293",
  },
};

export const muiTextField = {
  ...autocomplete,
  "& .MuiOutlinedInput-root": {
    padding: "0px 14px 0px 0",
  },
};

export const errorAutoComplete = {
  ...muiOutlinedInput,
  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    border: "2px solid red",
    borderRadius: "0.375rem",
  },
  "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: "red",
  },
  "& .Mui-focused.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "red",
  },
};

export const hoverAndFocusedStyle = {
  "& .MuiOutlinedInput-notchedOutline": {
    border: "2px solid #dadada",
    borderRadius: "0.375rem",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#a0a293",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#a0a293",
  },
};

export const hubSelectStyle = {
  "& .MuiSelect-select": {
    padding: "11px 14px",
  },
};

export const statusSelectStyle = {
  "& .MuiSelect-select": {
    width: "100px",
    padding: "11px 14px",
    "@media (max-width: 1450px)": {
      width: "50px",
    },
  },
};

export const paymentSelectStyle = {
  "& .MuiSelect-select": {
    width: "130px",
    padding: "12px 9px",
    "@media (max-width: 1450px)": {
      width: "80px",
    },
  },
};

export const ordersStatusSelect = {
  "& .MuiSelect-select": {
    width: "160px",
    padding: "12px 9px",
    "@media (max-width: 1450px)": {
      width: "80px",
      padding: "10px 9px",
    },
  },
};

export const select = {
  "& .MuiSelect-select": {
    padding: "19px 14px",
  },
};
