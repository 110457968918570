import React, { useState, useEffect } from "react";
import { IoChevronForward, IoSearchSharp } from "react-icons/io5";
import { CircularProgress } from "@mui/material";
import { IoIosAddCircle } from "react-icons/io";
import { handleBackgroundCheck } from "../../../constants/extras/statusesBadge";

import Table from "../../../components/shared/Table";
import { actions } from "../../../redux/store/store";
import "../../../styles/_data.scss";
import "../../../index.css";
import HeaderTextLayout from "../../../components/shared/HeaderTextLayout";
import { useGetAllMetrosQuery, useGetMetrosQuery } from "../../../api/metro";
import { Clear, Refresh } from "@mui/icons-material";

export default function Metros() {
  const { data: metros, isFetching, refetch } = useGetAllMetrosQuery(null, {
    refetchOnMountOrArgChange: true,
  });

  const [filteredmetros, setFilteredmetros] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(isFetching);
    if (!metros) return
    handleSearchingMetros()

  }, [metros, isFetching]);

  const handleSearchingMetros = (value) => {
    let filtered = metros.filter((metro) => {
      return (
        metro &&
        metro?.name &&
        metro?.name.toLowerCase().trim().includes(value === 'CLEAR' ? '' : searchQuery.toLowerCase().trim())
      );
    })

    setFilteredmetros((prev) => [...filtered]);

  }


  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearchingMetros();
    }
  };

  const columns = [
    { name: "name", label: "Metro", sortable: true },
    {
      label: "Bonus",
      name: "bonus",
      sortable: true,
      maxWidth: "30px",
    },
    {
      name: "bonusType",
      label: "Bonus Type",
      sortable: true,
      maxWidth: "30px",
      renderer: (item) => (
        <>
          {item.bonusType === "dollar" ? "Dollar ($)" : item.bonusType === "percentage" ? "Percentage (%)" : item.bonusType}
        </>
      ),
    },
    {
      name: "isAdminActive",
      label: "Admin Side",
      sortable: true,
      maxWidth: "25px",
      className: "table__td_centered table__td_min-horiz-padding",
      renderer: (item) => (
        <span
          className="textStyleForStatus"
          style={handleBackgroundCheck(item?.isAdminActive)}
        >
          {item?.isAdminActive ? 'Active' : 'Inactive'}
        </span>
      ),
    },
    {
      name: "isDriverActive",
      label: "Driver Side",
      sortable: true,
      maxWidth: "25px",
      className: "table__td_centered table__td_min-horiz-padding",
      renderer: (item) => (
        <span
          className="textStyleForStatus"
          style={handleBackgroundCheck(item?.isDriverActive)}
        >
          {item?.isDriverActive ? 'Active' : 'Inactive'}
        </span>
      ),
    },
    {
      name: "isBackgroundCheck",
      label: "Background Check",
      sortable: true,
      maxWidth: "25px",
      className: "table__td_centered table__td_min-horiz-padding",
      renderer: (item) => (
        <span
          className="textStyleForStatus"
          style={handleBackgroundCheck(item?.isBackgroundCheck)}
        >
          {item?.isBackgroundCheck ? 'Active' : 'Inactive'}
        </span>
      ),
    },
    {
      name: "details",
      className: "table__td_have-btn w-10 px-3",
      renderer: (item) => (
        <button
          className="btn btn_block btn_accent customized-details-btn"
          type="button"
          onClick={() => {
            actions.modal.openMetro(item);
          }}
        >
          Details <IoChevronForward className="btn__icon btn__icon_right" />
        </button>
      ),
    },
  ];

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="card">
            <HeaderTextLayout
              headerText="Metros"
              className="col-md-2 main__driver"
              data={
                filteredmetros && filteredmetros.length > 0
                  ? `${filteredmetros.length} Metros Found`
                  : ""
              }
              dataLength={
                Array.isArray(filteredmetros) ? filteredmetros.length : "0"
              }
            >
              <div className="col-md-10 col-sm-10 d-flex justify-content-end align-items-center">
                <div className="filter__ctrls-holder filter__input-holder d-flex justify-content-end">
                  <div className="searchbar__container pr-2">

                    <input
                      className="pr-2 form__input customized__input"
                      value={searchQuery}
                      onChange={(e) => {
                        setSearchQuery(e.target.value);
                        if (e.target.value === "")
                          handleSearchingMetros("CLEAR");
                      }}
                      placeholder="Quick Search"
                      onKeyDown={handleKeyDown}
                    />
                    {searchQuery?.length > 0 && (
                      <Clear
                        className="clear__btn filter__search-icon"
                        onClick={() => {
                          setSearchQuery("");
                          handleSearchingMetros("CLEAR");
                        }}
                        style={{
                          right: "45px",
                          top: "12px",
                        }}
                      />
                    )}
                    <button
                      disabled={searchQuery?.length <= 0}
                      className="search__button"
                      onClick={() => handleSearchingMetros()}
                    >
                      <IoSearchSharp className="filter__search-icon" />
                    </button>
                  </div>
                </div>

                <button
                  style={{
                    background: "white",
                    borderColor: "#e6e6e6",
                    width: "200px",
                  }}
                  className="btn btn__accent mr-0 ml-3"
                  onClick={() => actions.modal.openMetro()}
                >
                  <IoIosAddCircle style={{ fontSize: "22px" }} />
                  New Metro
                </button>

                <button
                  className="refresh_btn ml-3"
                  onClick={() => refetch()}
                >
                  <Refresh className="refresh_icon" />
                </button>
              </div>
            </HeaderTextLayout>
            {isLoading ? (
              <div className="d-flex justify-content-center align-items-center" style={{ height:"80vh" }}>
                <CircularProgress className="loading-progress" />
              </div>
            ) : (
              <React.Fragment>
                {filteredmetros && filteredmetros.length ? (
                  <div className="table-wrapper normal_table">
                    <Table
                      columns={columns}
                      items={filteredmetros}
                      className="table_metros"
                    />
                  </div>
                ) : (
                  <div className="no_results">No Results</div>
                )}
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
