import React from 'react'
import { Modal } from '@mui/material'

function PayoutWarning(props) {
  const { onCancel, amount, handlePayoutSubmit } = props

  return (
    <>
      <Modal
        open
        onClose={onCancel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="modal modal_dialog" style={{ height: "auto" }}>
          <div className="modal__header">
            <h2 className="modal__title">High Payout Amount</h2>
          </div>
          <div
            className={`modal__body`}
          >
            Are you sure you want to enter a payment for ${amount}?
          </div>

          <div className="modal__footer">
            <button className="btn" onClick={onCancel}>
              Cancel
            </button>
            <button
              className="btn btn_accent"
              onClick={handlePayoutSubmit}>
              Yes
            </button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default PayoutWarning
