export const ADHOC = "Adhoc Payments";

export const PAY_FOUND = "Payments Found";
export const PAGINATION = { COMMON__LIMIT: 500 };

export const drivers = {
  DELIVERY_STARTED: "Delivery Started",
};

export const transactionStatusList = [
  { title: "Pending", value: "pending"},
  { title: "Rejected", value: 'rejected'},
  { title: "Processed", value: "processed"},
  { title: "Approved", value: "approved"},
  { title: "Paid", value: "paid"},
  { title: "Cancelled", value: "cancelled"},
  { title: "Failed", value: "failed"},
];
