import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuthInterceptor } from "./util";

export const metrosApi = createApi({
  reducerPath: "metrosApi",
  baseQuery: baseQueryWithAuthInterceptor({ baseUrl: "/api/" }),
  tagTypes: ["Metros"],
  endpoints: (builder) => ({
    getMetros: builder.query({
      query: () => {
        return {
          url: "/metros",
          method: "GET",
        };
      },
      providesTags: ["Metros"],
    }),
    getAllMetros: builder.query({
      query: () => {
        return {
          url: "/allMetros",
          method: "GET",
        };
      },
      providesTags: ["Metros"],
    }),
    createMetro: builder.mutation({
      query: (body) => ({
        url: "/metros",
        method: "POST",
        body,
      }),
      invalidatesTags: (result, error, arg) => !error && ["Metros"],
    }),
    updateMetro: builder.mutation({
      query: (body) => ({
        url: `/metros/${body._id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (result, error, arg) => !error && ["Metros"],
    }),
  }),
});

export const {
  useGetMetrosQuery,
  useGetAllMetrosQuery,
  useCreateMetroMutation,
  useUpdateMetroMutation,
} = metrosApi;
