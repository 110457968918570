import React, { useState, useEffect, useRef, useCallback } from "react";
import moment from "moment";
import { IoSearchSharp } from "react-icons/io5";
import { CircularProgress, Fade, Menu, MenuItem } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ClearIcon from "@mui/icons-material/Clear";

import Table from "../../../components/shared/Table";
import { useOutsideAlerter } from "../../../constants/outsideAlert";
import { getTransactionsList } from "../../../api/reports";
import {
  transactionReport,
  headers,
  header,
} from "../../../constants/csv/csvTransactionsData";
import { handleStatusesBadge } from "../../../constants/extras/statusesBadge";
import { actions } from "../../../redux/store/store";
import "./style.scss";
import HeaderTextLayout from "../../../components/shared/HeaderTextLayout";
import InfiniteScroll from "react-infinite-scroll-component";
import { Refresh } from "@mui/icons-material";
import { CSVLink } from "react-csv";
import { dataExport, xlsxDataExport } from "../../../constants/extras/dataExport";
import filterLogo from "../../../images/filter-icon.svg"
import CustomTransactionsDrawer from "../../../components/shared/customDrawer/Transactions";
import { useSelector } from "react-redux";
import { DateCreater, currentDtArr, getFirstAndLastDate } from "../../../constants/variables/dates";
import { exportMenu, paymentMethodOptions } from "../../../constants/variables/arrays";
import { PAGINATION } from "../../../constants/variables";

const { COMMON__LIMIT } = PAGINATION;

export default function Pagination() {
  let d = new Date();
  d.setMonth(d.getMonth() - 1);
  const [hasMore, setHasMore] = useState(true);
  const [showDateRangePicker, setShowDateRangePicker] = useState(false);
  const [fromDate, setFromDate] = useState(moment(d).format("yyyy-MM-DD"));
  const [page, setPage] = useState(2);
  const [limit] = useState(COMMON__LIMIT);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [csvData, setCsvData] = useState(false);
  const csvInstance = useRef();
  const [total, setTotal] = useState(0)

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [prevDate, setPrevDate] = useState(state);
  const [toDate, setToDate] = useState(moment().format("yyyy-MM-DD"));

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setShowDateRangePicker);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingCsv, setLoading] = useState(false);


  const [filteredTransactionsData, setFilteredTransactionsData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isTotal] = useState(true)
  const [shift] = useState(false)
  const [openDrawer, setOpenDrawer] = useState(false)
  const [disable, setDisable] = useState(false);
  const [isApply, setIsApply] = useState(false)
  const [metros, setMetros] = useState([])
  const [payments, setPayments] = useState("")
  const [adhocPayments, setAdhocPayments] = useState(false);
  const [instantPayments, setInstantPayments] = useState(false);
  const [exceptionPayments, setExceptionPayments] = useState(false);
  const [weeklyPayments, setWeeklyPayments] = useState(false);
  const [shiftPayments, setShiftPayments] = useState(false);
  const [showBonusPrice, setShowBonusPrice] = useState(true);
  const [transactionStatus, setTransactionStatus] = useState([])
  const [driver, setDriver] = useState([])
  const [paymentMethod, setPaymentMethod] = useState("")

  function handlePaymentStateStatus(value) {
    setWeeklyPayments(['Weekly Payment'].includes(value));
    setInstantPayments(['Instant Payment'].includes(value));
    setAdhocPayments(['AdhocPayments'].includes(value));
    setShiftPayments(['Shift Payment'].includes(value));
    setExceptionPayments(['Exceptions Payment'].includes(value));
    setShowBonusPrice(!["Instant Payment", "AdhocPayments", "Exceptions Payment", "Shift Payment"].includes(value))
  }

  const [fromDateFilter, setFromDateFilter] = useState("");
  const [toDateFilter, setToDateFilter] = useState("");

  const [showDateRangePickerFilter, setShowDateRangePickerFilter] = useState(false);

  const [stateFilter, setStateFilter] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const [totalAmounts, settotalAmounts] = useState();

  // const totalAmount = filteredTransactionsData?.reduce((sum, object) => {
  //   return sum + object?.amount;
  // }, 0);

  // useEffect(() => {
  //   settotalAmounts(totalAmount?.toFixed(2))
  // }, [totalAmount, filteredTransactionsData])

  const [prevDateFilter, setPrevDateFilter] = useState(stateFilter);

  const propsobj = {
    metros,
    setMetros,
    showDateRangePickerFilter,
    setShowDateRangePickerFilter,
    fromDateFilter,
    setFromDateFilter,
    toDateFilter,
    setToDateFilter,
    stateFilter,
    setStateFilter,
    payments,
    setPayments,
    showDateRangePicker,
    setShowDateRangePicker,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    state,
    setState,
    transactionStatus,
    setTransactionStatus,
    driver,
    setDriver,
    paymentMethod,
    setPaymentMethod
  }

  const handleTimeRangeSelectionFilter = async () => {
    const { startDate, endDate } = stateFilter[0] || {};
    const { dt: strtDt } = new DateCreater(startDate);
    const { dt: endDt } = new DateCreater(endDate);
    setFromDateFilter(strtDt);
    setToDateFilter(endDt);
    setShowDateRangePickerFilter(false);
    setPrevDateFilter(stateFilter);
  };


  const onCancelClickFilter = () => {
    setShowDateRangePickerFilter(false);
    setStateFilter(prevDateFilter);
  }

  const onCancelClick = () => {
    setShowDateRangePicker(false);
    setState(prevDate);
  }

  const onResetClickFilter = async () => {
    setShowDateRangePickerFilter(false);
    setFromDateFilter("");
    setToDateFilter("");
    setShowDateRangePickerFilter(false);
    setStateFilter([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);
    setPrevDateFilter([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);
  };

  const onResetClick = async () => {
    setShowDateRangePicker(false);
    setFromDate("");
    setToDate("");
    setShowDateRangePicker(false);
    setState([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);
    setPrevDate([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);
  };

  const handleCustomRangeFilter = () => {
    !disable && setShowDateRangePickerFilter(!showDateRangePickerFilter);
    setStateFilter(prevDateFilter);
  }


  const isRefetch = useSelector((state) => state.refetch.value);

  const openTransactionModal = (e) => {
    e.preventDefault();
    actions.modal.openTransaction(
      e.currentTarget.getAttribute("data-store-id")
    );
  };

  const metroData = metros?.map(item => item.value)

  useEffect(() => {
    actions.refetch.setIsDataRefetch(true);
  }, [])

  useEffect(() => {

    if (isRefetch === false) return;
    setIsLoading(true);
    setHasMore(true);
    setFilteredTransactionsData([]);
    setPage(1);
    
      (async () => {
        settotalAmounts(undefined)
        const data = await getTransactionsList(
          1,
          limit,
          fromDate,
          toDate,
          searchQuery,
          isTotal,
          metroData,
          fromDateFilter,
          toDateFilter,
          adhocPayments,
          instantPayments,
          exceptionPayments,
          weeklyPayments,
          shiftPayments,
          shift,
          transactionStatus,
          driver,
          paymentMethod
        );
        setTotal(data?.total)
        setFilteredTransactionsData(data?.result);
        setIsLoading(false);
        settotalAmounts(data?.totalAmount)
        actions.drivers.addDrivers(data?.result);
        if (data?.result && data?.result?.length < limit) setHasMore(false);
        setPage(2);
      })();
      
      actions.refetch.setIsDataRefetch(false);
      setIsLoading(false);

  }, [isRefetch]);

  const handleApplyFilter = () => {
    setIsApply(true)
    setOpenDrawer(false)
    handlePaymentStateStatus(payments?.value)
    actions.refetch.setIsDataRefetch(true);
  }

  useEffect(() => {
    let startD = `${moment(fromDate).format("MM-DD-yyyy")}`;
    let endD = `${moment(toDate).format("MM-DD-yyyy")}`;
    setState([
      {
        startDate: new Date(startD),
        endDate: new Date(endD),
        key: "selection",
      },
    ]);
    setPrevDate([
      {
        startDate: new Date(startD),
        endDate: new Date(endD),
        key: "selection",
      },
    ]);
  }, []);

  const handleCustomRange = () => {
    setShowDateRangePicker(!showDateRangePicker);
    if (!fromDate.length) {
      setState([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: "selection",
        },
      ]);
    } else {
      setState(prevDate);
    }
  };

  const handleTimeRangeSelection = async (clear) => {

    const { startDate, endDate } = state[0] || {};
    const { dt: strtDt } = new DateCreater(startDate);
    const { dt: endDt } = new DateCreater(endDate);
    setFromDate(strtDt);
    setToDate(endDt);
    setShowDateRangePicker(false);
    setPrevDate(state);
    // const firstSelectionOfStartDate = moment(state[0].startDate).format(
    //   "yyyy-MM-DD"
    // );

    // const firstSelectionOfToDate = moment(state[0].endDate).format(
    //   "yyyy-MM-DD"
    // );
    // clear !== "CLEAR" && setFromDate(firstSelectionOfStartDate);
    // clear !== "CLEAR" && setToDate(firstSelectionOfToDate);
    // setPrevDate(state);
    // setShowDateRangePicker(false);

    // setHasMore(true);
    // setPage(1);
    // setFilteredTransactionsData([]);
    // const data = await getTransactionsList(
    //   1,
    //   limit,
    //   clear !== "CLEAR" ? firstSelectionOfStartDate : "",
    //   clear !== "CLEAR" ? firstSelectionOfToDate : "",
    //   searchQuery,
    //   isTotal,
    //   metroData,
    //   fromDateFilter,
    //   toDateFilter,
    //   adhocPayments,
    //   instantPayments,
    //   exceptionPayments,
    //   weeklyPayments,
    //   shift
    // );
    // setTotal(data?.total)
    // setPage(2);
    // setFilteredTransactionsData(data?.result);
    // if (data?.length < limit) setHasMore(false);
  };

  const getStatusText = (status) => {
    if (status === "completed") {
      return "Processed";
    } else if (status === "failed") {
      return "Failed";
    } else if (status === "pending" || status === "scheduled") {
      return "Pending"
    } else if (status === "canceled") {
      return "Cancelled"
    }
  };

  const handleSearchingDriver = useCallback(
    async (c) => {
      setFilteredTransactionsData([]);
      setHasMore(true);
      setPage(1);
      settotalAmounts(undefined)
      const data = await getTransactionsList(
        1,
        limit,
        fromDate,
        toDate,
        c === "CLEAR" ? "" : searchQuery,
        isTotal,
        metroData,
        fromDateFilter,
        toDateFilter,
        adhocPayments,
        instantPayments,
        exceptionPayments,
        weeklyPayments,
        shiftPayments,
        shift,
        transactionStatus,
        driver,
        paymentMethod
      );
      setTotal(data?.total)
      setFilteredTransactionsData(data?.result);
      settotalAmounts(data?.totalAmount)
      setPage(2);
      if (data?.result?.length < limit) setHasMore(false);
    },
    [limit, searchQuery, fromDate, toDate, adhocPayments, instantPayments, exceptionPayments, weeklyPayments, shiftPayments, transactionStatus, driver, shift, metroData]
  );

  function handleOrderPaymentData(data) {
    if (data?.bonusPrice) {
      return 'Weekly Payment'
    }

    if (data?.isRequestedPayments) {
      return 'Instant payment'
    }

    if (data?.exceptionNote) {
      return 'Payed with exception'
    }

    return 'Shift Payment'

  }

  function handlePaymentData(data) {
    if (data?.payment === 'adhocpayment') {
      return 'Adhoc Payment'
    }

    return 'Shift Payment'
  }


  const fetchMoreDrivers = async () => {
    let newData = await getTransactionsList(
      page,
      limit,
      fromDate,
      toDate,
      searchQuery,
      isTotal,
      metroData,
      fromDateFilter,
      toDateFilter,
      adhocPayments,
      instantPayments,
      exceptionPayments,
      weeklyPayments,
      shiftPayments,
      shift,
      transactionStatus,
      driver,
      paymentMethod
    );
    setTotal(newData?.total)

    setFilteredTransactionsData([...filteredTransactionsData, ...newData?.result]);
    if (newData?.result?.length === 0 || newData?.result?.length < limit) setHasMore(false);
    setPage(page + 1);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearchingDriver();
    }
  };

  const columns = [
    {
      name: "createdAt",
      label: "Transaction Date",
      sortable: true,
      className: "py-2 w-10",
      renderer: (item) =>
        item &&
        (item.createdAt
          ? moment(item.createdAt).format("MM-DD-YYYY hh:mm A")
          : "-"),
    },
    {
      name: "name",
      label: "Driver Name",
      sortable: true,
      className: "w-10",
    },
    {
      name: "metro",
      label: "Metro",
      className: "table__td_min-horiz-padding w-10",
      sortable: true,
    },
    {
      name: "amount",
      label: "Amount",
      sortable: true,
      className: "w-5",
      renderer: (item) =>
        item?.amount && typeof item.amount === "number"
          ? "$".concat(item.amount.toFixed(2))
          : "0.00",
    },
    (showBonusPrice) ? ({
      name: "bonusPrice",
      label: "Bonus Price",
      className: 'w-7',
      sortable: true,
      renderer: (item) => {
        return item?.order?.bonusPrice && typeof item?.order?.bonusPrice === "number"
          ? "$".concat(item?.order?.bonusPrice.toFixed(2))
          : "0.00";
      },
    }) : {
      label: "HIDE"
    },

    {
      name: "transactionId",
      label: "Transaction ID",
      className: "table__td_min-horiz-padding w-19",
      sortable: true,
      renderer: (item) =>
        item && (
          <>
            <span
              onClick={openTransactionModal}
              data-store-id={JSON.stringify(item)}
              className="transaction-id-text pr-1"
              style={{ wordBreak: 'break-all' }}
            >
              {item?.transactionId ? item?.transactionId : "-"}
            </span>
            {
              item?.order?.isRealTimePayments &&
              <span
                className="textStyleForStatus"
                style={handleStatusesBadge("created")}
              >
                RTP
              </span>
            }
          </>
        ),
    },
    {
      name: "order",
      label: "Payment Type",
      className: "table__td_min-horiz-padding w-9",
      sortable: true,
      renderer: (item) =>
        item && (
          <span
            className="payment-type-text"
          >
            {item?.order ? handleOrderPaymentData(item?.order) : handlePaymentData(item)}
          </span>
        ),
    },
    {
      name: "note",
      label: "Notes",
      className: "table__td_min-horiz-padding w-12",
      sortable: true,
    },
    {
      name: "paymentmode",
      label: "Method",
      className: "table__td_min-horiz-padding w-7",
      sortable: true,
      renderer: (item) => {
        return (
          <span >
            {item?.paymentmode || "DWOLLA"}
          </span>
        );
      },
    },
    {
      name: "shiftDate",
      label: "Shift Date",
      className: "table__td_min-horiz-padding w-7",
      sortable: true,
      renderer: (item) => {
        return (
          item &&
          (item.shiftDate
            ? moment(item.shiftDate.toString()).format("MM-DD-YYYY")
            : "-")
        );
      },
    },

    {
      name: "reason",
      label: "Reason",
      className: "w-10",
      sortable: true,
      renderer: (item) => (
        <>
          {item?.statusCode && <span
          >
            {item?.statusCode} : {item?.reason}
          </span>}
        </>
      ),
    },
    {
      name: "status",
      label: "Status",
      sortable: true,
      className: "table__td_centered table__td_min-horiz-padding w-7",
      renderer: (item) => (
        <span
          className="textStyleForStatus"
          style={handleStatusesBadge(item?.status)}>
          {item?.paymentmode === "BRANCH" ? (getStatusText(item?.status.toLowerCase())) : item?.status}
        </span>
      ),
    },
  ];

  const downloadReport = async () => {
    setLoading(true);
    settotalAmounts(undefined)
    const data = await getTransactionsList(
      undefined,
      undefined,
      fromDate,
      toDate,
      searchQuery,
      isTotal,
      metroData,
      fromDateFilter,
      toDateFilter,
      adhocPayments,
      instantPayments,
      exceptionPayments,
      weeklyPayments,
      shiftPayments,
      shift,
      transactionStatus,
      driver,
      paymentMethod
    );
    setTotal(data?.total)
    settotalAmounts(data?.totalAmount)
    if (data?.result && Array.isArray(data?.result) && data?.result?.length > 0) {
      return data?.result;
    }
    setLoading(false);
    return;
  };

  useEffect(() => {
    let timer = false;
    if (csvData && csvInstance.current && csvInstance.current.link) {
      timer = setTimeout(() => {
        csvInstance.current.link.click();
        setCsvData(false);
      });
    }

    return () => {
      clearTimeout(timer)
    }

  }, [csvData]);


  const exportData = async (value) => {
    setAnchorEl(null)
    setLoading(true);
    const download = await downloadReport()
    const data = transactionReport(download);
    const fileName = fromDate && toDate ? "Transactions_Infomation_from_".concat(fromDate).concat("_to_").concat(toDate).concat(".csv")
      : "Transactions_Infomation_All.csv";
    setLoading(false);
    if (value === "csv") {
      if (!showBonusPrice) {
        const obj = dataExport(data, fileName, header)
        setCsvData(obj);
      }
      else {
        const obj = dataExport(data, fileName, headers)
        setCsvData(obj);
      }

    } else if (value === "xlsx") {
      xlsxDataExport(data, fileName)
    }
  }

  const handleRefreshData = async () => {
    setIsLoading(true);
    settotalAmounts(undefined)
    setHasMore(false)
    const data = await getTransactionsList(
      1,
      limit,
      fromDate,
      toDate,
      searchQuery,
      isTotal,
      metroData,
      fromDateFilter,
      toDateFilter,
      adhocPayments,
      instantPayments,
      exceptionPayments,
      weeklyPayments,
      shiftPayments,
      shift,
      transactionStatus,
      driver,
      paymentMethod
    );
    if (data?.total >= limit) {
      setHasMore(true)
      setPage(2)
    }
    setTotal(data?.total)
    settotalAmounts(data?.totalAmount)
    setFilteredTransactionsData(data?.result);
    setIsLoading(false);
  }

  const handleCloseDrawer = () => {
    if (isApply) {
      setOpenDrawer(false)
    } else {
      setOpenDrawer(false)
      setMetros([])
      setPayments("")
      setPaymentMethod("")
      setFromDateFilter("")
      setToDateFilter("")
      setPrevDateFilter([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: "selection",
        },
      ])
      setState(currentDtArr)
    }
  }

  const handleReset = () => {
    setOpenDrawer(false)
    setMetros([])
    setPayments("")
    setPaymentMethod("")
    handlePaymentStateStatus(undefined)
    setFromDateFilter("")
    setToDateFilter("")
    setDriver([])
    setTransactionStatus([])
    setPrevDateFilter([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ])
    setIsApply(false)
    const { startDate, endDate } = getFirstAndLastDate(1)
    setShowDateRangePicker(false);
    setFromDate(() => moment(startDate).format("yyyy-MM-DD"))
    setToDate(() => moment(endDate).format("yyyy-MM-DD"))
    setShowDateRangePicker(false);
    setState([
      {
        startDate: new Date(startDate),
        endDate: new Date(endDate),
        key: "selection",
      }
    ]);
    setPrevDate([
      {
        startDate: new Date(startDate),
        endDate: new Date(endDate),
        key: "selection",
      }
    ]);
    actions.refetch.setIsDataRefetch(true);
  }
  return (
    <React.Fragment>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="card">
              <HeaderTextLayout
                headerText="Transactions Report"
                className="col-md-3 col-sm-6 main__driver"
                data={
                  filteredTransactionsData && filteredTransactionsData.length > 0
                    ? `${filteredTransactionsData.length} Transactions on Screen`
                    : ""
                }
                dataLength={
                  Array.isArray(filteredTransactionsData) ? filteredTransactionsData.length : "0"
                }

                totalLength={
                  total && total > 0
                    ? (filteredTransactionsData.length > 0 && filteredTransactionsData.length < 10)
                      ? `(${"0".concat(total)} Found)`
                      : filteredTransactionsData && filteredTransactionsData.length > 0 && `(${total} Found)`
                    : ""
                }
              >
                <div className="col-lg-9 col-md-9 filter">
                  <div className="filter__inner p-0 justify-content-end">
                    <div className="filter__ctrls-holder filter__input-holder d-flex justify-content-end">
                      <div className="label_container" style={{ marginRight: "10px" }}>
                        {
                          totalAmounts &&
                          <span className="label"> Total Amount: ${totalAmounts.toFixed(2)}</span>
                        }
                      </div>
                      <div style={{ display: "flex", gap: "1.25rem", alignItems: "center" }}>
                        <div className="searchbar__container" >
                          <input
                            disabled={isLoading ? true : false}
                            className="form__input customized__input"
                            value={searchQuery}
                            onChange={(e) => {
                              setSearchQuery(e.target.value);
                              if (e.target.value === "")
                                handleSearchingDriver("CLEAR");
                            }}
                            placeholder="Quick Search"
                            onKeyDown={handleKeyDown}
                          />
                          {searchQuery?.length > 0 && (
                            <ClearIcon
                              className="clear__btn filter__search-icon"
                              onClick={() => {
                                setSearchQuery("");
                                handleSearchingDriver("CLEAR");
                              }}
                              style={{
                                right: "45px",
                                top: "12px",
                              }}
                            />
                          )}
                          <button
                            disabled={searchQuery?.length <= 0}
                            className="search__button"
                            onClick={() => handleSearchingDriver()}
                          >
                            <IoSearchSharp />
                          </button>
                        </div>
                      </div>
                    </div>

                    {/* <div ref={wrapperRef}>
                      <div onClick={handleCustomRange} className="form__input">
                        <span>
                          {`${fromDate.length
                            ? `${moment(fromDate).format(
                              "MM-DD-yyyy"
                            )} TO ${moment(toDate).format("MM-DD-yyyy")}`
                            : "Select Custom Date Range"
                            }`}
                        </span>
                        <span className="pl-2">
                          {showDateRangePicker ? (
                            <KeyboardArrowUpIcon className="filter__search-icon" />
                          ) : (
                            <KeyboardArrowDownIcon className="filter__search-icon" />
                          )}
                        </span>
                      </div>
                      {showDateRangePicker && (
                        <div className="mainDateRangePicker">

                          <DateRangePicker
                            onChange={(item) => {
                              setState([item.selection]);
                            }}
                            showSelectionPreview={true}
                            moveRangeOnFirstSelection={false}
                            months={1}
                            ranges={state}
                            direction="horizontal"
                          />

                          <div className="button-container">
                            <button
                              onClick={() => {
                                setFromDate("");
                                setToDate("");
                                setShowDateRangePicker(false);
                                setState([
                                  {
                                    startDate: d,
                                    endDate: addDays(new Date(), 0),
                                    key: "selection",
                                  },
                                ]);
                                handleTimeRangeSelection("CLEAR");
                              }}
                              className="okButton clearButton"
                            >
                              <span>
                                <BackspaceIcon /> &nbsp;
                                <strong>CLEAR</strong>
                              </span>
                            </button>
                            <button
                              onClick={() => {
                                setShowDateRangePicker(false);
                              }}
                              className="cancelButton"
                            >
                              <span>
                                <CancelIcon
                                  sx={{ width: "1.5rem", height: "1.5rem" }}
                                />{" "}
                                <strong>cancel</strong>
                              </span>
                            </button>
                            <button
                              onClick={handleTimeRangeSelection}
                              className="okButton"
                            >
                              <span>
                                <ThumbUpAltIcon /> &nbsp;
                                <strong>Done</strong>
                              </span>
                            </button>
                          </div>
                        </div>
                      )}
                    </div> */}

                    <button className="refresh_btn filter_btn"
                      disabled={isLoading ? true : false}
                      onClick={() => setOpenDrawer(true)}
                    >
                      <img src={filterLogo} alt="" className="filterit" />
                    </button>

                    <div>
                      <button
                        id="fade-button"
                        className="btn btn_block btn_accent btn_export_15"
                        onClick={(event) => setAnchorEl(event.currentTarget)}
                        variant="contained"
                        disabled={
                          loadingCsv || filteredTransactionsData?.length === 0 || isLoading
                            ? true
                            : false
                        }
                      >
                        {loadingCsv ? (
                          <div className="loading" style={{ color: "white" }}>
                            <span className="loading__text" style={{ color: "white" }}>
                              Exporting
                            </span>
                            ...
                          </div>
                        ) : (
                          <> <span> Export <KeyboardArrowDownIcon className="mr-0" /></span> </>
                        )}
                      </button>
                      <Menu
                        id="fade-menu"
                        MenuListProps={{
                          'aria-labelledby': 'fade-button',
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClick={() => setAnchorEl(null)}
                        TransitionComponent={Fade}
                      >
                        {exportMenu.map(({ value, name }) => (
                          <MenuItem key={name} value={name} onClick={() => exportData(value)} style={{ width: "100px" }}>
                            {name}
                          </MenuItem>

                        ))}
                      </Menu>
                    </div>

                    <button disabled={isLoading ? true : false} className="refresh_btn" onClick={handleRefreshData}>
                      <Refresh className="refresh_icon" />
                    </button>
                    {csvData ? (
                      <CSVLink
                        data={csvData.data}
                        headers={csvData.headers}
                        filename={csvData.filename}
                        ref={csvInstance}
                      />
                    ) : undefined}
                  </div>
                </div>
              </HeaderTextLayout>

              <InfiniteScroll
              className="infinit_table"
                dataLength={filteredTransactionsData?.length}
                next={fetchMoreDrivers}
                hasMore={hasMore}
                style={{
                  paddingBottom: !hasMore ? "0px" : "10px",
                }}
                loader={
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{
                      height:
                        filteredTransactionsData &&
                          filteredTransactionsData?.length <= 0
                          ? "70vh"
                          : "auto",
                    }}
                  >
                    <CircularProgress className="loading-progress" />
                  </div>
                }
                height={"80vh"}
                endMessage={
                  filteredTransactionsData &&
                  filteredTransactionsData?.length <= 0 && (
                    <div
                      style={{
                        height: "70vh",
                      }}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <p>
                        <b>No Result Found</b>
                      </p>
                    </div>
                  )
                }
              >
                {isLoading ? (
                  <div className="loading-holder">
                    <CircularProgress className="loading-progress" />
                  </div>
                ) : (
                  <Table className={'table_fixed'} columns={columns} items={filteredTransactionsData} />
                )}
              </InfiniteScroll>

              <CustomTransactionsDrawer
                openDrawer={openDrawer}
                handleCloseDrawer={handleCloseDrawer}
                handleApplyFilter={handleApplyFilter}
                handleReset={handleReset}
                handleCustomRange={handleCustomRangeFilter}
                onResetClick={onResetClickFilter}
                handleTimeRangeSelection={handleTimeRangeSelectionFilter}
                onCancelClick={onCancelClickFilter}
                handleCustomRangeTransactions={handleCustomRange}
                handleTimeRangeSelectionTransactions={handleTimeRangeSelection}
                onCancelClickTransactions={onCancelClick}
                onResetClickTransactions={onResetClick}
                obj={propsobj}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
