import React, { useState, useEffect } from "react";
import { MdRefresh } from "react-icons/md";
import { CircularProgress } from "@mui/material";
import moment from "moment";
import { IoAdd, IoSearchSharp } from "react-icons/io5";
import cn from "classnames";

import Table from "../../../components/shared/Table";
import {
  useGetDepositListQuery,
  useDepositBalanceMutation,
  useGetDepositStatusMutation,
  useGetTotalBalanceQuery,
} from "../../../api/admins";
// import { useGetEstimatedPaymentQuery } from "../../../api/orders";
import HeaderTextLayout from "../../../components/shared/HeaderTextLayout";
import { convertToCapitalize } from "../../../constants/extras/convertToCapitalize";
import { handleStatusesBadge } from "../../../constants/extras/statusesBadge";
import "../../../styles/_data.scss";
import "./style.scss";
import { useGetEstimatedShiftPayQuery } from "../../../api/reports";
import { handleEstimatedShiftPay } from "../../../constants/extras/handleShiftPayAndTime";

export default function Deposit() {
  const { data: depositList, isFetching } = useGetDepositListQuery(null, {
    refetchOnMountOrArgChange: true,
  });

  const [amount, setAmount] = useState();
  const [estimated_pay, setEstimatedPay] = useState(["", ""]);
  const [refresh, refreshReq] = useGetDepositStatusMutation();
  const { data: totalBalance } = useGetTotalBalanceQuery(null, {
    refetchOnMountOrArgChange: true,
  });

  // const { data: estimated_pay } = useGetEstimatedPaymentQuery(null, {
  //   refetchOnMountOrArgChange: true,
  // });
  const { data: estimatedPay } = useGetEstimatedShiftPayQuery(null, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (!estimatedPay && estimatedPay?.status !== 200) return;

    setEstimatedPay([
      estimatedPay?.result?.estimatedShiftPayFrom,
      estimatedPay?.result?.estimatedShiftPayTo,
    ]);
  }, [estimatedPay]);

  const [filteredDepositList, setFilteredDepositList] = useState([]);
  const [depositBalance] = useDepositBalanceMutation();
  const [startDeposit, setStartDeposit] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (!depositList) return;

    setFilteredDepositList(depositList);
    if (searchQuery) {
      setFilteredDepositList(
        depositList.filter((item) => {
          return (
            (item.user &&
              item.user.username
                .toLowerCase()
                .includes(searchQuery.toLowerCase())) ||
            (item.user.firstName &&
              item.user.firstName
                .toLowerCase()
                .includes(searchQuery.toLowerCase())) ||
            (item.user.lastName &&
              item.user.lastName
                .toLowerCase()
                .includes(searchQuery.toLowerCase())) ||
            (item.transactionId &&
              item.transactionId
                .toLowerCase()
                .includes(searchQuery.toLowerCase()))
          );
        })
      );
    }
  }, [depositList, searchQuery]);

  const columns = [
    {
      name: "date",
      label: "Date",
      sortable: true,
      renderer: (item) =>
        item.date && moment(item.date).format("MM-DD-YYYY HH:mm A"),
    },
    {
      name: "user-username",
      label: "Username",
      sortable: true,
      renderer: (item) => item.user.username,
    },
    {
      name: "user-firstName",
      label: "Depositer Name",
      sortable: true,
      renderer: (item) =>
        (item?.user?.firstName ? item?.user?.firstName : "-") +
        " " +
        (item?.user?.lastName ? item?.user?.lastName : ""),
    },
    {
      name: "amount",
      label: "Amount",
      sortable: true,
      renderer: (item) => handleEstimatedShiftPay(item?.amount),
    },
    { name: "transactionId", label: "Transaction Id", sortable: true },
    {
      name: "status",
      label: "Status",
      sortable: true,
      className: "table__td_centered align-center w-10",
      renderer: (item) => (
        <span style={handleStatusesBadge(item && item?.status)}>
          {convertToCapitalize(item && item?.status)}
        </span>
      ),
    },
    {
      name: "refresh",
      className: "table__td_have-btn py-1",
      renderer: (item) => (
        <button
          className={cn("btn btn_accent customized-details-btn", {
            btn_disabled: refreshReq.isLoading,
          })}
          onClick={() => refresh(item._id)}
        >
          {refreshReq.originalArgs === item._id && refreshReq.isLoading && (
            <CircularProgress className="btn__progress" />
          )}
          Refresh <MdRefresh className="btn__icon btn__icon_right" />
        </button>
      ),
    },
  ];

  const showingTotalBalance = () => {
    if (typeof totalBalance === "number") {
      return totalBalance.toFixed(2);
    }
    return "0";
  };

  const handleDeposit = async () => {
    setStartDeposit(true);
    let result = await depositBalance({ amount });

    if (result) {
      setAmount("");
      setStartDeposit(false);
    }
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="card">
              <HeaderTextLayout
                headerText="Deposit"
                className="col-md-2"
                data={
                  filteredDepositList && filteredDepositList.length > 0
                    ? `${filteredDepositList.length} depositer Found`
                    : ""
                }
                dataLength={
                  Array.isArray(filteredDepositList)
                    ? filteredDepositList.length
                    : "0"
                }
              >
                <div className="col-md-10 d-flex filter justify-content-end">
                  <div className="filter__inner p-0">
                    <div className="filter__ctrls-holder filter__input-holder d-flex justify-content-end">
                      <input
                        type="number"
                        className="form__input"
                        style={{
                          width: "8.2rem",
                          marginRight: "3%",
                        }}
                        placeholder="Amount($)"
                        value={amount}
                        onInput={(e) => {
                          setAmount(e.target.value);
                        }}
                        min="0"
                      />
                      <button
                        disabled={startDeposit && true}
                        className="btn btn_accent"
                        onClick={() => {
                          handleDeposit();
                        }}
                      >
                        {startDeposit ? (
                          <span className="deposit__btn">
                            Deposit &nbsp;
                            <CircularProgress className="btn__progress" />
                          </span>
                        ) : (
                          <span className="deposit__btn">
                            Deposit &nbsp;
                            <IoAdd className="btn__icon btn__icon_right" />{" "}
                          </span>
                        )}
                      </button>
                      <div>
                        <div className="estimated__pay__text">
                          <span>Balance:</span>{" "}
                          <span>${showingTotalBalance()}</span>
                        </div>

                        <div className="estimated__pay__text">
                          <span>Estimated Pay:</span>
                          <span>
                            {handleEstimatedShiftPay(
                              estimated_pay[0],
                              estimated_pay[1]
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex justify-content-end align-items-center">
                        <IoSearchSharp className="filter__search-icon mx-2" />
                        <input
                          type="search"
                          className="form__input"
                          value={searchQuery}
                          onChange={(e) => setSearchQuery(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </HeaderTextLayout>
              {isFetching ? (
                <div className="loading-holder">
                  <CircularProgress className="loading-progress" />
                </div>
              ) : (
                <React.Fragment>
                  {filteredDepositList && filteredDepositList.length ? (
                    <div className="table-wrapper">
                      <Table
                        columns={columns}
                        items={filteredDepositList}
                        className="table_stores"
                      />
                    </div>
                  ) : (
                    <div className="no_results">No Results</div>
                  )}
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
