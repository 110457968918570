import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { timeTransform_h } from "../../../util/time";

const HourTimepicker = (props) => {
  const h_array = [];

  for (let i = 0; i <= 23; i++) {
    h_array.push(i < 10 ? "0" + i : i.toString());
  }

  const [time, setTime] = useState(timeTransform_h.toDisplay(props.value));

  useEffect(() => {
    props.updateTime(timeTransform_h.toValue(time));
  }, [time]);

  useEffect(() => {
    setTime(timeTransform_h.toDisplay(props.value));
  }, [props]);

  return (
    <div className="form__item">
      <Stack spacing={1.5} direction="row" mt={1.5}>
        <FormControl size="small">
          <InputLabel>Hours</InputLabel>
          <Select
            name="H"
            label="Hours"
            value={time}
            onChange={(e) => setTime(e.target.value)}
            disabled={props?.disabled}
          >
            {h_array.map((item, index) => (
              <MenuItem key={index} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
    </div>
  );
};

export default HourTimepicker;
