import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuthInterceptor } from "./util";

export const invitedAdminsApi = createApi({
  reducerPath: "invitedAdminsApi",
  baseQuery: baseQueryWithAuthInterceptor({ baseUrl: "/api/" }),
  tagTypes: ["invitedAdmin"],
  endpoints: (builder) => ({
    getInvited: builder.query({
      query: (val) => ({
        url: "invitationEmails",
        method: "GET",
      }),
    }),
    removeInvited: builder.mutation({
      query: (val) => ({
        url: `invitationEmails/${val}`,
        method: "DELETE",
        body: { id: val },
      }),
      //invalidatesTags: (result, error, arg) => !error && ['invitationEmails'],
    }),
    createInvited: builder.mutation({
      query: (body) => {
        return {
          url: "newInvitationEmail",
          method: "POST",
          body: { email: body.email, role: body.role, metros: body.metros },
        };
      },
    }),
    getEmailByHash: builder.mutation({
      query: (val) => ({
        url: `invitationEmail/${val.hash}`,
        method: "GET",
      }),
    }),
    getConfirmEmailByHash: builder.mutation({
      query: (val) => ({
        url: `confirmEmail/${val.hash}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetInvitedQuery,
  useRemoveInvitedMutation,
  useCreateInvitedMutation,
  useGetEmailByHashMutation,
  useGetConfirmEmailByHashMutation,
} = invitedAdminsApi;
