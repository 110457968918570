export const arrOfStatus = [
  "created",
  "assigned",
  "rejected",
  "cancelled",
  "delivery_started",
  "complete",
  "approved",
  "paid",
  "remain",
  "interestedDriver",
],
  arrOfExcludingStatus = ["created", "paid", "approved", "cancelled"],
  schedulersArr = ["pipeline_drivers", "active_drivers", "actual_shifts"];

export const arrOftype = ["FreeText", "Star", "Options"]

export const weekdays = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

export const scheduleTime = [
  'Early Morning (4AM - 8AM)',
  'Morning (8AM - 12PM)',
  'Afternoon (12PM - 4PM)',
  'Evening (4PM - 8PM)',
  'Late Evening (8PM - 12AM)',
];

export const sched_init = [
  {
    title: '1-10 hours',
    id: "0",
  },
  {
    title: '11-20 hours',
    id: "1",
  },
  {
    title: '21-30 hours',
    id: "2",
  },
  {
    title: '31 hours or more',
    id: "3",
  },
];


export const coachingSection = [{ title: "Onboarding", value: "onboarding" }, { title: "Shift", value:"shift" }]
export const coachingType = [{ title: "Video", value: "video" }, { title: "Link", value: "link"}, { title: "Document (PDF)", value: "document" }]
export const orderNumber = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]

export const coachReason = [
  { title:"No signature", value: "No signature"},
  { title:"No proof of delivery", value: "No proof of delivery"},
  { title:"No proof of delivery for +21 packages", value: "No proof of delivery for +21 packages"},
  { title:"No input of name and birthdate", value: "No input of name and birthdate"},
  { title:"Package delivered to the wrong address", value: "Package delivered to the wrong address"},
  { title:"Not returning packages", value: "Not returning packages"},
  { title:"Driver misbehaviour", value: "Driver misbehaviour"},
  { title:"POD", value: "POD"},
  { title:"Route verification", value: "Route verification"},
]
export const typeOfCoaching = [
  {title:"Onboarding videos sent", value:"Videos"},
  {title:"Coaching Call", value:"CoachingCall"},
  {title:"Coaching Message", value:"CoachingMessage"},
  {title:"Links", value:"Links"},
]
export const coachingStatus = ["Pending", "Done"]

export const attachmentType = ["Video", "Link", "Document (PDF)"]

export  const exportMenu = [
  { name: 'CSV', value: 'csv' },
  { name: 'XLSX', value: 'xlsx' },
];

export const statusList = ['complete', 'approved', 'paid'];

export const paymentMethodOptions = [
  { value: 'BRANCH', label: 'BRANCH' },
  { value: 'DWOLLA', label: 'DWOLLA' },
]

export const shiftRouteSelectOption = [
  { label: "All", value: "all" },
  { label: "Route", value: "route" },
  { label: "Sort", value: "sort" }
]