/* eslint-disable no-control-regex */
import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import { TiCancel } from "react-icons/ti";
import {
  CircularProgress,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  TextField,
} from "@mui/material";
import Table from "../shared/Table";
import {
  useGetInvitedQuery,
  useCreateInvitedMutation,
  useRemoveInvitedMutation,
} from "../../api/invitedAdmins";
import "./style.scss";
import ConfirmDialog from "../shared/customModal/confirmDialog";
import { actions } from "../../redux/store/store";
import { changeRoleDatas } from "../../constants/menuLists";
import ReactSelect from "react-select";
import { useGetMetrosQuery } from "../../api/metro";
import {
  Option,
  ValueContainer,
  animatedComponents,
  compare,
} from "../../components/shared/options/MessageOption";
import { metroListOptionStyles } from "./metroListOptionStyles";
import { sendErrorMessage } from "../../constants/extras/errorHandlers";

const Invited = () => {
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(undefined);
  const [isEmailDirty, setIsEmailDirty] = useState(false);
  const emailRegexp =
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
  const handleChangeEmail = (val) => {
    setIsEmailDirty(true);
    let result = emailRegexp.test(val);
    if (typeof val === "string") {
      val = val.toLowerCase().trim();
    }
    setEmail(val);
    setIsEmailValid(result);
  };

  const [adminsInvited, setAdminsInvited] = useState([]);
  const [withdrawInvitation, setWithdrawInvitation] = useState([false, ""]);
  const [role, setRole] = useState("admin");
  const [options, setOptions] = useState([]);
  const [metros, setMetros] = useState([]);
  const { data: admins_invited, isFetching } = useGetInvitedQuery(null, {
    refetchOnMountOrArgChange: true,
  });

  const { data: AllMetros } = useGetMetrosQuery(null, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    let metros =
      AllMetros && Array.isArray(AllMetros) && AllMetros?.slice().sort(compare);
    setOptions(
      metros &&
      metros?.map((item, i) => {
        return {
          value: item?.name ? item?.name : "-",
          label: `${item?.name ? item?.name : ""}`,
          id: item?._id
        };
      })
    );
  }, [AllMetros, role]);

  const [
    createInvited,
    { error: EmailSendingError, isLoading: isEmailSending },
  ] = useCreateInvitedMutation();
  const [removeInvitedAdmin] = useRemoveInvitedMutation();

  const checkForError = (result) => {
    actions.notifications.add({
      message:
        result?.error &&
        (result.error.message ||
          result.error.data.message ||
          "Something went wrong..!"),
      status: "error",
    });
  };

  const handleClickSend = async (e) => {
    e.preventDefault();
    const data = metros.map((metro) => {
      return metro.id;
    });
    const result =
      role === "metro_manager"
        ? await createInvited({ email: email, role: role, metros: data })
        : await createInvited({ email: email, role: role });
    if (result.data && !result.data.error) {
      setEmail("");
      setMetros([]);
      setIsEmailValid(undefined);
      setIsEmailDirty(false);
      setAdminsInvited(result.data);
      setRole("admin");
      actions.notifications.add({
        message: `Invitation for role of ${role} sent successfully`,
        status: "success",
      });
    } else if (result.data.error) {
      setEmail("");
      setMetros([]);
      setRole("admin");
      setIsEmailDirty(false);
      sendErrorMessage(result.data.message)
    } else {
      checkForError(result);
    }
  };

  const handleClickDelete = useCallback(
    async (val) => {
      const result = await removeInvitedAdmin(val);
      if (!result.error && result.data) {
        setAdminsInvited(result.data);
        actions.notifications.add({
          message: `Invitation withdraw successfully`,
          status: "success",
        });
        setWithdrawInvitation([false, ""]);
      } else {
        checkForError(result);
        setWithdrawInvitation([false, ""]);
      }
    },
    [removeInvitedAdmin]
  );

  useEffect(() => {
    setAdminsInvited(admins_invited);
  }, [admins_invited]);

  const columns_invited = [
    {
      name: "timestamp",
      label: "Date",
      sortable: true,
      className: "table__td-time",
      renderer: (item) =>
        item.timestamp && moment(item.timestamp).format("lll"),
    },
    {
      name: "invitingAdmin-username",
      label: "Inviting Super Admin",
      sortable: true,
      renderer: (item) => item.invitingAdmin && item.invitingAdmin.username,
    },
    {
      name: "email",
      label: "Email",
      sortable: true,
    },
    {
      name: "reject",
      className: "table__td_have-btn align-center",
      renderer: (item) => (
        <button
          className="btn btn_block customized-details-btn"
          onClick={() => setWithdrawInvitation([true, item._id])}
        >
          Withdraw Invitation
          <TiCancel className="btn__icon btn__icon_right btn__icon_danger btn__icon_larger" />
        </button>
      ),
    },
  ];

  return (
    <React.Fragment>
      {withdrawInvitation[0] && (
        <ConfirmDialog
          bodyText="Are you sure you want to withdraw this invitation?"
          isOpen
          onClose={() => {
            setWithdrawInvitation([false, ""]);
          }}
          confirmAction={() => {
            handleClickDelete(withdrawInvitation[1]);
          }}
        />
      )}
      <div className="screen-users__form-wrapper">
        <div className="container container_narrow screen-users__container mainTabBackground mainTabWidth">
          <div
            className="screen-users__form-body form-body-invited"
            style={{ maxWidth: role === "metro_manager" && "51rem", paddingTop: role === "metro_manager" ? "0px" :"10px" }}
          >
            <form>
              <div className="form__item">
                <div className="form__input-group" style={{ marginTop: "6%" }}>
                  <TextField
                    className={`form__input ${!isEmailValid && isEmailDirty
                      ? "form__input_invalid"
                      : null
                      }`}
                    type="email"
                    value={email}
                    onChange={(event) => handleChangeEmail(event.target.value)}
                    disabled={isEmailSending}
                    tabIndex="1"
                    style={{ height: "auto" }}
                    id="outlined-basic"
                    label="Email"
                    variant="outlined"
                    autoComplete="off"
                  />
                  <FormControl
                    sx={{ width: role === "metro_manager" ? "500px" : "250px" }}
                  >
                    <InputLabel id="demo-simple-select-label">Role</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      sx={{ height: "auto", width: "auto" }}
                      value={role}
                      onChange={(e) => {
                        setRole(e.target.value);
                      }}
                      defaultValue={role.value || "admin"}
                      label="Role"
                    >
                      {changeRoleDatas.map((changeRoleData, idx) => {
                        return (
                          <MenuItem value={changeRoleData.value} key={`${idx}`}>
                            {changeRoleData.title}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  {role === "metro_manager" && (
                    <ReactSelect
                      placeholder={"Select Metro"}
                      options={
                        options && Array.isArray(options) && options?.length > 0
                          ? options
                          : []
                      }
                      value={metros}
                      onChange={(value) => setMetros(value)}
                      isMulti={true}
                      allowSelectAll={true}
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      styles={metroListOptionStyles}
                      components={{
                        Option,
                        ValueContainer,
                        animatedComponents,
                        IndicatorSeparator: () => null,
                      }}
                    ></ReactSelect>
                  )}
                  <button
                    type="submit"
                    className="btn btn_accent"
                    disabled={
                      !isEmailValid ||
                      email.length === 0 ||
                      isEmailSending ||
                      (role === "metro_manager" && metros.length === 0)
                    }
                    onClick={(e) => handleClickSend(e)}
                    tabIndex="2"
                  >
                    {isEmailSending ? (
                      <CircularProgress className="btn__progress" />
                    ) : (
                      <React.Fragment>Send</React.Fragment>
                    )}
                  </button>
                </div>
                {!isEmailValid && isEmailDirty && email.length > 0 && (
                  <div className="form__msg form__msg_invalid">
                    Email is invalid
                  </div>
                )}
                {EmailSendingError && (
                  <div className="form__msg form__msg_invalid">
                    Error: email not sent
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
      <div
        className="container-fluid"
        style={{
          maxWidth: "91%",
        }}
      >
        <div className="card">
          {isFetching ? (
            <div className="user_loading">
              <CircularProgress className="loading-progress" />
            </div>
          ) : adminsInvited && adminsInvited.length ? (
            <div
              className="table-wrapper"
              style={{ height: "60vh", overflow: "auto", zIndex: "1" }}
            >
              <Table
                columns={columns_invited}
                items={adminsInvited}
                className="table_users table_users_current"
              />
            </div>
          ) : (
            <div className="no_results">No Results</div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Invited;
