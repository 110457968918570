import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ImUser, ImUserPlus, ImUsers } from "react-icons/im";
import { BiCodeBlock } from "react-icons/bi";
import "./style.scss";
import Current from "./Current";
import Invited from "./Invited";
import Logs from "./Logs";

const Users = (props) => {
  const { onSetUsersTab } = props;
  const { tab } = useParams();
  const navigate = useNavigate();
  const [tabActive, setTabActive] = useState(tab ? tab : props.usersTab);

  useEffect(() => {
    if (typeof onSetUsersTab === "function") {
      onSetUsersTab(tabActive);
    }
  }, [tabActive, onSetUsersTab]);

  const handleSetTab = (newTab) => {
    setTabActive(newTab);
    navigate(`/users/${newTab}`);
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="content-wrapper screen screen-users">
          <div className="container container_narrow container_tabs mainTabWidth">
            <div className="tabs tabs_users">
              <div
                className={`tabs__item ${
                  tabActive === "Current" ? "active" : ""
                } tabs__item_current`}
                onClick={() => {
                  handleSetTab("Current");
                }}
              >
                <ImUsers className="tabs__icon" /> Current
              </div>
              <div
                className={`tabs__item ${
                  tabActive === "Invited" ? "active" : ""
                } tabs__item_invited`}
                onClick={() => {
                  handleSetTab("Invited");
                }}
              >
                <ImUserPlus className="tabs__icon" /> Invited
              </div>
              <div
                className={`tabs__item ${
                  tabActive === "Logs" ? "active" : ""
                } tabs__item_log`}
                onClick={() => {
                  handleSetTab("Logs");
                }}
              >
                <div className="tabs__icon-group">
                  <ImUser className="tabs__icon" />
                  <BiCodeBlock className="tabs__icon_add" />
                </div>
                Logs
              </div>
            </div>
          </div>

          {(() => {
            switch (tabActive) {
              case "Current":
                return <Current />;
              case "Invited":
                return <Invited />;
              case "Logs":
                return <Logs />;
              default:
                return <React.Fragment></React.Fragment>;
            }
          })()}
        </div>
      </div>
    </div>
  );
};
export default Users;
