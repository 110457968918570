import _ from "lodash";
import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query/react";
import metroReducer, { metroSlice } from "../slices/metroSlice";
import companyReducer, { companySlice } from "../slices/companySlice";
import driversReducer, { driversSlice } from "../slices/driversSlice";
import isRefetchReducer, { refetchDataSlice } from "../slices/refetchDataSlice";
import hubReducer, { hubSlice } from "../slices/hubSlice";
import modalReducer, { modalSlice } from "../slices/modalSlice";
import authReducer, { authSlice } from "../slices/authSlice";
// import schedulingReducer, { schedulingSlice } from "../slices/schedulingSlice";
import notificationsReducer, {
  notificationsSlice,
} from "../slices/notificationsSlice";
import { ordersApi } from "../../api/orders";
import { storesApi } from "../../api/stores";
import { driversApi } from "../../api/drivers";
import { adminsApi } from "../../api/admins";
import { authApi } from "../../api/auth";
import { reportsApi } from "../../api/reports";
import { slackMessageApi } from "../../api/slackMessage";
import { metrosApi } from "../../api/metro";
import { invitedAdminsApi } from "../../api/invitedAdmins";
import { logsApi } from "../../api/logs";
import { emailApi } from "../../api/email";
import { notificationApi } from "../../api/notification";
import { shiftTemplateApi } from "../../api/shiftTemplate";
import { schedulesApi } from "../../api/schedules";
import { savedFilterApi } from "../../api/savedFilter";
import { companyApi } from "../../api/company";
import { feedbackApi } from "../../api/feedback";
import { driverCoachingApi } from "../../api/driverCoaching";
import { coachingApi } from "../../api/coaching";
import { settingApi } from "../../api/setting";
import { paymentCalendarApi } from "../../api/paymentCalendar";


const store = configureStore({
  reducer: {
    metro: metroReducer,
    company: companyReducer,
    hub: hubReducer,
    modal: modalReducer,
    auth: authReducer,
    notifications: notificationsReducer,
    drivers: driversReducer,
    refetch: isRefetchReducer,
    [ordersApi.reducerPath]: ordersApi.reducer,
    [storesApi.reducerPath]: storesApi.reducer,
    [driversApi.reducerPath]: driversApi.reducer,
    [adminsApi.reducerPath]: adminsApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [reportsApi.reducerPath]: reportsApi.reducer,
    [slackMessageApi.reducerPath]: slackMessageApi.reducer,
    [metrosApi.reducerPath]: metrosApi.reducer,
    [invitedAdminsApi.reducerPath]: invitedAdminsApi.reducer,
    [logsApi.reducerPath]: logsApi.reducer,
    [shiftTemplateApi.reducerPath]: shiftTemplateApi.reducer,
    [emailApi.reducerPath]: emailApi.reducer,
    [notificationApi.reducerPath]: notificationApi.reducer,
    [schedulesApi.reducerPath]: schedulesApi.reducer,
    [savedFilterApi.reducerPath]: savedFilterApi.reducer,
    [companyApi.reducerPath]: companyApi.reducer,
    [feedbackApi.reducerPath]: feedbackApi.reducer,
    [driverCoachingApi.reducerPath]: driverCoachingApi.reducer,
    [coachingApi.reducerPath]: coachingApi.reducer,
    [settingApi.reducerPath]: settingApi.reducer,
    [paymentCalendarApi.reducerPath]: paymentCalendarApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(ordersApi.middleware)
      .concat(storesApi.middleware)
      .concat(driversApi.middleware)
      .concat(adminsApi.middleware)
      .concat(authApi.middleware)
      .concat(reportsApi.middleware)
      .concat(slackMessageApi.middleware)
      .concat(metrosApi.middleware)
      .concat(companyApi.middleware)
      .concat(invitedAdminsApi.middleware)
      .concat(logsApi.middleware)
      .concat(shiftTemplateApi.middleware)
      .concat(emailApi.middleware)
      .concat(notificationApi.middleware)
      .concat(schedulesApi.middleware)
      .concat(savedFilterApi.middleware)
      .concat(feedbackApi.middleware)
      .concat(driverCoachingApi.middleware)
      .concat(coachingApi.middleware)
      .concat(settingApi.middleware)
      .concat(paymentCalendarApi.middleware)
});

setupListeners(store.dispatch);

const createActions = (slice) =>
  _.mapValues(
    slice.actions,
    (actionCreator) => (payload) => store.dispatch(actionCreator(payload))
  );

export const actions = {
  modal: createActions(modalSlice),
  metro: createActions(metroSlice),
  company: createActions(companySlice),
  hub: createActions(hubSlice),
  auth: createActions(authSlice),
  notifications: createActions(notificationsSlice),
  drivers: createActions(driversSlice),
  refetch: createActions(refetchDataSlice),
};

export default store;
