import { createSlice } from "@reduxjs/toolkit";

export const driversSlice = createSlice({
  name: "drivers",
  initialState: {
    value: [],
  },
  reducers: {
    addDrivers: (state, action) => {
      state.value = [...action.payload];
    },
  },
});

export const { addDrivers } = driversSlice.actions;
export default driversSlice.reducer;
