import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import HeaderTextLayout from '../../../components/shared/HeaderTextLayout';
import { actions } from '../../../redux/store/store';
import { CircularProgress } from '@mui/material';
import Table from '../../../components/shared/Table';
import { IoIosAddCircle } from 'react-icons/io';
import CustomCoachingDrawer from '../../../components/shared/customDrawer/driverCoaching';
import { useGetCoachingsQuery } from '../../../api/driverCoaching';
import details_icon from "../../../images/details.svg"

function DriverCoach() {
  const location = useLocation();
  const { pathname } = location;

  const { data: coaching, isFetching: isCoachLoading } = useGetCoachingsQuery("", {
    refetchOnMountOrArgChange: true
  });

  const [filteredDriverCoach, setFilteredDriverCoach] = useState([]);

  useEffect(() => {
    actions.refetch.setIsDataRefetch(true);
  }, [pathname])

  useEffect(() => {
    setFilteredDriverCoach(coaching)
  }, [coaching])

  const columns = [
    { name: "section", label: "Section", sortable: true, className: "w-8" },
    { name: "orderNumber", label: "Order Number", sortable: true, className: "w-8" },
    { name: "title", label: "Title", sortable: true },
    { name: "type", label: "Type", sortable: true, className: "w-8" },
    {
      name: "details",
      className: "table__td_have-btn w-5 text-center px-3",
      renderer: (item) => (
        <div
          onClick={() => {
            actions.modal.openDriverChoching(item);
          }}
          style={{ fontSize: "85%", padding: "0 0.3rem", cursor: "pointer", padding: "10px 0" }}
        >
          <img src={details_icon} alt="" width="20px" />
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="card">
              <HeaderTextLayout
                headerText="Driver Coaching"
                className="col-md-2 main__driver"
                data={
                  filteredDriverCoach && filteredDriverCoach.length > 0
                    ? `${filteredDriverCoach.length} Driver Coaching Found`
                    : ""
                }
                dataLength={
                  Array.isArray(filteredDriverCoach) ? filteredDriverCoach.length : "0"
                }
              >
                <div className="col-md-10 col-sm-10 d-flex justify-content-end align-items-center filter">
                  <div className="col-md-10 col-sm-10 d-flex justify-content-end align-items-center">
                    <button
                      style={{
                        background: "white",
                        borderColor: "#e6e6e6",
                        width: "220px",
                      }}
                      disabled={isCoachLoading}
                      className="btn btn__accent mr-0 ml-3"
                      onClick={() => actions.modal.openDriverChoching()}
                    >
                      <IoIosAddCircle style={{ fontSize: "22px" }} />
                      Add Driver Coaching
                    </button>
                  </div>
                </div>
              </HeaderTextLayout>

              <CustomCoachingDrawer coaching={coaching} />

              {isCoachLoading ? (
                <div className="loading-holder">
                  <CircularProgress className="loading-progress" />
                </div>
              ) : (
                <React.Fragment>
                  {filteredDriverCoach && filteredDriverCoach.length ? (
                    <div className="table-wrapper normal_table">
                      <Table
                        columns={columns}
                        items={filteredDriverCoach}
                        className="table_metros"
                      />
                    </div>
                  ) : (
                    <div className="no_results">No Results</div>
                  )}
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DriverCoach
