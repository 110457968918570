import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
// import { actions } from '../redux/store/store'
// import { useGetCurrentUserQuery } from '../api/auth'

const AuthHandler = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const currentUser = useSelector((state) => state.auth.currentUser);
  const isLoading = useSelector((state) => state.auth.isLoading);

  useEffect(() => {
    if (
      !currentUser &&
      isLoading === false &&
      !["/register", "/forgotPassword"].includes(pathname)
    ) {
      navigate("/login");
    } else if(
      currentUser && 
      ["/login"].includes(pathname)
    ) {
      navigate("/hubs");
    } else if (
      currentUser &&
      currentUser?.role === "bookkeeper" &&
      !["/deposit", "/reports/drivers-earning", "/transactions", "/payments"].includes(
        pathname
      ) // Bookkeeper can't access screens other than this
    ) {
      navigate("/deposit");
    } else if (
      currentUser &&
      currentUser?.role !== "root" &&
      [
        "/hubs",
        "/payments",
        "/users",
        "/reports/drivers-earning",
        "/reports/transactions",
        "/metros",
      ].includes(pathname) // only root can access this screens
    ) {
      navigate("/shifts");
    } else if (
      currentUser &&
      currentUser?.role !== "bookkeeper" &&
      ["/deposit"].includes(pathname) // only bookkeeper can access deposit screen
    ) {
      navigate("/shifts");
    }
  }, [currentUser, isLoading]);

  return null;
};

export default AuthHandler;
