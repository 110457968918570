import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import { actions } from "../../../../redux/store/store";

const EditCompany = (props) => {
    const title = props?.title
    const company = props?.company;
    const companyCode = props?.company && props?.company.companyCode;
    const companyName = props?.company && props?.company.companyName;
    const role = props && props.currentUser && props?.currentUser?.role;
    const id = props && props?.id && props?.id;
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "space-between",
            }}
        >
            <span>{title === "Company" ? <>{companyCode ? companyCode : ""} {companyCode && companyName ? "-" : ""} {companyName ? companyName : ""}</> : company }</span>
            {role === "root" && props.edit &&
                <span
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                        actions.modal.openCompany({
                            company,
                            id,
                            title
                        })
                    }
                >
                    <EditIcon style={{ width: "20px", height: "20px" }} />
                </span>
            }
        </div>
    );
};

export default EditCompany;
