export function getMonday(d) {
  d = new Date(d);
  var day = d.getDay(),
    diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
  return new Date(d.setDate(diff));
}

export function endOfWeek(date) {
  if (date?.getDay() === 0) return new Date(date); // return sunday when day equals 0
  var lastday = date.getDate() - (date.getDay() - 1) + 6;
  return new Date(date.setDate(lastday));
}

export const changeMonthAndYear = (monthAndYear, nextMonthAndYear) => {
  const { month, year } = monthAndYear;
  const { month: nextMonth, year: nextYear } = nextMonthAndYear;
  let monthAndYearStr;
  if (month === nextMonth && year === nextYear) {
    monthAndYearStr = `${month} ${year}`;
    return monthAndYearStr;
  } else if (month !== nextMonth && year === nextYear) {
    monthAndYearStr = `${month} - ${nextMonth} ${year}`;
    return monthAndYearStr;
  } else {
    monthAndYearStr = `${month} ${year} - ${nextMonth} ${nextYear}`;
    return monthAndYearStr;
  }
};

export const countWeeklySchedule = (arrOfData) => {
  let count = 0;
  arrOfData?.forEach((data) => {
    count += data?.totalShifts;
  });
  return count;
};
