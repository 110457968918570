const commonStyle = {
  borderRadius: "5px",
  padding: "4px 4px",
  textAlign: "center",
  width: "auto",
  fontSize: "80%",
  fontWeight: "500",
  whiteSpace: "nowrap",
  textTransform: "capitalize",
};

const approved = {
  backgroundColor: "#E7FAE3",
  color: "#2D6040",
  border: "1px solid #E7FAE3",
};

const rejected = {
  backgroundColor: "#F5CFD3",
  color: "#671A21",
  border: "1px solid  #F5CFD3",
};
const created = {
  backgroundColor: "#F5F2CF",
  color: "#6B5C1F",
  border: "1px solid #F5F2CF",
};

const pending = {
  backgroundColor: "gray",
  color: "#FFFFFF",
  border: "1px solid gray",
};

const interested = {
  backgroundColor: "#cad7ff",
  color: "#6d91ff",
  border: "1px solid #cad7ff",
  cursor: "pointer"
};

export const handleStatusesBadge = (status) => {
  if (status) {
    switch (status) {
      case "assigned":
      case "reassigned":
      case "pending":
      case "PENDING":
      case "SCHEDULED":
        return { ...commonStyle, ...pending };
      case "not_approved":
      case "cancelled":
      case "failed":
      case "blocked":
      case "payment_failed":
      case "inactive":
      case "offboarded":
      case "rejected":
      case "FAILED":
      case "CANCELED":
        return { ...commonStyle, ...rejected };
      case "processed":
      case "paid":
      case "complete":
      case "confirmed":
      case "done":
      case "approved" || "Waiting For Approval":
      case "COMPLETED":
        return { ...commonStyle, ...approved };
      case "delivery_started":
      case "Delivery Started":
      case "created":
        return { ...commonStyle, ...created };
      case "remain":
        return { ...commonStyle, ...rejected };
      case "interested": //from API it's interestedDriver
      case "sort_interested": //from API it's interestedDriver
        return { ...commonStyle, ...interested };
      case "interested": 
        return { ...commonStyle, ...interested };
      case "pickup_interested": 
        return { ...commonStyle, ...interested };
      case "sort_assigned":
        return { ...commonStyle, ...pending };
      case "pickup_assigned":
        return { ...commonStyle, ...pending };
      case "contractor_assigned":
        return { ...commonStyle, ...pending };
      case "Created":
        return { ...commonStyle, ...created }
      case "Waiting For Approval":
        return { ...commonStyle, ...pending }
      default:
        return commonStyle;
    }
  }
};

export const handleBackgroundCheck = (status) => {
  if (status) {
    return { ...commonStyle, ...approved };
  } else {
    return { ...commonStyle, ...rejected };
  }
}

export const handleBackgroundStatusesBadge = (status) => {
  if (status) {
    switch (status) {
      case "clear":
        return { ...commonStyle, ...approved };
      case "complete":
        return { ...commonStyle, ...approved };
      case "pending":
        return { ...commonStyle, ...created };
      case "created":
        return { ...commonStyle, ...created };
      case "consider":
        return { ...commonStyle, ...rejected };
      case "suspended":
        return { ...commonStyle, ...rejected };
      case "dispute":
        return { ...commonStyle, ...rejected };
      case "canceled":
        return { ...commonStyle, ...rejected };
      default:
        return commonStyle;
    }
  }
};

export const checkForIsDriverNew = (orderCount) => {
  if (!orderCount) return false;
  if (orderCount > 3) return true;
  return false;
};
