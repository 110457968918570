import React from "react";
import { CircularProgress, Modal } from "@mui/material";
import { actions } from "../../../../redux/store/store";
import { useForm } from "react-hook-form";
import { InputItem } from "../../Form";
import { useApproveOrderPaymentMutation } from "../../../../api/orders";

function AddExceptionModal({ id }) {
    const [changeStatusForApproveOrderPayments, { isLoading }] = useApproveOrderPaymentMutation();
    const onCancel = () => {
        actions.modal.closeException();
    };

    const form = useForm({
        defaultValues: { ExceptionNote: "" }
    });

    const onSubmit = async (data) => {
        const params = {
            _id: id,
            exceptionNote: data.ExceptionNote,
        }
        await changeStatusForApproveOrderPayments(params);
        if (!isLoading) {
            actions.modal.closeException();
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
        }
    };

    return (
        <Modal
            open
            onClose={onCancel}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <div className={`modal after-loading`} style={{ maxWidth: "35.875rem" }}>
                <div className="modal__header">
                    <h2 className="modal__title">{"Exception Note"}</h2>
                </div>
                <div className="modal__body">
                    <form>
                        <InputItem
                            title="Exception Note"
                            name="ExceptionNote"
                            form={form}
                            autoComplete="off"
                            onInput={(event) => {
                                form.setValue("ExceptionNote", event.target.value.trimStart());
                            }}
                            onKeyDown={handleKeyDown}
                        />
                    </form>
                </div>
                <div className="modal__footer">
                    <button className="btn" onClick={() => actions.modal.closeException()}>
                        Cancel
                    </button>
                    <div style={{ position: 'relative' }}>
                        <button
                            className="btn btn_accent"
                            disabled={!form.formState.isDirty || isLoading}
                            onClick={form.handleSubmit(onSubmit)}>
                            OK
                        </button>
                        {isLoading && (
                            <CircularProgress size={18}
                                sx={{
                                    color: '#fff',
                                    position: 'absolute',
                                    top: '50%',
                                    left: '83%',
                                    marginTop: '-8px',
                                    marginLeft: '-10px',
                                }} />
                        )}
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default AddExceptionModal;