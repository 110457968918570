import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { timeTransform_hm } from "../../../util/time";

const Timepicker = (props) => {
  const h_array = [];
  const m_array = [];
  const m_array_small_steps = []; //if need minutes with 1m step

  for (let i = 0; i <= 23; i++) {
    h_array.push(i < 10 ? "0" + i : i.toString());
  }

  for (let i = 0; i <= 5; i++) {
    m_array.push(i + "0");
  }

  //if need minutes with 1m step
  for (let i = 0; i <= 59; i++) {
    m_array_small_steps.push(i < 10 ? "0" + i : i.toString());
  }

  const [timeH, setTimeH] = useState("00");
  const [timeM, setTimeM] = useState("00");

  const reg = /[h] |m/;

  const [time, setTime] = useState(timeTransform_hm.toDisplay(props.value));
  const TimeM_rounded = Math.round(time.split(reg)[1] * 0.1) * 10; //rounding need because database still contain not rounded values for minutes like '00:53' or '23:06'

  useEffect(() => {
    setTimeH(time.split(reg)[0]);
    //setTimeH(time.split(reg)[1]); //if need minutes with 1m step
    setTimeM(TimeM_rounded === 0 ? "00" : TimeM_rounded);
    props.updateTravelTime(timeTransform_hm.toValue(time));
  }, [time]);

  useEffect(() => {
    setTime(timeTransform_hm.toDisplay(props.value));
  }, [props]);

  const setDHM = (e) => {
    const temp_time = time.split(reg);

    if (e.target.name === "H") {
      temp_time[0] = e.target.value;
    }

    if (e.target.name === "M") {
      temp_time[1] = e.target.value;
    }

    setTime(`${temp_time[0]}h ${temp_time[1]}m`);
  };

  return (
    <div className="form__item">
      <Stack spacing={1.5} direction="row" mt={1.5}>
        <FormControl size="small">
          <InputLabel>Hours</InputLabel>
          <Select
            name="H"
            label="Hours"
            value={timeH}
            onChange={(e) => setDHM(e)}
            style={{ width: "5rem" }}
            disabled={props?.disabled}
          >
            {h_array.map((item, index) => (
              <MenuItem key={index} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl size="small">
          <InputLabel>Minutes</InputLabel>
          <Select
            name="M"
            label="Minutes"
            value={timeM}
            onChange={(e) => setDHM(e)}
            style={{ width: "5rem" }}
            disabled={props?.disabled}
          >
            {m_array.map((item, index) => (
              <MenuItem key={index} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
    </div>
  );
};

export default Timepicker;
