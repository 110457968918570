import React, { useState, useRef, useEffect } from "react";
import moment from "moment";
import { Calendar } from "react-date-range";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import BackspaceIcon from "@mui/icons-material/Backspace";

import "./style.scss";
import { changeMonthAndYear } from "../../../../constants/extras/schedulingDt";
import { useOutsideAlerter } from "../../../../constants/hooks/useOutside";

const objData = [
  {
    icon: <ThumbUpAltIcon style={{ height: "1rem", width: "1rem" }} />,
    text: "Done",
  },
  {
    icon: <BackspaceIcon style={{ height: "1rem", width: "1rem" }} />,
    text: "Reset",
    customClass: "clearButton",
  },
];

const Header = (props) => {
  const {
    isFetching,
    day,
    nextDate,
    handleCustomRange,
    closeCalendar,
    changeWeekToPrevious,
    handleDateRangeFilter,
    showDateRangePicker,
    handleTimeRangeSelection,
    changeWeekToNext,
    date,
    state,
    handleReset,
  } = props || {};
  const [monthAndYear, setMonthAndYear] = useState({
    month: moment(day).format("MMMM"),
    year: moment(day).format("YYYY"),
  });
  const [nextMonthAndYear, setNextMonthAndYear] = useState({
    month: moment(nextDate).format("MMMM"),
    year: moment(nextDate).format("YYYY"),
  });

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, closeCalendar);

  useEffect(() => {
    setMonthAndYear({
      month: moment(day).format("MMMM"),
      year: moment(day).format("YYYY"),
    });
    setNextMonthAndYear({
      month: moment(nextDate).format("MMMM"),
      year: moment(nextDate).format("YYYY"),
    });
  }, [nextDate, day]);

  const handleWeek = () => {
    return `${moment(day).format("MM/DD/yy")} - ${moment(nextDate).format(
      "MM/DD/yy"
    )}`;
  };

  return (
    <div className="header">
      <div className="header__container">
        <h6 className="header__title">Better Trucks Scheduling Tool</h6>
      </div>
      <div className="header__container">
        <h6 className="header__title">
          {changeMonthAndYear(monthAndYear, nextMonthAndYear)}
        </h6>
      </div>
      <div className="header__dt__cont">
        <button
          className="header__dt__cont__icon"
          onClick={changeWeekToPrevious}
          id="decrease__week"
          aria-label="decrease__week"
          disabled={isFetching}
        >
          <ChevronLeftIcon />
        </button>
        <div ref={wrapperRef}>
          <div
            className="header__dt__cont__range__picker"
            onClick={() => handleCustomRange()}
          >
            <span>{handleWeek()}</span>
          </div>
          {showDateRangePicker && (
            <div
              className="mainDateRangePicker"
              style={{ top: "7.5%", right: "0%" }}
            >
              <Calendar
                onChange={(item) => handleDateRangeFilter(item)}
                date={date}
                ranges={state}
              />

              <div className="button-container" style={{ width: "inherit" }}>
                <FooterButton
                  icon={objData[1].icon}
                  isFetching={isFetching}
                  text={objData[1].text}
                  handleOnClick={() => handleReset()}
                  className={objData[1].customClass}
                />
                <FooterButton
                  icon={objData[0].icon}
                  isFetching={isFetching}
                  text={objData[0].text}
                  handleOnClick={(item) => handleTimeRangeSelection(item)}
                />
              </div>
            </div>
          )}
        </div>
        <button
          disabled={isFetching}
          className="header__dt__cont__icon"
          onClick={changeWeekToNext}
          id="increase__week"
          aria-label="increase__week"
        >
          <ChevronRightIcon />
        </button>
      </div>
    </div>
  );
};

export default Header;

const FooterButton = (props) => {
  const { className, isFetching, icon, text, handleOnClick } = props;
  return (
    <button
      onClick={(item) => {
        handleOnClick(item);
      }}
      className={`okButton ${className ? className : ""}`}
      style={{ width: "40%" }}
      disabled={isFetching}
    >
      <span>
        {icon}
        &nbsp;
        <strong>{text}</strong>
      </span>
    </button>
  );
};
