export const API_SUCCESS = {
  SHIFT: {
    DELIVERY_STARTED: "Delivery started successfully",
    SHIFT_COMPLETED: "Shift completed successfully",
    SHIFT_REJECTED: "Shift rejected successfully",
    CANCELLED_MULTIPLE: "All Selected shifts cancelled successfully!!",
    CANCELLED_SINGLE: "Shift cancelled successfully!!",
    CREATED: "Shift created successfully!!",
    UPDATED: "Shift updated successfully!!",
  },
  SHIFT_TEMPLATE: {
    UPDATED: "Shifts Template updated successfully!!",
    CREATED: "Shifts Template created successfully!!",
  },
  FEEDBACK: {
    UPDATED: "Feedback updated successfully!!",
    CREATED: "Feedback created successfully!!",
  },
};
