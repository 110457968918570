import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { IoSearchSharp } from "react-icons/io5";
import { DateRangePicker } from "react-date-range";
import CancelIcon from "@mui/icons-material/Cancel";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import BackspaceIcon from "@mui/icons-material/Backspace";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { CircularProgress, Fade, Menu, MenuItem } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import Table from "../../../components/shared/Table";
import ExportReactCSV from "../../../components/shared/CSVExportDriversEarningReport";
import { useGetDriversEarningReportQuery } from "../../../api/drivers";
import { driversEarningColumns as columns } from "../../../constants/columnData/driversEarning";
import { driversEarningReport } from "../../../constants/csv/csvExportData";
import { useOutsideAlerter } from "../../../constants/outsideAlert";
import HeaderTextLayout from "../../../components/shared/HeaderTextLayout";
import "../../../styles/_data.scss";
import { addDays } from "date-fns";
import { Clear, Refresh } from "@mui/icons-material";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { CSVLink } from "react-csv";
import { dataExport, xlsxDataExport } from "../../../constants/extras/dataExport";
import { exportMenu, paymentMethodOptions } from "../../../constants/variables/arrays";
import ReactSelect from "react-select";


export default function DriversEarning() {
  let d = new Date();
  d.setMonth(d.getMonth() - 3);
  const [showDateRangePicker, setShowDateRangePicker] = useState(false);
  const [fromDate, setFromDate] = useState(moment(d).format("yyyy-MM-DD"));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [csvData, setCsvData] = useState(false);
  const csvInstance = useRef();
  const [selectedMethod, setSelectedMethod] = useState("")

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [prevDate, setPrevDate] = useState();
  const [toDate, setToDate] = useState(moment().format("yyyy-MM-DD"));

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setShowDateRangePicker);

  const { data: driversEarningData, isFetching, refetch } =
    useGetDriversEarningReportQuery(
      {
        fromDate: fromDate,
        toDate: toDate,
        paymentMode: selectedMethod
      },
      {
        refetchOnMountOrArgChange: false,
      }
    );

  const [filteredDriversData, setFilteredDriversEarningData] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    setLoading(isFetching);
    if (!driversEarningData) {
      return;
    }
    handleSearchingDriverEarning() 
    
  }, [driversEarningData, isFetching]);

  const handleSearchingDriverEarning =  (value) => {
    const srch = value === 'CLEAR' ? '' : searchQuery;
    let filtered = driversEarningData?.filter((item) => {
      return (
        (item.username &&
          item.username
            .toLowerCase()
            .trim()
            .includes(srch.toLowerCase().trim())) ||
        (item.name
          ? item.name.toLowerCase().trim().includes(srch.toLowerCase().trim())
          : null)
      );
    })
      
    setFilteredDriversEarningData((prev) => [...filtered] );
    
  }
  

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearchingDriverEarning();
    }
  };

  useEffect(() => {
    let startD = `${moment(fromDate).format("MM-DD-yyyy")}`;
    let endD = `${moment(toDate).format("MM-DD-yyyy")}`;
    setState([
      {
        startDate: new Date(startD),
        endDate: new Date(endD),
        key: "selection",
      },
    ]);
    setPrevDate([
      {
        startDate: new Date(startD),
        endDate: new Date(endD),
        key: "selection",
      },
    ]);
  }, []);

  const handleCustomRange = () => {
    setShowDateRangePicker(!showDateRangePicker);
    if (!fromDate.length) {
      setState([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: "selection",
        },
      ]);
    } else {
      setState(prevDate);
    }
  };

  const handleTimeRangeSelection = () => {
    const firstSelectionOfStartDate = moment(state[0].startDate).format(
      "yyyy-MM-DD"
    );

    const firstSelectionOfToDate = moment(state[0].endDate).format(
      "yyyy-MM-DD"
    );
    setPrevDate(state);
    setFromDate(firstSelectionOfStartDate);
    setToDate(firstSelectionOfToDate);
    setShowDateRangePicker(false);
  };

  const headers = [
    { label: "Name", key: "name" },
    { label: "Email", key: "email" },
    { label: "SSN Number", key: "ssnNumber" },
    { label: "EIN Number", key: "einNumber" },
    { label: "Phone Number", key: "phoneNumber" },
    { label: "Address", key: "address" },
    { label: "City", key: "city" },
    { label: "State", key: "state" },
    { label: "Zip Code", key: "zipCode" },
    { label: "Method", key: "paymentmode" },
    { label: "Total Earnings", key: "totalEarnings" },
  ];

  useEffect(() => {
    if (csvData && csvInstance.current && csvInstance.current.link) {
      setTimeout(() => {
        csvInstance.current.link.click();
        setCsvData(false);
      });
    }
  }, [csvData]);

  const exportData = async (value) => {
    setAnchorEl(null)
    const data = driversEarningReport(filteredDriversData);
    const fileName = fromDate && toDate ? "Drivers_Earning_from_".concat(fromDate).concat("_to_").concat(toDate).concat("_csv.csv")
    : "Drivers_Earning_All.csv"
    if (value === "csv") {
      const obj = dataExport(data, fileName, headers)
      setCsvData(obj);

    } else if (value === "xlsx") {
          xlsxDataExport(data, fileName)
    }
  }

  const styles = {
    placeholder: (base) => ({
      ...base,
      fontSize: "0.9rem",
      fontWeight: "normal"
    }),
    control: (styles) => ({
      ...styles,
      borderRadius: "5px",
      boxShadow: "none",
      height: "2.9rem",
      "&:hover": {
        border: "2px solid #dadada",
      },
      border: "2px solid #dadada",
      fontSize: "1rem",
      width: "12rem",
    }),
  }

  return (
    <React.Fragment>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="card">
              <HeaderTextLayout
                headerText="Drivers Earning"
                className="col-md-3"
                data={
                  filteredDriversData && filteredDriversData.length > 0
                    ? `${filteredDriversData.length} Drivers Earning Found`
                    : ""
                }
                dataLength={
                  Array.isArray(filteredDriversData)
                    ? filteredDriversData.length
                    : "0"
                }
              >
                <div className="col-lg-9 col-md-9 col-sm-9 filter">
                  <div className="filter__inner p-0 justify-content-end">
                    <div className="filter__ctrls-holder filter__input-holder d-flex justify-content-end search__container" style={{ gap: "1.25rem" }}>
                      <div>
                        <ReactSelect
                          options={paymentMethodOptions}
                          isClearable={true}
                          onChange={(item) => {
                            setSelectedMethod(item)
                          }}
                          placeholder="Select Method"
                          value={selectedMethod}
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                          styles={styles}/>
                      </div>
                      <div className="searchbar__container">
                        <input
                          className="pr-2 form__input customized__input"
                          value={searchQuery}
                          onChange={(e) => {
                            setSearchQuery(e.target.value);
                            if (e.target.value === "")
                              handleSearchingDriverEarning("CLEAR");
                          }}
                          placeholder="Quick Search"
                          onKeyDown={handleKeyDown}
                        />
                        {searchQuery?.length > 0 && (
                          <Clear
                            className="clear__btn filter__search-icon"
                            onClick={() => {
                              setSearchQuery("");
                              handleSearchingDriverEarning("CLEAR");
                            }}
                            style={{
                              right: "52px",
                              top: "12px",
                            }}
                          />
                        )}
                        <button
                          disabled={searchQuery?.length <= 0}
                          className="search__button"
                          onClick={() => handleSearchingDriverEarning()}
                        >
                          <IoSearchSharp />
                        </button>
                      </div>
                    </div>

                    <div ref={wrapperRef}>
                      <div onClick={handleCustomRange} className="form__input">
                        <span>
                          {`${fromDate.length
                              ? `${moment(fromDate).format(
                                "MM-DD-yyyy"
                              )} TO ${moment(toDate).format("MM-DD-yyyy")}`
                              : "Select Custom Date Range"
                            }`}
                        </span>
                        <span className="pl-2">
                          {showDateRangePicker ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </span>
                      </div>
                      {showDateRangePicker && (
                        <div className="mainDateRangePicker">
                          <DateRangePicker
                            onChange={(item) => {
                              setState([item.selection]);
                            }}
                            showSelectionPreview={true}
                            moveRangeOnFirstSelection={false}
                            months={1}
                            ranges={state}
                            direction="horizontal"
                          />

                          <div className="button-container">
                            <button
                              onClick={() => {
                                setFromDate("");
                                setToDate("");
                                setShowDateRangePicker(false);
                                setState([
                                  {
                                    startDate: new Date(),
                                    endDate: addDays(new Date(), 0),
                                    key: "selection",
                                  },
                                ]);
                              }}
                              className="okButton clearButton"
                            >
                              <span>
                                <BackspaceIcon /> &nbsp;
                                <strong>CLEAR</strong>
                              </span>
                            </button>
                            <button
                              onClick={() => {
                                setShowDateRangePicker(false);
                              }}
                              className="cancelButton"
                            >
                              <span>
                                <CancelIcon
                                  sx={{ width: "1.5rem", height: "1.5rem" }}
                                />{" "}
                                <strong>cancel</strong>
                              </span>
                            </button>
                            <button
                              onClick={handleTimeRangeSelection}
                              className="okButton"
                            >
                              <span>
                                <ThumbUpAltIcon /> &nbsp;
                                <strong>Done</strong>
                              </span>
                            </button>
                          </div>
                        </div>
                      )}
                    </div>

                    <div>
                      <button
                        id="fade-button"
                        className="btn btn_block btn_accent btn_export_15"
                        onClick={(event) => setAnchorEl(event.currentTarget)}
                        variant="contained"
                        disabled={filteredDriversData?.length === 0 ? true : false
                        }
                      >
                        <span> Export <KeyboardArrowDownIcon className="mr-0" /></span> 
                      </button>
                      <Menu
                        id="fade-menu"
                        MenuListProps={{
                          'aria-labelledby': 'fade-button',
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClick={() => setAnchorEl(null)}
                        TransitionComponent={Fade}
                      >
                        {exportMenu.map(({ value, name }) => (
                          <MenuItem key={name} value={name} onClick={() => exportData(value)} style={{ width: "100px" }}>
                            {name}
                          </MenuItem>

                        ))}
                      </Menu>
                    </div>

                    <button className="refresh_btn" onClick={() => refetch()}>
                      <Refresh className="refresh_icon" />
                    </button>
                    
                    {csvData ? (
                      <CSVLink
                        data={csvData.data}
                        headers={csvData.headers}
                        filename={csvData.filename}
                        ref={csvInstance}
                      />
                    ) : undefined}
                  </div>
                </div>
              </HeaderTextLayout>

              {isLoading ? (
                <div className="loading-holder">
                  <CircularProgress className="loading-progress" />
                </div>
              ) : filteredDriversData && filteredDriversData.length ? (
                <div className="table-wrapper normal_table">
                  <Table
                    columns={columns}
                    items={filteredDriversData}
                    className="table_orders"
                  />
                </div>
              ) : (
                <div className="no_results">No Results</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
