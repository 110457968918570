import React, { forwardRef, useState, useEffect } from "react";
import { Autocomplete, TextField } from "@mui/material";
import "./style.scss";
import { useGetMetrosQuery } from "../../../api/metro";
import { autocomplete, errorAutoComplete } from "../orderHelper/styles";
import { checkArrForMapping } from "../../../constants/validaitonSchema/validation.ts";
import { useSelector } from "react-redux";

const MetroSelect = forwardRef(({ isError, disabled, isLoading, isAll, width, showLabel, ...props }, ref) => {
  const { data: metros, isFetching } = useGetMetrosQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const currentUser = useSelector((state) => state.auth.currentUser);

  const [metroList, setMetroList] = useState([]);
  const styleObj = isError ? { ...errorAutoComplete } : { ...autocomplete };

  useEffect(() => {
    if (currentUser?.role === "metro_manager") {
      let metro_manager_metros = JSON.parse(localStorage.getItem("user_metro"));
      setMetroList(
        metro_manager_metros?.map((metro) => {
          return metro;
        })
      );
    } else {
      if (isFetching || !metros) return;
      const metro =
        checkArrForMapping(metros) && metros.map(({ name }) => name);
      checkArrForMapping(metro) && (isAll ? setMetroList(["All", ...metro]) : setMetroList(["", ...metro]))
    }
  }, [metros, isFetching, currentUser]);

  return (
    <div className="metro_select_div">
      {(showLabel && props?.value ) && <div >
        <p className="metro_select_label" >{"Select Metro"}</p>
      </div>}
      <Autocomplete
        disabled={disabled || isLoading}
        defaultValue={""}
        options={Array.isArray(metroList) && metroList}
        sx={{
          width: width || 300,
          ...styleObj,
          fontSize: "10px"
        }}
        blurOnSelect={true}
        ref={ref}
        {...props}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Select Metro"
            inputProps={{
              ...params.inputProps,
            }}
          />
        )}
      />
    </div>
  );
});

export default MetroSelect;
