import React from "react";
import { CSVLink } from "react-csv";

const ExportReactCSV = ({ csvHeaders, csvData, fileName }) => (
  <CSVLink
    headers={csvHeaders}
    data={csvData}
    filename={fileName}
    style={{ textDecoration: "none", color: "#ffffff" }}
    className="csv-link-btn"
  >
    <button className="btn btn_block btn_accent btn_export_15" type="button">
      Export CSV
    </button>
  </CSVLink>
);

export default ExportReactCSV;
