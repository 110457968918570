import moment from "moment";
const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
const utc = moment().format().slice(19);


export const header = [
  { label: "Name", key: "name" },
  { label: "Username", key: "username" },
  { label: "Phone", key: "phone" },
  { label: "Metro", key: "metro" },
  { label: "Shifts", key: "orderCount" },
  { label: "Score", key: "score" },
  { label: "Status", key: "status" },
];

export const headers = [
  ...header,
  { label: "Checker Id", key: "candidateId" },
  { label: "Checker Status", key: "checkrBackgroundStatus" },
  { label: "shiftStatus", key: "shiftStatus" }
];

export const checkerHeader = [
  ...header,
  { label: "Checker Id", key: "candidateId" },
  { label: "Checker Status", key: "checkrBackgroundStatus" },
];

export const shiftStatusHeader = [
  ...header,
  { label: "shiftStatus", key: "shiftStatus" },
];

export function driverReportData(filteredDriversData) {
  const driverReportData = [];

  if (filteredDriversData && filteredDriversData.length) {
    for (let i = 0; i < filteredDriversData.length; i++) {
      let data = filteredDriversData[i];

      const {
        firstName,
        lastName,
        username,
        phone,
        metro,
        orderCount,
        score,
        status,
        rejectShiftCount,
        assigned,
        delivery_started,
        complete,
        waiting_for_approval,
        paid,
        cancelled,
        checkrBackgroundStatus,
        candidateId

      } = data || {};

      //   const { firstName, lastName, username, phone } = driver || {};

      driverReportData[i] = {
        name: `${firstName ? firstName : "-"} ${lastName ? lastName : ""}`,
        username: `${username ? username : "-"}`,
        phone: `${phone ? phone : "-"}`,
        metro: `${metro ? metro : "-"}`,
        orderCount: `${orderCount ? orderCount : "-"}`,
        score: `${score ? score : "-"}`,
        status: `${status ? status : "-"}`,
        candidateId: `${candidateId ? candidateId : "-"}`,
        checkrBackgroundStatus: `${checkrBackgroundStatus ? checkrBackgroundStatus : "-"}`,
        shiftStatus: `${assigned && "Assigned : " + assigned + ', ' || ''}${delivery_started && "Delivery Started : " + delivery_started + ', ' || ''}${complete && "Complete : " + complete + ', ' || ''}${waiting_for_approval && "Approval : " + waiting_for_approval + ', ' || ''}${paid && "Paid : " + paid + ', ' || ''}${cancelled && "Cancelled : " + cancelled + ', ' || ''}${rejectShiftCount && "Rejected : " + rejectShiftCount + ', ' || ''}`
      };
    }
  }
  return driverReportData;
}
