import { CircularProgress } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState, useCallback } from "react";
import { IoIosAddCircle } from "react-icons/io";
import { IoChevronForward, IoSearchSharp } from "react-icons/io5";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getNotificationsList } from "../../../api/notification";
import HeaderTextLayout from "../../../components/shared/HeaderTextLayout";
import MetroSelect from "../../../components/shared/metroSelect";
import Table from "../../../components/shared/Table";
import { checkArrForMapping } from "../../../constants/validaitonSchema/validation.ts";
import { DATE } from "../../../constants/variables/dates";
import { actions } from "../../../redux/store/store";
import ClearIcon from "@mui/icons-material/Clear";
import { Refresh } from "@mui/icons-material";

const Notifications = () => {
  const metro = useSelector((state) => state.metro.value);
  const isRefetch = useSelector((state) => state.refetch.value);
  const location = useLocation();
  const { pathname } = location;
  const [filteredNotifications, setFilteredNotifications] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(2);
  const [limit] = useState(100);

  // const { data: notificationsList, isFetching } = useGetNotificationsQuery(
  //   null,
  //   {
  //     refetchOnMountOrArgChange: true,
  //     // skip: email?.open === true,
  //   }
  // );

  // useEffect(() => {
  //   if (!notificationsList?.result) return;
  //   setFilteredNotifications(notificationsList?.result);

  //   if (searchQuery) {
  //     setFilteredNotifications((filteredNotifications) =>
  //       filteredNotifications.filter((notificationData) => {
  //         // const srch = searchQuery;
  //         // return handleSearch(payeeName || transactionId || note, srch);
  //        return (notificationData && notificationData?.title.toLowerCase()
  //           .includes(searchQuery.toLowerCase()))
  //       })
  //     );
  //   }
  // }, [notificationsList, notificationsList?.result, searchQuery]);

  useEffect(() => {
    actions.metro.selectMetro("");
    actions.refetch.setIsDataRefetch(true);
  }, [pathname]);

  useEffect(() => {
    if (isRefetch === false) return;
    setIsLoading(true);
    setHasMore(true);
    setFilteredNotifications([]);
    setPage(1);
    (async () => {
      const data = await getNotificationsList(
        1,
        limit,
        searchQuery,
        metro ? metro : ""
      );
      setFilteredNotifications(data);
      actions.notifications.addNotifications(data);
      if (data && data?.length < limit) setHasMore(false);
      setPage(2);
    })();
    actions.refetch.setIsDataRefetch(false);
    setIsLoading(false);
  }, [isRefetch, limit, metro, searchQuery]);

  const handleSearchingNotification = useCallback(
    async (c) => {
      setFilteredNotifications([]);
      setHasMore(true);
      setPage(1);
      const data = await getNotificationsList(
        1,
        limit,
        c === "CLEAR" ? "" : searchQuery,
        metro
      );
      setPage(2);
      setFilteredNotifications(data);
      if (data.length < limit) setHasMore(false);
    },
    [metro, limit, searchQuery]
  );

  const handleSearchingNotificationByMetro = async (updatedMetro) => {
    setHasMore(true);
    setPage(1);
    setFilteredNotifications([]);
    const data = await getNotificationsList(
      1,
      limit,
      searchQuery,
      updatedMetro
    );
    setPage(2);
    setFilteredNotifications(data);
    if (data?.length < limit) setHasMore(false);
  };

  const fetchMoreNotification = async () => {
    let notificationData = await getNotificationsList(
      page,
      limit,
      searchQuery,
      metro
    );
    setFilteredNotifications([...filteredNotifications, ...notificationData]);
    if (notificationData?.length === 0 || notificationData?.length < limit)
      setHasMore(false);
    setPage(page + 1);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearchingNotification();
    }
  };

  const columns = [
    {
      name: "metro",
      label: "Metro",
      className: "w-15",
      sortable: true,
      renderer: (item) => {
        return (
          (
            item && item?.isMetroAll ? "All" : (item?.metro ? item?.metro : "-")
          )
        )
      }
    },
    {
      name: "company",
      label: "Company",
      className: "w-15",
      sortable: true,
      renderer: (item) => {
        return (
          (item && item?.isCompanyAll ? "All" : (item?.company ? `${item.company?.companyCode ? item.company.companyCode : ""} ${item.company?.companyCode && item.company.companyName ? "-" : ""} 
          ${item.company?.companyName ? item.company.companyName : ""}` : "-"))
        )
      }
    },
    {
      name: "title",
      label: "Title",
      sortable: true,
    },
    {
      name: "createdAt",
      label: "Time",
      className: "w-16",
      sortable: true,
      renderer: ({ createdAt }) =>
        createdAt && moment(createdAt).format(DATE.DT_WITH_TIME),
    },
    {
      name: "sender",
      label: "Sent By",
      className: "w-16",
      sortable: true,
      renderer: (item) =>
        item && item?.sender && item?.sender?.username
          ? item?.sender?.username
          : "-",
    },
    {
      name: "viewed",
      label: "Viewed",
      className: "w-7",
      sortable: true,
      renderer: (item) => `${item?.seen}/${item?.receiversCount}`,
    },
    {
      name: "details",
      className: "table__td_have-btn align-center",
      renderer: (item) => {
        return (
          <button
            className="btn btn_block btn_accent customized-details-btn"
            type="button"
            onClick={() => {
              actions.modal.openNotification({ item });
            }}
          >
            Details <IoChevronForward className="btn__icon btn__icon_right" />
          </button>
        );
      },
    },
  ];

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="card">
            <HeaderTextLayout
              headerText="Notifications"
              className="col-md-2"
              data={
                checkArrForMapping(filteredNotifications)
                  ? `${filteredNotifications.length} Notifications Found`
                  : ""
              }
              dataLength={
                checkArrForMapping(filteredNotifications)
                  ? filteredNotifications.length
                  : "0"
              }
            >
              <div
                className="col-md-10 pull-right d-flex justify-content-end"
                style={{ alignItems: "center" }}
              >
                <div className="filter__ctrls-holder filter__input-holder d-flex justify-content-end search__container">
                  <div className="searchbar__container">
                    <input
                      className="form__input customized__input"
                      value={searchQuery}
                      onChange={(e) => {
                        setSearchQuery(e.target.value);
                        if (e.target.value === "")
                          handleSearchingNotification("CLEAR");
                      }}
                      placeholder="Quick Search"
                      onKeyDown={handleKeyDown}
                    />
                    {searchQuery?.length > 0 && (
                      <ClearIcon
                        className="clear__btn filter__search-icon"
                        onClick={() => {
                          setSearchQuery("");
                          handleSearchingNotification("CLEAR");
                        }}
                        style={{
                          right: "45px",
                          top: "12px",
                        }}
                      />
                    )}
                    <button
                      disabled={searchQuery?.length <= 0}
                      className="search__button"
                      onClick={() => handleSearchingNotification()}
                    >
                      <IoSearchSharp className="filter__search-icon" />
                    </button>
                  </div>
                </div>

                <div className="w-25" style={{ marginLeft: "15px" }}>
                  {" "}
                  <MetroSelect
                    style={{ height: "45px", width: "100%" }}
                    value={metro || ""}
                    onChange={(event, newValue) => {
                      actions.metro.selectMetro(newValue);
                      handleSearchingNotificationByMetro(newValue);
                    }}
                  />
                </div>

                <button
                  style={{
                    background: "white",
                    borderColor: "#e6e6e6",
                    padding: "0 20px"
                  }}
                  className="btn btn__accent mr-0 ml-3"
                  onClick={() => {
                    actions.modal.openNotification();
                    // actions.metro.selectMetro("");
                  }}
                >
                  <IoIosAddCircle style={{ fontSize: "22px" }} />
                  Create
                </button>

                <button className="refresh_btn ml-3" onClick={() => actions.refetch.setIsDataRefetch(true)}>
                  <Refresh className="refresh_icon" />
                </button>
              </div>
            </HeaderTextLayout>

            <InfiniteScroll
              className="infinit_table"
              dataLength={filteredNotifications?.length}
              next={fetchMoreNotification}
              hasMore={hasMore}
              style={{
                paddingBottom: !hasMore ? "0px" : "10px",
              }}
              loader={
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{
                    height:
                      filteredNotifications &&
                        filteredNotifications?.length <= 0
                        ? "70vh"
                        : "auto",
                  }}
                >
                  <CircularProgress className="loading-progress" />
                </div>
              }
              height={"80vh"}
              endMessage={
                filteredNotifications &&
                filteredNotifications?.length <= 0 && (
                  <div
                    style={{
                      height: "70vh",
                    }}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <p>
                      <b>No Result Found</b>
                    </p>
                  </div>
                )
              }
            >
              {isLoading ? (
                <div className="loading-holder">
                  <CircularProgress className="loading-progress" />
                </div>
              ) : (
                <Table
                  columns={columns}
                  items={filteredNotifications}
                  className="table_orders"
                />
              )}
            </InfiniteScroll>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
