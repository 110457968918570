import React from "react";
import { CircularProgress, Modal } from "@mui/material";
import "./style.scss";
import { AiFillQuestionCircle } from "react-icons/ai";

const ConfirmDialog = (props) => {
  const { modalText, bodyText, onClose, title } = props || {};
  return (
    <Modal
      open={props.isOpen}
      onClose={props.onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="modal modal_dialog" style={{ height: "auto" }}>
        <div className="modal__header">
          <h2 className="modal__title">{title || ""}</h2>
        </div>
        <div
          className={`modal__body ${!["PAY_WITH_EXCEPTION", "CANCEL__SHIFT"].includes(modalText)
              ? "pt-5"
              : ""
            }`}
        >
          {modalText === "PAY" && <AiFillQuestionCircle className="icon" />}
          {bodyText}
        </div>
        <div className="modal__footer">
          <button className="btn" onClick={onClose} disabled={props?.isLoading}>
            {modalText && modalText === "PAY_WITH_EXCEPTION" ? "Cancel" : "No"}
          </button>
          <button
            className={`btn ${props.isRejectingShift || modalText !== "PAY_WITH_EXCEPTION"
                ? modalText === "PAY"
                  ? "btn_accent"
                  : "btn_reject"
                : "btn_accent"
              }`}
            onClick={props.confirmAction}
            disabled={
              (props.isLoading && props.isLoading) ||
              (props?.isRejectingShift &&
                props?.payExceptionNote?.trim().length <= 0)
            }
          >
            {modalText && modalText === "PAY_WITH_EXCEPTION"
              ? props.isRejectingShift
                ? "Reject"
                : "Pay"
              : "Yes"}{" "}
            {props?.isLoading && <CircularProgress className="btn__progress" />}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmDialog;
