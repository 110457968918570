import { datadogRum } from '@datadog/browser-rum';

const {version} = require('../package.json');

export function initializeDatadogRum() {
    var env = 'development';
    if (process.env.NODE_ENV === 'production') {
        env = 'production';
    }
    datadogRum.init({
        applicationId: '0a92d676-1f3e-4906-8994-71797195e52a',
        clientToken: 'pub0a259e88ede32da50f0d274faa9a18cd',
        // `site` refers to the Datadog site parameter of your organization
        // see https://docs.datadoghq.com/getting_started/site/
        site: 'us5.datadoghq.com',
        service: 'shift-admin',
        env: env,
        // Specify a version number to identify the deployed version of your application in Datadog
        version: version,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
    });

    datadogRum.startSessionReplayRecording();
}