import React, { useState, useEffect } from "react";
import moment from "moment";

import { useGetSchedulesQuery } from "../../../api/schedules";
import Header from "../../../components/shared/schedulingHelper/Header";
import SchedulingTable from "../../../components/shared/schedulingHelper/Table";
import { endOfWeek, getMonday } from "../../../constants/extras/schedulingDt";
import CardLayout from "../../../components/layouts/CardLayout";

const Scheduling = () => {
  let d = new Date();
  const [day, setDay] = useState(getMonday(d));
  const [nextDate, setNextDate] = useState(endOfWeek(d));
  const [showDateRangePicker, setShowDateRangePicker] = useState(false);
  const [arr, setArr] = useState([]);
  const [state, setState] = useState([
    {
      startDate: getMonday(new Date()),
      endDate: endOfWeek(new Date()),
      key: "selection",
    },
  ]);
  const [date, setDate] = useState(new Date());

  const handleDateRangeFilter = (item) => {
    setDate(item);
    setState([
      {
        startDate: getMonday(new Date(item)),
        endDate: endOfWeek(new Date(item)),
        key: "selection",
      },
    ]);
  };

  const { data: schedulingResponse, isFetching } = useGetSchedulesQuery(
    {
      fromDate: day,
      toDate: nextDate,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const changeWeekToNext = () => {
    let d = new Date(day);
    let newDt = new Date(nextDate);
    setDay(d.setDate(d.getDate() + 7));
    setNextDate(newDt.setDate(newDt.getDate() + 7));
  };

  const changeWeekToPrevious = () => {
    let d = new Date(day);
    let newDt = new Date(nextDate);
    setDay(d.setDate(d.getDate() - 7));
    setNextDate(newDt.setDate(newDt.getDate() - 7));
  };

  useEffect(() => {
    let arr = [];
    for (let i = 0; i < 7; i++) {
      let date = moment(day).add(i, "day"); // converting date from first date of the week
      let weekDay = moment.weekdays((i + 1) % 7); // converting weekday from first date of the week
      arr.push({ date: moment(date).format("MM/DD/yy"), day: weekDay });
    }
    setArr(arr);
  }, [nextDate, day]);

  const handleTimeRangeSelection = () => {
    setDay(moment(state[0].startDate).format("MM/DD/YYYY"));
    setNextDate(moment(state[0].endDate).format("MM/DD/YYYY"));
    setShowDateRangePicker(false);
  };

  const handleCustomRange = () => {
    setShowDateRangePicker(!showDateRangePicker);
  };

  const handleReset = () => {
    // resets week to current week
    let firstDay = getMonday(new Date());
    let lastDay = endOfWeek(new Date());
    setDay(firstDay);
    setNextDate(lastDay);
    setState([
      {
        startDate: firstDay,
        endDate: lastDay,
        key: "selection",
      },
    ]);
    setShowDateRangePicker(false);
  };

  return (
    <CardLayout>
      <Header
        day={day}
        date={date}
        state={state}
        nextDate={nextDate}
        showDateRangePicker={showDateRangePicker}
        changeWeekToNext={changeWeekToNext}
        changeWeekToPrevious={changeWeekToPrevious}
        handleDateRangeFilter={(item) => handleDateRangeFilter(item)}
        handleTimeRangeSelection={handleTimeRangeSelection}
        handleCustomRange={handleCustomRange}
        handleReset={handleReset}
        closeCalendar={() => setShowDateRangePicker(false)}
        isFetching={isFetching}
      />

      <SchedulingTable
        arr={arr}
        isFetching={isFetching}
        schedulingResponse={schedulingResponse}
        day={day}
        nextDate={nextDate}
      />
    </CardLayout>
  );
};

export default Scheduling;
