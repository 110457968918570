import React, { useState, useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { actions } from "../../../redux/store/store";
import validator from "validator";
import { useRegisterMutation } from "../../../api/auth";
import { useGetEmailByHashMutation } from "../../../api/invitedAdmins";
import "../../../styles/_login.scss";

const validatorOptions = {
  minLength: 8,
  minLowercase: 1,
  minUppercase: 1,
  minNumbers: 1,
  minSymbols: 1,
  returnScore: true,
  pointsPerUnique: 3,
  pointsPerRepeat: 3,
  pointsForContainingLower: 10,
  pointsForContainingUpper: 10,
  pointsForContainingNumber: 10,
  pointsForContainingSymbol: 10,
};

export default function Register(props) {
  const { onStopUserQuery } = props;
  const [username, setUsername] = useState({
    username: "",
    role: "",
    metros: [],
  });
  const [password, setPassword] = useState("");
  const [passwordWarning, setPasswordWarning] = useState(0);
  const [password2, setPassword2] = useState("");
  const [isPassword2Warning, setIsPassword2Warning] = useState(false);
  const [invitationCode, setInvitationCode] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { hash } = useParams();

  const [register, { isLoading }] = useRegisterMutation();
  const [getEmailByHash] = useGetEmailByHashMutation();

  const handleChangePassword = (e) => {
    let val = e.target.value;
    if (!password.includes(username)) {
      let score = validator.isStrongPassword(val, validatorOptions);
      setPasswordWarning(score);
    } else {
      setPasswordWarning(-1);
    }
    setPassword(val);
    val === password2
      ? setIsPassword2Warning(false)
      : setIsPassword2Warning(true);
  };

  const handleChangePassword2 = (e) => {
    let val = e.target.value;
    val === password
      ? setIsPassword2Warning(false)
      : setIsPassword2Warning(true);
    setPassword2(val);
  };

  useEffect(() => {
    const getEmail = async () => {
      if (typeof onStopUserQuery === "function") {
        onStopUserQuery(true);
      }
      if (hash) {
        let newUser = await getEmailByHash({ hash: hash });
        setUsername({
          username: newUser && newUser?.data && newUser?.data?.email,
          role: newUser && newUser?.data && newUser?.data?.role,
          metros: newUser && newUser?.data && newUser?.data?.metros,
        });
        if (!newUser && !newUser.data && !newUser.data.email) {
          actions.notifications.add({
            message: "This link is invalid or expired, please try again!",
            status: "error",
          });
        }
        newUser.error &&
          setError(
            newUser.error && newUser.error.data && newUser.error.data.message
          );
      }
    };

    getEmail();
  }, [getEmailByHash, onStopUserQuery, hash]);

  const performRegistration = useCallback(
    async (e) => {
      e.preventDefault();
      setError("");
      const { data, error } = await register({
        username: username.username,
        role: username.role,
        password,
        metros: username.metros,
        invitationCode,
        isAdmin: true,
      });
      if (!error && data && data?.username) {
        if (typeof onStopUserQuery === "function") {
          onStopUserQuery(false);
        }
        navigate("/login", { login: username });
        actions.notifications.add({
          message: "Now you can login",
          status: "success",
        });
      } else {
        if (data && data?.message) {
          actions.notifications.add({
            message: data?.message,
            status: "error",
          });
        } else if (error && error.data.code === 11000) {
          setError("User already exists");
        } else {
          setError(
            (error.data && error.data.message) || "Something went wrong"
          );
        }
      }
    },
    [username, password, invitationCode, navigate, register, onStopUserQuery]
  );

  const toLogin = (e) => {
    e.preventDefault();
    if (typeof onStopUserQuery === "function") {
      onStopUserQuery(false);
    }
    navigate("/login");
  };

  return (
    <div className="screen screen_flex" style={{ height: "100vh" }}>
      <div className="container container_contain-center">
        <div className="login">
          <form>
            <div className="form__item">
              <label className="form__label" htmlFor="login">
                Email
              </label>
              <input
                id="login"
                className="form__input"
                type="text"
                value={username.username}
                onChange={() => {}}
                disabled
              />
            </div>
            <div className="form__item">
              <div className="form__msg">
                <span>the password must contain:</span>
                <ul>
                  <li>minimum of 8 non-repeating characters</li>
                  <li>At least one letter</li>
                  <li>At least one uppercase letter</li>
                  <li>At least one symbol</li>
                  <li>At least one digit</li>
                </ul>
                <span>the password must not contain</span>
                <ul>
                  <li>email</li>
                  <li>common words</li>
                  <li>common or weak passwords</li>
                </ul>
              </div>
              <label className="form__label" htmlFor="password">
                Password
              </label>
              <input
                id="password"
                className="form__input" /*form__input-invalid <= class for invalid value*/
                type="password"
                value={password}
                onChange={handleChangePassword}
              />
              {passwordWarning === -1 && (
                <div className="form__msg form__msg_invalid">
                  the password contains an email
                </div>
              )}
              {passwordWarning === 0 && (
                <div className="form__msg">please enter password</div>
              )}
              {passwordWarning > 0 && passwordWarning < 30 && (
                <div className="form__msg form__msg_invalid">
                  your password is very weak
                </div>
              )}
              {passwordWarning > 29 && passwordWarning < 52 && (
                <div className="form__msg form__msg_invalid">
                  your password is not strong enough
                </div>
              )}

              {passwordWarning > 50 && password.length < 8 && (
                <div className="form__msg form__msg_invalid">
                  Please enter atlease 8 characters strong password
                </div>
              )}
              {passwordWarning > 51.5 && password.length > 8 && (
                <div className="form__msg">great password</div>
              )}
            </div>
            <div className="form__item">
              <label className="form__label" htmlFor="password">
                Repeat password
              </label>
              <input
                id="password"
                className="form__input" /*form__input-invalid <= class for invalid value*/
                type="password"
                value={password2}
                onChange={handleChangePassword2}
              />
              {isPassword2Warning && (
                <div className="form__msg form__msg_invalid">
                  passwords don&#39;t match
                </div>
              )}
            </div>
            <div className="form__item">
              <label className="form__label" htmlFor="invitation-password">
                Invitation Code
              </label>
              <input
                id="invitation-password"
                className="form__input"
                type="password"
                value={invitationCode}
                onChange={(e) => setInvitationCode(e.target.value)}
              />
            </div>
            <div className="form__item form__item_min-margin">
              <button
                className="btn btn_block btn_accent"
                onClick={performRegistration}
                disabled={
                  isLoading ||
                  passwordWarning < 52 ||
                  isPassword2Warning ||
                  !username
                }
              >
                Register
              </button>
            </div>
            {error && (
              <div className="form__item form__item_min-margin">
                <div className="form__msg form__msg_invalid form__msg_summary">
                  {error}
                </div>
              </div>
            )}
            <div className="login__link-holder">
              <a href="/login" onClick={toLogin}>
                Login
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
