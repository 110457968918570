import { createSlice } from "@reduxjs/toolkit";

export const hubSlice = createSlice({
  name: "hubs",
  initialState: {
    value: { name: undefined, id: "" },
  },
  reducers: {
    selectHub: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { selectHub } = hubSlice.actions;
export default hubSlice.reducer;
