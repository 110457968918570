import axios from "axios";
var qs = require('qs');

export const getDriversReportList = async (
  page,
  limit,
  firstOrderDriver,
  isShiftCount,
  orderCountFrom,
  orderCountTo,
  scoreFrom,
  scoreTo,
  status,
  metro,
  state,
  shifFromDate,
  shifToDate,
  isBackgroundCheck

) => {

  try {

    const response = await axios.get(
      `/api/driversReport?page=${page ? page : ""}&limit=${limit ? limit : ""}
      &firstOrderDriver=${firstOrderDriver}&isShiftCount=${isShiftCount}&orderCountFrom=${orderCountFrom}&orderCountTo=${orderCountTo}
      &scoreFrom=${scoreFrom}&scoreTo=${scoreTo}&shifFromDate=${shifFromDate}&shifToDate=${shifToDate}&isBackgroundCheck=${isBackgroundCheck}`,
      {
        'params': { 'metro': metro, "state": state, "status": status },
        'paramsSerializer': function (params) {
          return qs.stringify(params, { arrayFormat: 'repeat' })
        },
      }
    )
    if (response.status === 200) return response?.data;
    else return "Something went wrong";
  } catch (e) {
    return e.response.data;
  }
};