import axios from "axios";
var qs = require('qs');

export const getPiplineStageReport = async (
    fromDate,
    toDate,
    metro
) => {
    try {
        const response = await axios.get(`/api/pipelineStageReport?fromDate=${fromDate}&toDate=${toDate}`,
        {
            'params': { 'metro': metro},
            'paramsSerializer': function (params) {
              return qs.stringify(params, { arrayFormat: 'repeat' })
            },
          })
        if (response.status === 200) return response?.data;
    else return "Something went wrong";
    } catch (error) {
        return error.response.data;
    }
}