import { createApi } from "@reduxjs/toolkit/query/react";
import axios from "axios";
import { baseQueryWithAuthInterceptor } from "./util";

export const feedbackApi = createApi({
    reducerPath: "feedbackApi",
    baseQuery: baseQueryWithAuthInterceptor({ baseUrl: "/api/feedback" }),
    tagTypes: ["Feedback"],
    endpoints: (builder) => ({
        getAllFeedback: builder.query({
            query: () => {
                return {
                    url: "",
                    method: "GET",
                };
            },
            providesTags: ["Feedback"],
        }),
        addFeedback: builder.mutation({
            query: (body) => {
                return {
                    url: "",
                    method: "POST",
                    body
                };
            },
            invalidatesTags: ["Feedback"],
        }),
        updateFeedback: builder.mutation({
            query: ({ params, ids }) => {
                return {
                    url: `${ids}`,
                    method: "PUT",
                    body: params
                };
            },
            invalidatesTags: ["Feedback"],
        }),
        getOneFeedback: builder.query({
            query: (id) => {
                return {
                    url: `${id}`,
                    method: "GET",
                };
            },
            providesTags: ["Feedback"],
        })
    }),
});

export const {
    useGetAllFeedbackQuery,
    useAddFeedbackMutation,
    useUpdateFeedbackMutation,
    useGetOneFeedbackQuery
} = feedbackApi


export const getFeedbackList = async (
    page,
    limit,
    type
) => {
    try {
        const response = await axios.get(
            `/api/feedback?page=${page ? page : ""}&limit=${limit ? limit : ""}&type=${type || ""}`
        );
        if (response?.status === 200) return response?.data;
        else return "Something went wrong";
    } catch (e) {
        return e.response.data;
    }
};
