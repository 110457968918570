import React, { useCallback } from 'react'
import { CircularProgress, Modal } from '@mui/material';
import { actions } from '../../../../redux/store/store';
import Table from '../../Table';
import moment from 'moment';
import { handleCurrency } from '../../../../constants/extras/handleNumbers';
import { useNavigate } from 'react-router-dom';
import { handleStatusesBadge } from '../../../../constants/extras/statusesBadge';
import { useGetPaymentCalendarByIdQuery } from '../../../../api/paymentCalendar';

function PaymentFailedDetailModal(props) {
  const navigate = useNavigate()
  const { id } = props;

  const { data: payment, isFetching: isPaymentLoading } = useGetPaymentCalendarByIdQuery({id, isPaid: false}, {
    refetchOnMountOrArgChange: true,
    skip: id.unsuccessfullPayments === 0
  });

  const onCancel = useCallback(() => {
    actions.modal.closeFailedPayment();
  }, []);

  const columns = [
    {
      name: "dayDeliveryStart",
      label: "Route Date",
      maxWidth: "90px",
      sortable: true,
      renderer: (item) =>
        item?.dayDeliveryStart &&
        moment(item?.dayDeliveryStart).utc().format("MM-DD-YYYY"),
    },
    {
      name: "driverFName",
      label: "Driver Name",
      maxWidth: "180px",
      sortable: true,
      renderer: (item) =>
        item?.driverUsername && (
          <span >
            {`${item?.driverFName || ""} ${item?.driverLName || ""}`}
          </span>
        ),
    },
    {
      name: "metro",
      label: "Metro",
      maxWidth: "150px",
      sortable: true,
      renderer: (item) => item?.metro ? item?.metro : "-"
    },
    {
      name: "price",
      label: "Payout",
      maxWidth: "70px",
      sortable: true,
      className: "align-right",
      renderer: (item) => (item?.price ? `$${handleCurrency(item?.price)}` : ""),
    },
    {
      name: "bonusPrice",
      label: "Bonus",
      maxWidth: "50px",
      sortable: true,
      className: "align-right",
      renderer: (item) => (item?.bonusPrice ? `$${handleCurrency(item?.bonusPrice)}` : ""),
    },
    {
      name: "totalPrice",
      label: "Total Payout",
      maxWidth: "70px",
      sortable: true,
      className: "align-right",
      renderer: (item) => (item?.totalPrice ? `$${handleCurrency(item?.totalPrice)}` : ""),
    },
    {
      name: "transactionFailedReason",
      label: "Failed Reason",
      sortable: true,
      renderer: (item) => item?.transactionFailedReason
    },
    {
      name: "status",
      label: "Status",
      sortable: true,
      renderer: (item) => (
        <div className="status-in-table">
          <div className="status-in-table__text" style={{ margin: '5px 0' }}>
            <span style={handleStatusesBadge(item?.status === 'paid' ? 'paid' : 'failed')}>
              {item?.status === 'paid' ? 'paid' : 'failed'}
            </span>
          </div>
        </div>
      )
    }
  ];

  const handleFailedPaymentOnce = () => {
    window.open(`/payments?isPaymentFail`, '_blank')
    onCancel()
  }

  return (
    <Modal
      open
      onClose={onCancel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div
        className={`modal after-loading`}
        style={{ maxWidth: "90rem" }}
      >
        <div className="modal__header">
          <h2 className="modal__title">Failed Payment Details</h2>
        </div>
        {
          isPaymentLoading ? (
            <div className="loading-holder">
              <CircularProgress className="loading-progress" />
            </div>
          ) : (
            <div className="modal__body">

              {payment?.result?.shiftPayments &&
                payment?.result?.shiftPayments?.length > 0 ? (
                <div className="card" style={{ border: "1px solid #DADADA" }}>
                  <div
                    style={{
                      height: "70vh",
                      overflow: "auto",
                    }}
                  >
                    <Table
                      columns={columns}
                      items={payment?.result && payment?.result?.shiftPayments && payment?.result?.shiftPayments?.filter(e => !e?.isPaid)}
                    />
                  </div>
                </div>
              ) : (
                <div
                  className="inner__scroll__view__container"
                  style={{ height: "100%" }}
                >
                  No result found
                </div>
              )}
            </div>
          )}
        <div className="modal__footer">
          <button
            className="btn"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            className="btn btn_accent"
            onClick={handleFailedPaymentOnce}
          >
            Failed payment at once
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default PaymentFailedDetailModal
