import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { IoChevronForward } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, Fade, Menu } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { MdPayment } from 'react-icons/md';
import { IoSearchSharp } from 'react-icons/io5';
import { DateRangePicker } from 'react-date-range';
import Table from '../shared/Table';
import { useApproveOrderPaymentMutation, useGetApprovedOrdersQuery, useRejectOrderPaymentMutation } from '../../api/orders';
import { getApprovePaymentList, useRejectDrivePaymentMutation } from '../../api/drivers';
import { getRoute } from '../../util/time';
import { actions } from '../../redux/store/store';
import MassPaymentsModal from '../shared/customModal/massPayments';
import { maskingInfo } from '../shared/helper/index';
import ConfirmDialogModal from '../shared/customModal/confirmDialog';
import { setLoading } from '../../redux/slices/authSlice';
import { handleStatusesBadge } from '../../constants/extras/statusesBadge';
import { convertToCapitalize } from '../../constants/extras/convertToCapitalize';
import HeaderTextLayout from '../shared/HeaderTextLayout';
import './style.scss';
import '../../styles/_elements.scss';
import { hoverAndFocusedStyle, paymentSelectStyle } from '../shared/orderHelper/styles';
import { ADHOC, PAGINATION, PAY_FOUND } from '../../constants/variables';
import { checkArrForMapping } from '../../constants/validaitonSchema/validation.ts';
import CardLayout from '../layouts/CardLayout';
import { handleCurrency } from '../../constants/extras/handleNumbers';
import { handleEstimatedShiftPay } from '../../constants/extras/handleShiftPayAndTime';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { DATE, DateCreater, createDate } from '../../constants/variables/dates';
import CancelIcon from '@mui/icons-material/Cancel';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import BackspaceIcon from '@mui/icons-material/Backspace';
import { Clear, Refresh } from '@mui/icons-material';
import { useGetUpdateDialogQuery } from '../../api/setting';
import { csvAdhocPaymentHeaders, csvHeaders, csvWeeklyPaymentsHeaders, paymentsData } from '../../constants/csv/csvPaymentData';
import { dataExport, xlsxDataExport } from '../../constants/extras/dataExport';
import { CSVLink } from 'react-csv';
import { xlsxPaymentsData } from '../../constants/xlsx/xlsxPaymentsData';
import cn from 'classnames';
import { handlePaymentStatus } from '../../constants/extras/handlePaymentStatus.js';
import InfiniteScroll from 'react-infinite-scroll-component';
import { exportMenu, paymentMethodOptions, statusList } from '../../constants/variables/arrays';
import ReactSelect from 'react-select';

const { COMMON__LIMIT } = PAGINATION;

export default function Payments() {
  const loc = useLocation();
  const checkParams = () => loc.search.length > 0 && loc.search.includes('isPaymentFail') ? true : false;
  const isRefetch = useSelector((state) => state.refetch.value);

  const dispatch = useDispatch();
  const wrapperRef = useRef(null);
  const csvInstance = useRef();

  const [fromDate, setFromDate] = useState(moment().format('yyyy-MM-DD'));
  const [toDate, setToDate] = useState(moment().format('yyyy-MM-DD'));
  const [changePaymentsLayout, setChangePaymentsLayout] = useState('AdhocPayments');
  const [hasMore, setHasMore] = useState(true);
  const [limit] = useState(COMMON__LIMIT);
  const [page, setPage] = useState(2);
  const [searchQuery, setSearchQuery] = useState('');
  const [isPaymentFail, setIsPaymentFail] = useState(false)

  const checkForValueAdhoc =
    changePaymentsLayout.includes('AdhocPayments') ||
    changePaymentsLayout.includes('Instant Payment') ||
    changePaymentsLayout.includes('Exceptions Payment') ||
    changePaymentsLayout.includes('Weekly Payment');

  const { data: orders, isFetching: isOrderDataLoading } =
    useGetApprovedOrdersQuery(null, {
      refetchOnMountOrArgChange: true,
      skip: changePaymentsLayout !== 'Shifts',
    });

  const { data, isFetching } = useGetUpdateDialogQuery();

  const [changeStatusForApproveOrderPayments, { isLoading: isApproveOrderLoading }] = useApproveOrderPaymentMutation();

  const [changeStatusesForRejectDrivers, { isLoading: isRejectDriversLoading }] = useRejectDrivePaymentMutation();
  const [changeStatusesForRejectOrders, { isLoading: isRejectOrdersLoading }] = useRejectOrderPaymentMutation();

  const [bonusDate, setbonusDate] = useState();
  const [bonusDay, setbonusDay] = useState();
  const [totalAmounts, settotalAmounts] = useState(0);
  const [isTotal, setIsTotal] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [disable, setDisable] = useState(false);

  const [filteredOrders] = useState([]);
  const [csvData, setCsvData] = useState();
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [filteredAdhocPayment, setFilteredAdhocPayment] = useState([]);

  const [showMassPaymentsModal, setShowMassPaymentsModal] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showConfirmApprove, setShowConfirmApprove] = useState(false);
  const [showDateRangePicker, setShowDateRangePicker] = useState(false);
  const [exceptionsPaydata, setExceptionsPaydata] = useState([]);
  const [exceptionsApprovedata, setExceptionsApprovedata] = useState([]);
  const [search, setSearch] = useState("");
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("")

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  const [prevDate, setPrevDate] = useState(state);

  // Export Functionalities
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loadingCsv, setLoadingCsv] = useState(false);
  const open = Boolean(anchorEl);

  const totalAmount = filteredAdhocPayment?.reduce((sum, object) => {
    return sum + object?.totalPrice;
  }, 0);

  useEffect(() => {
    actions.refetch.setIsDataRefetch(true);
  }, [loc?.pathname]);

  useEffect(() => {
    if (!isFetching && filteredAdhocPayment) {
      setbonusDate(data?.result?.bonusDayDate);
      setbonusDay(data?.result?.bonusDay);
      settotalAmounts(totalAmount?.toFixed(2));
    }
  }, [isFetching, data, filteredAdhocPayment, totalAmount]);

  const isShowingDtRangePicker = showDateRangePicker ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />;

  const dateRange = `${fromDate ? `${createDate(fromDate, DATE.M_D_Y)} TO ${createDate(toDate, DATE.M_D_Y)}` : ''}`;

  // const handleLastWeak = () => {
  //   const selectedDate = new Date();
  //   if (!isNaN(selectedDate.getTime())) {
  //     const dayOfWeek = selectedDate.getDay();
  //     const lastWeekStartDate = new Date(selectedDate);
  //     lastWeekStartDate.setDate(selectedDate.getDate() - dayOfWeek - 6); // Subtract 14 days to go back two weeks and to Sunday
  //     const lastWeekEndDate = new Date(lastWeekStartDate);
  //     lastWeekEndDate.setDate(lastWeekStartDate.getDate() + 6);

  //     setPrevDate([
  //       {
  //         startDate: new Date(lastWeekStartDate.toString()),
  //         endDate: new Date(lastWeekEndDate.toString()),
  //         key: "selection",
  //       },
  //     ])
  //     setFromDate(moment(lastWeekStartDate.toString()).format("yyyy-MM-DD"))
  //     setToDate(moment(lastWeekEndDate.toString()).format("yyyy-MM-DD"))
  //   }
  // }

  useEffect(() => {
    if (isRefetch === false || (checkParams() && !isPaymentFail)) return;
    setIsLoading(true);
    setHasMore(true);
    setFilteredAdhocPayment([]);
    setIsTotal([]);
    setPage(1);
    (async () => {
      setDisable(true);
      const data = checkParams() ? isPaymentFail && await getPaymentData(1, limit, search , selectedPaymentMethod) : await getPaymentData(1, limit, search ,selectedPaymentMethod);
      setIsTotal(data?.total);
      setFilteredAdhocPayment(data?.result || []);
      setDisable(false);
      if (data && data?.result?.length < limit) setHasMore(false);
      setPage(2);
    })();
    actions.refetch.setIsDataRefetch(false);
    setIsPaymentFail(false)
    setIsLoading(false);
  }, [isRefetch, isPaymentFail]);

  const getPaymentData = async (page, limit, search , PaymentMethod) => {
    const data = await getApprovePaymentList(
      limit,
      page,
      search,
      PaymentMethod,
      changePaymentsLayout === 'AdhocPayments',
      changePaymentsLayout === 'Instant Payment',
      changePaymentsLayout === 'Exceptions Payment',
      changePaymentsLayout === 'Weekly Payment',
      changePaymentsLayout === 'Weekly Payment' ? fromDate : '',
      changePaymentsLayout === 'Weekly Payment' ? toDate : '',
      checkParams(),
    );
    return data;
  };

  useEffect(()=>{
    handleFilterBySearchQuery();
  },[selectedPaymentMethod])

  useEffect(() => {
    if (loc?.search?.length > 0 && checkParams()) {
      setChangePaymentsLayout('Weekly Payment');
    }
  }, [loc?.search?.length > 0]);

  useEffect(() => {
    if (csvData && csvInstance.current && csvInstance.current.link) {
      setTimeout(() => {
        csvInstance.current.link.click();
        setCsvData(false);
      });
    }
  }, [csvData]);

  const downloadDriversData = async () => {
    const data = await getPaymentData(undefined, undefined, search , selectedPaymentMethod);
    if (data && Array.isArray(data.result) && data?.result?.length > 0) {
      return data.result;
    }
    return;
  };

  const exportData = async (value) => {
    setAnchorEl(null);
    setLoadingCsv(true);
    const download = await downloadDriversData();
    const filename = 'Payments_Information.csv';
    const headers = changePaymentsLayout === 'AdhocPayments' ? csvAdhocPaymentHeaders : changePaymentsLayout === 'Weekly Payment' ? csvWeeklyPaymentsHeaders : csvHeaders;
    setLoadingCsv(false);
    if (value === 'csv') {
      const data = paymentsData(download, changePaymentsLayout);
      const obj = dataExport(data, filename, headers);
      setCsvData(obj);
    } else if (value === 'xlsx') {
      const data = xlsxPaymentsData(download, changePaymentsLayout);
      xlsxDataExport(data, 'Payments_Information');
    }
  };

  const handleThisWeek = () => {
    const selectedDate = new Date();
    if (!isNaN(selectedDate.getTime())) {
      const dayOfWeek = selectedDate.getDay();
      const thisWeekStartDate = new Date(selectedDate);
      thisWeekStartDate.setDate(selectedDate.getDate() - dayOfWeek + 1); // Addition 1 to select date from Monday
      const thisWeekEndDate = new Date(thisWeekStartDate);
      thisWeekEndDate.setDate(thisWeekStartDate.getDate() + 6); // Addition 6 to select whole week 6+1: 7

      setPrevDate([
        {
          startDate: new Date(thisWeekStartDate.toString()),
          endDate: new Date(thisWeekEndDate.toString()),
          key: 'selection',
        },
      ]);
      setFromDate(moment(thisWeekStartDate.toString()).format('yyyy-MM-DD'));
      setToDate(moment(thisWeekEndDate.toString()).format('yyyy-MM-DD'));
    }
  };

  const handleLastThreeMonth = () => {
    const lastThreeMonth = moment().subtract(3, 'month');
    setPrevDate([
      {
        startDate: new Date(lastThreeMonth.toString()),
        endDate: new Date(),
        key: 'selection',
      }
    ]);
    setFromDate(moment(lastThreeMonth.toString()).format('yyyy-MM-DD'));
    setToDate(moment().format('yyyy-MM-DD'));
    setIsPaymentFail(true)
  };


  useEffect(() => {
    if (changePaymentsLayout === 'Weekly Payment') {
      if (loc?.search?.length > 0 && checkParams()) {
        handleLastThreeMonth();
      } else {
        handleThisWeek();
        setShowDateRangePicker(false);
      }
    }
  }, [changePaymentsLayout, loc?.search?.length]);


  const openDriverModal = useCallback((e) => {
    e.preventDefault();
    actions.modal.openDriver(e.currentTarget.getAttribute('data-driver-id'));
  }, []);

  const shouldShowPayButton =
    changePaymentsLayout !== 'Instant Payment' &&
    ((changePaymentsLayout === 'Exceptions Payment' && exceptionsPaydata.length > 0) ||
      ((changePaymentsLayout === 'Weekly Payment' || changePaymentsLayout === 'AdhocPayments') && selectedOrders.length > 0));

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      let newSelecteds = [];

      if (checkForValueAdhoc) {
        if (changePaymentsLayout.includes('AdhocPayments')) {
          newSelecteds = filteredAdhocPayment.filter(
            (n) => n.transactionStatus === 'approved'
          );
        } else {
          newSelecteds = filteredAdhocPayment.filter((n) => +n.price > 0);
        }
      } else {
        if (changePaymentsLayout.includes('AdhocPayments')) {
          newSelecteds = filteredAdhocPayment?.filter(
            (n) => n.transactionStatus === 'approved'
          );
        } else {
          newSelecteds = filteredOrders?.filter((n) => +n.price > 0);
        }
      }

      const exceptionsPay = newSelecteds.filter(
        (order) => order.bonusPrice === 0 || order.bonusPrice === null
      );

      const exceptionsApprove = newSelecteds.filter(
        (order) => order.bonusPrice && order.bonusPrice !== 0
      );

      setSelectedOrders(newSelecteds);
      setExceptionsPaydata(exceptionsPay);
      setExceptionsApprovedata(exceptionsApprove);
    } else {
      setSelectedOrders([]);
      setExceptionsPaydata([]);
      setExceptionsApprovedata([]);
    }
  };

  const handleCheckboxClick = (event, name) => {
    const selectedIndex = selectedOrders.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedOrders, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedOrders.slice(1));
    } else if (selectedIndex === selectedOrders.length - 1) {
      newSelected = newSelected.concat(selectedOrders.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedOrders.slice(0, selectedIndex),
        selectedOrders.slice(selectedIndex + 1)
      );
    }

    const exceptionsPay = newSelected.filter(
      (order) => order.bonusPrice === 0 || order.bonusPrice === null
    );

    const exceptionsApprove = newSelected.filter(
      (order) => order.bonusPrice && order.bonusPrice !== 0
    );

    setSelectedOrders(newSelected);
    setExceptionsPaydata(exceptionsPay);
    setExceptionsApprovedata(exceptionsApprove);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleFilterBySearchQuery();
    }
  };

  const handleOnChange = (e) => {
    setSearchQuery(e.target.value);
    e.target.value === '' && handleFilterBySearchQuery('CLEAR');
  };

  const handleFilterBySearchQuery = async (isClear) => {
    const searchData = isClear === 'CLEAR' ? '' : searchQuery;
    const PaymentMethod = selectedPaymentMethod ? selectedPaymentMethod : ""
    setSearch(searchData);
    setFilteredAdhocPayment([]);
    setIsTotal([]);
    setHasMore(true);
    setPage(1);
    setDisable(true);
    const data = await getPaymentData(1, limit, searchData ,PaymentMethod );
    setIsTotal(data?.total);
    setDisable(false);
    setPage(2);
    setFilteredAdhocPayment(data.result);
    if (data?.result?.length < limit) setHasMore(false);
  };

  useEffect(() => {
    setSelectedOrders([]);
    setExceptionsApprovedata([]);
    setExceptionsPaydata([]);
  }, [orders, isOrderDataLoading, filteredAdhocPayment]);

  let columns;
  if (changePaymentsLayout.includes('AdhocPayments')) {
    columns = [
      {
        name: 'checkbox',
        label: (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <input
              type="checkbox"
              onClick={(event) => handleSelectAllClick(event)}
              checked={selectedOrders.length === (filteredAdhocPayment?.length ? filteredAdhocPayment?.length : 1)}
              onChange={() => { }}
              className="mainAdPayCheckbox"
            />
          </div>
        ),
        className: 'table__td_has-checkbox',
        renderer: (item) => {
          const isSelected = (name) => selectedOrders.indexOf(name) !== -1;
          let isItemSelected = isSelected(item);
          return (
            item.transactionStatus === 'approved' && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  paddingTop: '0.18rem',
                }}
              >
                <input
                  type="checkbox"
                  onClick={(event) => handleCheckboxClick(event, item)}
                  checked={isItemSelected}
                  onChange={() => { }}
                />
              </div>
            )
          );
        },
      },
      {
        name: 'date',
        label: 'Date',
        sortable: true,
        renderer: (item) =>
          item.date && moment(item.date).format('MM-DD-YYYY HH:mm A'),
      },
      { name: 'payeeName', label: 'Payee Name', sortable: true },
      {
        name: 'amount',
        label: 'Amount',
        sortable: true,
        renderer: (item) => {
          return `$${item && item.amount && typeof item.amount === 'number'
            ? item.amount.toFixed(2)
            : '0'
            }`;
        },
      },
      {
        name: 'transactionStatus',
        label: 'Last Updated Status',
        sortable: true,
        className: 'table__td_centered table__td_min-horiz-padding py-2',
        renderer: (item) => (
          <>
            <span style={handleStatusesBadge(item.transactionStatus)}>
              {`${item &&
                (item.transactionStatus && item.transactionStatus === 'approved'
                  ? 'Waiting for approval'
                  : convertToCapitalize(item?.transactionStatus))
                }`}
            </span>
            <span> {item.description ? item.description : ''}</span>
          </>
        ),
      },
      {
        name: "paymentmode",
        label: "Method",
        sortable: true,
        renderer: (item) => {
          return (
            <span>{item?.paymentmode || "DWOLLA"}</span>
          );
        },
      },
      {
        name: 'accountInfo-depositAccountNumber',
        label: 'Account Detail',
        sortable: true,
        renderer: (item) =>
          item.accountInfo &&
          maskingInfo(item.accountInfo.depositAccountNumber),
      },
      { name: 'note', label: 'Notes', sortable: true },
    ];
  } else {
    columns = [
      !changePaymentsLayout.includes('Instant Payment') ? {
        name: 'checkbox',
        label: (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <input
              type="checkbox"
              onClick={(event) => handleSelectAllClick(event)}
              checked={selectedOrders?.length > 0 && selectedOrders?.length}
              onChange={() => { }}
              className="mainShiftPayCheckbox"
            />
          </div>
        ),
        className: 'table__td_has-checkbox',
        renderer: (item) => {
          const isSelected = (name) => selectedOrders.indexOf(name) !== -1;
          let isItemSelected = isSelected(item);
          return (
            <div className="shift-input-checkbox">
              {
                +item.price > 0 &&
                <input
                  type="checkbox"
                  onChange={() => 'please dont remove'}
                  onClick={(event) => handleCheckboxClick(event, item)}
                  checked={isItemSelected}
                />
              }
            </div>
          );
        },
      } : {
        label: 'HIDE'
      },
      { name: 'metro', label: 'Metro', sortable: true, maxWidth: '120px', },
      { name: 'routeCode', label: 'Route Code', sortable: true, maxWidth: '90px', },
      {
        name: 'dayDeliveryStart',
        className: 'table__td_nowrap w-5',
        label: 'Route Date',
        sortable: true,
        renderer: (item) =>
          moment(item?.dayDeliveryStart).utc().format('MM-DD-YYYY'),
      },
      {
        name: 'from-name',
        label: 'Starting Delivery Station',
        className: 'w-6',
        sortable: true,
        renderer: (item) => item?.from?.name,
      },
      {
        name: 'startTime',
        className: 'table__td_centered align-center',
        label: 'Start Time',
        sortable: true,
        renderer: ({ startTime }) => (
          <p style={{ width: '5rem', position: 'relative', margin: 'auto' }}>
            {getRoute(startTime)}
          </p>
        ),
      },
      {
        name: 'estimatedShiftPayFrom',
        // className: "w-15",
        label: 'Estimated Shift Pay',
        sortable: true,
        renderer: ({ estimatedShiftPayFrom, estimatedShiftPayTo }) =>
          handleEstimatedShiftPay(estimatedShiftPayFrom, estimatedShiftPayTo),
      },
      {
        name: 'price',
        label: 'Payout',
        sortable: true,
        renderer: ({ price }) => (price ? `$${handleCurrency(price)}` : ''),
      },
      changePaymentsLayout.includes('Weekly Payment') ? {
        name: 'bonusPrice',
        label: 'Bonus',
        sortable: true,
        renderer: ({ bonusPrice }) => (bonusPrice ? `$${handleCurrency(bonusPrice)}` : '')
      } : { label: 'HIDE' },
      changePaymentsLayout.includes('Weekly Payment') ?
        {
          name: 'totalPrice',
          label: 'Total Payout',
          sortable: true,
          renderer: ({ totalPrice }) => (totalPrice ? `$${handleCurrency(totalPrice)}` : '')
        } : { label: 'HIDE' },
      {
        name: 'driver-firstName',
        label: 'Driver Name',
        className: 'align-center w-8',
        sortable: true,
        renderer: ({ driver }) =>
          driver?.username && (
            <span data-driver-id={driver?._id} onClick={openDriverModal}>
              {`${driver?.firstName || ''} ${driver.lastName || ''}`}
            </span>
          ),
      },
      {
        name: 'driver-phone',
        label: 'Driver Phone Number',
        className: 'align-center w-8',
        sortable: true,
        renderer: (item) =>
          item.driver?.phone && (
            <a
              href={'tel: {item.driver.phone}'}
              className="table_orders__driver-phone"
            >
              {item.driver.phone}
            </a>
          ),
      },
      {
        className: 'align-center w-5',
        name: 'company', label: 'Company', sortable: true, maxWidth: '200px',
        renderer: (item) =>
          `${item?.driver?.company?.companyCode ? item?.driver?.company.companyCode : ''} 
          ${item?.driver?.company?.companyCode && item?.driver?.company?.companyName ? '-' : ''} 
          ${item?.driver?.company ? item?.driver?.company.companyName : ''}`,
      },
      {
        name: "paymentmode",
        label: "Method",
        sortable: true,
        renderer: (item) => {
          return (
            <span >{item?.paymentmode || "DWOLLA"}</span>
          );
        },
      },
      {
        name: 'exceptionNote',
        label: 'Exception Note',
        sortable: true,
        // className: "w-15",
      },
      {
        name: 'status',
        className: 'table__td_centered',
        label: 'Status',
        sortable: true,
        renderer: (item) =>
          item?.status && (
            <div style={{ margin: '0.5rem 0' }}>
              <span style={handleStatusesBadge(item.status)}>
                {item.status === 'approved'
                  ? changePaymentsLayout.includes('Weekly Payment') ? 'Approved' : 'Waiting for approval'
                  : item.status}
              </span>
              {
                (statusList.includes(item?.status) && item?.price) ?
                  (<>
                    <br />
                    <span style={handleStatusesBadge(item.status)}>
                      {statusList.includes(item?.status) && handlePaymentStatus(item?.status, item?.price, item?.isRequestedPayments, item?.isWeeklyPayments)}
                    </span>
                  </>) : null
              }
            </div>
          ),
      },
      {
        name: 'details',
        className: 'table__td_have-btn',
        renderer: (item) => {
          let path = loc.pathname;
          return (
            <button
              className="btn btn_block btn_accent customized-details-btn"
              type="button"
              onClick={() => {
                const refetch = () => actions.refetch.setIsDataRefetch(true);
                actions.modal.openOrder({ item, path, refetch });
              }}
            >
              Details <IoChevronForward className="btn__icon btn__icon_right" />
            </button>
          );
        },
      },
    ];
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChangePaymentLayout = useCallback((event) => {
    setSelectedOrders([]);
    setChangePaymentsLayout(event.target.value);
    if (checkParams() && event.target.value !== "Weekly Payment") setIsPaymentFail(true)
  });

  const handleRejectDriversPayments = async () => {
    // eslint-disable-next-line no-restricted-globals
    for (let order of selectedOrders) {
      try {
        if (changePaymentsLayout.includes('AdhocPayments')) {
          await changeStatusesForRejectDrivers({
            _id: order._id,
          });
        } else {
          await changeStatusesForRejectOrders({
            _id: order._id,
          });
        }
        actions.refetch.setIsDataRefetch(true);

      } catch (e) {
        dispatch(setLoading(false));
      }
    }
    setShowConfirm(false);
    setSelectedOrders([]);
    setExceptionsApprovedata([]);
    setExceptionsPaydata([]);
  };

  // useEffect(() => {
  //   if (!orders) {
  //     return;
  //   }
  //   handleSearchingPayments()

  // }, [orders,  checkForValueAdhoc]);

  const handleTimeRangeSelection = async () => {
    const { startDate, endDate } = state[0] || {};
    const { dt: strtDt } = new DateCreater(startDate);
    const { dt: endDt } = new DateCreater(endDate);
    setFromDate(strtDt);
    setToDate(endDt);
    setShowDateRangePicker(false);
    setPrevDate(state);
    actions.refetch.setIsDataRefetch(true);
    checkParams() && setIsPaymentFail(true)
  };

  const handleApprovePayments = async () => {
    for (let order of exceptionsApprovedata) {
      try {
        await changeStatusForApproveOrderPayments({ _id: order?._id, });
        actions.refetch.setIsDataRefetch(true);
      } catch (e) {
        dispatch(setLoading(false));
      }
    }
    setShowConfirmApprove(false);
    setSelectedOrders([]);
    setExceptionsApprovedata([]);
    setExceptionsPaydata([]);
  };

  const fetchMoreData = async () => {
    setDisable(true);
    let data = await getPaymentData(page, limit, search,selectedPaymentMethod);
    const paymentData = data.result;
    setIsTotal(data?.total);
    setDisable(false);
    setFilteredAdhocPayment([...filteredAdhocPayment, ...paymentData]);
    if (paymentData?.length === 0 || paymentData?.length < limit)
      setHasMore(false);
    setPage(page + 1);
  };

  const styles = {
    placeholder: (base) => ({
      ...base,
      fontSize: "0.9rem",
      fontWeight: "normal"
    }),
    control: (styles) => ({
      ...styles,
      borderRadius: "5px",
      boxShadow: "none",
      height: "2.9rem",
      "&:hover": {
        border: "2px solid #dadada",
      },
      border: "2px solid #dadada",
      fontSize: "1rem",
      width: "12rem",
    }),
  }

  return (
    <React.Fragment>
      {showMassPaymentsModal && (

        // <MassPaymentsModal
        //   isOpen
        //   onClose={() => {
        //     setShowMassPaymentsModal(null);
        //     setSelectedOrders([]);
        //   }}
        //   orders={selectedOrders}
        //   changePaymentsLayout={changePaymentsLayout}
        // />

        <MassPaymentsModal
          isOpen
          onClose={() => {
            setShowMassPaymentsModal(null);
            setSelectedOrders([]);
            setExceptionsPaydata([]);
            setExceptionsApprovedata([]);
          }}
          orders={changePaymentsLayout === 'Exceptions Payment' ? exceptionsPaydata : selectedOrders}
          changePaymentsLayout={changePaymentsLayout}
        />
      )}

      {csvData && (
        <CSVLink
          data={csvData.data}
          headers={csvData.headers}
          filename={csvData.filename}
          ref={csvInstance}
        />
      )}

      {showConfirm && (
        <ConfirmDialogModal
          bodyText={
            isRejectDriversLoading || isRejectOrdersLoading ? (
              <CircularProgress />
            ) : (
              'Reject all Payments?'
            )
          }
          isOpen
          onClose={() => {
            if (
              (checkForValueAdhoc && !isRejectDriversLoading) ||
              (!checkForValueAdhoc && !isRejectOrdersLoading)
            ) {
              setShowConfirm(false);
              setSelectedOrders([]);
              setExceptionsApprovedata([]);
              setExceptionsPaydata([]);
            }
          }}
          confirmAction={() => {
            handleRejectDriversPayments();
          }}
        />
      )}


      {showConfirmApprove && (
        <ConfirmDialogModal
          bodyText={
            isApproveOrderLoading ? (
              <CircularProgress />
            ) : (
              'Approve all Payments?'
            )
          }
          isOpen
          onClose={() => {
            if (
              (checkForValueAdhoc && !isApproveOrderLoading)
            ) {
              setShowConfirmApprove(false);
              setSelectedOrders([]);
              setExceptionsApprovedata([]);
              setExceptionsPaydata([]);
            }
          }}
          confirmAction={() => {
            handleApprovePayments();
          }}
        />
      )}

      <CardLayout>
        <HeaderTextLayout
          headerText="Payments"
          className="col-lg-2 col-md-3 H-2"
          data={
            checkForValueAdhoc
              ? checkArrForMapping(filteredAdhocPayment)
                ? `${filteredAdhocPayment.length} ${PAY_FOUND}`
                : ''
              : checkArrForMapping(filteredOrders)
                ? `${filteredOrders.length} ${PAY_FOUND}`
                : ''
          }
          dataLength={
            checkForValueAdhoc
              ? Array.isArray(filteredAdhocPayment)
                ? filteredAdhocPayment.length
                : '0'
              : Array.isArray(filteredOrders)
                ? filteredOrders.length
                : '0'
          }
          totalLength={isTotal && isTotal > 0 ? (filteredAdhocPayment.length > 0 && filteredAdhocPayment.length < 10) ? `(${'0'.concat(isTotal)} Found)` : `(${isTotal} Found)` : ''}
        >
          <div className="col-lg-10 col-md-9 filter">
            <div className="filter__inner p-0 justify-content-end">
              <div className="filter__ctrls-holder filter__input-holder search__container">

                {changePaymentsLayout === 'Weekly Payment' ? (
                  <div className="label_container">
                    <span className="label">Total Amount: ${totalAmounts}</span>
                    <span className="label label_yellow">Payment date: {bonusDate} ({bonusDay}) </span>
                  </div>
                ) : (null)
                }

                {(changePaymentsLayout === 'Exceptions Payment' && exceptionsApprovedata.length > 0) && (
                  <button
                    onClick={() => {
                      setShowConfirmApprove(true);
                    }}
                    // disabled={!exceptionsApprovedata.length}
                    className="btn btn_accent customized-details-btn"
                  >
                    Approve
                    {exceptionsApprovedata.length > 0 && ` (${exceptionsApprovedata.length})`}
                  </button>
                )}

                {shouldShowPayButton && (
                  <button
                    onClick={() => setShowMassPaymentsModal(true)}
                    className="btn btn_accent customized-details-btn"
                  >
                    <MdPayment className="btn__icon" />
                    Pay
                    {changePaymentsLayout === 'Exceptions Payment' ? (exceptionsPaydata.length > 0 && ` (${exceptionsPaydata.length})`) : (selectedOrders.length > 0 && ` (${selectedOrders.length})`)}
                  </button>
                )}

                {changePaymentsLayout !== 'Instant Payment' &&
                  <button
                    className="btn btn_accent customized-details-btn"
                    onClick={() => {
                      setShowConfirm(true);
                    }}
                    disabled={!selectedOrders.length}
                  >
                    Reject{' '}

                    {selectedOrders.length > 0 && ` (${selectedOrders.length})`}
                  </button>}

                <div style={{ display: "flex", gap: "1.25rem", alignItems: "center" }}>
                  <ReactSelect
                    options={paymentMethodOptions}
                    isClearable={true}
                    onChange={(item) => {
                      setSelectedPaymentMethod(item)
                    }}
                    placeholder="Select Method"
                    value={selectedPaymentMethod}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    styles={styles} />

                  <div className="searchbar__container" style={{ width: "240px" }}>
                    <input
                      className="pr-2 form__input customized__input"
                      value={searchQuery}
                      onChange={handleOnChange}
                      placeholder="Quick Search"
                      onKeyDown={handleKeyDown}
                      disabled={disable}
                    />
                    {searchQuery?.length > 0 && (
                      <Clear
                        className="clear__btn filter__search-icon"
                        onClick={() => {
                          setSearchQuery('');
                          handleFilterBySearchQuery('CLEAR');
                        }}
                        style={{
                          right: '52px',
                          top: '12px',
                        }}
                      />
                    )}
                    <button
                      disabled={searchQuery?.length <= 0}
                      className="search__button"
                      onClick={() => handleFilterBySearchQuery()}
                    >
                      <IoSearchSharp />
                    </button>
                  </div>
                </div>
              </div>

              {
                changePaymentsLayout === 'Weekly Payment' &&
                <div ref={wrapperRef}>
                  <div onClick={() => {
                    setShowDateRangePicker(!showDateRangePicker);
                    setState(prevDate);
                  }} className="form__input">
                    <div style={{ position: 'relative' }}>
                      <span className="label-date-picker">Route Date</span>
                    </div>
                    <span>{dateRange}</span>
                    <span className="ml-2">{isShowingDtRangePicker}</span>
                  </div>
                  {showDateRangePicker && (
                    <div className="mainDateRangePicker">
                      <DateRangePicker
                        onChange={(item) => setState([item.selection])}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={1}
                        ranges={state}
                        direction="horizontal"
                      />
                      <div className="button-container">
                        <button
                          onClick={() => {
                            setShowDateRangePicker(false);
                            checkParams() ? handleLastThreeMonth() : handleThisWeek();
                            actions.refetch.setIsDataRefetch(true);
                          }}
                          className="okButton clearButton"
                        >
                          <span>
                            <BackspaceIcon /> &nbsp;
                            <strong>RESET</strong>
                          </span>
                        </button>
                        <button
                          onClick={() => {
                            setShowDateRangePicker(false);
                          }}
                          className="cancelButton"
                        >
                          <span>
                            <CancelIcon
                              sx={{ width: '1.5rem', height: '1.5rem' }}
                            />{' '}
                            <strong>cancel</strong>
                          </span>
                        </button>
                        <button
                          onClick={handleTimeRangeSelection}
                          className="okButton"
                        >
                          <span>
                            <ThumbUpAltIcon /> &nbsp;
                            <strong>Done</strong>
                          </span>
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              }

              <FormControl style={{ width: 'auto' }}>
                <InputLabel id="demo-simple-select-required-label">
                  Select
                </InputLabel>
                <Select
                  labelId="demo-simple-select-required-label"
                  id="demo-simple-select-required"
                  value={changePaymentsLayout}
                  label="Select"
                  onChange={(e) => {
                    handleChangePaymentLayout(e);
                    setSelectedOrders([]);
                    setExceptionsApprovedata([]);
                    setExceptionsPaydata([]);
                    handleThisWeek();
                    actions.refetch.setIsDataRefetch(true);
                    setSearchQuery("")
                    setSearch("")
                  }}
                  sx={{
                    ...paymentSelectStyle,
                    ...hoverAndFocusedStyle
                  }}
                >
                  <MenuItem value="AdhocPayments">{ADHOC}</MenuItem>
                  <MenuItem value="Exceptions Payment">Approve with exceptions</MenuItem>
                  <MenuItem value="Instant Payment">Instant Payment</MenuItem>
                  <MenuItem value="Weekly Payment">Weekly Payment</MenuItem>
                </Select>
              </FormControl>

              <div>
                <button
                  id="fade-button"
                  className="btn btn_block btn_accent btn_export_15"
                  onClick={(event) => setAnchorEl(event.currentTarget)}
                  // variant="contained"
                  disabled={
                    loadingCsv || filteredAdhocPayment?.length === 0
                      ? true
                      : false
                  }
                >
                  {loadingCsv ? (
                    <div className="loading" style={{ color: 'white' }}>
                      <span className="loading__text" style={{ color: 'white' }}>
                        Exporting
                      </span>
                      ...
                    </div>
                  ) : (
                    <> <span> Export <KeyboardArrowDownIcon className="mr-0" /></span> </>
                  )}
                </button>
                <Menu
                  id="fade-menu"
                  MenuListProps={{
                    'aria-labelledby': 'fade-button',
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClick={() => setAnchorEl(null)}
                  TransitionComponent={Fade}
                >
                  {exportMenu.map(({ value, name }) => (
                    <MenuItem key={name} value={name} onClick={() => exportData(value)} style={{ width: '100px' }}>
                      {name}
                    </MenuItem>

                  ))}
                </Menu>
              </div>

              <button className="refresh_btn" onClick={() => actions.refetch.setIsDataRefetch(true)}>
                <Refresh className="refresh_icon" />
              </button>
            </div>
          </div>
        </HeaderTextLayout>

        <InfiniteScroll
        className='infinit_table'
          dataLength={checkForValueAdhoc ? filteredAdhocPayment?.length : filteredOrders?.length}
          next={fetchMoreData}
          hasMore={hasMore}
          style={{
            paddingBottom: !hasMore ? '0px' : '10px',
          }}
          loader={
            <div
              className="d-flex justify-content-center align-items-center"
              style={{
                height:
                  (filteredAdhocPayment && filteredAdhocPayment?.length <= 0)
                    ? '70vh'
                    : 'auto',
              }}
            >
              <CircularProgress className="loading-progress" />
            </div>
          }
          height={'80vh'}
          endMessage={
            (filteredAdhocPayment && filteredAdhocPayment?.length <= 0) && (
              <div
                style={{
                  height: '70vh',
                }}
                className="d-flex justify-content-center align-items-center"
              >
                <p>
                  <b>No Result Found</b>
                </p>
              </div>
            )
          }
        >
          {isLoading ? (
            <div className="loading-holder">
              <CircularProgress className="loading-progress" />
            </div>
          ) : (
            <Table
              columns={columns}
              items={checkForValueAdhoc ? filteredAdhocPayment : filteredOrders}
              className="table_stores"
              width={'100%'}
              rowClass={(item) =>
                cn({
                  'table__tr-danger':
                    checkParams() && item?.isPaymentFail
                })
              }
            />
          )}
        </InfiniteScroll>
      </CardLayout>
    </React.Fragment>
  );
}
