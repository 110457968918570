import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import { actions } from "../../../../redux/store/store";

const EditNotes = (props) => {
  const state = props?.state
  const title = props?.title
  const notes = props && props?.notes && props?.notes;
  const id = props && props?.id && props?.id;
  const role = props && props.currentUser && props?.currentUser?.role;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <span>{props?.notes}</span>
        {role === "root" &&
          <span
            style={{ cursor: "pointer" }}
            onClick={() =>
              actions.modal.openNotes({
                notes,
                id,
                title,
                state
              })
            }
          >
            <EditIcon style={{ width: "20px", height: "20px" }} />
          </span>
        }
    </div>
  );
};

export default EditNotes;
