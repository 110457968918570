import { Modal } from '@mui/material';
import moment from 'moment';
import React from 'react'
import PinchZoomPan from 'react-responsive-pinch-zoom-pan';
import { actions } from '../../../../redux/store/store';

function PODsModal(props) {
  const { id } = props;

  const onCancel = () => {
    actions.modal.closePodsModal();
  };
  return (
    <div>
      <Modal
        open
        onClose={onCancel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          className={`modal after-loading`}
          style={{ maxWidth: "60.875rem" }}
        >
          <div className="modal__header">
            <h2 className="modal__title">
              {id?.ParcelTrackingNumber} | {id?.ShipmentTrackingNumber} | {id?.EventDateTime && moment(id?.EventDateTime).format("MM-DD-YYYY hh:mm:ss")}
            </h2>
          </div>
          <div className="modal__body">
            <div style={{ position: "relative" }}>
              <PinchZoomPan maxScale={10}>
                <div style={{ width: "100%" }}>
                  <img
                    alt="Test"
                    src={id?.PodUrl}
                    className="responsive"
                    style={{
                      objectFit: "contain",
                      width: "50%",
                      textAlign: "center",
                    }}
                  />
                </div>
              </PinchZoomPan>
            </div>
          </div>
          <div className="modal__footer">
            <button
              className="btn btn_accent"
              onClick={onCancel}
            >
              OK
            </button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default PODsModal
