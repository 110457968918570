import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuthInterceptor } from "./util";

export const settingApi = createApi({
  reducerPath: "settingApi",
  baseQuery: baseQueryWithAuthInterceptor({ baseUrl: "/api/" }),
  tagTypes: ["Setting"],
  endpoints: (builder) => ({
    getUpdateDialog: builder.query({
      query: () => {
        return {
          url: "/getUpdateDialog",
          method: "GET",
        };
      },
      providesTags: ["Setting"],
    }),
 
  }),
});

export const {
 useGetUpdateDialogQuery
} = settingApi;
