import React, { useCallback, useRef, useState } from 'react'
import { Autocomplete, Box, CircularProgress, Drawer, TextField } from '@mui/material'
import { useSelector } from 'react-redux'
import ClearIcon from "@mui/icons-material/Clear";
import { actions } from '../../../../redux/store/store';
import "./style.scss"
import { Controller, useForm } from 'react-hook-form';
import { ErrorMessage } from '../../Form';
import { coachingSection, coachingType, orderNumber } from '../../../../constants/variables/arrays';
import { driverCoachingValidationSchema } from '../../../../constants/validaitonSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { BsFillFileEarmarkPdfFill } from 'react-icons/bs';
import { useCreateCoachingMutation, useUpdateCoachingMutation, useUploadCoachingMutation } from '../../../../api/driverCoaching';
import { sendSuccessMessage } from '../../../../constants/extras/sendSuccessMsg';
import { AiFillCloseCircle } from 'react-icons/ai';
import video_img from "../../../../images/video.svg"
import document_img from "../../../../images/document.svg"
import axios from 'axios';

function CustomCoachingDrawer(props) {
    const { coaching } = props
    const imageInputRef = useRef(null)
    const driverCoaching = useSelector((state) => state.modal.driverCoaching)
    const id = driverCoaching?.id

    const [createCoaching] = useCreateCoachingMutation()
    const [uploadCoaching, { isLoading: loadUploadCoach }] = useUploadCoachingMutation()
    const [updateCoaching] = useUpdateCoachingMutation()

    const form = useForm({
        defaultValues: {
            section: null,
            orderNumber: "",
            title: "",
            type: null,
            description: ""

        },
        resolver: yupResolver(driverCoachingValidationSchema),
    });

    const [title, setTitle] = useState(false)
    const [video, setVideo] = useState("")
    const [pdf, setPdf] = useState("")
    const [link, setLink] = useState("")
    const [isFile, setIsfile] = useState(false)
    const [onboardingNumber, setOnboardingNumber] = useState([])
    const [shiftNumber, setShiftNumber] = useState([])
    const [videoPreview, setVideoPreview] = useState(null)
    const [documentPreview, setDocumentPreview] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [limit, setLimit] = useState(false)
    const [urlPreview, setUrlPreview] = useState(false)
    const [isValid, setIsValid] = useState(false)
    const [isLink, setIsLink] = useState(false)

    const type = form.watch("type")
    const section = form.watch("section")

    const fetchImage = async () => {
        if (driverCoaching.open) {
            setVideo("")
            setPdf("")
            if (id?.type === "video") {
                setIsLoading(true)
                const arr = await axios.get(`/api/coaching/${id?._id}/debugCoaching`);
                setIsLoading(false)
                setVideoPreview(arr?.data?.result?.url)
            } else if (id?.type === "document") {
                setIsLoading(true)
                const result = await fetch(`/api/coaching/${id?._id}/debugCoaching`, { credentials: 'include' })
                setIsLoading(false)
                setDocumentPreview(result.url)
            }
        }
    }

    useEffect(() => {
        if (driverCoaching.open) {
            if (section?.value === "onboarding") {
                const data = orderNumber?.filter((elem) => !coaching?.some((item) => item?.section === "onboarding" && item?.orderNumber === +elem))
                const arr = id ? [String(id?.orderNumber), ...data] : data
                setOnboardingNumber(arr.sort((a, b) => a - b));
            } else {
                const data = orderNumber?.filter((elem) => !coaching?.some((item) => item?.section === "shift" && item?.orderNumber === +elem))
                const arr = id ? [String(id?.orderNumber), ...data] : data
                setShiftNumber(arr.sort((a, b) => a - b));
            }
        }
    }, [section])

    useEffect(() => {
        if (driverCoaching.open) {
            (id && id?.type !== "link") && fetchImage()
            const section = coachingSection?.filter((e) => e?.value === id?.section)
            const type = coachingType?.filter((e) => e?.value === id?.type)
            form.reset()
            setTitle(id ? true : false)
            form.setValue("section", id && section[0] || null)
            form.setValue("orderNumber", id && String(id?.orderNumber) || "")
            form.setValue("title", id?.title || "")
            form.setValue("type", id && type[0] || null)
            form.setValue("description", id?.description || "")
            setLink(id ? id?.url : "")
            setUrlPreview(id ? true : false)
            setIsfile(false)
            setLimit(false)
            setIsValid(false)
            setIsLink(false)
        }
    }, [driverCoaching, id])

    const onSubmit = async (data) => {
        const params = {
            ...data,
            section: data?.section?.value,
            type: data?.type?.value,
            orderNumber: +data?.orderNumber,
            url: link
        }

        const upload = {
            video: video,
            document: pdf
        }

        const isCall = (videoPreview || documentPreview || link) && !limit && !isValid && !isLink

        const result = isCall && await (id ? updateCoaching({ id, params }) : createCoaching(params));
        if (result?.data && !result?.error) {
            if (video || pdf) {
                const getData = await uploadCoaching({ id: result?.data?._id, upload })
                if (getData?.error?.originalStatus === 200) {
                    sendSuccessMessage(id ? "Coaching updated successfully!!" : "Coaching created successfully!!");
                    actions.refetch.setIsDataRefetch(true);
                    actions.modal.closeDriverChoching();
                }
            } else {
                sendSuccessMessage(id ? "Coaching updated successfully!!" : "Coaching created successfully!!");
                actions.refetch.setIsDataRefetch(true);
                actions.modal.closeDriverChoching();
            }
        }
        setIsfile(true)
    }

    const onCancel = () => {
        actions.modal.closeDriverChoching()
    }

    const onCancelFile = () => {
        setVideo("")
        setPdf("")
        setVideoPreview("")
        setDocumentPreview("")
        setLimit(false)
        setIsValid(false)
        imageInputRef.current.value = null
    }

    const handleTypeCHange = (e) => {
        setVideo("")
        setPdf("")
        setLink("")
        setVideoPreview("")
        setDocumentPreview("")
        setIsfile(false)
        setLimit(false)
        setUrlPreview(false)
        setIsLink(false)
    }

    const handleChangeFile = (e) => {
        const file = e.target.files[0]

        setVideo(type?.value === "video" ? file : null)
        setPdf(type?.value === "document" ? file : null)
        setVideoPreview(type?.value === "video" ? file ? URL.createObjectURL(file) : "" : null)
        setDocumentPreview(type?.value === "document" ? file ? URL.createObjectURL(file) : "" : null)

        if (!file?.name?.includes("pdf") && !file?.name?.includes("mp4")) setIsValid(true)
        else setIsValid(false)
        if ((file?.size > 30 * 1024 * 1024) && type?.value === "video") setLimit(true)
        else setLimit(false)
    }

    function validURL(str) {
        var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        return pattern.test(str);
    }

    return (
        <>
            <Drawer
                anchor='right'
                open={driverCoaching.open}
                transitionDuration={{ enter: 1000, exit: 1000 }}
            >
                <Box className='coaching_contain' role="presentation" >
                    <div className='drawer_header_wrapper' style={{ width: "800px" }}>
                        <div className='drawer_head_left' style={{ alignItems: "center" }}>
                            <div className="drawer-title">
                                <h2 className="modal__title">
                                    {title ? "Update Driver Coaching" : "Create Driver Coaching"}
                                </h2>
                            </div>
                        </div>
                        <div className="me-2" onClick={onCancel}>
                            <ClearIcon style={{ fontSize: "24px", color: "#adbfc7", cursor: "pointer" }} />
                        </div>
                    </div>


                    <div style={{ height: "100vh", overflow: "auto", padding: "90px 40px" }}>
                        <div className=" d-flex mt-3" style={{ gap: "20px" }} >
                            <div className='inputData w-100' >
                                <Controller
                                    control={form.control}
                                    name="section"
                                    render={({
                                        field: { onChange, value, ref },
                                        fieldState: { error },
                                    }) => (
                                        <React.Fragment>
                                            <label>Section:</label>
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                options={coachingSection}
                                                onChange={(e, val) => {
                                                    onChange(val)
                                                }}
                                                value={value || null}
                                                getOptionLabel={(option) => option?.title}
                                                fullWidth
                                                ref={ref}
                                                renderInput={(params) => <TextField {...params} placeholder="Select Section" />}
                                                isOptionEqualToValue={(option, value) => option?.value === value?.value}
                                            />
                                            <ErrorMessage error={error} />
                                        </React.Fragment>
                                    )}
                                />
                            </div>
                            <div className="inputData w-100" >
                                <Controller
                                    control={form.control}
                                    name="orderNumber"
                                    render={({
                                        field: { onChange, value, ref },
                                        fieldState: { error },
                                    }) => (
                                        <React.Fragment>
                                            <label>Order Number:</label>
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                options={section?.value === "onboarding" ? onboardingNumber : shiftNumber}
                                                onChange={(e, val) => {
                                                    onChange(val)
                                                }}
                                                value={value || null}
                                                isOptionEqualToValue={(option, value) => +option === +value}
                                                fullWidth
                                                ref={ref}
                                                sx={{ "& .MuiAutocomplete-input ": { cursor: !section && "no-drop" } }}
                                                disabled={!section}
                                                renderInput={(params) => <TextField {...params} placeholder="Select Type" />}
                                            />
                                            <ErrorMessage error={error} />
                                        </React.Fragment>
                                    )}
                                />
                            </div>
                        </div>
                        <div className="inputData w-100 mt-4" >
                            <Controller
                                control={form.control}
                                name="title"
                                render={({
                                    field: { onChange, value },
                                    fieldState: { error },
                                }) => (
                                    <React.Fragment>
                                        <label>Title:</label>
                                        <input
                                            type="text"
                                            value={value}
                                            onChange={(e) => onChange(e.target.value.trimStart())}
                                            placeholder="Enter Title"
                                            className='order_input'
                                        />
                                        <ErrorMessage error={error} />
                                    </React.Fragment>
                                )}
                            />
                        </div>
                        <div className='inputData w-100 mt-4' >
                            <Controller
                                control={form.control}
                                name="type"
                                render={({
                                    field: { onChange, value, ref },
                                    fieldState: { error },
                                }) => (
                                    <React.Fragment>
                                        <label>Type:</label>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            options={coachingType}
                                            onChange={(e, val) => {
                                                onChange(val)
                                                handleTypeCHange()
                                            }}
                                            value={value || null}
                                            getOptionLabel={(option) => option?.title}
                                            isOptionEqualToValue={(option, value) => option?.value === value?.value}
                                            fullWidth
                                            ref={ref}
                                            renderInput={(params) => <TextField {...params} placeholder="Select Type" />}
                                        />
                                        <ErrorMessage error={error} />
                                    </React.Fragment>
                                )}
                            />
                        </div>

                        {
                            (type?.title === "Video" || type?.title === "Document (PDF)") &&
                            <div className="d-flex justify-content-center align-items-center flex-column" style={{ marginTop: "20px" }} >
                                {
                                    isLoading || loadUploadCoach ?
                                        <div >
                                            <CircularProgress className="loading-progress" />
                                        </div> : (<>
                                            <div onClick={() => (!video || !pdf) && imageInputRef.current.click()} >
                                                <input
                                                    hidden
                                                    ref={imageInputRef}
                                                    type="file"
                                                    accept={type?.title === "Video" ? "video/mp4" : ".pdf"}
                                                    onChange={handleChangeFile}
                                                />
                                                {
                                                    (!videoPreview && !documentPreview) && <div className="image-select">
                                                        <div className="select_img">
                                                            <img src={type?.title === "Video" ? video_img : document_img} alt="" />
                                                            <p className='mt-2'>{type?.title === "Video" ? "Upload Video" : "Upload Document"}</p>
                                                        </div>
                                                    </div>
                                                }
                                            </div>

                                            {type?.title === "Video"?(videoPreview &&
                                                <video controls width="400" height="250">
                                                    <source src={videoPreview} type="video/mp4" />
                                                </video>
                                            ):(null)}
                                            
                                                {type?.title === "Document (PDF)"?(documentPreview && <div className="pdf" >
                                                <a href={documentPreview} target="_blank" >
                                                    <BsFillFileEarmarkPdfFill className='pdf_icon' />
                                                    <span> {pdf?.name ? pdf?.name : documentPreview}</span>
                                                </a>
                                            </div>):(null) }
                                            

                                            {type?.title === "Video" && limit && <ErrorMessage error={{ message: "Your video size is more than 30 mb" }} />}
                                            {isFile && (!videoPreview && !documentPreview) && <ErrorMessage error={{ message: "This field is required" }} />}
                                            {videoPreview && isValid && <ErrorMessage error={{ message: "Only mp4 file extensions is allowed." }} />}
                                            {documentPreview && isValid && <ErrorMessage error={{ message: "Only pdf file extensions is allowed." }} />}

                                            {(videoPreview || documentPreview || isLoading) && <AiFillCloseCircle className="mt-2" onClick={onCancelFile} style={{ color: "red", fontSize: "25px", cursor: "pointer" }} />}
                                        </>)
                                }
                            </div>
                        }
                        {
                            type?.title === "Link" &&
                            <div className="mt-4 inputData">
                                <label>Link:</label>
                                {
                                    !urlPreview && <input
                                        type="text"
                                        value={link}
                                        onChange={(e) => {
                                            setLink(e.target.value.trimStart())
                                            setIsLink(validURL(e.target.value) ? false : true)
                                        }}
                                        placeholder="Enter Link"
                                        className='order_input'
                                    />
                                }
                                {id && urlPreview && <div >
                                    <a href={link?.includes("https") ? link : `https://${link}`} className="mr-2" target="_blank" >{link}</a>
                                    <AiFillCloseCircle style={{ color: "red", fontSize: "20px", cursor: "pointer" }} onClick={() => {
                                        setUrlPreview(false)
                                        setLink("")
                                        setIsLink(true)
                                    }} />
                                </div>
                                }
                                {isLink && <ErrorMessage error={{ message: "Please add valid url" }} />}
                            </div>
                        }

                        <div className="mt-4 inputData" >
                            <Controller
                                control={form.control}
                                name="description"
                                render={({
                                    field: { onChange, value, ref },
                                }) => (
                                    <React.Fragment>
                                        <label>Description:</label>
                                        <textarea
                                            type="text"
                                            value={value}
                                            onChange={(e) => onChange(e.target.value.trimStart())}
                                            placeholder="Enter Description"
                                        />
                                    </React.Fragment>
                                )}
                            />
                        </div>
                    </div>

                    <div className='drawer__footer' style={{ width: "800px", zIndex: "unset" }}>
                        <div>
                            <button
                                className="btn"
                                onClick={onCancel}
                                disabled={loadUploadCoach}
                            >
                                Cancel
                            </button>
                        </div>
                        <div>
                            <button className="btn btn_accent" style={{ width: "80px" }} onClick={form.handleSubmit(onSubmit)} disabled={loadUploadCoach}>
                                {title ? "Update" : "Save"}
                                {loadUploadCoach && (
                                    <span>
                                        <CircularProgress size={20} style={{ color: "white" }} />
                                    </span>
                                )}
                            </button>
                        </div>
                    </div>
                </Box>
            </Drawer>
        </>
    )
}

export default CustomCoachingDrawer
