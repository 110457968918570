import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import { actions } from "../../../../redux/store/store";

const EditMetro = (props) => {
  const metro = props && props?.metro && props?.metro;
  const id = props && props?.id && props?.id;
  const role = props && props.currentUser && props?.currentUser?.role;
  let checkForUpdateMetro = "CHANGE__DRIVERS__METRO";
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <span>{props?.metro}</span>
      {role === "root" && metro && (
        <span
          style={{ cursor: "pointer" }}
          onClick={() =>
            actions.modal.openMetro({
              metro,
              id,
              checkForUpdateMetro,
            })
          }
        >
          <EditIcon style={{ width: "20px", height: "20px" }} />
        </span>
      )}
    </div>
  );
};

export default EditMetro;
