import { timeTransform_h } from "../../util/time";

export const handleEstimatedShiftPay = (
  estimatedShiftPayFrom,
  estimatedShiftPayTo
) => {
  let formattedEstimatedShiftPay;
  if (estimatedShiftPayFrom && estimatedShiftPayTo) {
    formattedEstimatedShiftPay = `${
      estimatedShiftPayFrom && typeof estimatedShiftPayFrom === "number"
        ? "$" + estimatedShiftPayFrom.toFixed(2)
        : ""
    } - ${
      estimatedShiftPayTo && typeof estimatedShiftPayTo === "number"
        ? "$" + estimatedShiftPayTo.toFixed(2)
        : ""
    }`;
  } else if (estimatedShiftPayFrom) {
    formattedEstimatedShiftPay = `${
      estimatedShiftPayFrom && typeof estimatedShiftPayFrom === "number"
        ? "$" + estimatedShiftPayFrom.toFixed(2)
        : ""
    }`;
  } else if (estimatedShiftPayTo) {
    formattedEstimatedShiftPay = `${
      estimatedShiftPayTo && typeof estimatedShiftPayTo === "number"
        ? "$" + estimatedShiftPayTo.toFixed(2)
        : ""
    }`;
  } else {
    return "-";
  }

  return formattedEstimatedShiftPay;
};

export const handleEstimatedShiftTime = (
  estimatedShiftTimeFrom,
  estimatedShiftTimeTo
) => {
  let formattedEstimatedShiftTime = `${
    estimatedShiftTimeFrom && typeof estimatedShiftTimeTo === "string"
      ? timeTransform_h.toDisplay(estimatedShiftTimeFrom).concat("h")
      : ""
  } - ${
    estimatedShiftTimeTo && typeof estimatedShiftTimeTo === "string"
      ? timeTransform_h.toDisplay(estimatedShiftTimeTo).concat("h")
      : ""
  }`;

  return formattedEstimatedShiftTime;
};

export const handlePrice = (price) => {
  let formattedPrice = `${
    price && typeof price === "number" ? "$".concat(price.toFixed(2)) : "-"
  }`;

  return formattedPrice;
};
