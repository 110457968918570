import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuthInterceptor } from "./util";

export const adminsApi = createApi({
  reducerPath: "adminsApi",
  baseQuery: baseQueryWithAuthInterceptor({ baseUrl: "/api/admins" }),
  tagTypes: ["Admin"],
  endpoints: (builder) => ({
    getAdmins: builder.query({
      query: () => "",
      providesTags: ["Admin"],
    }),
    changeRole: builder.mutation({
      query: ({ id, role, metros }) => ({
        url: `${id}/changeRole`,
        method: "POST",
        body: { role, metros },
      }),
      invalidatesTags: (result, error, arg) =>
        !error && [{ type: "Admin", _id: result._id }],
    }),
    getDepositList: builder.query({
      query: () => "depositBalance",
      providesTags: ["Admin"],
    }),
    depositBalance: builder.mutation({
      query: ({ id, amount }) => ({
        url: `/depositBalance`,
        method: "POST",
        body: { amount },
      }),
      invalidatesTags: (result, error, arg) =>
        !error && [{ type: "Admin", _id: result._id }],
    }),
    getTotalBalance: builder.query({
      query: () => "totalBalance",
      providesTags: ["Admin"],
    }),
    getDepositStatus: builder.query({
      query: ({ id }) => `depositStatus/${id}`,
      providesTags: ["Admin"],
    }),
    getDepositStatus: builder.mutation({
      query: (id) => ({
        url: `depositStatus/${id}`,
      }),
      invalidatesTags: (result, error, arg) =>
        !error && [{ type: "Driver", _id: result._id }],
    }),
  }),
});

export const {
  useGetAdminsQuery,
  useChangeRoleMutation,
  useGetDepositListQuery,
  useDepositBalanceMutation,
  useGetTotalBalanceQuery,
  useGetDepositStatusMutation,
} = adminsApi;
