import { createApi } from "@reduxjs/toolkit/query/react";
import axios from "axios";
import { baseQueryWithAuthInterceptor } from "./util";

export const companyApi = createApi({
  reducerPath: "companyApi",
  baseQuery: baseQueryWithAuthInterceptor({ baseUrl: "/api/" }),
  tagTypes: ["Company"],
  endpoints: (builder) => ({
    getCompany: builder.query({
      query: () => {
        return {
          url: "company",
          method: "GET",
        };
      },
      providesTags: ["Company"],
    }),
    getCompanyById: builder.query({
      query: (id) => {
        return {
          url: `company/${id}`,
          method: "GET",
        };
      },
      providesTags: ["Company"],
    }),
    createCompany: builder.mutation({
      query: (body) => ({
        url: "company",
        method: "POST",
        body,
      }),
      invalidatesTags: (result, error, arg) => !error && ["Company"],
    }),
    updateCompany: builder.mutation({
      query: (body) => ({
        url: `company/${body._id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (result, error, arg) => !error && ["Company"],
    }),
  }),
});

export const {
  useGetCompanyQuery,
  useGetCompanyByIdQuery,
  useCreateCompanyMutation,
  useUpdateCompanyMutation
} = companyApi


export const getCompanyList = async (
search
) => {
  try {
    const response = await axios.get(
      `/api/company?search=${search}`,
    );
    if (response.status === 200) return response?.data;
    else return "Something went wrong";
  } catch (e) {
    return e.response.data;
  }
};
