import React, { useState, useEffect } from "react";
import { IoSearchSharp, IoChevronForward } from "react-icons/io5";
import { CircularProgress } from "@mui/material";
import { IoIosAddCircle } from "react-icons/io";

import Table from "../../../components/shared/Table";
import { actions } from "../../../redux/store/store";
import "../../../styles/_data.scss";
import "../../../index.css";
import HeaderTextLayout from "../../../components/shared/HeaderTextLayout";
import moment from "moment";
import { useGetEmailsQuery } from "../../../api/email";
import MetroSelect from "../../../components/shared/metroSelect";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Clear, Refresh } from "@mui/icons-material";

export default function Email() {
  const location = useLocation();
  const { pathname } = location;

  let checkForUpdateMetro = "";
  const metro = useSelector((state) => state.metro.value);
  const email = useSelector((state) => state.modal.email);
  const { data: emailsList, isFetching, refetch } = useGetEmailsQuery(
    { metro: metro && email.open === false && metro },
    {
      refetchOnMountOrArgChange: true,
      skip: email?.open === true,
    }
  );

  const [filteredEmails, setFilteredEmails] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    actions.metro.selectMetro("");
    actions.refetch.setIsDataRefetch(true);
  }, [pathname]);

  useEffect(() => {
    if (!emailsList?.result) return;
    
    handleSearchingEmails()

  }, [emailsList, emailsList?.result]);

  const handleSearchingEmails =  (value) => {
    const srch = value === 'CLEAR' ? '' : searchQuery;
    let filtered = emailsList?.result?.filter((emailData) => {
      return (
        (emailData &&
          emailData?.subject &&
          emailData?.subject
            .toLowerCase()
            .trim()
            .includes(srch.toLowerCase().trim())) ||
        (emailData?.sentBy &&
          emailData?.sentBy?.username
            .toLowerCase()
            .trim()
            .includes(srch.toLowerCase().trim())) ||
        emailData?.emails[0]?.toLowerCase().trim().includes(srch.toLowerCase().trim())
      );
    })
      
    setFilteredEmails((prev) => [...filtered] );
    
  }
  

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearchingEmails();
    }
  };

  const columns = [
    {
      name: "emails",
      label: "Email",
      sortable: true,
      renderer: (item) =>
        item &&
          item?.emails &&
          Array.isArray(item?.emails) &&
          item?.emails?.length > 0
          ? item?.emails?.length === 1
            ? item.emails[0]
            : `${item.emails[0]},...`
          : "-",
    },
    {
      name: "metro",
      label: "Metro",
      sortable: true,
      className: "align-center",
      renderer: (item) => (item && item?.metro ? item?.metro : "-"),
    },
    {
      name: "subject",
      label: "Subject",
      sortable: true,
      className: "align-center",
    },
    {
      name: "date",
      label: "Time",
      sortable: true,
      className: "align-center",
      renderer: (item) => moment(item.date).format("MMM D, YYYY hh:mm A"),
    },
    {
      name: "sentBy-username",
      label: "Sent By",
      sortable: true,
      className: "align-center",
      renderer: (item) =>
        item && item?.sentBy && item?.sentBy?.username
          ? item.sentBy.username
          : "-",
    },
    {
      name: "details",
      className: "table__td_have-btn align-center",
      renderer: (item) => {
        return (
          <button
            className="btn btn_block btn_accent customized-details-btn"
            type="button"
            onClick={() => {
              actions.modal.openEmail({ item, checkForUpdateMetro });
            }}
          >
            Details <IoChevronForward className="btn__icon btn__icon_right" />
          </button>
        );
      },
    },
  ];

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="card">
            <HeaderTextLayout
              headerText="Emails"
              className="col-md-2"
              data={
                filteredEmails && filteredEmails.length > 0
                  ? `${filteredEmails.length} Emails Found`
                  : ""
              }
              dataLength={
                Array.isArray(filteredEmails) ? filteredEmails.length : "0"
              }
            >
              <div
                className="col-md-10 pull-right d-flex justify-content-end"
                style={{ alignItems: "center" }}
              >
                  <div className="filter__ctrls-holder filter__input-holder d-flex justify-content-end">
                      <div className="searchbar__container pr-2">

                      <input
                        className="pr-2 form__input customized__input"
                        value={searchQuery}
                        onChange={(e) => {
                          setSearchQuery(e.target.value);
                          if (e.target.value === "")
                          handleSearchingEmails("CLEAR");
                        }}
                        placeholder="Quick Search"
                        onKeyDown={handleKeyDown}
                      />
                      {searchQuery?.length > 0 && (
                        <Clear
                          className="clear__btn filter__search-icon"
                          onClick={() => {
                            setSearchQuery("");
                            handleSearchingEmails("CLEAR");
                          }}
                          style={{
                            right: "52px",
                            top: "12px",
                          }}
                        />
                      )}
                      <button
                        disabled={searchQuery?.length <= 0}
                        className="search__button"
                        onClick={() => handleSearchingEmails()}
                      >
                        <IoSearchSharp className="filter__search-icon" />
                      </button>
                      </div>
                    </div>

                <div className="w-25">
                  {" "}
                  <MetroSelect
                    style={{ height: "45px", width: "100%" }}
                    value={metro || ""}
                    onChange={(event, newValue) =>
                      actions.metro.selectMetro(newValue)
                    }
                  />
                </div>

                <button
                  style={{
                    background: "white",
                    borderColor: "#e6e6e6",
                    padding: "0 20px"
                  }}
                  className="btn btn__accent mr-0 ml-3"
                  onClick={() => {
                    actions.modal.openEmail();
                    actions.metro.selectMetro("");
                  }}
                >
                  <IoIosAddCircle style={{ fontSize: "22px" }} />
                  New Email
                </button>

                <button className="refresh_btn ml-3" onClick={() => refetch()}>
                  <Refresh className="refresh_icon" />
                </button>
              </div>
            </HeaderTextLayout>
            {isFetching ? (
              <div className="loading-holder">
                <CircularProgress className="loading-progress" />
              </div>
            ) : (
              <React.Fragment>
                {filteredEmails &&
                  Array.isArray(filteredEmails) &&
                  filteredEmails?.length ? (
                  <div className="table-wrapper normal_table">
                    <Table
                      columns={columns}
                      items={filteredEmails}
                      className="table_metros"
                    />
                  </div>
                ) : (
                  <div className="no_results">No Results</div>
                )}
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
