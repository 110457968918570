export const rewordingOrderStatus = (status, path) => {
  if (!status) return;
  if (status === "interestedDriver_delivery") return "interested";
  if (status === "interestedDriver_sort") return "sort_interested";
  if (status === "interestedDriver_pickup") return "pickup_interested";
  if (status === "delivery_started") return "Delivery Started";
  if (status === "approved" && path !== '/payments') return "Waiting For Approval";
  return status;
};

export const handleType = (type) => {
  if (!type || typeof type !== "string") return "";
  const strArr = type?.split("_");
  let str = "";
  strArr?.forEach(
    (s) => (str += s.slice(0, 1).toUpperCase() + s.slice(1) + " ")
  );
  return str;
};

export const cancelType = (type) => {
  if (!type) return;
  if (type === "excess_drivers") return "Excess drivers";
  if (type === "driver_canceled") return "Driver canceled";
  if (type === "no_call") return "No call, no show";
  if (type === "arrived_late") return "Arrived late";
  if (type === "other") return "Other";
}

export const cancelScore = (score) => {
  if (!score) return;
  if (score === "excess_drivers") return 0;
  if (score === "driver_canceled") return -2;
  if (score === "no_call") return -4;
  if (score === "arrived_late") return -0.5;
  if (score === "other") return 0;
  return score
}
