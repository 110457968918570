import moment from "moment";
import { convertToCapitalize, removeDash } from "../extras/convertToCapitalize";
import { handleNumberwithPadding } from "../extras/handleNumbers";
import { scheduleTime, weekdays } from "../variables/arrays";
const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
const utc = moment().format().slice(19);

const handleData = (data) => {
    return `${data ? convertToCapitalize(data) : "-"}`;
};

const handleDate = (date) => {
    return `${date ? moment(date).format("MMM D, YYYY hh:mm A") : "-"}`;
};

const commonData = (data, column) => {
    const {
        created,
        firstName,
        lastName,
        username,
        phone,
        metro,
        orderCount,
        shiftLastDate,
        status,
        isBlocked,
        onboardingBackgroundFillStatus,
        onboardingDepositFillStatus,
        onboardingInsuranceFillStatus,
        onboardingTaxFillStatus,
        onboardingLicenceFillStatus,
        firstAssignedShift,
        firstInterestedShift,
        score,
        lastLoginTime,
        onboardingAt,
        approvedAt,
        referrerName,
        referrerPhone,
        internalNote,
        company,
        availableDays,
        availableTime,
        timeAmount,
        licenceExpiredDays,
        insuranceExpiredDays,
    } = data || {};

    let count = 0;
    const arr = [
        onboardingBackgroundFillStatus,
        onboardingDepositFillStatus,
        onboardingInsuranceFillStatus,
        onboardingTaxFillStatus,
        onboardingLicenceFillStatus,
    ];

    for (const status of arr) {
        if (["completed", "complete"].includes(status)) count++;
    }

    const dayData = weekdays?.filter((el, index) => availableDays?.some((item) => item === index))
    const timeData = scheduleTime?.filter((el, index) => availableTime?.some((item) => item === index))
    let day = "", time = "";
    for (const data of dayData) {
        day += `${data},`
    }
    for (const data of timeData) {
        time += `${data},`
    }

    const showColumn = (data) => {
        return column?.includes(data)
    }

    return {
        ...(showColumn("Created") && { "Created": handleDate(created) }),
        ...(showColumn("Name") && { "Name": `${firstName ? firstName : "-"} ${lastName ? lastName : ""}` }),
        ...(showColumn("Username") && { "username": ` ${username ? username : "-"}` }),
        ...(showColumn("Company") && { "Company": `${company?.companyCode ? company?.companyCode : ""} ${company?.companyName && company?.companyCode ? "-" : ""} ${company?.companyName ? company?.companyName : ""}` }),
        ...(showColumn("Phone") && { "phone": ` ${phone ? phone : "-"}` }),
        ...(showColumn("Metro") && { "metro": `${metro ? metro : "-"}` }),
        ...(showColumn("Shifts") && { "Shifts": `${orderCount ? handleNumberwithPadding(orderCount) : "-"}` }),
        ...(showColumn("Shift Last Date") && { "Shift Last Date": `${shiftLastDate ? moment(shiftLastDate).utc().format('MM/DD/YYYY') : "-"}` }),
        "Background Fill Status": handleData(
            onboardingBackgroundFillStatus
        ),
        "Deposit Fill Status": handleData(onboardingDepositFillStatus),
        "Insurance Fill Status": handleData(
            onboardingInsuranceFillStatus
        ),
        "Tax Fill Status": handleData(onboardingTaxFillStatus),
        "Licence Fill Status": handleData(onboardingLicenceFillStatus),

        "Onboarding Status": count === 5 ? "Completed" : "-",

        "First interestedAt": handleDate(firstInterestedShift),
        "First assignedAt": handleDate(firstAssignedShift),
        ...(showColumn("Score") && { "score": `${score ? score : "-"}` }),
        ...(showColumn("Status") && {
            "status": `${status
                ? convertToCapitalize(removeDash(isBlocked ? "blocked" : status))
                : "-"
                }`
        }),
        "Last loginAt": handleDate(lastLoginTime),
        ...(showColumn("OnboardingAt") && { "OnboardingAt": handleDate(onboardingAt) }),
        ...(showColumn("Days & Time") && { "Days": dayData?.length ? day : "-" }),
        ...(showColumn("Days & Time") && { "Time": timeData?.length ? time : "-" }),
        ...(showColumn("Time Amount") && { "Time Amount": timeAmount?.title ? timeAmount?.title : "-" }),
        "ApprovedAt": handleDate(approvedAt),
        "Referral Name": handleData(referrerName),
        "Referral Phone": handleData(referrerPhone),
        ...(showColumn("Notes") && { "Notes": handleData(internalNote) }),
        "License Expired": status === "approved" && licenceExpiredDays ? `${licenceExpiredDays >= 0 ? "expires in " + licenceExpiredDays + ' days' : "expired " + Math.abs(licenceExpiredDays) + " days ago"}` : "-",
        "Insurance Expired": status === "approved" && insuranceExpiredDays ? `${insuranceExpiredDays >= 0 ? "expires in " + insuranceExpiredDays + ' days' : "expired " + Math.abs(insuranceExpiredDays) + " days ago"}` : "-",
    };
}

export function xlsxDriversData(filteredDriversData, showColumn) {
    const driversData = [];

    if (filteredDriversData && filteredDriversData.length) {
        for (let i = 0; i < filteredDriversData.length; i++) {
            const data = filteredDriversData[i];

            const allData = commonData(data, showColumn)
            driversData[i] = {
                ...allData,
            }
        }
    }
    driversData.unshift({ "Created": `This report based on "${timeZone}  UTC ${utc}" Timezone ` })
    return driversData;
}


export function xlsxAllDriversData(filteredDriversData, showColumn) {
    const driversData = [];

    if (filteredDriversData && filteredDriversData.length) {
        for (let i = 0; i < filteredDriversData.length; i++) {
            const data = filteredDriversData[i];

            const {
                candidateId,
                checkrBackgroundStatus,
                rejectShiftCount,
                assigned,
                delivery_started,
                complete,
                waiting_for_approval,
                paid, cancelled,
                coachedNumber,
                lastCoached,
                maxNumberOfCoaches,
                maxNumberOfCoachesCounts
            } = data

            const allData = commonData(data, showColumn)
            driversData[i] = {
                ...allData,
                "Checker Id": `${candidateId ? candidateId : "-"}`,
                "Checker Status": `${checkrBackgroundStatus ? checkrBackgroundStatus : "-"}`,
                "Coached number": `${coachedNumber ? coachedNumber : "-"}`,
                "Last coach": `${lastCoached ? moment(lastCoached).format("MM/DD/YYYY") : "-"}`,
                "Number of coaches": `${maxNumberOfCoaches ? `${maxNumberOfCoaches} (${maxNumberOfCoachesCounts})` : "-"}`,
                "shiftStatus": `${assigned && "Assigned : " + assigned + ', ' || ''}${delivery_started && "Delivery Started : " + delivery_started + ', ' || ''}${complete && "Complete : " + complete + ', ' || ''}${waiting_for_approval && "Approval : " + waiting_for_approval + ', ' || ''}${paid && "Paid : " + paid + ', ' || ''}${cancelled && "Cancelled : " + cancelled + ', ' || ''}${rejectShiftCount && "Rejected : " + rejectShiftCount + ', ' || ''}`
            }
        }
    }
    driversData.unshift({ "Created": `This report based on "${timeZone}  UTC ${utc}" Timezone ` })
    return driversData;
}


export function xlsxChecker(filteredDriversData, showColumn) {
    const driversData = [];

    if (filteredDriversData && filteredDriversData.length) {
        for (let i = 0; i < filteredDriversData.length; i++) {
            const data = filteredDriversData[i];
            const { candidateId, checkrBackgroundStatus } = data

            const allData = commonData(data, showColumn)
            driversData[i] = {
                ...allData,
                "Checker Id": `${candidateId ? candidateId : "-"}`,
                "Checker Status": `${checkrBackgroundStatus ? checkrBackgroundStatus : "-"}`,
            }
        }
    }
    driversData.unshift({ "Created": `This report based on "${timeZone}  UTC ${utc}" Timezone ` })
    return driversData;
}


export function xlsxShiftStatus(filteredDriversData, showColumn) {
    const driversData = [];

    if (filteredDriversData && filteredDriversData.length) {
        for (let i = 0; i < filteredDriversData.length; i++) {
            const data = filteredDriversData[i];

            const { rejectShiftCount, assigned, delivery_started, complete, waiting_for_approval, paid, cancelled } = data

            const allData = commonData(data, showColumn)
            driversData[i] = {
                ...allData,
                "shiftStatus": `${assigned && "Assigned : " + assigned + ', ' || ''}${delivery_started && "Delivery Started : " + delivery_started + ', ' || ''}${complete && "Complete : " + complete + ', ' || ''}${waiting_for_approval && "Approval : " + waiting_for_approval + ', ' || ''}${paid && "Paid : " + paid + ', ' || ''}${cancelled && "Cancelled : " + cancelled + ', ' || ''}${rejectShiftCount && "Rejected : " + rejectShiftCount + ', ' || ''}`
            }
        }
    }
    driversData.unshift({ "Created": `This report based on "${timeZone}  UTC ${utc}" Timezone ` })
    return driversData;
}

export function xlsxCheckerAndShiftHeader(filteredDriversData, showColumn) {
    const driversData = [];

    if (filteredDriversData && filteredDriversData.length) {
        for (let i = 0; i < filteredDriversData.length; i++) {
            const data = filteredDriversData[i];

            const {
                candidateId,
                checkrBackgroundStatus,
                rejectShiftCount,
                assigned,
                delivery_started,
                complete,
                waiting_for_approval,
                paid, cancelled,
            } = data

            const allData = commonData(data, showColumn)
            driversData[i] = {
                ...allData,
                "Checker Id": `${candidateId ? candidateId : "-"}`,
                "Checker Status": `${checkrBackgroundStatus ? checkrBackgroundStatus : "-"}`,
                "shiftStatus": `${assigned && "Assigned : " + assigned + ', ' || ''}${delivery_started && "Delivery Started : " + delivery_started + ', ' || ''}${complete && "Complete : " + complete + ', ' || ''}${waiting_for_approval && "Approval : " + waiting_for_approval + ', ' || ''}${paid && "Paid : " + paid + ', ' || ''}${cancelled && "Cancelled : " + cancelled + ', ' || ''}${rejectShiftCount && "Rejected : " + rejectShiftCount + ', ' || ''}`
            }
        }
    }
    driversData.unshift({ "Created": `This report based on "${timeZone}  UTC ${utc}" Timezone ` })
    return driversData;
}

export function xlsxCheckerAndCoachingHeader(filteredDriversData, showColumn) {
    const driversData = [];

    if (filteredDriversData && filteredDriversData.length) {
        for (let i = 0; i < filteredDriversData.length; i++) {
            const data = filteredDriversData[i];
            const { candidateId, checkrBackgroundStatus, coachedNumber, lastCoached, maxNumberOfCoaches, maxNumberOfCoachesCounts } = data

            const allData = commonData(data, showColumn)
            driversData[i] = {
                ...allData,
                "Checker Id": `${candidateId ? candidateId : "-"}`,
                "Checker Status": `${checkrBackgroundStatus ? checkrBackgroundStatus : "-"}`,
                "Coached number": `${coachedNumber ? coachedNumber : "-"}`,
                "Last coach": `${lastCoached ? moment(lastCoached).format("MM/DD/YYYY") : "-"}`,
                "Number of coaches": `${maxNumberOfCoaches ? `${maxNumberOfCoaches} (${maxNumberOfCoachesCounts})` : "-"}`,
            }
        }
    }
    driversData.unshift({ "Created": `This report based on "${timeZone}  UTC ${utc}" Timezone ` })
    return driversData;
}

export function xlsxShiftStatusAndCoachingHeader(filteredDriversData, showColumn) {
    const driversData = [];

    if (filteredDriversData && filteredDriversData.length) {
        for (let i = 0; i < filteredDriversData.length; i++) {
            const data = filteredDriversData[i];

            const { rejectShiftCount, assigned, delivery_started, complete, waiting_for_approval, paid, cancelled,
                coachedNumber, lastCoached, maxNumberOfCoaches, maxNumberOfCoachesCounts } = data

            const allData = commonData(data, showColumn)
            driversData[i] = {
                ...allData,
                "Coached number": `${coachedNumber ? coachedNumber : "-"}`,
                "Last coach": `${lastCoached ? moment(lastCoached).format("MM/DD/YYYY") : "-"}`,
                "Number of coaches": `${maxNumberOfCoaches ? `${maxNumberOfCoaches} (${maxNumberOfCoachesCounts})` : "-"}`,
                "shiftStatus": `${assigned && "Assigned : " + assigned + ', ' || ''}${delivery_started && "Delivery Started : " + delivery_started + ', ' || ''}${complete && "Complete : " + complete + ', ' || ''}${waiting_for_approval && "Approval : " + waiting_for_approval + ', ' || ''}${paid && "Paid : " + paid + ', ' || ''}${cancelled && "Cancelled : " + cancelled + ', ' || ''}${rejectShiftCount && "Rejected : " + rejectShiftCount + ', ' || ''}`
            }
        }
    }
    driversData.unshift({ "Created": `This report based on "${timeZone}  UTC ${utc}" Timezone ` })
    return driversData;
}

export function xlsxCoachingHeader(filteredDriversData, showColumn) {
    const driversData = [];

    if (filteredDriversData && filteredDriversData.length) {
        for (let i = 0; i < filteredDriversData.length; i++) {
            const data = filteredDriversData[i];

            const { coachedNumber, lastCoached, maxNumberOfCoaches, maxNumberOfCoachesCounts } = data

            const allData = commonData(data, showColumn)
            driversData[i] = {
                ...allData,
                "Coached number": `${coachedNumber ? coachedNumber : "-"}`,
                "Last coach": `${lastCoached ? moment(lastCoached).format("MM/DD/YYYY") : "-"}`,
                "Number of coaches": `${maxNumberOfCoaches ? `${maxNumberOfCoaches} (${maxNumberOfCoachesCounts})` : "-"}`,
            }
        }
    }
    driversData.unshift({ "Created": `This report based on "${timeZone}  UTC ${utc}" Timezone ` })
    return driversData;
}