import React, { useCallback, useState } from "react";
import {
  CircularProgress,
  Modal,
  Autocomplete,
  TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Select from "react-select";
import { ErrorMessage, InputItem } from "../../Form";
import { actions } from "../../../../redux/store/store";
import { autocomplete, errorAutoComplete } from "../../orderHelper/styles";
import {
  useCreateMetroMutation,
  useGetMetrosQuery,
  useUpdateMetroMutation,
} from "../../../../api/metro";
import { checkForError } from "../../../../constants/extras/errorHandlers";
import { metroOptionsStyles } from "./metroSelect";
import { useUpdateDriverMutation } from "../../../../api/drivers";
import { sendSuccessMessage } from "../../../../constants/extras/sendSuccessMsg";
import "./style.scss";

const data = ["Percentage (%)", "Dollar ($)"];

const requiredMessage = "This field is required";
const validationSchema = Yup.object().shape(
  {
    name: Yup.string()
      .test("len", "Please Enter valid metro name", (value) => {
        return !!value.trim();
      })
      .required(requiredMessage),

    bonus: Yup.string()
      .ensure()
      .when("bonusType", {
        is: (bonusType) => bonusType !== "",
        then: Yup.string().required("Bonus is required field"),
      }),
    bonusType: Yup.string()
      .ensure()
      .when("bonus", {
        is: (bonus) => bonus,
        then: Yup.string().required("Bonus Type is required field"),
      }),
    isBackgroundCheck: Yup.boolean()
      .default(true)
      .required("Background Check is required field"),
    isAdminActive: Yup.boolean()
      .default(true)
      .required("Admin Side is required field"),
    isDriverActive: Yup.boolean()
      .default(true)
      .required("Driver Side is required field"),
  },
  [["bonus", "bonusType"]]
);

const MetroModal = (props) => {

  const { id } = props;
  const checkForUpdateMetro =
    id && id?.checkForUpdateMetro && id?.checkForUpdateMetro;
  const metro = props && props?.id?.metro && props?.id?.metro;
  const driverId = props && props?.id?.id && props?.id?.id;
  const checkForUpdateDriversMetro =
    checkForUpdateMetro === "CHANGE__DRIVERS__METRO";

  const title =
    id && id._id
      ? `Metro ${id && id?._id ? "#".concat(id?._id) : ""}`
      : !checkForUpdateDriversMetro
        ? "New Metro"
        : "Update Metro";

  const { data: metros, isFetching } = useGetMetrosQuery(null, {
    refetchOnMountOrArgChange: true,
    skip: !checkForUpdateDriversMetro || !driverId,
  });

  const [updateDriverMetro, { isLoading: isUpdatingDriver }] =
    useUpdateDriverMutation();

  const [create, { error: createError, isLoading: isCreating }] =
    useCreateMetroMutation();

  const [update, { error: updateError, isLoading: isUpdating }] =
    useUpdateMetroMutation();
  const [selectedOption, setSelectedOption] = useState({
    value: metro || "",
    label: metro || "",
  });
  const [options, setOptions] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDriverSideCheck, setIsDriverSideCheck] = useState(id ? id?.isDriverActive === false ? false : true : true)

  const form = useForm({
    defaultValues: checkForUpdateDriversMetro
      ? {
        metro: metro || "",
        bonus: id && id?.bonus ? id?.bonus : "",
        bonusType:
          id && id?.bonusType === "percentage"
            ? "Percentage (%)"
            : id && id?.bonusType === "dollar"
              ? "Dollar ($)"
              : "",
        isBackgroundCheck: id && id?.isBackgroundCheck === false ? false : true,
        isAdminActive: id && id?.isAdminActive === false ? false : true,
        isDriverActive: id && id?.isDriverActive === false ? false : true
      }
      : {
        name: id && id?.name ? id?.name : "",
        bonus: id && id?.bonus ? id?.bonus : "",
        bonusType:
          id && id?.bonusType === "percentage"
            ? "Percentage (%)"
            : id && id?.bonusType === "dollar"
              ? "Dollar ($)"
              : "",
        isBackgroundCheck: id && id?.isBackgroundCheck === false ? false : true,
        isAdminActive: id && id?.isAdminActive === false ? false : true,
        isDriverActive: id && id?.isDriverActive === false ? false : true
      },
    resolver: yupResolver(validationSchema),
  });
  const { control } = form;

  const onSubmit = useCallback(
    (data) => {
      if (checkForUpdateDriversMetro) {
        (async () => {
          let result = await updateDriverMetro({
            metro: selectedOption?.value,
            _id: driverId,
          });
          if (!result?.error && result?.data?.metro === selectedOption?.value) {
            sendSuccessMessage("Metro updated successfully");
            actions.refetch.setIsDataRefetch(true);
          } else {
            checkForError(result);
          }
          actions.modal.closeMetro();
        })();
      } else {
        (async () => {
          // Check and modify data.bonusType based on conditions
          if (data.bonusType === "Percentage (%)") {
            data.bonusType = "percentage";
          } else if (data.bonusType === "Dollar ($)") {
            data.bonusType = "dollar";
          }

          (data.bonusType === "" || data.bonus === "0") && (data.bonusType = null);
          data.bonus === "0" && (data.bonus = "");

          let result = await (id && id?._id
            ? update({
              name: data?.name,
              bonus: data?.bonus,
              bonusType: data?.bonusType,
              isBackgroundCheck: data?.isBackgroundCheck,
              isAdminActive: data?.isAdminActive,
              isDriverActive: data?.isDriverActive,
              _id: id && id?._id,
            })
            : create(data));
          if (!result?.data?.error && result?.data?.name) {
            sendSuccessMessage(
              `Metro ${id?._id ? "updated" : "created"} successfully`
            );
          } else {
            const error = updateError || createError;
            checkForError(error || result);
          }
          actions.modal.closeMetro();
        })();
      }
    },
    [
      id,
      driverId,
      selectedOption.value,
      checkForUpdateDriversMetro,
      updateDriverMetro,
      create,
      update,
      createError,
      updateError,
    ]
  );

  const onCancel = useCallback(() => {
    if (
      !form.formState.isDirty ||
      window.confirm("Do you want to close the dialog? Changes will be lost.")
    ) {
      actions.modal.closeMetro();
    }
  }, [form.formState.isDirty]);

  React.useEffect(() => {
    setOptions(
      !isFetching &&
      metros?.length > 0 &&
      metros?.map(function (item) {
        return {
          value: item?.name,
          label: item?.name,
        };
      })
    );
  }, [metros, isFetching]);

  return (
    <Modal
      open
      onClose={onCancel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={`modal after-loading`} style={{ maxWidth: "30.875rem" }}>
        <div className="modal__header">
          <h2 className="modal__title">{title}</h2>
        </div>
        <div className="modal__body">
          {!checkForUpdateDriversMetro && (
            <form>
              <InputItem
                name="name"
                form={form}
                title="Metro"
                autoComplete="off"
              />
              <InputItem
                name="bonus"
                form={form}
                title="Bonus"
                autoComplete="off"
                numeric
              />
              <div className="w-auto d-flex flex-column">
                <label style={{ marginBottom: "0.1rem" }}>Bonus Type</label>
                <Controller
                  control={control}
                  name="bonusType"
                  render={({
                    field: { onChange, value, ref },
                    fieldState: { error },
                  }) => {
                    const styleObj = error
                      ? { ...errorAutoComplete }
                      : { ...autocomplete };
                    return (
                      <React.Fragment>
                        <Autocomplete
                          noOptionsText={"Select Bonus Type"}
                          options={data}
                          value={value || ""}
                          getOptionLabel={(option) => option}
                          sx={{
                            ...styleObj,
                          }}
                          onChange={(e, data) => {
                            onChange(data);
                          }}
                          blurOnSelect={true}
                          ref={ref}
                          isAll={true}
                          isError={error && true}
                          {...props}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Select Bonus Type"
                              inputProps={{
                                ...params.inputProps,
                              }}
                              required={false}
                            />
                          )}
                        />
                        <ErrorMessage error={error} />
                      </React.Fragment>
                    );
                  }}
                />
              </div>

              <div className="d-flex" style={{ gap: "20px" }}>
                <div className=" d-flex flex-row mt-2 align-items-center gap-1 w-50">
                  <label style={{ marginBottom: "0.1rem" }}>Driver Side :</label>
                  <Controller
                    control={control}
                    name="isDriverActive"
                    render={({
                      field: { onChange, value, ref },
                      fieldState: { error },
                    }) => {
                      return (
                        <React.Fragment>
                          <div className="toggle_container_metros pl-2">
                            <div className="toggle-switch-metros">
                              <input
                                type="checkbox"
                                className="checkbox"
                                name="isDriverActive"
                                id="driver"
                                checked={value}
                                onChange={(e) => {
                                  onChange(e.target.checked);
                                  setIsDriverSideCheck(e.target.checked)
                                }}
                              />
                              <label className="label" htmlFor="driver">
                                <span className="backgroundC" />
                                <span className="switch-metros" />
                              </label>
                            </div>
                          </div>
                          <ErrorMessage error={error} />
                        </React.Fragment>
                      );
                    }}
                  />
                </div>
                <div className=" d-flex flex-row mt-2 align-items-center gap-1 w-50">
                  <label style={{ marginBottom: "0.1rem" }}>Admin Side :</label>
                  <Controller
                    control={control}
                    name="isAdminActive"
                    render={({
                      field: { onChange, value, ref },
                      fieldState: { error },
                    }) => {
                      return (
                        <React.Fragment>
                          <div className="toggle_container_metros pl-2">
                            <div className="toggle-switch-metros">
                              <input
                                type="checkbox"
                                className="checkbox"
                                name="isAdminActive"
                                id="admin"
                                checked={value}
                                onChange={(e) => {
                                  onChange(e.target.checked);
                                  if (isDriverSideCheck) {
                                    form.setValue("isDriverActive", e.target.checked)
                                  }
                                }}
                              />
                              <label className="label" htmlFor="admin">
                                <span className="backgroundC" />
                                <span className="switch-metros" />
                              </label>
                            </div>
                          </div>
                          <ErrorMessage error={error} />
                        </React.Fragment>
                      );
                    }}
                  />
                </div>
              </div>

              <div className="w-auto d-flex flex-row mt-2 align-items-center gap-1">
                <label style={{ marginBottom: "0.1rem" }}>Background Check :</label>
                <Controller
                  control={control}
                  name="isBackgroundCheck"
                  render={({
                    field: { onChange, value, ref },
                    fieldState: { error },
                  }) => {
                    return (
                      <React.Fragment>
                        <div className="toggle_container_metros pl-2">
                          <div className="toggle-switch-metros">
                            <input
                              type="checkbox"
                              className="checkbox"
                              name="isBackgroundCheck"
                              id="on"
                              checked={value}
                              onChange={(e) => {
                                onChange(e.target.checked);
                              }}
                            />
                            <label className="label" htmlFor="on">
                              <span className="backgroundC" />
                              <span className="switch-metros" />
                            </label>
                          </div>
                        </div>
                        <ErrorMessage error={error} />
                      </React.Fragment>
                    );
                  }}
                />
              </div>
            </form>
          )}
          {isFetching ? (
            <div
              className="inner__scroll__view__container"
              style={{ height: "8rem" }}
            >
              <CircularProgress />
            </div>
          ) : (
            checkForUpdateDriversMetro && (
              <form style={{ height: isMenuOpen ? "20vh" : "auto" }}>
                <Select
                  onChange={(item) => {
                    setSelectedOption(item);
                  }}
                  options={options}
                  value={selectedOption}
                  styles={metroOptionsStyles}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  closeMenuOnSelect={true}
                  onMenuClose={() => setIsMenuOpen(false)}
                  onMenuOpen={() => setIsMenuOpen(true)}
                  menuIsOpen={isMenuOpen}
                />
              </form>
            )
          )}
        </div>
        <div className="modal__footer">
          <button className="btn" onClick={() => actions.modal.closeMetro()}>
            Cancel
          </button>
          {!checkForUpdateDriversMetro ? (
            <div style={{ position: 'relative' }}>
              <button
                className={`btn btn_accent ${isUpdating || isCreating ? "with-progress" : ""
                  }`}
                disabled={
                  isUpdating || isCreating
                  // (!form.formState.isDirty && !checkForUpdateDriversMetro) ||
                  // isBonusPercentageMatch === id?.bonus ||
                  // isBonusType === id?.bonusType
                }
                onClick={form.handleSubmit(onSubmit)}
              >
                OK
              </button>
              {(isCreating || isUpdating) && (
                <CircularProgress size={18}
                  sx={{
                    color: '#fff',
                    position: 'absolute',
                    top: '50%',
                    left: '83%',
                    marginTop: '-8px',
                    marginLeft: '-10px',
                  }} />
              )}
            </div>

          ) : (
            <div style={{ position: 'relative' }}>
              <button
                className="btn btn_accent"
                disabled={isUpdatingDriver || metro === selectedOption?.value}
                onClick={onSubmit}
              >
                OK
              </button>
              {isUpdatingDriver && (

                <CircularProgress size={18}
                  sx={{
                    color: '#fff',
                    position: 'absolute',
                    top: '50%',
                    left: '83%',
                    marginTop: '-8px',
                    marginLeft: '-10px',
                  }} />

              )}
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default MetroModal;
