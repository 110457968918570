import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithAuthInterceptor } from './util'

export const storesApi = createApi({
    reducerPath: 'storesApi',
    baseQuery: baseQueryWithAuthInterceptor({ baseUrl: '/api/stores' }),
    tagTypes: ['Store'],
    endpoints: builder => ({
        getStores: builder.query({
            query: () => '',
            providesTags: ['Store'],
        }),
        getStore: builder.query({
            query: id => id,
            providesTags: ['Store'],
        }),
        updateStore: builder.mutation({
            query: body => ({
                url: body._id,
                method: 'PUT',
                body
            }),
            invalidatesTags: (result, error, arg) => !error && [{ type: 'Store', _id: result._id }],
        }),
        createStore: builder.mutation({
            query: body => ({
                url: '',
                method: 'POST',
                body
            }),
            invalidatesTags: (result, error, arg) => !error && ['Store'],
        }),
        removeStore: builder.mutation({
            query: id => ({
                url: id,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, arg) => !error && ['Store'],
        }),
    })
})

export const {
    useGetStoresQuery,
    useGetStoreQuery,
    useUpdateStoreMutation,
    useCreateStoreMutation,
    useRemoveStoreMutation,
} = storesApi