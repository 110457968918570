import React, { useState, useEffect } from "react";
import moment from "moment";
import { CircularProgress } from "@mui/material";
import Table from "../shared/Table";
import { useGetLogsQuery, useGetLogsByDayQuery } from "../../api/logs";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import "./style.scss";
import "./style.logs.scss";

const Logs = () => {
  const columns_logs = [
    {
      name: "timestamp",
      label: "Date",
      sortable: true,
      className: "table__td-time py-2",
      renderer: (item) =>
        item.timestamp &&
        moment(item.timestamp).format("MMM D, YYYY h:mm:ss A"),
    },
    {
      name: "activity",
      label: "Activity",
      sortable: true,
    },
    {
      name: "status",
      label: "Status",
      sortable: true,
      renderer: (item) => (
        <span className="textStyleForStatus">{item.status}</span>
      ),
    },
    {
      name: "ip",
      label: "IP",
      sortable: true,
    },
    {
      name: "actorUser-username",
      label: "User",
      sortable: true,
      renderer: (item) => item.actorUser && item.actorUser.username,
    },
    {
      name: "targetUser",
      label: "Target User",
      sortable: true,
    },
    {
      name: "targetOrder",
      label: "Target Shift",
      sortable: true,
    },
    {
      name: "targetStore",
      label: "Target Hub",
      sortable: true,
    },
  ];

  const today = new Date();
  const todayFormatted = moment(today).format("YYYY-MM-DD");
  const [data, setData] = useState(todayFormatted);
  const [isReadyToRequest, setIsReadyToRequest] = useState(false);
  const [logs, setlogs] = useState([]);
  const {
    data: activity_logs_byDate,
    isFetching,
    status: logsStatus,
    error: logsError,
  } = useGetLogsByDayQuery(parseInt(new Date(data).getTime()), {
    skip: !isReadyToRequest,
  });

  const handleChangeData = (val) => {
    setData(val.target.value);
  };

  const onClickHandler = (event) => {
    event.preventDefault();
    setIsReadyToRequest(true);
  };

  useEffect(() => {
    setIsReadyToRequest(true);
  }, []);

  useEffect(() => {
    setlogs(activity_logs_byDate);

    if (logsStatus === "fulfilled") {
      setIsReadyToRequest(false);
    }
  }, [activity_logs_byDate, logsStatus]);

  const iterator = (sign) => {
    let newData = new Date(data);
    newData.setDate(eval(newData.getDate() + sign + 1));
    setData(moment(newData).format("YYYY-MM-DD"));
    setIsReadyToRequest(true);
  };

  const onClickHandlerPrev = (event) => {
    event.preventDefault();
    iterator("-");
  };

  const onClickHandlerNext = (event) => {
    event.preventDefault();
    iterator("+");
  };

  return (
    <React.Fragment>
      <div className="screen-users__form-wrapper">
        <div className="container container_narrow_ screen-users__container mainTabBackground mainTabWidth">
          <div className="screen-users__form-body">
            <form>
              <div className="screen-users__logs-filter">
                <div className="logs-filter">
                  <button
                    className="btn logs-filter__paginator-btn"
                    disabled={!data}
                    onClick={onClickHandlerPrev}
                    tabIndex="1"
                  >
                    <IoIosArrowBack className="logs-filter__paginator-icon" />
                    <span className="logs-filter__paginator-text">Prev</span>
                  </button>

                  <div className="form__item logs-filter__main-control">
                    <label className="form__label">Show Logs By Date</label>
                    <div className="form__input-group">
                      <input
                        className="form__input"
                        type="date"
                        max={todayFormatted}
                        onChange={handleChangeData}
                        value={data}
                        tabIndex="2"
                      />
                      <button
                        type="submit"
                        className="btn btn_accent"
                        disabled={!data}
                        onClick={onClickHandler}
                        tabIndex="3"
                      >
                        Show
                      </button>
                    </div>
                  </div>

                  <button
                    className="btn logs-filter__paginator-btn"
                    disabled={!data || todayFormatted === data}
                    onClick={onClickHandlerNext}
                    tabIndex="4"
                  >
                    <span className="logs-filter__paginator-text">Next</span>
                    <IoIosArrowForward className="logs-filter__paginator-icon" />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div
        className="container container_narrow_  mainTabBackground"
        style={{ maxWidth: "90%", padding: "0" }}
      >
        <div className="card">
          {logs && logs.length > 0 && logsStatus !== "pending" && (
            <div className="table-wrapper" style={{height:"60vh", overflow:"auto"}}>
              <Table
                columns={columns_logs}
                items={logs}
                className="table_users table_users_logs"
              />
            </div>
          )}

          {logs &&
            logs.length === 0 &&
            logsStatus !== "pending" &&
            data &&
            data.length && (
              <div className="user_loading">
                <span className="log-result-status">
                  No logs for this data!
                </span>
              </div>
            )}

          {logsError && (
            <div className="user_loading">
              <span className="log-result-status log-result-status_error">
                Error loading logs!
              </span>
            </div>
          )}

          {(logsStatus === "pending" || isFetching) && (
            <div className="user_loading">
              <CircularProgress className="loading-progress" />
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Logs;
