import React from "react";
import { CircularProgress, Modal, Rating } from "@mui/material";
import { actions } from "../../../../redux/store/store";
import { useForm } from "react-hook-form";
import "./style.scss"
import { useUpdateCoachingSessionsMutation } from "../../../../api/coaching";

function Retings({ id }) {
    const [updateCoaching, { isLoading }] = useUpdateCoachingSessionsMutation()
    const onCancel = () => {
        actions.modal.closeRating();
    };
    const form = useForm({
        defaultValues: {rating: 0}
    });
    const onSubmit = async (data) => {
        const params = {
            driverQualification: data.rating
        }
         await updateCoaching({ id, params })
        if (!isLoading) {actions.modal.closeRating();}
    }

    const isRatingSelected = form.watch("rating") > 0;
    return (
        <Modal
            open
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <div className={`modal after-loading`} style={{ maxWidth: "35.875rem" }}>
                <div className="modal__header">
                    <h2 className="modal__title">{"Driver Qualification"}</h2>
                </div>
                <div className="modal__body">
                    <form style={{ display: "flex", justifyContent: "center" }}>
                        <Rating
                            name="half-rating"
                            value={form.watch("rating")}
                            onChange={(event, newValue) => {
                                form.setValue("rating", newValue);
                            }}
                            size="large"
                            style={{ fontSize: "50px" }}
                        />
                    </form>
                </div>
                <div className="modal__footer">
                    <button className="btn" onClick={() => actions.modal.closeRating()}>
                        Cancel
                    </button>
                    <button
                        className="btn btn_accent"
                        isLoading
                        disabled={
                            !isRatingSelected || isLoading
                            
                        }
                        type="submit" onClick={form.handleSubmit(onSubmit)} >
                        OK &nbsp;
                        {isLoading && (
                            <span>
                                <CircularProgress size={20} style={{ color: "white" }} />
                            </span>
                        )}
                    </button>
                </div>
            </div>
        </Modal>
    );
};
export default Retings;