import { createApi } from "@reduxjs/toolkit/query/react";

import { createDate } from "../constants/variables/dates";
import { baseQueryWithAuthInterceptor } from "./util";

export const schedulesApi = createApi({
  reducerPath: "schedulesApi",
  baseQuery: baseQueryWithAuthInterceptor({ baseUrl: "/api/schedules/" }),
  tagTypes: ["Schedules"],
  endpoints: (builder) => ({
    getSchedules: builder.query({
      query: (body) => {
        const opts = {};
        if (body?.fromDate && body?.toDate) {
          opts.params = {
            fromDate: createDate(body.fromDate),
            toDate: createDate(body.toDate),
          };
        }
        return {
          url: "view",
          ...opts,
        };
      },
    }),
    getDriversView: builder.query({
      query: (body) => {
        const opts = {};
        if (body && body.fromDate && body.toDate && body.metro) {
          opts.params = {
            fromDate: createDate(body.fromDate),
            toDate: createDate(body.toDate),
            metro: body.metro,
          };
        }
        return {
          url: "driversView",
          ...opts,
        };
      },
    }),
    getSchedulers: builder.query({
      query: ({ fromDate, toDate, metro, type }) => {
        const opts = {};
        opts.params = {
          fromDate: fromDate ? createDate(fromDate) : "",
          toDate: toDate ? createDate(toDate) : "",
          type: type || "pipeline_drivers",
          metro: metro || "",
        };
        return {
          url: "",
          ...opts,
        };
      },
      providesTags: ["Schedules"],
    }),
    createSchedulers: builder.mutation({
      query: (body) => ({
        url: "",
        method: "POST",
        body,
      }),
      invalidatesTags: (result, error, arg) =>
        !error && [{ type: "Schedules" }],
    }),
    updateSchedulers: builder.mutation({
      query: (body) => ({
        url: "",
        method: "PATCH",
        body,
      }),
      invalidatesTags: (result, error, arg) =>
        !error && [{ type: "Schedules" }],
    }),
  }),
});

export const {
  useGetSchedulesQuery,
  useGetDriversViewQuery,
  useGetSchedulersQuery,
  useCreateSchedulersMutation,
  useUpdateSchedulersMutation,
} = schedulesApi;
