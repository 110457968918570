import { createSlice } from "@reduxjs/toolkit";

export const companySlice = createSlice({
  name: "company",
  initialState: {
    value: undefined,
  },
  reducers: {
    selectCompany: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { selectCompany } = companySlice.actions;
export default companySlice.reducer;
