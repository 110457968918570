const CardLayout = ({ children }) => {
  return (
    <div className="content-wrapper" style={{minHeight:"none"}}>
      <div className="content-header">
        <div className="container-fluid">
          <div className="card">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default CardLayout;
