import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  forwardRef,
} from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Modal,
  Paper,
  MenuItem,
  Select,
  CircularProgress,
} from "@mui/material";
import { TiWarningOutline } from "react-icons/ti";
import moment from "moment";
import uploadImage from "../../../../images/upload-Image.svg";
import ClearIcon from "@mui/icons-material/Clear";
import "./style.scss";
import "../style.scss";
import MySelect from "../../options/MessageOption";
import {
  Option,
  ValueContainer,
  MultiValue,
  animatedComponents,
  MenuList,
  compare,
} from "../../options/MessageOption";
import { messageListOptionStyles } from "../email/optionStyles";
import { actions } from "../../../../redux/store/store";
import { notificationValidationSchema as validationSchema } from "../../../../constants/validaitonSchema";
import { useGetOneplusShiftQuery } from "../../../../api/drivers";
import { ErrorMessage, InputItem, Label } from "../../Form";
import { checkForError } from "../../../../constants/extras/errorHandlers";
import MetroSelect from "../../metroSelect";
import CompanySelect from "../../companySelect";
import { sendSuccessMessage } from "../../../../constants/extras/sendSuccessMsg";
import { hubSelectStyle, hoverAndFocusedStyle } from "../../orderHelper/styles";
import { checkArrForMapping } from "../../../../constants/validaitonSchema/validation.ts";
import {
  createDate,
  currentDtArr,
  DATE,
} from "../../../../constants/variables/dates";
import {
  createNotificationsList,
  useGetSingleNotificationQuery,
} from "../../../../api/notification";
import PinchZoomPan from "react-responsive-pinch-zoom-pan";
import { IoSearchSharp } from "react-icons/io5";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { DateRangePicker } from "react-date-range";

const NotificationModal = (props) => {
  const id = props?.id?.item?._id;
  const [status, setStatusFilter] = useState("approved");
  const data = props?.id?.item;
  const title = id ? `Notification #${id} details` : "New Notification";
  const [isLoadingAllDrivers, setLoadingAllDrivers] = useState(false);
  const [selectedOption, setSelectedOption] = useState([]);
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(data?.description || "");
  const [isError, setIsError] = useState(false);
  const [image, setImage] = useState(data?.file || null);
  const inputFile = useRef(null);
  const [seen, setSeen] = useState([]);
  const [searchData, setSearchData] = useState("");
  const [togglebtn, setTogglebtn] = useState(true);
  const [showDateRangePicker, setShowDateRangePicker] = useState(false);
  const [state, setState] = useState(currentDtArr);
  const [prevDate, setPrevDate] = useState(state);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [notificationData, setNotificationData] = useState();
  const [metro, setMetro] = useState("");
  const [company, setCompany] = useState("");
  const [loadingBtn, setLoadingBtn] = useState(false)

  const wrapperRef = useRef(null);

  const dateRange = `${fromDate
    ? `${createDate(fromDate, DATE.M_D_Y)} TO ${createDate(
      toDate,
      DATE.M_D_Y
    )}`
    : "Select Custom Date Range"
    }`;

  const isShowingDtRangePicker = showDateRangePicker ? (
    <KeyboardArrowUpIcon />
  ) : (
    <KeyboardArrowDownIcon />
  );

  const form = useForm({
    defaultValues: {
      userIds: [],
      title: data?.title || "",
      description: data?.description || "",
      file: data?.file || "",
      metro: "",
      company: "",
    },
    resolver: yupResolver(validationSchema),
  });
  const { control, watch } = form;

  useEffect(() => {
    const subscription = watch((value) => setMetro(value?.metro));
    return () => (subscription.unsubscribe());

  }, [watch]);

  const { data: allDrivers, isFetching } = useGetOneplusShiftQuery(
    {
      metro: metro === "All" ? undefined : metro,
      company: company === "all" ? undefined : company,
      status: status && status !== "all" ? status : "",
      fromDate: ["assigned", "delivery_started"].includes(status) && fromDate,
      toDate: ["assigned", "delivery_started"].includes(status) && toDate,
      isMetroAll:  metro === "All" ? true : undefined,
      isCompanyAll:  company === "all" ? true : undefined,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !metro,
    }
  );
  const { data: Single_Nofitication, isFetching: singledata_Fetching } =
    useGetSingleNotificationQuery(id, {
      refetchOnMountOrArgChange: true,
      skip: !id,
    });

  useEffect(() => {
    if (!["assigned", "delivery_started"].includes(status)) return;
    const currDt = createDate(new Date(), DATE.Y_M_D);
    const currDtStateArr = [
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ];
    setFromDate(currDt);
    setToDate(currDt);
    setPrevDate(currDtStateArr);
    setState(currDtStateArr);
  }, [status]);

  const handleCustomRange = () => {
    setShowDateRangePicker((showDateRangePicker) => !showDateRangePicker);
    setState(prevDate);
  };

  const handleTimeRangeSelection = async () => {
    setShowDateRangePicker(false);
    setPrevDate(state);
    setFromDate(createDate(state[0]?.startDate, DATE.Y_M_D));
    setToDate(createDate(state[0]?.endDate, DATE.Y_M_D));
  };

  // const DriverChangeByMetros = () => {
  //   setSelectedOption([]);
  // };

  useEffect(() => {
    setNotificationData(Single_Nofitication?.result?.[0]);
  }, [Single_Nofitication]);

  useEffect(() => {
    let seenData = [];
    if (togglebtn) {
      seenData = notificationData?.receivers?.filter(
        (data) => data?.seen === true && data
      );
      setSeen(seenData);
    } else {
      seenData = notificationData?.receivers?.filter(
        (data) => data?.seen === false && data
      );
      setSeen(seenData);
    }
    if (searchData) {
      setSeen(
        seenData?.filter((data) => data?.user.username.includes(searchData))
      );
    }
  }, [togglebtn, searchData, notificationData?.receivers]);

  const onSubmit = useCallback(async () => {
    let data = form.getValues();
    data.userIds = [];
    setLoadingBtn(true)
    let userIds;
    if (selectedOption[0]?.value === "*") {
      data.isSendToAll = true;
    } else {
      data.isSendToAll = false;
      userIds = selectedOption?.map((driver) => {
        return driver?.id;
      });
      data.userIds = userIds;
    }

    let result;
    if (
      data?.userIds?.length > 0 ||
      (data?.title?.length > 0 && data?.description?.length > 0)
    ) {
      data.metro = (metro === "All" ? "all" : metro) || "";
      data.isMetroAll = (metro === "All" ? true : false)
      data.isCompanyAll = (company === "all" ? true : false)
      // data.file = image || "";
      data.status = status;

      if (status === "assigned") {
        data.fromDate = fromDate;
        data.toDate = toDate;
      }
      data.company && (data.company = data.company?.value)
      // data.company=data.company?.value

      result = await createNotificationsList(data);
    }
    if (result?.status === 200) {
      actions.modal.closeNotification();
      actions.metro.selectMetro("");
      actions.refetch.setIsDataRefetch(true);
      sendSuccessMessage("Notification send successfully");
    } else {
      checkForError(result?.message);
    }
    setLoadingBtn(true)
  }, [form, selectedOption, status, metro, fromDate, toDate]);

  const onCancel = useCallback(() => {
    if (
      !form.formState.isDirty ||
      window.confirm("Do you want to close the dialog? Changes will be lost.")
    ) {
      actions.modal.closeNotification();
      // actions.metro.selectMetro("");
    }
  }, [form.formState.isDirty]);

  useEffect(() => {
    setLoadingAllDrivers(isFetching);
    if (!allDrivers) return;
    const drivers =
      checkArrForMapping(allDrivers) && allDrivers?.slice().sort(compare);
    setOptions(
      drivers
        ?.filter?.((item) => item?.notificationToken)
        ?.map(({ username, firstName, lastName, _id }) => {
          return {
            value: username || "-",
            label: `${firstName || ""} ${lastName || ""} (${username || ""})`,
            id: _id,
            labelByFirstName: username || "-",
          };
        })
    );
  }, [allDrivers, metro, isFetching]);

  useEffect(() => {
    if (isError) {
      (function () {
        const input = document.getElementById("error__container");
        input?.classList?.add("bounce");
        setTimeout(function () {
          input?.classList?.remove("bounce");
        }, 2000);
      })();
    }
  }, [isError]);

  return (
    <Modal
      open
      onClose={onCancel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div
        className={`modal ${!singledata_Fetching ? "after-loading" : "when-loading"
          }`}
      >
        <div className="modal__header">
          <h2 className="modal__title">{title}</h2>
        </div>

        <div className="modal__body" style={{ paddingBottom: "0px" }}>
          {!singledata_Fetching ? (
            <div>
              <div style={{ display: "flex" }}>
                <div style={{ width: "55%" }}>
                  {notificationData && notificationData?.createdAt && (
                    <div className="date__container">
                      <dl className="term term_inline">
                        <dt className="term__title">Date:</dt>
                        <dd className="term__value">
                          {moment(notificationData?.createdAt).format(
                            DATE.DT_WITH_TIME
                          )}
                        </dd>
                      </dl>
                    </div>
                  )}

                  {notificationData && (notificationData?.metro || notificationData?.isMetroAll)  && (
                    <>
                      <label>
                        <strong>Metro</strong>
                      </label>
                      <Paper
                        className="mb-2"
                        style={{
                          marginTop: "-0.5rem",
                          padding: "0.5rem",
                          border: "1px solid #dadada",
                          cursor: "no-drop"
                        }}
                      >
                        {notificationData?.isMetroAll ? "All" : notificationData?.metro}
                      </Paper>
                    </>
                  )}
                  {notificationData && notificationData?.title && (
                    <>
                      <div className="mb-3">
                        <label style={{ marginBottom: "-0.2rem" }}>Title</label>
                        <input
                          value={notificationData?.title}
                          disabled={notificationData?._id}
                          name="description"
                          style={{
                            width: "100%",
                            borderRadius: "7px",
                            padding: "8px 6px",
                            cursor: "no-drop"
                          }}
                        />
                      </div>
                    </>
                  )}
                  {notificationData && notificationData?.description && (
                    <>
                      <NotificationBodyImg
                        data={notificationData}
                        id={notificationData?._id}
                        value={notificationData?.description}
                        image={notificationData?.file}
                        inputFile={inputFile}
                      />
                    </>
                  )}
                </div>
                {notificationData && notificationData?._id && (
                  <div className="userSeens" style={{ width: "45%" }}>
                    <div
                      className="w-auto d-flex"
                      style={{ marginTop: "0.1rem", justifyContent: "space-between" }}
                    >
                      <div className="searchbar__container">
                        <IoSearchSharp className="search_icon" />
                        <input
                          className="search"
                          value={searchData}
                          placeholder="Search Driver"
                          onChange={(e) => setSearchData(e.target.value)}
                        />
                        {searchData?.length > 0 && (
                          <ClearIcon
                            className="clear__btn filter__search-icon"
                            onClick={() => {
                              setSearchData("");
                            }}
                            style={{
                              right: "5px",
                              top: "8px",
                            }}
                          />
                        )}
                      </div>

                      <div className="toggle_container">
                        <div className="toggle-switch">
                          <input
                            type="checkbox"
                            className="checkbox"
                            name="onn"
                            id="on"
                            checked={togglebtn}
                            onChange={(e) => {
                              setTogglebtn(e.target.checked);
                            }}
                          />
                          <label className="label" htmlFor="on">
                            <span className="inner" />
                            <span className="switch" />
                          </label>
                        </div>
                      </div>
                    </div>
                    <div style={{ margin: "3px" }}>
                      <span>
                        <b>Count </b> : {seen?.length}
                      </span>
                    </div>
                    <div
                      className={` ${data?.file ? "metro_data" : "seen_unseen"
                        }`}
                    >
                      {seen?.map((item, i) => {
                        return (
                          <div
                            style={{
                              borderBottom: "1px dashed rgb(218, 218, 218)",
                              padding: "10px 10px",
                              textAlign: "center",
                              cursor: "no-drop"
                            }}
                            key={i}
                          >
                            {`${item.user.firstName || ""} ${item.user.lastName || ""} (${item.user.username || ""})`}
                          </div>
                        );
                      })}
                      {!seen?.length && (
                        <p
                          className="d-flex justify-content-center align-items-center"
                          style={{ height: "90%" }}
                        >
                          <b>No Driver Found</b>
                        </p>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div>
                {!id && (
                  <div className="metro__select__container mb-3">
                    <div className="w-50">
                      <label style={{ marginBottom: "-0.5rem" }}>
                        Select Metro
                      </label>
                      <Controller
                        control={control}
                        name="metro"
                        render={({
                          field: { onChange, value, ref },
                          fieldState: { error },
                        }) => (
                          <React.Fragment>
                            <MetroSelect
                              value={value || ""}
                              onChange={(e, data) => {
                                onChange(data);
                                setSelectedOption([]);
                              }}
                              isAll={true}
                              ref={ref}
                              isError={error && true}
                              style={{ width: "100%" }}
                            />
                            <ErrorMessage error={error} />
                          </React.Fragment>
                        )}
                      />
                    </div>
                    <div className="w-50">
                      <label style={{ marginBottom: "-0.5rem" }}>
                        Select Company
                      </label>
                      <Controller
                        control={control}
                        name="company"
                        render={({
                          field: { onChange, value, ref },
                          fieldState: { error },
                        }) => (
                          <React.Fragment>
                            <CompanySelect
                              value={value || ""}
                              onChange={(e, data) => {
                                onChange(data);
                                setCompany(data?.value)
                                setSelectedOption([]);
                              }}
                              ref={ref}
                              isAll={true}
                              isError={error && true}
                              style={{ width: "100%" }}
                            />
                            <ErrorMessage error={error} />
                          </React.Fragment>
                        )}
                      />

                    </div>
                    <div
                      className="w-auto d-flex flex-column"
                      style={{ marginTop: "0.1rem" }}
                    >
                      <label style={{ marginBottom: "-0.1rem" }}>
                        Select Status
                      </label>
                      <Select
                        sx={{ ...hubSelectStyle, ...hoverAndFocusedStyle }}
                        style={{ width: "180px" }}
                        value={status}
                        onChange={(e) => {
                          setStatusFilter(e.target.value);
                          setSelectedOption([]);
                          setShowDateRangePicker(false);
                        }}
                        defaultValue={status}
                      >
                        <MenuItem value={"all"}>All</MenuItem>
                        <MenuItem value={"approved"}>Approved</MenuItem>
                        <MenuItem value={"oneplusshift"}>1+ Shifts</MenuItem>
                        <MenuItem value={"assigned"}>Assigned</MenuItem>
                        <MenuItem value={"onboarding_incomplete"}>Onboarding Incomplete</MenuItem>
                        <MenuItem value={"delivery_started"}>Delivery Started</MenuItem>
                      </Select>
                    </div>
                  </div>
                )}
                {!id && (
                  <>
                    {["assigned", "delivery_started"].includes(status) && (
                      <div
                        style={{
                          width: "400px",
                          marginBottom: `${!showDateRangePicker && "20px"}`,
                        }}
                      >
                        <div className="form__col form__col_height-flex">
                          <Label htmlFor="date-picker" title="Criteria" />
                          <div ref={wrapperRef}>
                            <div
                              onClick={() => handleCustomRange()}
                              className="form__input d-flex justify-content-between"
                            >
                              <span>{dateRange}</span>
                              <span className="ml-2">
                                {isShowingDtRangePicker}
                              </span>
                            </div>
                            {showDateRangePicker && (
                              <div className="dt__rng__pickr__cont">
                                <DateRangePicker
                                  onChange={(item) => {
                                    setState([item.selection]);
                                    setSelectedOption([]);
                                  }}
                                  ranges={state}
                                  className="date__range__picker"
                                  footerContent={
                                    <div className="button-container">
                                      <button
                                        onClick={handleCustomRange}
                                        className="cancelButton px-2 w-100"
                                      >
                                        <span>
                                          {" "}
                                          <strong>cancel</strong>{" "}
                                        </span>
                                      </button>
                                      <button
                                        onClick={handleTimeRangeSelection}
                                        className="okButton px-2 w-100"
                                      >
                                        <span>
                                          {" "}
                                          <strong>Done</strong>
                                        </span>
                                      </button>
                                    </div>
                                  }
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="mb-3">
                      <div className="d-flex justify-space-between">
                        <label style={{ marginBottom: "-0.2rem" }}>
                          Drivers
                        </label>
                        <span className="ml-1">
                          {selectedOption &&
                            selectedOption.length > 0 &&
                            selectedOption[0]?.value === "*"
                            ? `(${selectedOption.length - 1})`
                            : selectedOption.length > 0 &&
                            `(${selectedOption.length})`}
                        </span>
                        {isError && (
                          <div
                            className={`form__msg form__msg_invalid `}
                            id="error__container"
                          >
                            <TiWarningOutline
                              style={{
                                fontSize: "16px",
                                marginBottom: "0.2em",
                              }}
                            />
                            Please enter valid email
                          </div>
                        )}
                      </div>
                      <MySelect
                        placeholder={
                          isLoadingAllDrivers ? (
                            <div className="loading">
                              <span className="loading__text">
                                Loading Drivers
                              </span>
                              ...
                            </div>
                          ) : (
                            "Select Drivers"
                          )
                        }
                        options={checkArrForMapping(options) ? options : []}
                        isMulti={true}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        value={selectedOption}
                        onChange={(val) => {
                          setSelectedOption(val);
                          setIsError(false);
                          form.setValue("userIds", val);
                        }}
                        allowSelectAll={
                          checkArrForMapping(options) ? true : false
                        }
                        styles={messageListOptionStyles}
                        components={{
                          MenuList,
                          Option,
                          ValueContainer,
                          animatedComponents,
                          MultiValue,
                          IndicatorSeparator: () => null,
                          LoadingIndicator: () => null,
                        }}
                        noOptionsMessage={() => "No Drivers available"}
                        isLoading={isLoadingAllDrivers}
                        isDisabled={
                          isLoadingAllDrivers || !metro || !options?.length
                        }
                      />
                    </div>
                    <div>
                      <InputItem
                        name="title"
                        form={form}
                        autoComplete="off"
                        disabled={id}
                      />
                    </div>

                    <NotificationBodyImg
                      data={data}
                      id={id}
                      form={form}
                      value={value}
                      setValue={setValue}
                      setImage={setImage}
                      image={image}
                      ref={inputFile}
                    />
                  </>
                )}
              </div>
            </div>
          ) : (
            <div className="loading-wrapper">
              <CircularProgress />
            </div>
          )}
        </div>
        <div className="modal__footer" style={{alignItems: 'flex-end'}}>
          <button
            className="btn"
            onClick={() => {
              actions.modal.closeNotification();
            }}
          >
            Cancel
          </button>
          {!id && !loadingBtn && (
            <button
              className="btn btn_accent"
              disabled={
                selectedOption?.length > 0 && value?.length > 0 ? false : true
              }
              onClick={onSubmit}
            >
              Send
            </button>
          )}
          {
            loadingBtn && 
            <div className="notification_btn_loader" >
              <CircularProgress className="notification-loading-progress"/>
            </div>
          }
        </div>
      </div>
    </Modal>
  );
};

export default NotificationModal;

const NotificationBodyImg = forwardRef((props, ref) => {
  const { data, id, form, value, setValue, setImage, image } = props;
  return (
    <div
      style={{
        display: `${!id ? "flex" : "block"}`,
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div className="mb-3">
        <label style={{ marginBottom: "-0.2rem" }}>Body</label>
        <textarea
          value={value}
          disabled={id}
          onChange={(val) => {
            setValue(val.target.value);
            form.setValue("description", val.target.value);
          }}
          name="description"
          className={`md-textarea ${id && "detail_body"}`}
          rows="4"
          style={{ borderColor: id ? "#dadada" : null, width: id && "100%", cursor: id && "no-drop" }}
        />
      </div>
      {(data?.file || !data) && (
        <div className="mb-3">
          <label style={{ marginBottom: "-0.2rem" }}>File</label>
          <div
            className="image_attachment"
            onClick={() => (!id ? ref.current.click() : {})}
            style={{ borderColor: id ? "#dadada" : null, width: id && "100%" }}
          >
            <input
              type="file"
              id="img"
              name="img"
              onChange={(e) => {
                setImage(URL.createObjectURL(e.target.files[0]));
                form.setValue("file", e.target.files[0]);
              }}
              ref={ref}
              style={{ display: "none" }}
              accept="image/*"
            ></input>
            {image ? (
              id ? (
                <PinchZoomPan maxScale={10}>
                  <img
                    src={
                      data?.file
                        ? `/api/notifications/${data?._id}/attachments`
                        : image
                    }
                    alt=""
                    loading="lazy"
                    style={{
                      objectFit: "cover",
                      maxWidth: "100%",
                      maxHeight: "100%",
                    }}
                  />
                </PinchZoomPan>
              ) : (
                <img
                  src={
                    data?.file
                      ? `/api/notifications/${data?._id}/attachments`
                      : image
                  }
                  alt=""
                  loading="lazy"
                  style={{
                    objectFit: "cover",
                    maxWidth: "100%",
                    maxHeight: "100%",
                  }}
                />
              )
            ) : (
              <div className="image-icon-div">
                <img src={uploadImage} alt="" className="image-icon" />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
});
