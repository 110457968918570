export const zipcode = [
  {
    szip: 35004,
    ezip: 36925,
    state: 'Alabama',
  },
  {
    szip: 99501,
    ezip: 99950,
    state: 'Alaska',
  },
  {
    szip: 96799,
    state: 'American Samoa',
  },
  {
    szip: 85001,
    ezip: 86556,
    state: 'Arizona',
  },
  {
    szip: 71601,
    ezip: 72959,
    state: 'Arkansas',
  },
  {
    szip: 90001,
    ezip: 96162,
    state: 'California',
  },
  {
    szip: 80001,
    ezip: 81658,
    state: 'Colorado',
  },
  {
    szip: '06001',
    ezip: '06928',
    state: 'Connecticut',
  },
  {
    szip: 19701,
    ezip: 19980,
    state: 'Delaware',
  },
  {
    szip: 20001,
    ezip: 88888,
    state: 'district of columbia ',
  },
  {
    szip: 96941,
    ezip: 96944,
    state: 'Federated States Of Micronesia',
  },
  {
    szip: 93939,
    ezip: 96940,
    state: 'Palau',
  },
  {
    szip: 96960,
    ezip: 96970,
    state: 'Marshall Islands',
  },
  {
    szip: 32003,
    ezip: 34997,
    state: 'Florida',
  },
  {
    szip: 30002,
    ezip: 39901,
    state: 'Georgia',
  },
  {
    szip: 96910,
    ezip: 96932,
    state: 'Guam',
  },
  {
    szip: 96701,
    ezip: 96898,
    state: 'Hawaii',
  },
  {
    szip: 83201,
    ezip: 83877,
    state: 'Idaho',
  },
  {
    szip: 60001,
    ezip: 62999,
    state: 'Illinois',
  },
  {
    szip: 46001,
    ezip: 47997,
    state: 'Indiana',
  },
  {
    szip: 50001,
    ezip: 52809,
    state: 'Iowa',
  },
  {
    szip: 66002,
    ezip: 67954,
    state: 'Kansas',
  },
  {
    szip: 40003,
    ezip: 42778,
    state: 'Kentucky',
  },
  {
    szip: 70001,
    ezip: 71497,
    state: 'Louisiana',
  },
  {
    szip: '03901',
    ezip: '04992',
    state: 'Maine',
  },
  {
    szip: 20588,
    ezip: 21930,
    state: 'Maryland',
  },
  {
    szip: '01001',
    ezip: 21930,
    state: 'Massachusetts',
  },
  {
    szip: 48001,
    ezip: 49971,
    state: 'Michigan',
  },
  {
    szip: 55001,
    ezip: 56763,
    state: 'Minnesota',
  },
  {
    szip: 38601,
    ezip: 39776,
    state: 'Mississippi',
  },
  {
    szip: 63001,
    ezip: 65899,
    state: 'Missouri',
  },
  {
    szip: 59001,
    ezip: 59937,
    state: 'Montana',
  },
  {
    szip: 68001,
    ezip: 69367,
    state: 'Nebraska',
  },
  {
    szip: 88901,
    ezip: 89883,
    state: 'Nevada',
  },
  {
    szip: 88901,
    ezip: 89883,
    state: 'Nevada',
  },
  {
    szip: '03031',
    ezip: '03897',
    state: 'New Hampshire',
  },
  {
    szip: '07001',
    ezip: '08989',
    state: 'New Jersey',
  },
  {
    szip: 87001,
    ezip: 88439,
    state: 'New Mexico',
  },
  {
    szip: '00501',
    ezip: 14925,
    state: 'New York',
  },
  {
    szip: 27006,
    ezip: 28909,
    state: 'North Carolina',
  },
  {
    szip: 58001,
    ezip: 58856,
    state: 'North Dakota',
  },
  {
    szip: 96950,
    ezip: 96952,
    state: 'Northern Mariana Islands',
  },
  {
    szip: 43001,
    ezip: 45999,
    state: 'Ohio',
  },
  {
    szip: 73001,
    ezip: 74966,
    state: 'Oklahoma',
  },
  {
    szip: 97001,
    ezip: 97920,
    state: 'Oregon',
  },
  {
    szip: 15001,
    ezip: 19640,
    state: 'Pennsylvania',
  },
  {
    szip: '00601',
    ezip: '00988',
    state: 'Puerto Rico',
  },
  {
    szip: '02801',
    ezip: '02940',
    state: 'Rhode Island',
  },
  {
    szip: 29001,
    ezip: 29945,
    state: 'South Carolina',
  },
  {
    szip: 57001,
    ezip: 57799,
    state: 'South Dakota',
  },
  {
    szip: 37010,
    ezip: 38589,
    state: 'Tennessee',
  },
  {
    szip: 73301,
    ezip: 88595,
    state: 'Texas',
  },
  {
    szip: 84001,
    ezip: 84791,
    state: 'Utah',
  },
  {
    szip: '05001',
    ezip: '05907',
    state: 'Vermont',
  },
  {
    szip: '00801',
    ezip: '00851',
    state: 'Virgin Islands',
  },
  {
    szip: 20101,
    ezip: 24658,
    state: 'Virginia',
  },
  {
    szip: 98001,
    ezip: 99403,
    state: 'Washington',
  },
  {
    szip: 24701,
    ezip: 26886,
    state: 'West Virginia',
  },
  {
    szip: 53001,
    ezip: 54990,
    state: 'Wisconsin',
  },
  {
    szip: 82001,
    ezip: 83414,
    state: 'Wyoming',
  },
];