import React, { useState, useEffect, useRef, Fragment } from "react";
import propsTypes from "prop-types";
import { CSVLink } from "react-csv";

const CsvExport = ({ asyncExportMethod, disable, loadingCsv }) => {
  const [csvData, setCsvData] = useState(false);
  const csvInstance = useRef();
  useEffect(() => {
    if (csvData && csvInstance.current && csvInstance.current.link) {
      setTimeout(() => {
        csvInstance.current.link.click();
        setCsvData(false);
      });
    }
  }, [csvData]);
  return (
    <Fragment>
      <div
        onClick={async () => {
          if (disable) {
            return;
          }
          const newCsvData = await asyncExportMethod();
          setCsvData(newCsvData);
        }}
        className="csv-link-btn"
      >
        <button
          className="btn btn_block btn_accent btn_export_15"
          type="button"
          disabled={disable}
        >
          {loadingCsv ? (
            <div className="loading" style={{ color: "white" }}>
              <span className="loading__text" style={{ color: "white" }}>
                Exporting
              </span>
              ...
            </div>
          ) : (
            "Export CSV"
          )}
        </button>
      </div>
      {csvData ? (
        <CSVLink
          data={csvData.data}
          headers={csvData.headers}
          filename={csvData.filename}
          ref={csvInstance}
        />
      ) : undefined}
    </Fragment>
  );
};

export default CsvExport;

CsvExport.defaultProps = {
  children: undefined,
  asyncExportMethod: () => null,
  disable: false,
};

CsvExport.propTypes = {
  children: propsTypes.node,
  asyncExportMethod: propsTypes.func,
  disable: propsTypes.bool,
};
