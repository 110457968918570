import moment from "moment";
import _ from "lodash";

export const DATE = {
  Y_M_D: "yyyy-MM-DD",
  M_D_Y: "MM-DD-yyyy",
  DT_WITH_TIME: "MMM D, YYYY hh:mm A",
};

export const currentDtArr = [
  {
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  },
];

export const getFirstAndLastDate = (f, t=0) => {
  let fromDate = new Date();
  fromDate.setMonth(fromDate.getMonth() - f);

  let toDate = new Date();
  toDate.setMonth(toDate.getMonth() - t);


  let startD = `${moment(fromDate).format("MM-DD-yyyy")}`;
  let endD = `${moment(toDate).format("MM-DD-yyyy")}`;

  return { 
    startDate: new Date(startD),
    endDate: new Date(endD),
   }
}

export class DateCreater {
  constructor(date, format) {
    this.date = date;
    this.format = format;
  }

  get dt() {
    return this.createDate();
  }

  createDate() {
    return moment(this.date).format(this.format || DATE.Y_M_D);
  }
}

export const createDate = (date, format, isUtc) => {
  const d = date || new Date();
  const f = format || DATE.Y_M_D;
  return isUtc ? moment(d).utc().format(f) : moment(d).format(f);
};

export const toggleTime = (value, timeIndex) => {
  if (!value) return [];
  return value?.includes(timeIndex)
    ? _.without(value, timeIndex)
    : [...value, timeIndex].sort();
};
