import React, {
    useState,
    useEffect,
    useCallback,
    useRef,
    useMemo,
} from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import moment from "moment";
import CancelIcon from "@mui/icons-material/Cancel";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import BackspaceIcon from "@mui/icons-material/Backspace";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import StoreIcon from "@mui/icons-material/Store";
import { Select, MenuItem, CircularProgress } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { IoIosAddCircle } from "react-icons/io";
import { MdPlace } from "react-icons/md";
import { IoChevronForward, IoSearchSharp } from "react-icons/io5";
import { MdPayment, MdCancel } from "react-icons/md";
import { ClearAll } from "@mui/icons-material";
import ClearIcon from "@mui/icons-material/Clear";
import { DateRangePicker } from "react-date-range";

import "../../../styles/_data.scss";
import "./style.scss";
import "../../../components/DirectPayment/style.css";
import MetroSelect from "../../../components/shared/metroSelect";
import {
    getOrdersList,
    getOrdersCount,
    useRemoveOrderMutation,
    useUpdateOrderMutation,
} from "../../../api/orders";
import MassPaymentsModal from "../../../components/shared/customModal/massPayments";
import { getRoute, timeTransform_h } from "../../../util/time";
import { actions } from "../../../redux/store/store";
import HubSelect from "../../../components/shared/hubSelect";
import {
    // arrOfStatusesCount as initialArrOfStatusCnt,
    orderStatuses,
} from "../../../constants/menuLists";
import ConfirmDialogModal from "../../../components/shared/customModal/confirmDialog";
import {
    convertToCapitalize,
    removeDash,
} from "../../../constants/extras/convertToCapitalize";
import HeaderTextLayout from "../../../components/shared/HeaderTextLayout";
import { handleStatusesBadge } from "../../../constants/extras/statusesBadge";
import {
    hoverAndFocusedStyle,
    ordersStatusSelect,
} from "../../../components/shared/orderHelper/styles";
import { headers, shiftsData } from "../../../constants/csv/csvShifts";
import { handleEstimatedShiftPay } from "../../../constants/extras/handleShiftPayAndTime";
import {
    handleOrderClick,
    handleSelectAll,
    handleSelectAllClick,
} from "../../../constants/checkBoxClick";

import CsvExport from "../../../components/shared/CSVExportDriversEarningReport/CsvLink";
import { sendSuccessMessage } from "../../../constants/extras/sendSuccessMsg";
import { checkForError } from "../../../constants/extras/errorHandlers";
import { PAGINATION } from "../../../constants/variables/index";
// import { useOutsideAlerter } from "../../../constants/outsideAlert/index";
import { checkArrForMapping } from "../../../constants/validaitonSchema/validation.ts";
import {
    DateCreater,
    currentDtArr,
    DATE,
    createDate,
} from "../../../constants/variables/dates";
import CardLayout from "../../../components/layouts/CardLayout";
import RejectShift from "../../../components/shared/orderHelper/RejectShift";
import { API_SUCCESS } from "../../../constants/variables/successMessage";
import Table from "../../../components/shared/Table";
import InfiniteScroll from "react-infinite-scroll-component";
import { rewordingOrderStatus } from "../../../constants/extras/rewording";
import { arrOfExcludingStatus } from "../../../constants/variables/arrays";
import { handleCurrency } from "../../../constants/extras/handleNumbers";
import { handleStatusesBadgeCss } from "../../../constants/extras/handleStatusBadgeCss";
import TableGrid from "../../../components/shared/TableGrid";
// import { handleStatusesBadgeCss } from "../../../constants/extras/statusBadgeCss";

const { CANCELLED_MULTIPLE, CANCELLED_SINGLE } = API_SUCCESS.SHIFT;
const { COMMON__LIMIT: limit } = PAGINATION;

const selectStyle = {
    ...hoverAndFocusedStyle,
    ...ordersStatusSelect,
};

export default function ShiftPage() {
    const loc = useLocation();
    const { pathname } = loc;
    const [showMassPaymentsModal, setShowMassPaymentsModal] = useState(false);
    const [showDateRangePicker, setShowDateRangePicker] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1);
    const [fromDate, setFromDate] = useState(createDate(new Date(), DATE.Y_M_D));
    const [toDate, setToDate] = useState(createDate(new Date(), DATE.Y_M_D));
    const [state, setState] = useState(currentDtArr);
    const [disable, setDisable] = useState(false);

    const wrapperRef = useRef(null);
    // useOutsideAlerter(wrapperRef, setShowDateRangePicker);

    const [remove, removeReq] = useRemoveOrderMutation();
    const metro = useSelector((state) => state.metro.value);
    const { id: hub } = useSelector((state) => state.hub.value);
    const isRefetch = useSelector((state) => state.refetch.value);

    const [filteredOrders, setFilteredOrders] = useState([]);

    const [selectedOrders, setSelectedOrders] = useState([]);
    const [payedOrdersChecked, setPayedOrdersChecked] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [search, setSearch] = useState("");
    const [activeItem, setActiveItem] = useState();
    const [status, setStatus] = useState("all");
    const [deleteShiftTemplate, setDeleteShiftTemplate] = useState([false, ""]);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingCsv, setIsLoadingCsv] = useState(false);
    const [arrOfStatusesCount, setStatusArr] = useState([]);

    const modalOrderOpen = useSelector((state) => state.modal.order.open);
    const { role } = useSelector((state) => state.auth.currentUser || {});
    const { M_D_Y } = DATE;
    const [prevDate, setPrevDate] = useState(state);

    const dateRange = `${fromDate
        ? `${createDate(fromDate, M_D_Y)} TO ${createDate(toDate, M_D_Y)}`
        : "Select Custom Date Range"
        }`;
    const paddStyle = {
        padding: !hasMore ? "0px" : "0 0 10px 0",
    };
    const loaderContainer = {
        height: filteredOrders?.length < 1 ? `60vh` : "auto",
    };

    const isShowingDtRangePicker = showDateRangePicker ? (
        <KeyboardArrowUpIcon />
    ) : (
        <KeyboardArrowDownIcon />
    );

    const queryObj = useMemo(
        () => ({
            fromDate,
            toDate,
            metro,
            hub,
            status,
            search,
        }),
        [fromDate, toDate, metro, hub, status, search]
    );
    const refreshData = () => actions.refetch.setIsDataRefetch(true);
    const [cancelShiftReason, setCancelShiftReason] = useState("");
    const [update] = useUpdateOrderMutation();

    const handleFirstQuery = () => {
        setFilteredOrders([]);
        setPage(1);
        setHasMore(true);
    };

    const checkIsMore = (data) =>
        data?.length < limit ? setHasMore(false) : setHasMore(true);

    useEffect(() => {
        selectedOrders.length > 0
            ? setPayedOrdersChecked(true)
            : setPayedOrdersChecked(false);
    }, [selectedOrders]);

    useEffect(() => {
        actions.metro.selectMetro("");
        actions.hub.selectHub({ name: undefined, id: "" });
        actions.refetch.setIsDataRefetch(true);
    }, [pathname]);

    useEffect(() => {
        let timer = false;
        if (!modalOrderOpen) timer = setTimeout(() => setActiveItem(false), 1500);
        return () => clearTimeout(timer);
    }, [modalOrderOpen]);

    useEffect(() => {
        if (!isRefetch) return;
        handleFirstQuery();
        getShiftData({ ...queryObj, page: 1, limit });
    }, [isRefetch]);

    useEffect(() => {
        (async () => {
            const response = await getOrdersCount(queryObj);
            const { shiftCountData } = response || {};
            setStatusArr(shiftCountData);
        })();
    }, [isRefetch, queryObj]);

    const getShiftData = async (query) => {
        setDisable(true);
        const response = await getOrdersList(query);
        const { shiftsData } = response || {};
        setFilteredOrders((filteredOrders) => [
            ...new Set([...filteredOrders, ...shiftsData]),
        ]);
        setDisable(false);
        checkIsMore(shiftsData); //setHasMore state to true or false according to data length
        setPage((page) => page + 1);
        setIsLoading(false);
        actions.refetch.setIsDataRefetch(false);
    };

    const openDriverModal = useCallback((e) => {
        e.preventDefault();
        actions.modal.openDriver(e.currentTarget.getAttribute("data-driver-id"));
    }, []);

    const getActiveItem = (id) => setActiveItem(id);

    const handleConfirm = async (_id) => {
        console.log(_id, "id");
        const confirmReqBody = {
            isConfirmed: true,
            _id,
        };
        await update(confirmReqBody);
        refreshData();
    };

    const grid = {
        columns: [
            {
                minWidth: 40, id: " ", header: [{ text: `<input class="handleSelectAllClick check" id="check" type="checkbox" />`, rowspan: 2 }], htmlEnable: true,
                align: "center",
                template: function (text, val) {
                    const isSelected = (name) => selectedOrders.indexOf(name) !== -1;
                    let isItemSelected = isSelected(val);
                    const data = val?.status === "complete" && val?.price?.toString().length > 0 && (
                        "<input class=\"handleOrderCheck\" type=\"checkbox\"  " + (isItemSelected ? "checked" : "") + " ></div>"
                    )
                    return data
                },
                sortable: false
            },
            {
                maxWidth: 125,
                id: "metro",
                align: "center",
                header: [{ text: "<b>Metro</b>", align: "center" }, { content: "selectFilter" },],
                footer: [
                    { text: `<div style="font-size:18px;">Total</div>` },
                ],
            },
            {
                maxWidth: 125,
                align: "center",
                id: "routeCode",
                header: [{ text: "<b>Route Code</b>", align: "center" }, { content: "inputFilter" }]
            },
            {
                maxWidth: 125,
                align: "center",
                id: "dayDeliveryStart",
                header: [{ text: "<b>Route Date</b>", align: "center", rowspan: 2 }],
                template: i => moment(i).utc().format("MM-DD-YYYY")
            },
            {
                minWidth: 125,
                align: "center",
                id: "from",
                header: [{ text: "<b>Starting Delivery Station</b>", align: "center" },
                { content: "inputFilter", customFilter: (value, match, data) => value.name.includes(match) }],
                htmlEnable: true,
                template: i => i.name,
            },
            {
                maxWidth: 125,
                align: "center",
                id: "startTime",
                header: [{ text: "<b>Start Time</b>", align: "center", rowspan: 2 }],
                template: i => getRoute(i)
            },
            {
                maxWidth: 170,
                align: "center",
                id: "estimatedShiftTimeFrom",
                header: [{ text: "<b>Estimated Shift Time</b>", align: "center", rowspan: 2 }],
                template: i => `${timeTransform_h.toDisplay(i)}h - ${timeTransform_h.toDisplay(i)}h`,
            },
            {
                maxWidth: 170,
                align: "center",
                id: "estimatedShiftPayFrom",
                header: [{ text: "<b>Estimated Shift Pay</b>", align: "center", rowspan: 2 }],
                template: (val, row) => handleEstimatedShiftPay(
                    row.estimatedShiftPayFrom,
                    row.estimatedShiftPayTo
                )
            },
            {
                maxWidth: 100,
                align: "center",
                id: "price",
                header: [{ text: "<b>Payout</b>", align: "center", rowspan: 2 }],
                footer: [{ content: "sum", align: "center" }],
                template: price => (price ? `$${parseFloat(price).toFixed(2)}` : "-")
            },
            {
                maxWidth: 130,
                align: "center",
                id: "driver",
                header: [{ text: "<b>Driver Name</b>", align: "center" },
                { content: "inputFilter", customFilter: (value, match) => value?.firstName?.toString().includes(match) || value?.lastName?.toString().includes(match) }],
                htmlEnable: true,
                template: driver => {
                    const data = driver?.username ? (
                        `<span class="openDriverModal" data-driver-id=${driver?._id} > ${driver?.firstName || ""} ${driver.lastName || ""}</span>`
                    ) : "-"
                    return data
                }
            },
            {
                minWidth: 125,
                align: "center",
                id: "phone",
                header: [{ text: "<b>Driver Phone Number</b>", align: "center" },
                { content: "inputFilter", customFilter: (value, match, data) => data?.driver?.phone?.includes(match) }],
                template: (e, { driver }) => driver?.phone ? `<a href="tel:driver.phone" class="table_orders__driver-phone">${driver.phone}</a>` : "-",
                htmlEnable: true,
            },
            {
                minWidth: 125,
                maxWidth: 250,
                align: "center",
                id: "note",
                header: [{ text: "<b>Zones/Notes</b>", align: "center" }, { content: "inputFilter" }],
                template: i => i ? i : "-",
            },
            {
                minWidth: 125,
                align: "center",
                id: "internalNote",
                header: [{ text: "<b>Internal notes</b>", align: "center" }, { content: "inputFilter" }],
                template: i => i ? i : "-",
            },
            {
                maxWidth: 90,
                align: "center",
                id: "isConfirmed",
                header: [{ text: "<b>Confirm</b>", align: "center", rowspan: 2 }],
                htmlEnable: true,
                template: (e, val) => {
                    const data = val?.isConfirmed ? `<span class="commonStyle ${handleStatusesBadgeCss("confirmed")}">Confirmed</span>` : (
                        val?.status === "assigned" ? (
                            `<button class="btn btn_block customized-details-btn handleConfirm" type="button"> Confirm </button>`
                        ) : ("-"))
                    return data
                }
            },
            {
                minWidth: 125,
                align: "center",
                id: "status",
                header: [{ text: "<b>Status</b>", align: "center" }, { content: "selectFilter" }],
                htmlEnable: true,
                template: i => `<span class="commonStyle ${handleStatusesBadgeCss(i)}">${i}</span>`
            },
            {
                minWidth: 90,
                id: "detail", gravity: 1.5, header: [{ align: "center", rowspan: 2 }],
                htmlEnable: true, align: "center",
                template: function () {
                    return `<button class='btn btn_block btn_accent customized-details-btn handledetail'>Details</button>`
                }
            },
            {
                minWidth: 40, id: "check", header: [{ text: `<input class="handleSelectAll"  type="checkbox" />`, rowspan: 2 }], htmlEnable: true,
                align: "center",
                template: (e, val) => {
                    const isSelected = (name) => selectedOrders.indexOf(name) !== -1;
                    let isItemSelected = isSelected(val);
                    const data = ["created", "assigned", "rejected"].includes(val?.status) && (
                        `<input class="handleOrderCheck" id="myCheck" type="checkbox" />`)
                    return data
                },
                sortable: false
            },
            {
                minWidth: 90,
                id: "cancel", gravity: 1.5, header: [{ align: "center", rowspan: 2 }],
                htmlEnable: true, align: "center",
                template: (e, val) => {
                    const div1 = val.status && ["created", "assigned", "rejected"].includes(val.status) && (
                        ` <button class="btn btn_block customized-details-btn handlecancel" type="button" > Cancel </button>`)
                    return div1
                }
            }
        ],
        css: "alternate_row",
        data: filteredOrders,
        adjust: true,
        autoWidth: true,
        autoHeight: true,
        tooltip: false,
        leftSplit: 4,
        selection: "row",
        eventHandlers: {
            onclick: {
                "handledetail": function (e, val) {
                    const path = loc.pathname
                    const item = val.row
                    actions.modal.openOrder({ item, path });
                    getActiveItem(item._id);
                },
                "handlecancel": function (e, val) {
                    const id = val.row._id
                    setDeleteShiftTemplate([true, [id]]);
                    setCancelShiftReason("");
                },
                "handleOrderCheck": function (e, val) {
                    const value = val.row
                    handleOrderClick(e, value, selectedOrders, setSelectedOrders)
                },
                "handleConfirm": function (e, val) {
                    const id = val.row._id
                    handleConfirm(id)
                },
                "openDriverModal": function (e, val) {
                    e.preventDefault();
                    actions.modal.openDriver(e.target.getAttribute("data-driver-id"));
                },
                "handleSelectAll": function (event, val) {
                    // handleSelectAll( event, selectedOrders, filteredOrders, setSelectedOrders )
                    console.log("click");
                },
                "handleSelectAllClick": function (event, val) {
                    console.log(event, "event");
                    // handleSelectAllClick( event, filteredOrders, selectedOrders, setSelectedOrders )
                }
            }
        }
    }



    const handleSelectAll = () => {

    }

    /** `
     *     Fetching data with SearchQuery...`
     **/
    const handleOnChange = (e) => {
        setSearchQuery(e.target.value);
        e.target.value === "" && handleFilterBySearchQuery("CLEAR");
    };
    const handleFilterBySearchQuery = async (isClear) => {
        const search = isClear && isClear === "CLEAR" ? "" : searchQuery;
        setSearch(search);
        handleFirstQuery();
        await getShiftData({ ...queryObj, page: 1, search, limit });
    };

    /** `
     *     Fetching data with Hub filter...`
     **/
    const handleHubFilter = async (hubObj) => {
        const { name, _id: id } = hubObj || {};
        const obj = { name, id };
        actions.hub.selectHub(obj);
        handleFirstQuery();
        await getShiftData({ ...queryObj, page: 1, hub: id, limit });
    };

    /** `
     *     Fetching data with Metro filter...`
     **/
    const handleMetroFilter = async (newMetro) => {
        actions.metro.selectMetro(newMetro);
        handleFirstQuery();
        await getShiftData({ ...queryObj, metro: newMetro, page: 1, limit });
    };

    /** `
     *     Fetching data with status filter...`
     **/
    const handleStatusFilter = async (e) => {
        const newStatus = e.target.value;
        setStatus(newStatus);
        handleFirstQuery();
        await getShiftData({ ...queryObj, status: newStatus, page: 1, limit });
    };
    const onResetClick = async () => {
        setShowDateRangePicker(false);
        const currDt = new Date();
        setFromDate(currDt);
        setToDate(currDt);
        setState([
            {
                startDate: currDt,
                endDate: currDt,
                key: "selection",
            },
        ]);
        setIsLoading(true);
        setPrevDate([
            {
                startDate: currDt,
                endDate: currDt,
                key: "selection",
            },
        ]);
        handleFirstQuery();
        const query = {
            ...queryObj,
            fromDate: createDate(),
            toDate: createDate(),
            page: 1,
            limit,
        };
        await getShiftData(query);
    };

    const onCancelClick = () => {
        setShowDateRangePicker(false);
        setState(prevDate);
    };

    useEffect(() => {
        if (filteredOrders) {
            setIsLoading(true)
        }
    }, [filteredOrders])

    const handleTimeRangeSelection = async () => {
        const { startDate, endDate } = state[0] || {};
        const { dt: strtDt } = new DateCreater(startDate);
        const { dt: endDt } = new DateCreater(endDate);
        setFromDate(strtDt);
        setToDate(endDt);
        setShowDateRangePicker(false);
        setPrevDate(state);
        handleFirstQuery();
        const query = {
            ...queryObj,
            fromDate: strtDt,
            toDate: endDt,
            page: 1,
            limit,
        };
        await getShiftData(query);
    };

    const handleCancellingShift = async () => {
        const isMultipleCancel = deleteShiftTemplate[1]?.length > 1;
        const addMultipleIds = () => selectedOrders?.map((shift) => shift?._id);
        const ids = isMultipleCancel ? addMultipleIds() : deleteShiftTemplate[1];

        const result = await remove({
            ids,
            reason: cancelShiftReason,
        });
        const msg = isMultipleCancel ? CANCELLED_MULTIPLE : CANCELLED_SINGLE;
        if (!result?.error && result?.data?.status === 200) sendSuccessMessage(msg);
        else checkForError(result);

        refreshData();
        setDeleteShiftTemplate([false, []]);
        setSelectedOrders([]);
    };

    const fetchMoreShifts = async (e) => {
        const isFetchMore = !isRefetch && page > 1;
        const query = {
            ...queryObj,
            page,
            limit,
        };
        isFetchMore && (await getShiftData(query));
    };

    /**
     *   `CSV downloading function without pagination`
     **/
    const downloadReport = useCallback(async () => {
        const { shiftsData: data } = await getOrdersList(queryObj);
        if (checkArrForMapping(data)) return data;
        return;
    }, [queryObj]);

    const handleKeyDown = ({ key }) =>
        key === "Enter" && handleFilterBySearchQuery();

    const handleStatusBadge = useMemo(() => {
        return arrOfStatusesCount?.map(({ status, count }) => {
            return (
                count > 0 &&
                !arrOfExcludingStatus.includes(status) && (
                    <StatusBadge
                        key={status}
                        count={count}
                        title={rewordingOrderStatus(status)}
                    />
                )
            );
        });
    }, [arrOfStatusesCount]);

    return (
        <React.Fragment>
            {deleteShiftTemplate[0] && (
                <ConfirmDialogModal
                    title="Write a reason for cancelling shift"
                    bodyText={
                        <RejectShift
                            payExceptionNote={cancelShiftReason}
                            setPayExceptionNote={(e) => setCancelShiftReason(e.target.value)}
                            isCancellingShift
                        />
                    }
                    isOpen
                    onClose={() => setDeleteShiftTemplate([false, ""])}
                    confirmAction={handleCancellingShift}
                    modalText="CANCEL__SHIFT"
                    isLoading={removeReq.isLoading}
                />
            )}
            {showMassPaymentsModal && (
                <MassPaymentsModal
                    isOpen
                    onClose={() => {
                        setShowMassPaymentsModal(false);
                        setSelectedOrders([]);
                    }}
                    orders={selectedOrders}
                />
            )}

            <CardLayout>
                <HeaderTextLayout
                    headerText="Shifts"
                    className="col-md-2 main__driver"
                    data={
                        checkArrForMapping(filteredOrders) &&
                        `${filteredOrders.length} Shifts Found`
                    }
                    dataLength={
                        checkArrForMapping(filteredOrders) ? filteredOrders.length : "0"
                    }
                >
                    <div className="col-md-10 card__header__container">
                        <div className="d-flex align-items-center mr-2">
                            <StoreIcon className="filter__label" />
                            <p
                                style={{
                                    margin: "auto 3% auto 0",
                                }}
                                className="filter__label"
                            >
                                Hub:
                            </p>
                            <HubSelect
                                onChange={(event, newValue) => handleHubFilter(newValue)}
                                className="auto__complete"
                                isLoading={disable}
                            />
                        </div>
                        <div className="d-flex align-items-center mr-2">
                            <MdPlace className="filter__label" />
                            <p style={{ margin: "auto 3% auto 0" }} className="filter__label">
                                Metro:
                            </p>
                            <MetroSelect
                                value={metro || ""}
                                onChange={(event, newValue) => handleMetroFilter(newValue)}
                                className="auto__complete"
                                isLoading={disable}
                            />
                        </div>
                        <button
                            style={{
                                background: "white",
                                borderColor: "#e6e6e6",
                                padding: "0 20px"
                            }}
                            className="btn btn__accent mr-3 ml-2 "
                            onClick={() => actions.modal.openOrder({ state })}
                        >
                            <IoIosAddCircle style={{ fontSize: "22px" }} />
                            New shift
                        </button>
                        <div className="main_exportcvs">
                            <CsvExport
                                asyncExportMethod={async () => {
                                    setIsLoadingCsv(true);
                                    const data = await downloadReport();
                                    const obj = {
                                        headers,
                                        data: shiftsData(data),
                                        filename:
                                            fromDate && toDate
                                                ? `Shifts_from_${fromDate}_to_${toDate}.csv`
                                                : "Shifts.csv",
                                    };
                                    setIsLoadingCsv(false);
                                    return obj;
                                }}
                                disable={isLoadingCsv || filteredOrders?.length === 0}
                                loadingCsv={isLoadingCsv}
                            />
                        </div>
                    </div>
                </HeaderTextLayout>

                <div className="filter">
                    <div className="container container_extra-width filter__container">
                        <div className="filter__inner d-flex justify-content-end">
                            {payedOrdersChecked &&
                                selectedOrders[0]?.status === "complete" ? (
                                <button
                                    onClick={() => setShowMassPaymentsModal(true)}
                                    disabled={!selectedOrders.length}
                                    className="btn btn_accent"
                                >
                                    <MdPayment className="btn__icon" />
                                    Pay
                                    {selectedOrders.length ? ` (${selectedOrders.length})` : ""}
                                </button>
                            ) : (
                                payedOrdersChecked && (
                                    <button
                                        onClick={() => {
                                            setDeleteShiftTemplate([true, selectedOrders]);
                                            setCancelShiftReason("");
                                        }}
                                        disabled={!selectedOrders.length}
                                        className="btn btn_accent"
                                    >
                                        <ClearAll className="btn__icon" />
                                        Cancel
                                        {selectedOrders.length ? ` (${selectedOrders.length})` : ""}
                                    </button>
                                )
                            )}
                            <div>{handleStatusBadge}</div>
                            <div className="searchbar__container">
                                <input
                                    className="form__input customized__input"
                                    value={searchQuery}
                                    onChange={handleOnChange}
                                    onKeyDown={handleKeyDown}
                                    disabled={disable}
                                />
                                {searchQuery?.length > 0 && (
                                    <ClearIcon
                                        className="clear__btn filter__search-icon"
                                        onClick={() => {
                                            setSearchQuery("");
                                            handleFilterBySearchQuery("CLEAR");
                                        }}
                                        style={{
                                            right: "45px",
                                            top: "12px",
                                        }}
                                    />
                                )}
                                <button
                                    disabled={searchQuery?.length < 1}
                                    className="search__button"
                                    onClick={handleFilterBySearchQuery}
                                >
                                    <IoSearchSharp className="filter__search-icon" />
                                </button>
                            </div>

                            <div ref={wrapperRef}>
                                <div
                                    onClick={() => {
                                        !disable && setShowDateRangePicker(!showDateRangePicker);
                                        setState(prevDate);
                                    }}
                                    className="form__input"
                                >
                                    <span>{dateRange}</span>
                                    <span className="ml-2">{isShowingDtRangePicker}</span>
                                </div>
                                {showDateRangePicker && (
                                    <div className="DateRangePicker">
                                        <DateRangePicker
                                            onChange={(item) => setState([item.selection])}
                                            months={1}
                                            ranges={state}
                                        />
                                        <div className="button-container">
                                            <button
                                                onClick={onResetClick}
                                                className="okButton clearButton"
                                            >
                                                <span>
                                                    <BackspaceIcon /> &nbsp;
                                                    <strong>RESET</strong>
                                                </span>
                                            </button>
                                            <button onClick={onCancelClick} className="cancelButton">
                                                <span>
                                                    <CancelIcon
                                                        sx={{ width: "1.5rem", height: "1.5rem" }}
                                                    />
                                                    <strong>cancel</strong>
                                                </span>
                                            </button>
                                            <button
                                                onClick={handleTimeRangeSelection}
                                                className="okButton"
                                            >
                                                <span>
                                                    <ThumbUpAltIcon /> &nbsp;
                                                    <strong>Done</strong>
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div>
                                <Select
                                    sx={selectStyle}
                                    value={status}
                                    onChange={handleStatusFilter}
                                    disabled={disable}
                                >
                                    {orderStatuses.map(({ value, title }, idx) => (
                                        <MenuItem value={value} key={`${value} ${idx}`}>
                                            {title}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </div>
                        </div>
                    </div>
                </div>

                <InfiniteScroll
                    dataLength={filteredOrders?.length}
                    next={fetchMoreShifts}
                    hasMore={hasMore}
                    style={paddStyle}
                    loader={
                        <div
                            className="d-flex justify-content-center align-items-center"
                            style={loaderContainer}
                        >
                            <CircularProgress className="loading-progress" />
                        </div>
                    }
                    height={`70vh`}
                    endMessage={
                        !isLoading &&
                        filteredOrders?.length < 1 && (
                            <div
                                style={{
                                    height: `60vh`,
                                }}
                                className="d-flex justify-content-center align-items-center"
                            >
                                <p>
                                    <b>No Result Found</b>
                                </p>
                            </div>
                        )
                    }
                >
                    {isLoading ? (
                        <div className="loading-holder">
                            <CircularProgress className="loading-progress" />
                        </div>
                    ) : (
                        <TableGrid columns={grid} height="70vh"/>
                    )}
                </InfiniteScroll>
            </CardLayout>
        </React.Fragment>
    );
}

const StatusBadge = ({ count, title }) => (
    <span style={handleStatusesBadge(title)} className={`mb-2 mr-2`}>
        {removeDash(convertToCapitalize(title))}: {count}
    </span>
);
