import React from 'react'

const Svg = (props) => {
  return (
    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 640 512" className={props.className} height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
      {props.children}
    </svg>
  )
};

const Sedan = (props) => {
  return (
    <Svg {...props}>
      <path d="M 56.881 209.795 C 35.811 215.254 20.185 234.222 20.185 256.996 L 20.185 342.621 C 20.185 349.378 25.658 354.852 32.416 354.852 L 112.304 354.852 C 112.304 395.387 145.163 428.245 185.696 428.245 C 226.23 428.245 259.089 395.387 259.089 354.852 L 448.003 354.852 C 448.003 395.387 480.861 428.245 521.394 428.245 C 561.928 428.245 594.787 395.387 594.787 354.852 L 613.548 354.852 C 620.305 354.852 625.779 349.378 625.779 342.621 L 625.779 281.46 C 625.779 240.926 592.921 208.068 552.387 208.068 L 490.861 208.068 L 407.698 104.111 C 398.409 92.506 384.352 85.75 369.488 85.747 L 193.16 85.747 C 173.153 85.747 155.165 97.926 147.733 116.504 L 106.494 209.795 L 56.881 209.795 Z M 185.696 391.549 C 165.46 391.549 148.999 375.088 148.999 354.852 C 148.999 334.616 165.46 318.157 185.696 318.157 C 205.933 318.157 222.392 334.616 222.392 354.852 C 222.392 375.088 205.933 391.549 185.696 391.549 Z M 264.568 208.068 L 163.803 208.068 L 193.16 134.676 L 264.568 134.676 L 264.568 208.068 Z M 301.263 208.068 L 301.263 134.676 L 369.488 134.676 L 428.201 208.068 L 301.263 208.068 Z M 521.394 391.549 C 501.159 391.549 484.698 375.088 484.698 354.852 C 484.698 334.616 501.159 318.157 521.394 318.157 C 541.631 318.157 558.092 334.616 558.092 354.852 C 558.092 375.088 541.631 391.549 521.394 391.549 Z"/>
    </Svg>
  )
};

const Suv = (props) => {
  return (
    <Svg {...props}>
      <path d="M 62.241 44.833 L 366.488 44.833 C 381.352 44.836 395.409 51.592 404.698 63.197 L 487.861 187.472 L 564.169 187.472 C 604.703 187.472 637.561 207.224 637.561 247.758 L 637.561 342.621 C 637.561 349.378 632.087 354.852 625.33 354.852 L 579.51 354.852 C 579.51 395.387 546.651 428.245 506.117 428.245 C 465.584 428.245 432.726 395.387 432.726 354.852 L 232.149 354.852 C 232.149 395.387 199.29 428.245 158.756 428.245 C 118.223 428.245 85.364 395.387 85.364 354.852 L 18.693 354.852 C 11.935 354.852 6.462 349.378 6.462 342.621 L 7.954 188.436 C 21.799 80.785 29.662 74.449 33.064 59.554 C 40.668 44.452 52.408 44.833 62.241 44.833 Z M 195.452 354.852 C 195.452 334.616 178.993 318.157 158.756 318.157 C 138.52 318.157 122.059 334.616 122.059 354.852 C 122.059 375.088 138.52 391.549 158.756 391.549 C 178.993 391.549 195.452 375.088 195.452 354.852 Z M 141.587 93.547 L 63.531 93.762 L 48.634 187.472 L 139.511 187.058 L 141.587 93.547 Z M 438.471 187.808 L 377.91 92.741 L 298.263 93.762 L 298.263 187.472 L 438.471 187.808 Z M 542.815 354.852 C 542.815 334.616 526.354 318.157 506.117 318.157 C 485.882 318.157 469.421 334.616 469.421 354.852 C 469.421 375.088 485.882 391.549 506.117 391.549 C 526.354 391.549 542.815 375.088 542.815 354.852 Z M 170.414 187.472 L 269.823 187.267 L 269.823 94.094 L 171.343 93.966 L 170.414 187.472 Z"/>
    </Svg>
  )
};

const CarIcon = (props) => {
  if (props.type === "0") {
    return (
      <Sedan className={props.className}/>
    )
  } else if (props.type === "1") {
    return (
      <Suv className={props.className}/>
    )
  } else {
    return null
  }
}

export default CarIcon;


