export const handleSelectAll = (
  event,
  selectedOrders,
  filteredOrders,
  setSelectedOrders
) => {
  if (event.target.checked || selectedOrders.length !== filteredOrders.length) {
    const newSelecteds = filteredOrders?.filter((order) => {
      if (["created", "assigned"].includes(order?.status))
        return order;
      else return null;
    });
    if (newSelecteds.length === selectedOrders.length) setSelectedOrders([]);
    else setSelectedOrders(newSelecteds);
    return;
  }
  setSelectedOrders([]);
};

// export const handleOrderClick = (
//   e,
//   name,
//   selectedOrders,
//   setSelectedOrders,
//   exceptionsPaydata,
//   setExceptionsPaydata,
//   exceptionsApprovedata,
//   setExceptionsApprovedata
// ) => {
//   const selectedIndex = selectedOrders.indexOf(name);
//   let newSelected = [];

//   if (selectedIndex === -1) {
//     newSelected = newSelected.concat(selectedOrders, name);
//   } else if (selectedIndex === 0) {
//     newSelected = newSelected.concat(selectedOrders.slice(1));
//   } else if (selectedIndex === selectedOrders.length - 1) {
//     newSelected = newSelected.concat(selectedOrders.slice(0, -1));
//   } else if (selectedIndex > 0) {
//     newSelected = newSelected.concat(
//       selectedOrders.slice(0, selectedIndex),
//       selectedOrders.slice(selectedIndex + 1)
//     );
//   }

//   setSelectedOrders(newSelected);
// };


export const handleOrderClick = (
  e,
  name,
  selectedOrders,
  setSelectedOrders,
  exceptionsPaydata,
  setExceptionsPaydata,
  exceptionsApprovedata,
  setExceptionsApprovedata
) => {
  const selectedIndex = selectedOrders.indexOf(name);
  let newSelected = [];

  if (selectedIndex === -1) {
    newSelected = newSelected.concat(selectedOrders, name);
  } else if (selectedIndex === 0) {
    newSelected = newSelected.concat(selectedOrders.slice(1));
  } else if (selectedIndex === selectedOrders.length - 1) {
    newSelected = newSelected.concat(selectedOrders.slice(0, -1));
  } else if (selectedIndex > 0) {
    newSelected = newSelected.concat(
      selectedOrders.slice(0, selectedIndex),
      selectedOrders.slice(selectedIndex + 1)
    );
  }

  // Separate orders based on bonusPrice (unchanged conditions)
  const paydata = newSelected.filter(
    (order) => order?.bonusPrice === 0 || order?.bonusPrice === null
  );
  const appdata = newSelected.filter(
    (order) => order?.bonusPrice !== 0 && order?.bonusPrice !== null
  );

  setSelectedOrders(newSelected);
  setExceptionsPaydata(paydata);
  setExceptionsApprovedata(appdata);
};


// export const handleSelectAllClick = (
//   event,
//   filteredOrders,
//   selectedOrders,
//   setSelectedOrders,
//   exceptionsPaydata,
//   setExceptionsPaydata,
//   exceptionsApprovedata,
//   setExceptionsApprovedata

// ) => {
//   if (event.target.checked || selectedOrders.length !== filteredOrders.length) {
//     const newSelecteds = filteredOrders?.filter((n) => {
//       if (
//         n?.status?.includes("complete") &&
//         n?.price?.toString().length > 0 &&
//         n?.price !== 0
//       )
//         return n;
//       else return null;
//     });
//     if (newSelecteds.length === selectedOrders.length) setSelectedOrders([]);
//     else setSelectedOrders(newSelecteds);

//     console.log(newSelecteds, "newSelecteds")

//     return;
//   }
//   setSelectedOrders([]);
// };


export const handleSelectAllClick = (
  event,
  filteredOrders,
  selectedOrders,
  setSelectedOrders,
  exceptionsPaydata,
  setExceptionsPaydata,
  exceptionsApprovedata,
  setExceptionsApprovedata
) => {
  if (event.target.checked || selectedOrders.length !== filteredOrders.length) {
    const newSelecteds = filteredOrders?.filter((n) => {
      if (
        n?.status?.includes("complete") &&
        n?.price?.toString().length > 0 &&
        n?.price !== 0
        // (n?.bonusPrice === 0 || n?.bonusPrice === null)
      ) {
        return n;
      } else {
        return null;
      }
    });

    if (newSelecteds.length === selectedOrders.length) {
      setSelectedOrders([]);
      setExceptionsPaydata([]);
      setExceptionsApprovedata([]);
    } else {
      setSelectedOrders(newSelecteds);

      // Separate orders based on bonusPrice (unchanged conditions)
      const paydata = newSelecteds.filter(
        (order) => order?.bonusPrice === 0 || order?.bonusPrice === null
      );
      const appdata = newSelecteds.filter(
        (order) => order?.bonusPrice !== 0 && order?.bonusPrice !== null
      );

      setExceptionsPaydata(paydata);
      setExceptionsApprovedata(appdata);
    }

    return;
  }
  setSelectedOrders([]);
  setExceptionsPaydata([]);
  setExceptionsApprovedata([]);
};

