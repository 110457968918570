import React, { forwardRef, useState, useEffect } from "react";
import { Autocomplete, TextField } from "@mui/material";
import "./style.scss";
import { useGetCompanyQuery } from "../../../api/company";
import { autocomplete, errorAutoComplete } from "../orderHelper/styles";
import { checkArrForMapping } from "../../../constants/validaitonSchema/validation.ts";
import { useSelector } from "react-redux";

const CompanySelect = forwardRef(({ isError, disabled,isLoading,isAll, ...props }, ref) => {
  const { data: companies, isFetching } = useGetCompanyQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const currentUser = useSelector((state) => state.auth.currentUser);

  const [companyList, setCompanyList] = useState([]);
  const styleObj = isError ? { ...errorAutoComplete } : { ...autocomplete }; 
  useEffect(() => {
    
      if (isFetching || !companies) return;
      const company =
        checkArrForMapping(companies) && companies.map(({ _id,companyName,companyCode }) =>{return({label:`${companyCode ? companyCode : ""}  ${companyCode && companyName ? "- " : ""} ${companyName ? companyName : ""}`,value:_id})} );
      checkArrForMapping(company) && ( isAll ? setCompanyList([{label:"All", value:"all"}, ...company]) :  setCompanyList(["", ...company]) )
  }, [companies, isFetching, currentUser]);

  return (
    <Autocomplete
      disabled={disabled || isLoading}
      defaultValue={""}
      options={Array?.isArray(companyList) && companyList}
      sx={{
        width: 300,
        ...styleObj,
      }}
      blurOnSelect={true}
      ref={ref}
      {...props}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Select Company"
          inputProps={{
            ...params.inputProps,
          }}
        />
      )}
    />
  );
});

export default CompanySelect;
