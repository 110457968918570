import React, { useState, useEffect, useCallback } from "react";
import { IoChevronForward, IoSearchSharp } from "react-icons/io5";
import { CircularProgress } from "@mui/material";
import { IoIosAddCircle } from "react-icons/io";

import Table from "../../../components/shared/Table";
import { actions } from "../../../redux/store/store";
import "../../../styles/_data.scss";
import "../../../index.css";
import HeaderTextLayout from "../../../components/shared/HeaderTextLayout";
import { Refresh } from "@mui/icons-material";
import { getCompanyList, useGetCompanyQuery } from "../../../api/company";
import { useSelector } from "react-redux";
import ClearIcon from "@mui/icons-material/Clear";
import { useLocation } from "react-router-dom";


export default function Company() {
  const location = useLocation();
  const { pathname } = location;

  const isRefetch = useSelector((state) => state.refetch.value);

  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [disable, setDisable] = useState(false);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    actions.refetch.setIsDataRefetch(true);
  },[pathname])

  useEffect(() => {
    if (isRefetch === false) return;
    setLoading(true);

    (async () => {
      setDisable(true);
      const data = await getCompanyList(searchQuery);
      setFilteredCompanies(data)
      setDisable(false);
      actions.refetch.setIsDataRefetch(false);
      setLoading(false);
    })();

  }, [isRefetch]);

  const handleSearchingDriver = useCallback(
    async (c) => {
      const search = c === "CLEAR" ? "" : searchQuery;
      setFilteredCompanies([]);
      setDisable(true);
      setLoading(true);
      const data = await getCompanyList(search)
      setFilteredCompanies(data)
      setDisable(false);
      setLoading(false);
    },
    [searchQuery]
  );

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearchingDriver();
    }
  };

  const columns = [
    { name: "companyName", label: "Company Name", sortable: true },
    { name: "companyCode", label: "Company Code", sortable: true },
    {
      name: "details",
      className: "table__td_have-btn w-10 px-3",
      renderer: (item) => (
        <button
          className="btn btn_block btn_accent customized-details-btn"
          type="button"
          onClick={() => {
            actions.modal.openCompanyMain(item)
          }}
        >
          Details <IoChevronForward className="btn__icon btn__icon_right" />
        </button>
      ),
    },
  ];

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="card">
            <HeaderTextLayout
              headerText="Company"
              className="col-md-2 main__driver"
              data={
                filteredCompanies && filteredCompanies.length > 0
                  ? `${filteredCompanies.length} Companies Found`
                  : ""
              }
              dataLength={
                Array.isArray(filteredCompanies) ? filteredCompanies.length : "0"
              }
            >
              <div className="col-md-10 col-sm-10 d-flex justify-content-end align-items-center filter">
                <div className="col-md-10 col-sm-10 d-flex justify-content-end align-items-center">
                  <div className="filter__ctrls-holder filter__input-holder d-flex justify-content-end search__container">
                    <div className="searchbar__container">
                      <input
                        className="form__input customized__input"
                        value={searchQuery}
                        onChange={(e) => {
                          setSearchQuery(e.target.value);
                          if (e.target.value === "")
                            handleSearchingDriver("CLEAR");
                        }}
                        placeholder="Quick Search"
                        onKeyDown={handleKeyDown}
                        disabled={disable}
                      />
                      {searchQuery?.length > 0 && (
                        <ClearIcon
                          className="clear__btn filter__search-icon"
                          onClick={() => {
                            if (!disable) {
                              setSearchQuery("");
                              handleSearchingDriver("CLEAR");
                            }
                          }}
                          style={{
                            right: "45px",
                            top: "12px",
                          }}
                        />
                      )}
                      <button
                        disabled={searchQuery?.length <= 0}
                        className="search__button"
                        onClick={() => handleSearchingDriver()}
                      >
                        <IoSearchSharp />
                      </button>
                    </div>
                  </div>

                  <button
                    style={{
                      background: "white",
                      borderColor: "#e6e6e6",
                      width: "200px",
                    }}
                    className="btn btn__accent mr-0 ml-3"
                    onClick={() => actions.modal.openCompanyMain()}
                  >
                    <IoIosAddCircle style={{ fontSize: "22px" }} />
                    New Company
                  </button>

                  <button className="refresh_btn ml-3" onClick={() => actions.refetch.setIsDataRefetch(true)}>
                    <Refresh className="refresh_icon" />
                  </button>
                </div>
              </div>
            </HeaderTextLayout>
            {isLoading ? (
              <div className="loading-holder">
                <CircularProgress className="loading-progress" />
              </div>
            ) : (
              <React.Fragment>
                {filteredCompanies && filteredCompanies.length ? (
                  <div className="table-wrapper normal_table">
                    <Table
                      columns={columns}
                      items={filteredCompanies}
                      className="table_metros"
                    />
                  </div>
                ) : (
                  <div className="no_results">No Results</div>
                )}
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
