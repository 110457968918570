/** `
 *   Add field in this array to define the "CaseInSensitive" sorting...`
 **/
export const arrayOfCaseInSensitivity = [
  "metro",
  "name",
  "address",
  "payeeName",
  "transactionStatus",
  "firstName",
  "username",
  "metro",
  "status",
  "role",
  "email",
  "activity",
  "ip",
  "routeCode",
  "note",
  "title",
  "exceptionNote",
  "driver-firstName",
  "driver-orderCount",
  "invitingAdmin-username",
  "actorUser-username",
  "from-name",
  "accountInfo-depositAccountNumber",
  "user-username",
  "user-firstName",
  "sentBy-username",
  "approvedBy-firstName",
  "paidBy-firstName",
  "createdBy-username",
  "updatedBy-username",
  "cancelType",
  "percentage",
];

/** `
 *   Add dashed name field in this array to know the sorting
 *    function that this field has value inside objects for sorting...`
 **/
export const arrayofInnerObjCaseSensitivity = [
  "driver-firstName",
  "driver-orderCount",
  "invitingAdmin-username",
  "actorUser-username",
  "from-name",
  "accountInfo-depositAccountNumber",
  "user-username",
  "user-firstName",
  "sentBy-username",
  "approvedBy-firstName",
  "paidBy-firstName",
  "createdBy-username",
  "updatedBy-username",
];

export const oppositeDir = (dir) => (dir === "asc" ? "desc" : "asc");
