import React, { useEffect, useState } from 'react'
import HeaderTextLayout from '../../../components/shared/HeaderTextLayout'
import { actions } from '../../../redux/store/store'
import { Refresh } from "@mui/icons-material";
import { IoIosAddCircle } from "react-icons/io";
import CustomFeedbackDrawer from '../../../components/shared/customDrawer/feedback';
import { IoChevronForward } from "react-icons/io5";
import { getFeedbackList } from '../../../api/feedback';
import { useSelector } from 'react-redux';
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import Table from '../../../components/shared/Table';
import "./style.scss"
import InfiniteScroll from 'react-infinite-scroll-component';
import { useLocation } from 'react-router-dom';
import moment from 'moment';

function Feedback() {
    const isRefetch = useSelector((state) => state.refetch.value);
    const location = useLocation();
    const { pathname } = location;

    const [filterFeedback, setFilterFeedback] = useState([]);
    const [activeItem, setActiveItem] = useState();
    const [typeValue, setTypeValue] = useState("")
    const [hasMore, setHasMore] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(2);
    const [limit] = useState(100);
    const modalFeedbackOpen = useSelector(
        (state) => state.modal.feedback.open
    );

    useEffect(() => {
        let timer = false;
        if (!modalFeedbackOpen) {
            timer = setTimeout(() => {
                setActiveItem(false);
            }, 1500);
        }
        return () => clearTimeout(timer);
    }, [modalFeedbackOpen]);

    useEffect(() => {
        actions.metro.selectMetro("");
        actions.refetch.setIsDataRefetch(true);
    }, [pathname]);

    useEffect(() => {
        if (isRefetch === false) return;
        setIsLoading(true);
        setHasMore(true);
        setFilterFeedback([]);
        setPage(1);
        (async () => {
            const data = await getFeedbackList(
                1,
                limit,
                typeValue
            );
            setFilterFeedback(data);
            if (data && data?.length < limit) setHasMore(false);
            setPage(2);
        })();
        actions.refetch.setIsDataRefetch(false);
        setIsLoading(false);
    }, [isRefetch, limit]);

    const getActiveItem = (id) => {
        setActiveItem(id);
    };

    const typeData = [
        { label: "FreeText", value: "freeText" },
        { label: "Star", value: "star" },
        { label: "Options", value: "options" },
    ]

    const columns = [
        { name: "question", label: "Question", sortable: true, maxWidth: "200px" },
        {
            name: "createdAt",
            label: "Created At",
            sortable: true,
            renderer: (item) => moment(item.createdAt).format("MMM D, YYYY hh:mm A"),
            className: "w-10"
        },
        { name: "questionsType", label: "Type", sortable: true, className: "w-5" },
        {
            name: "metro",
            label: "Metro",
            sortable: true,
            renderer: (item) => {
                return (
                    item?.metro?.length &&
                    (item?.metro?.length === 1 ?
                        item?.metro[0] :
                        `${item?.metro[0]}, ...`)
                    || "-"
                )
            },
            className: "w-15"
        },
        {
            name: "company",
            label: "Company",
            sortable: true,
            renderer: (item) => {
                const company = item?.company[0]?.companyName
                const companyCode = item?.company[0]?.companyCode
                return (
                    item?.company?.length &&
                    (item?.company?.length === 1 ?
                        `${companyCode ? companyCode : ""}  ${companyCode && company ? "- " : ""} ${company ? company : ""}` :
                        `${`${companyCode ? companyCode : ""}  ${companyCode && company ? "- " : ""} ${company ? company : ""}`}, ...`)
                    || "-"
                )
            },
            className: "w-15"
        },
        {
            name: "isActive",
            label: "Active",
            sortable: true,
            renderer: (item) => `${item?.isActive}`,
            className: "w-5"
        },
        {
            name: "details",
            className: "table__td_have-btn align-center",
            renderer: (item) => (
                <button
                    className="btn btn_block btn_accent customized-details-btn"
                    type="button"
                    onClick={() => {
                        actions.modal.openFeedback(item._id);
                        getActiveItem(item._id);
                    }}
                >
                    Details <IoChevronForward className="btn__icon btn__icon_right" />
                </button>
            ),
        },
    ];

    const fetchMoreNotification = async () => {
        let feedbackData = await getFeedbackList(
            page,
            limit,
        );
        setFilterFeedback([...filterFeedback, ...feedbackData]);
        if (feedbackData?.length === 0 || feedbackData?.length < limit)
            setHasMore(false);
        setPage(page + 1);
    };

    return (
        <>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="card">
                            <HeaderTextLayout
                                headerText="Feedback"
                                className="col-lg-2 col-md-2 main__driver"
                                data={
                                    filterFeedback && filterFeedback.length > 0
                                        ? `${filterFeedback.length} Metros Found`
                                        : ""
                                }
                                dataLength={
                                    Array.isArray(filterFeedback) ? filterFeedback.length : "0"
                                }
                            >
                                <div className="col-lg-10 col-md-10 col-sm-12 filter">
                                    <div className="filter__inner p-0 justify-content-end">
                                        <div className='optType' >
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                options={typeData}
                                                onChange={(e, val) => {
                                                    setTypeValue(val?.value);
                                                    actions.refetch.setIsDataRefetch(true)
                                                }}
                                                value={typeValue || null}
                                                isOptionEqualToValue={(option, value) => option === value}
                                                fullWidth
                                                renderInput={(params) => <TextField {...params} placeholder="Select Type" />}
                                            />
                                        </div>

                                        <button
                                            style={{
                                                background: "white",
                                                borderColor: "#e6e6e6",
                                                padding: "0 20px"
                                            }}
                                            className="btn btn__accent "
                                            onClick={() => actions.modal.openFeedback()}
                                        >
                                            <IoIosAddCircle style={{ fontSize: "22px" }} />
                                            New Feedback
                                        </button>
                                        <button className="refresh_btn" onClick={() => actions.refetch.setIsDataRefetch(true)} >
                                            <Refresh className="refresh_icon" />
                                        </button>

                                    </div>
                                </div>

                            </HeaderTextLayout>

                            <CustomFeedbackDrawer />

                            <InfiniteScroll
                                className='infinit_table'
                                dataLength={filterFeedback?.length}
                                next={fetchMoreNotification}
                                hasMore={hasMore}
                                style={{
                                    paddingBottom: !hasMore ? "0px" : "10px",
                                }}
                                loader={
                                    <div
                                        className="d-flex justify-content-center align-items-center"
                                        style={{
                                            height:
                                                filterFeedback &&
                                                    filterFeedback?.length <= 0
                                                    ? "70vh"
                                                    : "auto",
                                        }}
                                    >
                                        <CircularProgress className="loading-progress" />
                                    </div>
                                }
                                height={"80vh"}
                                endMessage={
                                    filterFeedback &&
                                    filterFeedback?.length <= 0 && (
                                        <div
                                            style={{
                                                height: "70vh",
                                            }}
                                            className="d-flex justify-content-center align-items-center"
                                        >
                                            <p>
                                                <b>No Result Found</b>
                                            </p>
                                        </div>
                                    )
                                }
                            >
                                {isLoading ? (
                                    <div className="loading-holder">
                                        <CircularProgress className="loading-progress" />
                                    </div>
                                ) : (
                                    <Table
                                        columns={columns}
                                        items={filterFeedback}
                                        className="table_orders"
                                        activeItem={activeItem}

                                    />
                                )}
                            </InfiniteScroll>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Feedback
