const commonStyle = {
    "borde-radius": "5px",
    "padding": "4px 4px",
    "text-align": "center",
    "width": "auto",
    "font-size": "80%",
    "font-weight": "500",
    "white-space": "nowrap",
    "text-transform": "capitalize",
  };

  const approved = {
    "background-color": "#E7FAE3",
    "color": "#2D6040",
    "border": "1px solid #E7FAE3",
  };
  
  const rejected = {
    "background-color": "#F5CFD3",
    "color": "#671A21",
    "border": "1px solid  #F5CFD3",
  };
  const created = {
    "background-color": "#F5F2CF",
    "color": "#6B5C1F",
    "border": "1px solid #F5F2CF",
  };
  
  const pending = {
    "background-color": "gray",
    "color": "#FFFFFF",
    "border": "1px solid gray",
  };
  
  const interested = {
    "background-color": "#cad7ff",
    "color": "#6d91ff",
    "border": "1px solid #cad7ff",
  };

  export const handleStatusesBadgeCss = (status) => {
    if (status) {
      switch (status) {
        case "assigned":
        case "reassigned":
        case "pending":
          return "pending";
        case "not_approved":
        case "cancelled":
        case "failed":
        case "blocked":
        case "payment_failed":
        case "inactive":
        case "offboarded":
        case "rejected":
          return "rejected";
        case "processed":
        case "paid":
        case "complete":
        case "confirmed":
        case "approved" || "Waiting For Approval":
          return "approved"
        case "delivery_started":
        case "Delivery Started":
        case "created":
          return "created";
        case "remain":
          return "rejected";
        case "interested_driver": //from API it's interestedDriver
          return "interested";
        default:
          return "commonStyle";
      }
    }
  };