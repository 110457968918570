import { createApi } from "@reduxjs/toolkit/query/react";
import axios from "axios";
import { baseQueryWithAuthInterceptor } from "./util";

export const notificationApi = createApi({
  reducerPath: "notificationApi",
  baseQuery: baseQueryWithAuthInterceptor({ baseUrl: "/api/notifications/" }),
  tagTypes: ["Notifications"],
  endpoints: (builder) => ({
    getNotifications: builder.query({
      query: (body) => {
        let opts = {};
        if (body && body.metro) {
          opts.params = { metro: body.metro };
        }
        if (body && body.company && body.metro) {
          opts.params = { company: body.company }
        }
        return {
          url: "",
          ...opts,
        };
      },
      providesTags: ["Notifications"],
    }),
    getSingleNotification: builder.query({
      query: (id) => ({
        url: `${id}`,
        method: "GET",
      }),
      providesTags: ["Notifications"],
    }),
    createNotification: builder.mutation({
      query: (body) => ({
        url: "",
        method: "POST",
        body,
      }),
      invalidatesTags: (result, error, arg) => !error && ["Emails"],
    }),
  }),
});

export const {
  useGetNotificationsQuery,
  useGetSingleNotificationQuery,
  useCreateNotificationMutation,
} = notificationApi;

export const getNotificationsList = async (page, limit, searchQuery, metro) => {
  try {
    const response = await axios.get(
      `/api/notifications?page=${page ? page : ""}&limit=${limit ? limit : ""
      }&search=${searchQuery}&metro=${metro ? metro : ""}`
    );
    if (response.status === 200) {
      return response?.data?.result;
    } else return "Something went wrong";
  } catch (e) {
    return e.response.data;
  }
};

export const createNotificationsList = async (params) => {
  const formData = new FormData();
  formData.append("title", params.title);
  formData.append("description", params.description);
  !params.isMetroAll && formData.append("metro", params.metro);
  formData.append("isSendToAll", params.isSendToAll);
  formData.append("file", params.file);
  formData.append("status", params.status);
  !params.isCompanyAll && formData.append("company", params.company);
  params.isMetroAll && formData.append("isMetroAll", params.isMetroAll);
  params.isCompanyAll && formData.append("isCompanyAll", params.isCompanyAll);
  params.status === "assigned" && formData.append("fromDate", params?.fromDate);
  params.status === "assigned" && formData.append("toDate", params?.toDate);
  params.userIds.forEach(function (value) {
    formData.append("userIds[]", value);
  });

  try {
    const response = await axios.post(`/api/notifications`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "*/*",
      },
    });
    if (response.status === 200) {
      return response?.data;
    } else return "Something went wrong";
  } catch (e) {
    return e.response.data;
  }
};
