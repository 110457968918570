import * as Yup from "yup";

const requiredMessage = "This field is required";
const VALID_COUNT = "Please Enter Valid Count";
const timeMismatchMessage = "Update time should not be the same as start time";
export const ordersValidationSchema = Yup.object().shape({
  metro: Yup.string()
    .typeError("This field is required")
    .required(requiredMessage),
  from: Yup.string()
    .typeError("This field is required")
    .required(requiredMessage),
  dayDeliveryStart: Yup.string().nullable().required(requiredMessage),
  routeCode: Yup.string().transform((v) => (v === null ? " " : v)),
});

export const emailValidationSchema = Yup.object().shape({
  emails: Yup.array().of(Yup.string()),
  subject: Yup.string().required(),
  body: Yup.string(),
});

export const notificationValidationSchema = Yup.object().shape({
  userIds: Yup.array().of(Yup.string()),
  title: Yup.string().required(),
  description: Yup.string().required(),
});

export const shiftTemplateValidationSchema = Yup.object().shape({
  title: Yup.string()
    .typeError("This field is required")
    .required(requiredMessage),
  metro: Yup.string()
    .typeError("This field is required")
    .required(requiredMessage),
  from: Yup.string()
    .typeError("This field is required")
    .required(requiredMessage),
  numberOfShifts: Yup.number()
    .typeError("This field is required")
    .required(requiredMessage),
});

export const editschedulersVS = Yup.object().shape({
  metro: Yup.string().nullable().required(requiredMessage),
  type: Yup.string().required(requiredMessage),
  fromDate: Yup.string().required(requiredMessage),
  toDate: Yup.string().required(requiredMessage),
  count: Yup.number().min(1, VALID_COUNT).required(requiredMessage),
});

export const createSchedulersVS = Yup.object().shape({
  metro: Yup.string().nullable().required(requiredMessage),
  type: Yup.string().required(requiredMessage),
  date: Yup.string().required(requiredMessage),
  count: Yup.number().min(1, VALID_COUNT).required(requiredMessage),
});

export const feedbackValidationSchema = Yup.object().shape({
  question: Yup.string().nullable().required(requiredMessage),
  questionsType: Yup.string().required(requiredMessage),
})

export const driverCoachingValidationSchema = Yup.object().shape({
  section: Yup.object().nullable().required(requiredMessage),
  orderNumber: Yup.string().nullable().required(requiredMessage),
  type: Yup.object().nullable().required(requiredMessage),
  title: Yup.string().required(requiredMessage),
})

export const addCoachingValidationSchema = Yup.object().shape({
  coachingType: Yup.object().nullable().required(requiredMessage),
  coachReason: Yup.object().nullable().required(requiredMessage),
  status: Yup.string().nullable().required(requiredMessage),
  notes: Yup.string().nullable().required(requiredMessage),

})

export const bulkOrderValidationSchema = Yup.object().shape({
  metro: Yup.string().nullable().required(requiredMessage),
  startTime: Yup.string().required(requiredMessage),
  updateTime: Yup.string()
    .required(requiredMessage)
    .notOneOf([Yup.ref('startTime')], timeMismatchMessage),
});