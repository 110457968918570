import { Autocomplete, Box, CircularProgress, Drawer, FormControlLabel, styled, Switch, TextField } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { actions } from '../../../../redux/store/store'
import "./style.scss"
import "../driver/style.driver.scss"
import ClearIcon from "@mui/icons-material/Clear";
import { arrOftype } from '../../../../constants/variables/arrays'
import { Controller, useForm } from 'react-hook-form'
import { IoIosAddCircle } from 'react-icons/io';
import { AiFillCloseCircle } from 'react-icons/ai';
import { sendErrorMessage } from '../../../../constants/extras/errorHandlers'
import { DatePicker } from '../driver'
import { currentDtArr } from '../../../../constants/variables/dates'
import { addDays } from 'date-fns'
import moment from 'moment'
import { useAddFeedbackMutation, useGetOneFeedbackQuery, useUpdateFeedbackMutation } from '../../../../api/feedback'
import { sendSuccessMessage } from '../../../../constants/extras/sendSuccessMsg'
import { feedbackValidationSchema } from '../../../../constants/validaitonSchema'
import { yupResolver } from '@hookform/resolvers/yup'
import { ErrorMessage } from '../../Form'
import { API_SUCCESS } from '../../../../constants/variables/successMessage'
import FeedbackAnswer from '../../orderHelper/FeedbackAnswer'
import MultiSelect from '../../../../constants/multiSelectDropdown';
import { useGetMetrosQuery } from '../../../../api/metro'
import { useGetCompanyQuery } from '../../../../api/company'
import { checkArrForMapping } from "../../../../constants/validaitonSchema/validation.ts"

const { CREATED, UPDATED } = API_SUCCESS.FEEDBACK;

const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 6,
    '& .MuiSwitch-switchBase': {
        transitionDuration: '500ms',
    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 20,
        height: 20,
        margin: 0,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#ccd7dd' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 1000,
        }),
    },
}));

export const multiSelectStyle = {
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? "#4195F5" : "white",
        paddingTop: "3px",
        paddingBottom: "0px",
        "&:hover": {
            background: "#4195F5",
            color: "#FFFFFF !important",
            transition: "all 0.8s",
        },
    }),
    control: (styles) => ({
        ...styles,
        borderRadius: "5px",
        padding: "3px 0",
        boxShadow: "none",
        "&:hover": {
            border: "2px solid #e3eaf3",
        },
        border: "2px solid #e3eaf3",
        height: "50px",
        overflowY: "auto",
        padding: 0
    }),
    placeholder: (base) => ({
        ...base,
        fontSize: "1rem",
        color: "#a2a2a2"
    }),
};


function CustomFeedbackDrawer() {
    const feedback = useSelector((state) => state.modal.feedback)
    const id = feedback?.id

    const { data: getOneFeedback, isFetching } = useGetOneFeedbackQuery(id, {
        refetchOnMountOrArgChange: true,
        skip: !id
    });
    const { data: companies } = useGetCompanyQuery(null, {
        refetchOnMountOrArgChange: true,
    });
    const { data: metros } = useGetMetrosQuery(null, {
        refetchOnMountOrArgChange: true,
    });

    const [addFeedback] = useAddFeedbackMutation()
    const [updateFeedback] = useUpdateFeedbackMutation()

    const form = useForm({
        defaultValues: {
            question: "",
            questionsType: "",
            metro: "",
            company: "",
            isActive: true,
            isFirstDriver: false
        },
        resolver: yupResolver(feedbackValidationSchema),
    });

    const [option, setOption] = useState([])
    const [state, setState] = useState(currentDtArr)
    const [fromDate, setFromDate] = useState("")
    const [toDate, setToDate] = useState("")
    const [prevDate, setPrevDate] = useState(state);
    const [showDateRangePicker, setShowDateRangePicker] = useState(false);
    const [feedbackReview, setFeedbackReview] = useState([])
    const [company, setCompany] = useState([])
    const [title, setTitle] = useState(false)

    useEffect(() => {
        const companyData =
            checkArrForMapping(companies) && companies.map(({ _id, companyName, companyCode }) => { return ({ label: `${companyCode ? companyCode : ""}  ${companyCode && companyName ? "- " : ""} ${companyName ? companyName : ""}`, value: _id }) });
        checkArrForMapping(companyData) && setCompany(companyData)
    }, [companies])

    useEffect(() => {
        const type = getOneFeedback?.questionsType?.charAt(0).toUpperCase() + getOneFeedback?.questionsType?.slice(1);
        const companyData = getOneFeedback?.company?.map((e) => {
            return { label: e?.companyName, value: e?._id }
        })
        const metroData = getOneFeedback?.metro?.map((val) => {
            return { label: val, value: val }
        })

        if (feedback.open) {
            form.setValue("question", (id && getOneFeedback?.question) || "")
            form.setValue("questionsType", (id && type) || "")
            form.setValue("metro", (id && metroData) || [])
            form.setValue("company", (id && companyData) || [])
            form.setValue("isActive", id ? getOneFeedback?.isActive : true)
            form.setValue("isFirstDriver", (id && getOneFeedback?.isFirstDriver) || false)
            setFromDate(id ? getOneFeedback?.startDate : "")
            setToDate(id ? getOneFeedback?.endDate : "")
            setPrevDate([
                {
                    startDate: new Date(id && getOneFeedback?.startDate),
                    endDate: new Date(id && getOneFeedback?.endDate),
                    key: "selection"
                }
            ])
            setOption(id ? getOneFeedback?.options?.map((elem) => {
                return { badge: elem, isBadge: true }
            }) : [])
            setTitle(id ? true : false)

            setFeedbackReview(id && getOneFeedback?.answers)
        }

    }, [feedback, getOneFeedback, isFetching])

    const optType = form.watch("questionsType")

    const handleAddOption = () => {
        const AllOptionSelected = option?.every((item) => {
            return item.badge !== "";
        });
        if (AllOptionSelected) {
            setOption([...option, { badge: "", isBadge: false }])
        } else {
            sendErrorMessage("enter something in option ")
        }
    }

    const handleRemoveOption = (i) => {
        const newData = [...option]
        newData.splice(i, 1)
        setOption(newData)
    }

    const handleBadgeChange = (e, i) => {
        const value = e.target.value.trimStart()
        setOption((prev) => {
            return prev.map((elem, index) => {
                if (index === i) {
                    return { ...elem, badge: value }
                } else return { ...elem }
            })
        })
    }

    const handleBadgeApply = (i) => {
        const AllOptionSelected = option?.some((item, index) => {
            if (index === i) return item.badge !== "";
        });

        if (AllOptionSelected) {
            setOption((prev) => {
                return prev.map((elem, index) => {
                    if (index === i) {
                        return { ...elem, isBadge: true }
                    } else return { ...elem }
                })
            })
        } else {
            sendErrorMessage("enter something in option ")
        }
    }

    const handleKeyDown = (e, i) => {
        const { key } = e
        if (key === "Enter" || key === "Tab") {
            handleBadgeApply(i)
        }
    }

    const handleCustomRange = () => {
        setShowDateRangePicker(!showDateRangePicker);
        if (!fromDate?.length) {
            setState([
                {
                    startDate: new Date(),
                    endDate: new Date(),
                    key: "selection",
                },
            ]);
        } else {
            setState(prevDate);
        }
    }

    const handleTimeRangeSelection = async () => {
        const firstSelectionOfStartDate = moment(state[0].startDate).format("yyyy-MM-DD");
        const firstSelectionOfToDate = moment(state[0].endDate).format("yyyy-MM-DD");
        setPrevDate(state);
        setFromDate(firstSelectionOfStartDate);
        setToDate(firstSelectionOfToDate);
        setShowDateRangePicker(false);
    }

    const clearTimeRange = () => {
        setFromDate("");
        setToDate("");
        setShowDateRangePicker(false);
        setState([
            {
                startDate: new Date(),
                endDate: addDays(new Date(), 0),
                key: "selection",
            },
        ]);
    }

    const onSubmit = useCallback(
        async (data) => {
            const type = data.questionsType?.charAt(0)?.toLocaleLowerCase() + data?.questionsType?.slice(1) || ""
            const options = type === "options" ? option.map(e => e?.badge) : undefined
            data.company = data?.company?.map((e) => e?.value)
            data.questionsType = type
            data.metro = data?.metro?.map((e) => e?.value)
            data.options = options

            const params = {
                startDate: fromDate,
                endDate: toDate,
                ...data
            }

            const ids = id
            if (type === "options" && option?.length < 2) {
                sendErrorMessage("create minimum 2 options")
            } else {
                const AllOptionSelected = option?.every((item) => {
                    return item.badge !== "";
                });
                if (AllOptionSelected) {
                    const result = await (id ? updateFeedback({ params, ids }) : addFeedback(params));
                    if (!result.error) {
                        sendSuccessMessage(id ? UPDATED : CREATED);
                        actions.refetch.setIsDataRefetch(true);
                        actions.modal.closeFeedback();
                    }
                } else {
                    sendErrorMessage("enter something in option ")
                }

            }

        },
        [id, fromDate, toDate, option]
    );

    const onCancel = () => {
        actions.modal.closeFeedback()
        setShowDateRangePicker(false);
        form.clearErrors()
    }

    return (
        <>
            <Drawer
                anchor='right'
                open={feedback.open}
                transitionDuration={{ enter: 1000, exit: 1000 }}
            >
                <Box className='feedback_contain' role="presentation" >
                    <div className='drawer_header_wrapper' style={{ width: "900px" }}>
                        <div className='drawer_head_left' style={{ alignItems: "center" }}>
                            <div className="drawer-title">
                                <h2 className="modal__title">
                                    {title ? "Edit Feedback" : "Created Feedback"}
                                </h2>
                            </div>
                        </div>
                        <div className="me-2" onClick={onCancel}>
                            <ClearIcon style={{ fontSize: "24px", color: "#adbfc7", cursor: "pointer" }} />
                        </div>
                    </div>

                    {
                        isFetching ? <div style={{ position: "absolute", top: "50%", left: "50%" }}>
                            <CircularProgress className="loading-progress" />
                        </div> : (
                            <div style={{ height: "100vh", overflow: "auto", padding: "90px 40px" }}>
                                <div className="mt-3 inputData" >
                                    <Controller
                                        control={form.control}
                                        name="question"
                                        render={({
                                            field: { onChange, value, ref },
                                            fieldState: { error },
                                        }) => (
                                            <React.Fragment>
                                                <label>Question:</label>
                                                <textarea
                                                    type="text"
                                                    value={value}
                                                    onChange={(e) => onChange(e.target.value.trimStart())}
                                                    placeholder="Enter Question"
                                                />
                                                <ErrorMessage error={error} />
                                            </React.Fragment>

                                        )}
                                    />
                                </div>

                                <div className="mt-3 inputData w-100" >
                                    <Controller
                                        control={form.control}
                                        name="questionsType"
                                        render={({
                                            field: { onChange, value, ref },
                                            fieldState: { error },
                                        }) => (
                                            <React.Fragment>
                                                <label>Type:</label>
                                                <Autocomplete
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={arrOftype}
                                                    onChange={(e, val) => {
                                                        if (val !== "Options") setOption([])
                                                        onChange(val)
                                                    }}
                                                    value={value || null}
                                                    isOptionEqualToValue={(option, value) => option === value}
                                                    fullWidth
                                                    ref={ref}
                                                    disabled={title}
                                                    renderInput={(params) => <TextField {...params} placeholder="Select Type" />}
                                                />
                                                <ErrorMessage error={error} />
                                            </React.Fragment>
                                        )}
                                    />

                                </div>
                                <div className={`${(optType === "options" || optType === "Options") && "options_wrapper"}`}>
                                    {
                                        (optType === "options" || optType === "Options") && (
                                            <div className='options_detail'>
                                                <div className="option_btn" onClick={handleAddOption}>
                                                    <IoIosAddCircle className='opt_icon' />
                                                    <span>
                                                        Add Option
                                                    </span>
                                                </div>

                                                <div className='d-flex' style={{ gapColumn: "10px", flexWrap: "wrap" }}>
                                                    {
                                                        option?.map((elem, index) => {
                                                            return (
                                                                !elem.isBadge && (
                                                                    <div className='d-flex align-items-center mt-4 mr-2' key={index}>
                                                                        <input className="mr-2"
                                                                            type="text"
                                                                            value={elem.badge}
                                                                            onChange={(e) => handleBadgeChange(e, index)}
                                                                            onKeyDown={(e) => handleKeyDown(e, index)}
                                                                        />
                                                                        <span onClick={() => handleRemoveOption(index)}>
                                                                            <AiFillCloseCircle style={{ fontSize: "20px", color: "red", cursor: "pointer" }} />
                                                                        </span>
                                                                    </div>
                                                                )
                                                            )
                                                        })
                                                    }
                                                </div>

                                                <div className='d-flex' style={{ gap: "10px", flexWrap: "wrap" }}>
                                                    {
                                                        option?.map((elem, index) => {
                                                            return (
                                                                elem.isBadge && (
                                                                    <div className='badge mt-4' key={index}>
                                                                        <span>
                                                                            {elem.badge}
                                                                            <span onClick={() => handleRemoveOption(index)}>
                                                                                <AiFillCloseCircle className="clear_badge" />
                                                                            </span>
                                                                        </span>
                                                                    </div>
                                                                )
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>

                                <div className='accordionDetails mt-4'>
                                    <div>
                                        <label> Date Range: </label>
                                        <DatePicker
                                            handleCustomRange={handleCustomRange}
                                            fromDate={fromDate}
                                            toDate={toDate}
                                            showDateRangePicker={showDateRangePicker}
                                            onChange={(item) => {
                                                setState([item.selection]);
                                            }}
                                            ranges={state}
                                            cancelDatePicker={() => {
                                                setShowDateRangePicker(false);
                                            }}
                                            handleTimeRangeSelection={handleTimeRangeSelection}
                                            clearTimeRange={clearTimeRange}
                                            sx={{ position: 'relative' }}
                                            placeholder="Select Date Range"
                                            className={`daterange ${fromDate && "text-dark"}`}
                                        />
                                    </div>
                                </div>

                                <div className="d-flex" style={{ gap: "20px", marginBottom: "40px" }}>
                                    <div className="inputData mt-4 w-100" style={{ color: "red !important" }} >
                                        <label> Select Metro: </label>
                                        <Controller
                                            control={form.control}
                                            name="metro"
                                            render={({
                                                field: { onChange, value, ref },
                                                fieldState: { error },
                                            }) => (
                                                <React.Fragment>
                                                    <div className="select_multi">
                                                        <MultiSelect
                                                            value={value || null}
                                                            placeholder="Select Metro"
                                                            itemData={metros?.map((item, i) => {
                                                                return {
                                                                    value: item?.name ? item?.name : "-",
                                                                    label: `${item?.name ? item?.name : ""}`,
                                                                };
                                                            })}
                                                            onChange={(value) => {
                                                                onChange(value)
                                                            }}
                                                            styles={multiSelectStyle}
                                                        />
                                                    </div>
                                                </React.Fragment>
                                            )}
                                        />
                                    </div>
                                    <div className="inputData mt-4 w-100" style={{ color: "red !important" }} >
                                        <label> Select Company: </label>
                                        <Controller
                                            control={form.control}
                                            name="company"
                                            render={({
                                                field: { onChange, value, ref },
                                                fieldState: { error },
                                            }) => (
                                                <React.Fragment>
                                                    <div className="select_multi">
                                                        <MultiSelect
                                                            value={value || null}
                                                            placeholder="Select Company"
                                                            itemData={company}
                                                            onChange={(value) => {
                                                                onChange(value)
                                                            }}
                                                            styles={multiSelectStyle}
                                                        />
                                                    </div>
                                                </React.Fragment>
                                            )}
                                        />
                                    </div>
                                </div>

                                <div className="d-flex" style={{ gap: "20px" }}>
                                    <div className='toggle_box w-100' style={{ border: "2px solid #e3eaf3 !important", color: "#a2a2a2" }}>
                                        <Controller
                                            control={form.control}
                                            name="isFirstDriver"
                                            render={({
                                                field: { onChange, value, ref },
                                                fieldState: { error },
                                            }) => (
                                                <React.Fragment>
                                                    <label className='m-0' style={{ fontWeight: "normal", fontSize: "1rem" }}> First Driver </label>
                                                    <FormControlLabel
                                                        control={
                                                            <Android12Switch
                                                                checked={value}
                                                                onChange={(e) => onChange(e.target.checked)}
                                                            />}
                                                        style={{ marginTop: "8px" }}
                                                    />
                                                </React.Fragment>
                                            )}
                                        />
                                    </div>

                                    <div className='toggle_box w-100' style={{ border: "2px solid #e3eaf3 !important" }}>
                                        <Controller
                                            control={form.control}
                                            name="isActive"
                                            render={({
                                                field: { onChange, value, ref },
                                                fieldState: { error },
                                            }) => (
                                                <React.Fragment>
                                                    <label className='m-0' style={{ fontWeight: "normal", fontSize: "1rem" }}> Active </label>
                                                    <FormControlLabel
                                                        control={
                                                            <Android12Switch
                                                                checked={value}
                                                                onChange={(e) => onChange(e.target.checked)}
                                                            />}
                                                        style={{ marginTop: "8px" }}
                                                    />
                                                </React.Fragment>
                                            )}
                                        />
                                    </div>
                                </div>
                                {
                                    id && (
                                        <div style={{ marginTop: "40px" }}>
                                            <FieldSet
                                                title={"Feedback Answer" + (getOneFeedback?.answers?.length ? (getOneFeedback?.answers?.length > 0 && getOneFeedback?.answers?.length < 10) ?
                                                    ` (${"0".concat(getOneFeedback?.answers?.length)})` : ` (${getOneFeedback?.answers?.length})` : "")}
                                                body={
                                                    <FeedbackAnswer
                                                        feedbackReview={feedbackReview}
                                                        setFeedbackReview={setFeedbackReview}
                                                        filterData={getOneFeedback}
                                                    />
                                                }
                                                className="feedback_answer"
                                            />
                                        </div>
                                    )}
                            </div>
                        )
                    }

                    <div className='drawer__footer' style={{ width: "900px", zIndex: "unset" }}>
                        <div>
                            <button
                                className="btn"
                                onClick={onCancel}
                            >
                                Cancel
                            </button>
                        </div>
                        <div>
                            <button className="btn btn_accent" style={{ width: "80px" }} onClick={form.handleSubmit(onSubmit)}>
                                {title ? "Update" : "Save"}
                            </button>
                        </div>
                    </div>
                </Box>
            </Drawer>
        </>
    )
}

export default CustomFeedbackDrawer

const FieldSet = ({ title, body, className, style }) => {
    return (
        <fieldset className={className || ""} style={style || null}>
            <legend>{title}</legend>
            {body || ""}
        </fieldset>
    );
};
