import React, { useEffect, useRef } from "react";
import { DateRangePicker } from "react-date-range";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { FooterComponent } from "../../DateRangePicker";
import "./style.scss";
import { createDate, DATE } from "../../../../constants/variables/dates";
import { useOutsideAlerter } from "../../../../constants/hooks/useOutside";
import { FormItem, Label } from "../../Form";
const { M_D_Y } = DATE;

const RouteDateRange = (props) => {
  const {
    state,
    handleCloseDtPickr,
    form: { setValue },
    title,
    formTitle,
    showDateRangePicker,
    handleChangeDtRange,
    handleCustomRange,
    className,
  } = props || {};
  const { startDate, endDate } = state[0] || {};
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, handleCloseDtPickr);

  useEffect(() => {
    const startDt = formTitle ? formTitle.fromDate : "dayDeliveryStart";
    const endDt = formTitle ? formTitle.toDate : "dayDeliveryStartTo";
    setValue(startDt, createDate(startDate));
    setValue(endDt, createDate(endDate));
  }, [setValue, startDate, formTitle, endDate]);

  return (
    <div className="form__col form__col_height-flex">
      <Label htmlFor="date-picker" title={title || `Route Date`} />
      <div ref={wrapperRef}>
        <FormItem>
          <div
            onClick={handleCustomRange}
            className="form__input d-flex justify-content-between"
          >
            <span>{createDtRangeTxt(startDate, endDate)}</span>
            <span>{handleUpDownIcon(showDateRangePicker)}</span>
          </div>
          <>
            {props.showDateRangePicker && (
              <div className="dt__rng__pickr__cont">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateRangePicker
                    onChange={(item) => handleChangeDtRange([item?.selection])}
                    ranges={state}
                    className={`date__range__picker ${className || ""}`}
                    footerContent={
                      <FooterComponent {...FooterProps(handleCloseDtPickr)} />
                    }
                  />
                </LocalizationProvider>
              </div>
            )}
          </>
        </FormItem>
      </div>
    </div>
  );
};

export default RouteDateRange;

const FooterProps = (handleCloseDtPickr) => ({
  handleCloseDtPickr,
});

export const handleUpDownIcon = (isOpen) => {
  return isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />;
};

export const createDtRangeTxt = (strtDt, endDt) => {
  return `${createDate(strtDt, M_D_Y)} TO ${createDate(endDt, M_D_Y)}`;
};
