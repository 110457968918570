import moment from "moment";
import { convertToCapitalize, removeDash } from "../extras/convertToCapitalize";
import { handleNumberwithPadding } from "../extras/handleNumbers";
import { scheduleTime, weekdays } from "../variables/arrays";
const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
const utc = moment().format().slice(19);

export const headers = [
  { label: "Created Date", key: "created" },
  { label: "Name", key: "name" },
  { label: "Username", key: "username" },
  { label: "Company", key: "company" },
  { label: "Phone", key: "phone" },
  { label: "Metro", key: "metro" },
  { label: "Shifts", key: "orderCount" },
  { label: "Shift Last Date", key: "shiftLastDate" },
  { label: "Score", key: "score" },
  { label: "Status", key: "status" },
  { label: "OnboardingAt", key: "onboardingAt" },
  { label: "Days", key: "availableDays" },
  { label: "Time", key: "availableTime" },
  { label: "Time Amount", key: "timeAmount" },
  { label: "Notes", key: "internalNote" },
];

export const inbuildHeaders = [
  { label: "Tax Fill Status", key: "onboardingTaxFillStatus" },
  { label: "Insurance Fill Status", key: "onboardingInsuranceFillStatus" },
  { label: "Background Fill Status", key: "onboardingBackgroundFillStatus" },
  { label: "Deposit Fill Status", key: "onboardingDepositFillStatus" },
  { label: "Licence Fill Status", key: "onboardingLicenceFillStatus" },
  { label: "Onboarding Status", key: "onboardingStatus" },
  { label: "First interestedAt", key: "firstInterestedShift" },
  { label: "First assignedAt", key: "firstAssignedShift" },
  { label: "ApprovedAt", key: "approvedAt" },
  { label: "OnBoarding Completion Date", key: "onboardingAt" },
  { label: "OffBoardedAt", key: "offboardedAt" },
  { label: "OffBoardedBy", key: "offboardedBy" },
  { label: "Referral Name", key: "referrerName" },
  { label: "Referral Phone", key: "referrerPhone" },
  { label: "Last loginAt", key: "lastLoginTime" },
  { label: "License Expired", key: "licenceExpiredDays" },
  { label: "Insurance Expired", key: "insuranceExpiredDays" },
]

export const header = [
  // ...headers,
  { label: "Checker Id", key: "candidateId" },
  { label: "Checker Status", key: "checkrBackgroundStatus" },
  { label: "shiftStatus", key: "shiftStatus" },
  { label: "Coached number", key: "coachedNumber" },
  { label: "Last coach", key: "lastCoached" },
  { label: "Number of coaches", key: "maxNumberOfCoaches" }
];

export const checkerHeader = [
  // ...headers,
  { label: "Checker Id", key: "candidateId" },
  { label: "Checker Status", key: "checkrBackgroundStatus" },
];

export const shiftStatusHeader = [
  // ...headers,
  { label: "shiftStatus", key: "shiftStatus" },
];

export const checkerAndShiftHeader = [
  { label: "Checker Id", key: "candidateId" },
  { label: "Checker Status", key: "checkrBackgroundStatus" },
  { label: "shiftStatus", key: "shiftStatus" }
];

export const checkerAndCoachingHeader = [
  { label: "Checker Id", key: "candidateId" },
  { label: "Checker Status", key: "checkrBackgroundStatus" },
  { label: "Coached number", key: "coachedNumber" },
  { label: "Last coach", key: "lastCoached" },
  { label: "Number of coaches", key: "maxNumberOfCoaches" }
];

export const shiftStatusAndCoachingHeader = [
  { label: "shiftStatus", key: "shiftStatus" },
  { label: "Coached number", key: "coachedNumber" },
  { label: "Last coach", key: "lastCoached" },
  { label: "Number of coaches", key: "maxNumberOfCoaches" }
];

export const coachingHeader = [
  { label: "Coached number", key: "coachedNumber" },
  { label: "Last coach", key: "lastCoached" },
  { label: "Number of coaches", key: "maxNumberOfCoaches" }
];

const handleData = (data) => {
  return `${data ? convertToCapitalize(data) : "-"}`;
};

const handleDate = (date) => {
  return `${date ? moment(date).format("MMM D, YYYY hh:mm A") : "-"}`;
};

export function driversData(filteredDriversData) {
  const driversData = [];

  if (filteredDriversData && filteredDriversData.length) {
    for (let i = 0; i < filteredDriversData.length; i++) {
      const data = filteredDriversData[i];

      const {
        created,
        firstName,
        lastName,
        username,
        phone,
        metro,
        orderCount,
        shiftLastDate,
        status,
        isBlocked,
        onboardingBackgroundFillStatus,
        onboardingDepositFillStatus,
        onboardingInsuranceFillStatus,
        onboardingTaxFillStatus,
        onboardingLicenceFillStatus,
        firstAssignedShift,
        firstInterestedShift,
        score,
        lastLoginTime,
        onboardingAt,
        approvedAt,
        offboardedAt,
        offboardedBy,
        referrerName,
        referrerPhone,
        internalNote,
        rejectShiftCount,
        assigned,
        delivery_started,
        complete,
        waiting_for_approval,
        paid,
        cancelled,
        checkrBackgroundStatus,
        candidateId,
        company,
        availableDays,
        availableTime,
        timeAmount,
        coachedNumber,
        lastCoached,
        maxNumberOfCoaches,
        maxNumberOfCoachesCounts,
        licenceExpiredDays,
        insuranceExpiredDays,
      } = data || {};

      let count = 0;
      const arr = [
        onboardingBackgroundFillStatus,
        onboardingDepositFillStatus,
        onboardingInsuranceFillStatus,
        onboardingTaxFillStatus,
        onboardingLicenceFillStatus,
      ];

      for (const status of arr) {
        if (["completed", "complete"].includes(status)) count++;
      }

      const dayData = weekdays?.filter((el, index) => availableDays?.some((item) => item === index))
      const timeData = scheduleTime?.filter((el, index) => availableTime?.some((item) => item === index))

      driversData[i] = {
        created: handleDate(created),
        name: `${firstName ? firstName : "-"} ${lastName ? lastName : ""}`,
        username: ` ${username ? username : "-"}`,
        company: `${company?.companyCode ? company?.companyCode : ""} ${company?.companyName && company?.companyCode ? "-" : ""} ${company?.companyName ? company?.companyName : ""}`,
        phone: ` ${phone ? phone : "-"}`,
        metro: `${metro ? metro : "-"}`,
        orderCount: `${orderCount ? handleNumberwithPadding(orderCount) : "-"}`,
        shiftLastDate: `${shiftLastDate ? moment(shiftLastDate).utc().format('MM/DD/YYYY') : "-"}`,
        onboardingBackgroundFillStatus: handleData(
          onboardingBackgroundFillStatus
        ),
        onboardingDepositFillStatus: handleData(onboardingDepositFillStatus),
        onboardingInsuranceFillStatus: handleData(
          onboardingInsuranceFillStatus
        ),
        onboardingTaxFillStatus: handleData(onboardingTaxFillStatus),
        onboardingLicenceFillStatus: handleData(onboardingLicenceFillStatus),

        onboardingStatus: count === 5 ? "Completed" : "-",

        firstInterestedShift: handleDate(firstInterestedShift),
        firstAssignedShift: handleDate(firstAssignedShift),
        score: `${score ? score : "-"}`,
        status: `${status
          ? convertToCapitalize(removeDash(isBlocked ? "blocked" : status))
          : "-"
          }`,
        lastLoginTime: handleDate(lastLoginTime),
        onboardingAt: handleDate(onboardingAt),
        availableDays: dayData?.length ? dayData : "-",
        availableTime: timeData?.length ? timeData : "-",
        timeAmount: timeAmount?.title ? timeAmount?.title : "-",
        approvedAt: handleDate(approvedAt),
        offboardedAt: handleDate(offboardedAt),
        offboardedBy: `${offboardedBy?.username || ""}`.trim(),
        referrerName: handleData(referrerName),
        referrerPhone: handleData(referrerPhone),
        internalNote: handleData(internalNote),
        candidateId: `${candidateId ? candidateId : "-"}`,
        coachedNumber: `${coachedNumber ? coachedNumber : "-"}`,
        lastCoached: `${lastCoached ? moment(lastCoached).format("MM/DD/YYYY") : "-"}`,
        maxNumberOfCoaches: `${maxNumberOfCoaches ? `${maxNumberOfCoaches} (${maxNumberOfCoachesCounts})` : "-"}`,
        checkrBackgroundStatus: `${checkrBackgroundStatus ? checkrBackgroundStatus : "-"}`,
        shiftStatus: `${assigned && "Assigned : " + assigned + ', ' || ''}${delivery_started && "Delivery Started : " + delivery_started + ', ' || ''}${complete && "Complete : " + complete + ', ' || ''}${waiting_for_approval && "Approval : " + waiting_for_approval + ', ' || ''}${paid && "Paid : " + paid + ', ' || ''}${cancelled && "Cancelled : " + cancelled + ', ' || ''}${rejectShiftCount && "Rejected : " + rejectShiftCount + ', ' || ''}`,
        licenceExpiredDays: status === "approved" && licenceExpiredDays ? `${licenceExpiredDays >= 0 ? "expires in " + licenceExpiredDays + ' days' : "expired " + Math.abs(licenceExpiredDays) + " days ago"}` : "-",
        insuranceExpiredDays: status === "approved" && insuranceExpiredDays ? `${insuranceExpiredDays >= 0 ? "expires in " + insuranceExpiredDays + ' days' : "expired " + Math.abs(insuranceExpiredDays) + " days ago"}` : "-",
      };
    }
  }
  driversData.unshift({ created: `This report based on "${timeZone}  UTC ${utc}" Timezone ` })
  return driversData;
}
