import "./style.scss";

const HeaderLayout = ({ children, width, className }) => {
  return (
    <td
      className={`th__layout ${className ? className : ""}`}
      style={{ width: width ? width : "auto" }}
    >
      {children}
    </td>
  );
};

export default HeaderLayout;
