import ConfirmDialog from "../shared/customModal/confirmDialog";

const SwitchRoleConfirmation = ({switchRole, setSwitchRole, handleSwitchRole, name, title}) => {
    return (
        <>
            {switchRole[0] && <ConfirmDialog
            title={title}
            bodyText={`Are you sure you want to switch role to ${name}?`}
            isOpen
            onClose={() => {
                setSwitchRole([false, ""]);
            }}
            confirmAction={() => {
                handleSwitchRole([switchRole[1]])
            }}
            />}
        </>
    )
}

export default SwitchRoleConfirmation