import React, { useCallback, useEffect, useState } from "react";
import { Modal, CircularProgress } from "@mui/material";
import { useForm } from "react-hook-form";
import cn from "classnames";

import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { ErrorMessage, InputItem } from "../Form";
import {
  useDeleteDriverMutation,
  useDriverInactiveMutation,
  useRejectDriverMutation,
  useReviewMutation,
} from "../../../api/drivers";
import { actions } from "../../../redux/store/store";
import { sendSuccessMessage } from "../../../constants/extras/sendSuccessMsg";
import "./style.scss";
import { checkForError, sendErrorMessage } from "../../../constants/extras/errorHandlers";
import { useSelector } from "react-redux";
import {
  useChangeStatusMutation,
  useSwapShiftMutation,
} from "../../../api/orders";

const requiredMessage = "This field is required";
const rejectDriverValidation = Yup.object().shape({
  reason: Yup.string().required(requiredMessage),
});

const splitRoutesValidation = Yup.object().shape({
  internalNote: Yup.string().required(requiredMessage),
});

const swapShiftValidation = Yup.object().shape({
  routeCode: Yup.string().required(requiredMessage),
});
const offboardDriverValidation = Yup.object().shape({
  routeCode: Yup.string().required(requiredMessage),
});
const inactivateDriverValidation = Yup.object().shape({
  routeCode: Yup.string().required(requiredMessage),
});

const BtReviewValidation = Yup.object().shape({
  remark: Yup.string().trim().required(requiredMessage),
});

const WARNING_MSG = "Do you want to close the dialog? Changes will be lost.";

const ReasonModal = (props) => {
  const { id: dataObj } = props;
  const { obj } = dataObj;
  const { _id: id } = obj;

  const { modal, refetch } = actions;

  const data = useSelector((state) => state.modal?.reason?.id);
  const refreshData = () => refetch.setIsDataRefetch(true);
  const [rejectDriver, rejectDriverReq] = useRejectDriverMutation();
  const [changeStatus, changeStatusReq] = useChangeStatusMutation();
  const [swapShift, swapShiftReq] = useSwapShiftMutation();
  const [offBoardDriver, deleteDriverReq] = useDeleteDriverMutation();
  const [inactiveDriver, inactiveDriverReq] = useDriverInactiveMutation();
  const [Review, reviewReq] = useReviewMutation();
  const [str, setStr] = useState("");
  const [isError, setIsError] = useState([false, ""]);

  const rejectReasonHandlear = async (rejectedReason) => {
    const result = await rejectDriver({ _id: id, reason: rejectedReason });
    if (result?.data?.status === "rejected") {
      sendSuccessMessage("Driver rejected successfully");
      refreshData();
    } else checkForError(result?.error || result?.data?.error || result);

    modal.closeReason();
  };

  /***  API for Swapping shift payout ***/
  const handleSwapingShift = async (routeCode) => {
    setIsError([false, ""]);
    const result = await swapShift({
      _id: id,
      routeCode: routeCode,
    });
    form.reset();
    if (result?.data && result?.data?.status === 200 && !result?.error) {
      sendSuccessMessage("Shift swapped successfully");
      modal.closeReason();
      refreshData();
    } else setIsError([true, result?.data?.message || "something went wrong"]);
  };

  const handleSplitRouting = async (internalNote) => {
    const order = data?.obj && data?.obj;
    const body = {
      _id: order?._id,
      driverId: order?.driver._id,
      splitRouting: true,
      internalNote: internalNote,
      status: "complete",
    };
    const result = await changeStatus(body);
    if (result?.data?.status === "complete") {
      sendSuccessMessage("Route split successfully");
      refreshData();
    } else checkForError(result?.error || result?.data?.error || result);

    modal.closeReason();
  };

  const handleNewTypeOfStatus = (result, status) => {
    if (
      result?.data?.result?.status &&
      result?.data?.result?.status === status &&
      result?.data?.status === 200
    ) {
      sendSuccessMessage(
        `Driver ${status === "onboard" ? "onboarded" : status} successfully`
      );
      refreshData();
    } else checkForError(result);
  };

  const handleInactivatingDriver = async (inactivatedReason) => {
    const result = await inactiveDriver({
      _id: id,
      inactivatedReason: inactivatedReason,
    });
    handleNewTypeOfStatus(result, "inactive");
    modal.closeReason();
  };

  const handleOffboardingDriver = async (offboardedReason) => {
    const result = await offBoardDriver({
      _id: id,
      offboardedReason: offboardedReason,
    });
    handleNewTypeOfStatus(result, "offboarded");
    modal.closeReason();
  };

  const handleBtReview = async (remark) => {
    const result = await Review({
      userId: id,
      remark: remark
    })
    if (!result?.data?.error && result?.data) {
      sendSuccessMessage("BT Review Add successfully");
      modal.closeReason();
    } else if (result?.data?.error) {
      if (result?.data?.error) {
        sendErrorMessage(result?.data?.message)
      }
    }
  };

  const handleUI = (functionality) => {
    let obj;
    switch (functionality) {
      case "SPLIT":
        obj = {
          title: "Write a note for splitting route",
          fieldObj: { internalNote: "" },
          validationSchema: splitRoutesValidation,
          UITitle: "Internal Note",
          formName: "internalNote",
          btnText: "Split",
          loading: changeStatusReq.isLoading,
        };
        return obj;
      case "REJECT__DRIVER":
        obj = {
          title: "Reason Profile",
          fieldObj: { reason: "" },
          validationSchema: rejectDriverValidation,
          UITitle: "Reason",
          formName: "reason",
          btnClass: "btn_reject",
          btnText: "Reject",
          loading: rejectDriverReq.isLoading,
        };
        return obj;
      case "SWAP__SHIFT":
        obj = {
          title: "Enter route code for swapping",
          fieldObj: { routeCode: "" },
          validationSchema: swapShiftValidation,
          formName: "routeCode",
          btnText: "Swap",
          loading: swapShiftReq.isLoading,
        };
        return obj;
      case "OFF__BOARD__DRIVER":
        obj = {
          title: "Enter a reason for offboarding driver",
          fieldObj: { offboardedReason: "" },
          validationSchema: offboardDriverValidation,
          formName: "offboardedReason",
          btnClass: "btn_reject",
          btnText: "Offboard",
          loading: deleteDriverReq.isLoading,
        };
        return obj;
      case "INACTIVE__DRIVER":
        obj = {
          title: "Enter a reason for inactivating driver",
          fieldObj: { inactivatedReason: "" },
          validationSchema: inactivateDriverValidation,
          formName: "inactivatedReason",
          btnClass: "btn_reject",
          btnText: "Inactivate",
          loading: inactiveDriverReq.isLoading,
        };
        return obj;
      case "BT__REVIEW":
        obj = {
          UITitle: "Remark",
          title: "BT Review",
          fieldObj: { remark: "" },
          validationSchema: BtReviewValidation,
          formName: "remark",
          btnText: "OK",
          loading: reviewReq.isLoading,
        };
        return obj;
      default:
        break;
    }
  };

  const uiData = handleUI(data?.title);
  let {
    title,
    fieldObj,
    validationSchema,
    UITitle,
    formName,
    btnClass,
    btnText,
    loading,
  } = uiData;

  const form = useForm({
    defaultValues: fieldObj,
    resolver: yupResolver(validationSchema),
  });

  const { getValues, watch, formState, handleSubmit } = form;

  const handleSubmitForm = (title) => {
    switch (title) {
      case "SPLIT":
        return handleSplitRouting(getValues(formName));
      case "REJECT__DRIVER":
        return rejectReasonHandlear(getValues(formName));
      case "SWAP__SHIFT":
        return handleSwapingShift(getValues(formName));
      case "OFF__BOARD__DRIVER":
        return handleOffboardingDriver(getValues(formName));
      case "INACTIVE__DRIVER":
        return handleInactivatingDriver(getValues(formName));
      case "BT__REVIEW":
        return handleBtReview(getValues(formName));
      default:
        break;
    }
  };

  useEffect(() => {
    const subscription = watch((val) =>
      setStr(!!val[formName]?.trim() ? val[formName] : "")
    );
    return () => subscription.unsubscribe();
  }, [formName, watch]);

  const onCancel = useCallback(() => {
    const { isDirty } = formState;
    if (!isDirty || window.confirm(WARNING_MSG)) actions.modal.closeReason();
  }, [formState]);

  return (
    <Modal
      open
      onClose={onCancel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="modal" style={{ height: "auto" }}>
        <div className="modal__header">
          <h2 className="modal__title">{title}</h2>
        </div>
        <div className="modal__body">
          <form>
            <InputItem
              title={`${UITitle || ""}`}
              name={`${formName}`}
              form={form}
            />
            {isError[0] && (
              <ErrorMessage
                error={{ message: isError[1]?.toString() }}
                className="margin__negative bounce"
              />
            )}
          </form>
        </div>
        <div className="modal__footer">
          <button className="btn" onClick={() => modal.closeReason()}>
            Cancel
          </button>
          <div style={{ position: 'relative' }}>
            <button
              className={cn(`btn ${btnClass || "btn_accent"}`, {
                btn_disabled: loading || str?.length === 0,
              })}
              onClick={() => {
                handleSubmit(handleSubmitForm(data?.title));
              }}
            >
              {btnText}
              {loading && <div style={{ width: '25px' }}></div>}
            </button>
            {
              loading &&
              <CircularProgress size={18}
                sx={{
                  color: `${btnClass ? '#f61818' : '#fff'}`,
                  position: 'absolute',
                  top: '50%',
                  left: '83%',
                  marginTop: '-8px',
                  marginLeft: '-10px',
                }} />
            }
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ReasonModal;
