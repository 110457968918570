import React, { useCallback, useState } from "react";
import { CircularProgress, Modal } from "@mui/material";

import { actions } from "../../../redux/store/store";
import { checkForError } from "../../../constants/extras/errorHandlers";
import { useUpdateDriverMutation } from "../../../api/drivers";
import { sendSuccessMessage } from "../../../constants/extras/sendSuccessMsg";
import ReactInputMask from "react-input-mask";


const PhoneModal = (props) => {
    const title = props?.id?.title
    const driverId = props && props?.id?.id && props?.id?.id;

    const notes = props && props?.id?.notes && props?.id?.notes;
    const [updateDriverMetro, { isLoading: isUpdatingDriver }] =
        useUpdateDriverMutation();
    const [phoneNumber, setPhoneNumber] = useState(notes || "");

    const onNotesSubmit = async () => {
            let params = {
                phone: phoneNumber,
                _id: driverId,
            }
            
            let result = await updateDriverMetro(params);
            if (!result?.error) {
                sendSuccessMessage(`${title} updated successfully`);
                actions.refetch.setIsDataRefetch(true);
            } else {
                checkForError(result);
            }
            actions.modal.closePhone();
        }

    const onCancel = useCallback(() => {
        
            actions.modal.closePhone();
        
    }, []);

    function allowNumbersOnly(e) {
        var code = (e.which) ? e.which : e.keyCode;
        if (code > 31 && (code < 48 || code > 57)) {
            e.preventDefault();
        }
    }

    return (
        <Modal
            open
            onClose={onCancel}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className={`modal after-loading`} style={{ maxWidth: "35.875rem" }}>
                <div className="modal__header">
                    <h2 className="modal__title">{`Update ${title}`}</h2>
                </div>
                <div className="modal__body"> 
                <span>Phone</span>
                    <ReactInputMask
                        mask="+1 (999) 999-9999"
                        value={phoneNumber}
                        className="form-control"
                        onChange={(e) => setPhoneNumber(e?.target?.value)}
                    />
                </div>
                <div className="modal__footer">
                    <button className="btn" onClick={() => actions.modal.closePhone()}>
                        Cancel
                    </button>
                    <div style={{ position: 'relative' }}>
                        <button
                            className="btn btn_accent"
                            disabled={isUpdatingDriver || notes === phoneNumber}
                            onClick={onNotesSubmit}
                        >
                            OK
                        </button>
                        {isUpdatingDriver && (
                            <CircularProgress size={18}
                            sx={{
                                color: '#fff',
                                position: 'absolute',
                                top: '50%',
                                left: '83%',
                                marginTop: '-8px',
                                marginLeft: '-10px',
                            }} />
                        )}
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default PhoneModal;
