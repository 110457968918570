import moment from "moment";
import React from "react";

export const Times = [
  {
    title: "Early Morning",
    range: "(4AM - 8AM)",
    checked: false,
  },
  {
    title: "Morning",
    range: "(8AM - 12PM)",
    checked: false,
  },
  {
    title: "Afternoon",
    range: "(12PM - 4PM)",
    checked: false,
  },
  {
    title: "Evening ",
    range: "(4PM - 8PM)",
    checked: false,
  },
  {
    title: "Late Evening",
    range: "(8PM - 12AM)",
    checked: false,
  },
];

export const TimeRanges = [
  { from: "4AM", to: "8AM" },
  { from: "8AM", to: "12PM" },
  { from: "12PM", to: "4PM" },
  { from: "4PM", to: "8PM" },
  { from: "8PM", to: "12AM" },
];

const pad = (s) => `0${s}`.slice(-2);

export const travelTimeTransform = {
  toDisplay: (hours) =>
    `${pad(Math.floor((hours || 0) / 60))}:${pad((hours || 0) % 60)}`,
  toValue: (value) => {
    const [travelHours, travelMinutes] = value.split(":");
    return Number(travelHours) * 60 + Number(travelMinutes);
  },
};

export const timeTransform_hm = {
  toDisplay: (time) => {
    return `${pad(Math.floor(((time || 0) / 60) % 24))}h ${pad(
      (time || 0) % 60
    )}m`;
  },
  toValue: (value) => {
    const [travelHours, travelMinutes] = value.split(/[h] |m/);
    return Number(travelHours) * 60 + Number(travelMinutes);
  },
};

export const getRoute = (days) => {
  const newDays = timeTransform_hm?.toDisplay(days);
  return convertTimeIntoAmPMFormat(newDays);
};

const convertTimeIntoAmPMFormat = (time) => {
  const splitTime = time?.split(" ");
  const timeFormat = moment(
    splitTime[0]?.concat(":").concat(splitTime[1]),
    "H:mm"
  ).format("LT");
  const timeWithoutPadded = timeFormat?.split(":");
  let paddedHrs =
    +timeWithoutPadded[0] <= 9
      ? "0".concat(timeWithoutPadded[0])
      : timeWithoutPadded[0];
  return paddedHrs.concat(":").concat(timeWithoutPadded[1]);
};

export const timeTransform_h = {
  toDisplay: (time) => {
    return `${pad(time || 0)}`;
  },
  toValue: (value) => {
    return Number(value);
  },
};

//Temporarily remained for testing and debug, need remove further
export const getTimes = (val) => {
  return val.reduce((sum, item, index, array) => {
    sum.push(
      <div key={index} className="table_orders__loading-time-range">
        {Times[item].range.slice(1, -1).toLocaleLowerCase()}
        {index < array.length ? ", " : ""}
      </div>
    );
    return sum;
  }, []);
};

export const getTimesAndNormalize = (num) => {
  let prev_num;
  let result_array = [];

  num.forEach((item, index) => {
    if (item === prev_num + 1) {
      let str = result_array[result_array.length - 1] + " "; //concatenation with white space is need for avoid strange exception while split method
      let time1 = str.split(" ")[0];
      let time2 = Times[item].range.slice(1, -1).split(" ")[2];
      result_array[result_array.length - 1] = time1 + " - " + time2;
    } else {
      result_array.push(Times[item].range.slice(1, -1));
    }
    prev_num = item;
  });

  return result_array.reduce((sum, item, index, array) => {
    sum.push(
      <div key={index} className="table_orders__loading-time-range">
        {item.toLocaleLowerCase()}
        {index < array.length - 1 ? ", " : ""}
      </div>
    );
    return sum;
  }, []);
};
