import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const EmailsList = (props) => {
  const data = props && props.data && props.data;
  const allDrivers = props && props.allDrivers && props.allDrivers;

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className="mb-3">
      <label style={{ marginBottom: "-0.8rem" }}>Emails</label>
      {data &&
      data?.emails &&
      Array.isArray(data?.emails) &&
      data?.emails?.length > 0 &&
      data.emails?.length === allDrivers?.length ? (
        <div className="driver__badge__container">All Drivers</div>
      ) : (
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange(
            Array.isArray(data?.emails) &&
              data?.emails &&
              data?.emails?.length > 3
              ? "panel1"
              : ""
          )}
          sx={{
            width: "100%",
            border: "1px solid #dadada",
          }}
        >
          <AccordionSummary
            expandIcon={
              Array.isArray(data?.emails) &&
              data?.emails &&
              data?.emails?.length > 3 && <ExpandMoreIcon />
            }
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                gap: "0.5rem",
              }}
            >
              {data?.emails?.map((email, idx) => {
                return (
                  idx < 3 && (
                    <p key={idx} className="driver__badge__container">
                      {email}
                    </p>
                  )
                );
              })}
            </div>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              height: "20vh",
              overflow: "auto",
            }}
            className="selected__drivers"
          >
            {data?.emails?.map((email, idx) => {
              return (
                idx > 2 && (
                  <p key={idx} className="driver__badge__container">
                    {email}
                  </p>
                )
              );
            })}
          </AccordionDetails>
        </Accordion>
      )}
    </div>
  );
};

export default EmailsList;
