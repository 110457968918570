import React from 'react'

const LogoWithText = (props) => {
  return (
    <svg stroke="currentColor"
         strokeWidth="0"
         viewBox="0 0 2514.01 999.26"
         className={props.className}
         height="1em"
         width="1em"
         xmlns="http://www.w3.org/2000/svg">
      <g id="Layer_4">
        <path fill="#4195F7" d="M1196.56,168.1c53.81,0,107.63,0,161.44,0c-53.81,94.6-107.63,189.2-161.44,283.8
		c27.23,49.51,54.46,99.02,81.69,148.53c-54.46-0.17-108.93-0.35-163.39-0.52c-28.82-47.88-57.63-95.77-86.45-143.65
		C1084.46,360.21,1140.51,264.15,1196.56,168.1z"/>
        <path fill="#4195F7" d="M944.51,600.05c21.19-35.93,42.38-71.85,63.57-107.78c19.68,36.05,39.36,72.11,59.04,108.16
		C1026.25,600.3,985.38,600.18,944.51,600.05z"/>
        <path fill="#969EAB" d="M1278.5,310.23c54.81,0.35,109.63,0.71,164.45,1.06c27.23,48.1,54.46,96.19,81.69,144.29
		c-27.23,48.27-54.46,96.55-81.69,144.82c-54.59,0.01-109.17,0.03-163.76,0.04c25.85-48.29,51.71-96.57,77.56-144.86
		C1330.67,407.12,1304.58,358.68,1278.5,310.23z"/>
        <g>
          <rect x="-34.05" y="707.15" fill="none" width="2551.1" height="492.63"/>
          <path fill="#3E4F68" d="M263.43,866.5V715.77h67c20.54,0,36.33,3.08,47.36,9.25c11.04,6.17,16.56,14.86,16.56,26.07
			c0,8.13-3.36,15.24-10.08,21.34c-6.72,6.1-15.3,10.34-25.74,12.72v0.42c13.09,1.33,23.55,5.29,31.38,11.88
			c7.83,6.59,11.75,14.61,11.75,24.07c0,13.81-6.03,24.75-18.1,32.85s-28.54,12.14-49.42,12.14H263.43z M304.89,740.79v35.74h18.23
			c8.56,0,15.29-1.7,20.22-5.1c4.92-3.4,7.38-8.08,7.38-14.03c0-11.07-10.1-16.61-30.29-16.61H304.89z M304.89,801.75v39.73h22.46
			c9.58,0,17.09-1.82,22.53-5.47c5.43-3.64,8.15-8.62,8.15-14.93c0-6.03-2.67-10.76-8.02-14.19c-5.35-3.43-12.82-5.15-22.4-5.15
			H304.89z"/>
          <path fill="#3E4F68" d="M542.35,866.5H431.96V715.77h106.15v27.64h-64.69v33.53h60.2v27.54h-60.2v34.48h68.93V866.5z"/>
          <path fill="#3E4F68" d="M701.13,743.42h-52.5V866.5h-41.59V743.42H554.8v-27.64h146.33V743.42z"/>
          <path fill="#3E4F68" d="M855.15,743.42h-52.5V866.5h-41.59V743.42h-52.24v-27.64h146.33V743.42z"/>
          <path fill="#3E4F68" d="M990.31,866.5H879.93V715.77h106.15v27.64h-64.69v33.53h60.2v27.54h-60.2v34.48h68.93V866.5z"/>
          <path fill="#3E4F68" d="M1174.25,866.5h-47.62L1098,827.72c-2.14-2.94-4.19-5.57-6.16-7.88c-1.97-2.31-3.96-4.29-5.97-5.94
			c-2.01-1.65-4.11-2.91-6.29-3.78c-2.18-0.88-4.56-1.31-7.12-1.31h-11.17v57.71h-41.46V715.77h65.72c44.67,0,67,13.67,67,40.99
			c0,5.26-0.99,10.11-2.95,14.56c-1.97,4.45-4.75,8.46-8.34,12.04c-3.59,3.57-7.94,6.66-13.03,9.25c-5.09,2.59-10.76,4.63-17.01,6.1
			v0.42c2.74,0.7,5.39,1.84,7.96,3.42c2.57,1.58,5.05,3.42,7.45,5.52c2.39,2.1,4.68,4.36,6.87,6.78c2.18,2.42,4.17,4.78,5.97,7.1
			L1174.25,866.5z M1061.29,741.21v41.94h17.97c8.9,0,16.04-2.1,21.43-6.31c5.48-4.27,8.21-9.56,8.21-15.87
			c0-13.17-9.63-19.76-28.88-19.76H1061.29z"/>
          <path fill="#3E4F68" d="M1393.22,743.42h-52.5V866.5h-41.59V743.42h-52.24v-27.64h146.33V743.42z"/>
          <path fill="#3E4F68" d="M1572.41,866.5h-47.62l-28.62-38.79c-2.14-2.94-4.19-5.57-6.16-7.88c-1.97-2.31-3.96-4.29-5.97-5.94
			c-2.01-1.65-4.11-2.91-6.29-3.78c-2.18-0.88-4.56-1.31-7.12-1.31h-11.17v57.71h-41.46V715.77h65.72c44.67,0,67,13.67,67,40.99
			c0,5.26-0.99,10.11-2.95,14.56c-1.97,4.45-4.75,8.46-8.34,12.04c-3.59,3.57-7.94,6.66-13.03,9.25c-5.09,2.59-10.76,4.63-17.01,6.1
			v0.42c2.74,0.7,5.39,1.84,7.96,3.42c2.57,1.58,5.05,3.42,7.45,5.52c2.39,2.1,4.68,4.36,6.87,6.78c2.18,2.42,4.17,4.78,5.97,7.1
			L1572.41,866.5z M1459.45,741.21v41.94h17.97c8.9,0,16.04-2.1,21.43-6.31c5.48-4.27,8.21-9.56,8.21-15.87
			c0-13.17-9.63-19.76-28.88-19.76H1459.45z"/>
          <path fill="#3E4F68" d="M1739.65,801.23c0,45.27-25.76,67.9-77.27,67.9c-49.97,0-74.96-22.11-74.96-66.33v-87.03h41.59v87.45
			c0,24.39,11.64,36.58,34.91,36.58c22.85,0,34.27-11.77,34.27-35.32v-88.71h41.46V801.23z"/>
          <path fill="#3E4F68" d="M1911.65,861.14c-13.44,5.33-30.98,7.99-52.63,7.99c-28.24,0-50.44-6.8-66.62-20.39
			c-16.17-13.59-24.26-31.71-24.26-54.34c0-24.1,9.09-43.66,27.28-58.65c18.18-15,41.78-22.49,70.79-22.49
			c17.97,0,33.12,1.86,45.44,5.57v32.69c-12.32-6.03-26.36-9.04-42.1-9.04c-17.29,0-31.23,4.45-41.84,13.35
			c-10.61,8.9-15.92,20.95-15.92,36.16c0,14.58,5.01,26.19,15.02,34.84c10.01,8.66,23.49,12.98,40.43,12.98
			c16.17,0,30.98-3.22,44.41-9.67V861.14z"/>
          <path fill="#3E4F68" d="M2096.74,866.5h-52.37l-53.65-65.49c-1.03-1.26-2.65-4.03-4.88-8.3h-0.64v73.79h-41.46V715.77h41.46v71.27
			h0.64c1.03-1.96,2.74-4.76,5.13-8.41l50.83-62.86h49.42l-64.18,71.9L2096.74,866.5z"/>
          <path fill="#3E4F68" d="M2105.85,860.72v-33.64c7.44,5.12,15.53,8.95,24.26,11.51c8.73,2.56,17.54,3.84,26.44,3.84
			c5.22,0,9.78-0.38,13.67-1.16c3.89-0.77,7.14-1.84,9.75-3.21c2.61-1.37,4.56-2.98,5.84-4.83c1.28-1.86,1.92-3.87,1.92-6.04
			c0-2.94-1.03-5.57-3.08-7.88s-4.86-4.45-8.41-6.41c-3.55-1.96-7.77-3.85-12.64-5.68c-4.88-1.82-10.14-3.68-15.79-5.57
			c-14.38-4.9-25.09-10.9-32.15-17.97c-7.06-7.08-10.59-15.63-10.59-25.65c0-7.85,1.93-14.59,5.78-20.23
			c3.85-5.64,9.09-10.28,15.72-13.93c6.63-3.64,14.31-6.32,23.04-8.04c8.73-1.72,17.97-2.58,27.72-2.58
			c9.58,0,18.08,0.47,25.48,1.42c7.4,0.95,14.23,2.4,20.47,4.36v31.43c-3.08-1.75-6.44-3.29-10.08-4.63
			c-3.64-1.33-7.38-2.43-11.23-3.31c-3.85-0.88-7.68-1.52-11.49-1.95c-3.81-0.42-7.42-0.63-10.85-0.63c-4.71,0-8.99,0.37-12.84,1.1
			c-3.85,0.74-7.1,1.77-9.75,3.1c-2.65,1.33-4.71,2.93-6.16,4.78c-1.46,1.86-2.18,3.94-2.18,6.25c0,2.52,0.81,4.78,2.44,6.78
			c1.62,2,3.93,3.89,6.93,5.68c3,1.79,6.63,3.54,10.91,5.26c4.28,1.72,9.11,3.49,14.5,5.31c7.36,2.52,13.97,5.2,19.83,8.04
			c5.86,2.84,10.89,6.04,15.08,9.62c4.19,3.57,7.4,7.66,9.63,12.25c2.22,4.59,3.34,9.93,3.34,16.03c0,8.41-1.95,15.47-5.84,21.18
			c-3.89,5.71-9.18,10.34-15.85,13.87c-6.67,3.54-14.44,6.08-23.3,7.62c-8.86,1.54-18.21,2.31-28.05,2.31
			c-10.1,0-19.7-0.7-28.82-2.1C2120.42,865.63,2112.53,863.52,2105.85,860.72z"/>
        </g>
      </g>
    </svg>
  )
};

export default LogoWithText;


