import { maskingInfo } from "../../components/shared/helper";

export const driversEarningColumns = [
  {
    name: "name",
    label: "Name",
    sortable: true,
    className: "py-2",
  },
  { name: "username", label: "Email", sortable: true },
  {
    name: "ssn",
    className: "table__td_centered table__td_min-horiz-padding",
    label: "SSN Number",
    sortable: true,
    renderer: (item) => (`${item.ssn ? maskingInfo(item.ssn) : item?.driver?.SSN ? maskingInfo(item?.driver?.SSN) : "-"}`),
  },
  { name: "ein", label: "EIN Number", sortable: true, renderer: (item) => (`${item?.driver?.EIN ? item?.driver?.EIN : "-"}`), },
  { name: "phone", label: "Phone Number", sortable: true, renderer: (item) => (`${item?.driver?.phone ? item?.driver?.phone : "-"}`), },
  { name: "address", label: "Address", sortable: true, renderer: (item) => (`${(item?.driver?.address && item?.driver?.city && item?.driver?.state) ? item?.driver?.address + ", " + item?.driver?.city + ", " + item?.driver?.state + ", " + item?.driver?.zipCode : "-"}`), },
  { name: "paymentmode", label: "Method", sortable: true, renderer: (item) => (<span>{item?.paymentmode === "BRANCH" ? "BRANCH" : "DWOLLA"}</span>) },
  {
    name: "totalEarning",
    label: "Total Earning",
    sortable: true,
    renderer: (item) =>
      item.totalEarning && typeof item.totalEarning === "number"
        ? "$" + item.totalEarning.toFixed(2)
        : "$00",
  },
];
