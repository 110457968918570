import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuthInterceptor } from "./util";

export const coachingApi = createApi({
    reducerPath: "coachingApi",
    baseQuery: baseQueryWithAuthInterceptor({ baseUrl: "/api/" }),
    tagTypes: ["Coaching"],
    endpoints: (builder) => ({
        getCoachingHistory: builder.query({
            query: (id) => {
                return {
                    url: `driver/${id}/coachingSessions`,
                    method: "GET",
                };
            },
            providesTags: ["Coaching"],
        }),
        getAllCoachingReasons: builder.query({
            query: () => {
                return {
                    url: `coachingReasons`,
                    method: "GET",
                };
            },
            providesTags: ["Coaching"],
        }),
        getSingleCoaching: builder.query({
            query: (id) => {
                return {
                    url: `coachingSessions/${id}`,
                    method: "GET",
                };
            },
            providesTags: ["Coaching"],
        }),
        createCoachingSessions: builder.mutation({
            query: (body) => ({
                url: "coachingSessions",
                method: "POST",
                body,
            }),
            invalidatesTags: ["Coaching"],
        }),
        updateCoachingSessions: builder.mutation({
            query: ({ id, params }) => ({
                url: `coachingSessions/${id}`,
                method: "Put",
                body: params,
            }),
            invalidatesTags: ["Coaching"],
        }),
        uploadCoachingSessions: builder.mutation({
            query: ({id, params}) => ({
                url: `coachingSessions/${id}/attachments`,
                method: "POST",
                body:uploadCoachingData(params),
            }),
            invalidatesTags: ["Coaching"],
        }),
    }),
});

export const {
    useGetCoachingHistoryQuery,
    useGetAllCoachingReasonsQuery,
    useGetSingleCoachingQuery,
    useCreateCoachingSessionsMutation,
    useUpdateCoachingSessionsMutation,
    useUploadCoachingSessionsMutation
} = coachingApi;

const uploadCoachingData = (params) => {
    console.log(params, "paramshello");
    const formData = new FormData();
    params?.file?.forEach((url) => formData.append('file', url));
    return formData
  }
  
