import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuthInterceptor } from "./util";

export const savedFilterApi = createApi({
    reducerPath: "savedFilterApi",
    baseQuery: baseQueryWithAuthInterceptor({ baseUrl: "/api/" }),
    tagTypes: ["savedFilter"],
    endpoints: (builder) => ({
        driverSavedFilter: builder.mutation({
            query: (body) => ({
                url: `savedfilter`,
                method: "POST",
                body,
            }),
            invalidatesTags: (result, error, arg) => !error && ["savedFilter"],
        }),
        updateSavedFilter: builder.mutation({
            query: ({id, body}) => ({
                url: `updatesavedfilter/${id}`,
                method: "POST",
                body,
            }),
            invalidatesTags: (result, error, arg) => !error && ["savedFilter"],
        }),
        getSavedFilterData: builder.query({
            query: ({id, screen}) => ({
                url: `getsavedfilter/${id}?screen=${screen}`,
            }),
            providesTags: ["savedFilter"],
        }),
    }),

});

export const {
    useDriverSavedFilterMutation,
    useUpdateSavedFilterMutation,
    useGetSavedFilterDataQuery
} = savedFilterApi;

