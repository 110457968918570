export async function fetchJSON(url, method = 'GET', data = undefined) {
  const params = {
    method,
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
  }
  if (data) {
    params.body = data ? JSON.stringify(data) : undefined
  }
  return await fetch(url, params)
}

// export async function uploadFile(url, name, file) {
//   const formData = new FormData()
//   formData.append(name, file)
//   return await(await fetch(`/api/${url}`, {
//     method: 'POST',
//     body: formData
//   })).on()
// }

export async function uploadFile(url, name, file) {
  try {
    const formData = new FormData();
    formData.append(name, file);

    const response = await fetch(`/api/${url}`, {
      method: 'POST',
      body: formData,
    });

    if (response.ok) {
      const responseData = await response.json(); // Assuming the response is in JSON format
      return responseData;
    }
  } catch (error) {
    console.error('Error uploading file:', error);
  }
}

export async function fetchBlob (url) {
  return new Promise(async (resolve, reject) => {
    const blob = await (await fetch(`/api/${url}`, { credentials: 'include' })).blob()
    const reader = new FileReader()
    reader.onload = function() {
      resolve(this.result)
    }
    reader.readAsDataURL(blob)
  })
}
