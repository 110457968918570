import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { IoChevronForward } from "react-icons/io5";
import { MdCancel } from "react-icons/md";
import { CircularProgress } from "@mui/material";
import { IoIosAddCircle } from "react-icons/io";
import { MdPlace } from "react-icons/md";

import Table from "../shared/Table";
import {
  useGetShiftTemplatesQuery,
  useRemoveShiftTemplateMutation,
} from "../../api/shiftTemplate";
import { getRoute } from "../../util/time";
import { actions } from "../../redux/store/store";
import ConfirmDialogModal from "../shared/customModal/confirmDialog";
import MetroSelect from "../shared/metroSelect";
import HeaderTextLayout from "../shared/HeaderTextLayout";
import "../../styles/_data.scss";
import "./style.scss";
import { Refresh } from "@mui/icons-material";

export default function ShiftTemplate() {
  const { data: shiftTemplate, isFetching, refetch } = useGetShiftTemplatesQuery(null, {
    refetchOnMountOrArgChange: true,
  });

  const [remove, removeReq] = useRemoveShiftTemplateMutation();

  const metro = useSelector((state) => state.metro.value);

  const [filterShiftTemplate, setFilterShiftTemplate] = useState([]);
  const [deleteShiftTemplate, setDeleteShiftTemplate] = useState([false, ""]);
  const [isLoading, setLoading] = useState(false);

  const currentUser = useSelector((state) => state.auth.currentUser);

  useEffect(() => {
    setLoading(isFetching);
    if (!shiftTemplate) {
      return;
    }
    let result = metro
      ? shiftTemplate.filter((order) => order.metro === metro)
      : shiftTemplate;
    setFilterShiftTemplate(result);
  }, [shiftTemplate, metro, isFetching]);

  const [activeItem, setActiveItem] = useState();
  const modalShiftTemplateOpen = useSelector(
    (state) => state.modal.shifttemplate.open
  );

  useEffect(() => {
    let timer = false;
    if (!modalShiftTemplateOpen) {
      timer = setTimeout(() => {
        setActiveItem(false);
      }, 1500);
    }
    return () => clearTimeout(timer);
  }, [modalShiftTemplateOpen]);

  const getActiveItem = (id) => {
    setActiveItem(id);
  };

  const columns = [
    { name: "title", label: "Title", sortable: true },
    { name: "numberOfShifts", label: "No. Of Shifts", sortable: true },
    {
      name: "maxTaskPerDriver",
      label: "Max Task",
      sortable: true,
      renderer: (item) =>
        item && item?.maxTaskPerDriver !== 0 ? item?.maxTaskPerDriver : "",
    },
    { name: "metro", label: "Metro", sortable: true },
    { name: "routeCode", label: "Route Code", sortable: true },
    { name: "shiftType", label: "Shift Type", sortable: true },
    {
      name: "from-name",
      label: "Starting Delivery Station",
      sortable: true,
      renderer: (item) => item.from && <span>{item.from.name}</span>,
    },
    //{ name: 'availableTime', label: 'Loading Time', sortable: true, renderer: item => item.availableTime && getTimes(item.availableTime)}, //Temporarily remained for testing and debug, need remove further
    {
      name: "startTime",
      className: "table__td_centered table__td_min-horiz-padding",
      label: "Start Time",
      sortable: true,
      renderer: (item) => {
        return (
          <p style={{ width: "5rem", position: "relative", margin: "auto" }}>
            {getRoute(item && item?.startTime)}
          </p>
        );
      },
    },
    {
      name: "price",
      label: "Payout",
      className: "table__td_centered table__td_min-horiz-padding",
      sortable: true,
      renderer: (item) => {
        return `${item && item.price ? "$".concat(item.price.toFixed(2)) : "-"
          }`;
      },
    },
    {
      name: "details",
      className: "table__td_have-btn align-center",
      renderer: (item) => (
        <button
          className="btn btn_block btn_accent customized-details-btn"
          type="button"
          onClick={() => {
            actions.modal.openShiftTemplate(item._id);
            getActiveItem(item._id);
          }}
        >
          Details <IoChevronForward className="btn__icon btn__icon_right" />
        </button>
      ),
    },
    currentUser && currentUser.role === "root"
      ? {
        className: "table__td_have-btn align-center",
        renderer: (item) => (
          <button
            className="btn btn_block customized-details-btn"
            type="button"
            disabled={removeReq.originalArgs === item._id}
            onClick={() => {
              setDeleteShiftTemplate([true, item._id]);
            }}
          >
            Delete
            <MdCancel className="btn__icon btn__icon_right btn__icon_danger" />
          </button>
        ),
      }
      : { label: "ONLY_ROOT_CAN_SEE" },
  ];

  return (
    <React.Fragment>
      {deleteShiftTemplate[0] && (
        <ConfirmDialogModal
          bodyText="Are you sure you want to delete this shifts template?"
          isOpen
          onClose={() => {
            setDeleteShiftTemplate([false, ""]);
          }}
          confirmAction={async () => {
            const { data: result } = await remove(deleteShiftTemplate[1]);
            if (!result.error) {
              actions.notifications.add({
                message: `Shifts Template deleted successfully!!`,
                status: "success",
              });
              setDeleteShiftTemplate([false, ""]);
            } else {
              actions.notifications.add({
                message:
                  result?.error &&
                  (result.error.message ||
                    result.error.data.message ||
                    "Something went wrong..!"),
                status: "error",
              });
              setDeleteShiftTemplate([false, ""]);
            }
          }}
          modalText="DELETE__SHIFT"
        />
      )}

      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="card">
              <HeaderTextLayout
                headerText="Shifts Template"
                className="col-md-3 main__driver"
                data={
                  filterShiftTemplate && filterShiftTemplate.length > 0
                    ? `${filterShiftTemplate.length} Templates Found`
                    : ""
                }
                dataLength={
                  Array.isArray(filterShiftTemplate)
                    ? filterShiftTemplate.length
                    : "0"
                }
              >
                <div className="col-md-9 d-flex justify-content-end align-items-center">
                  <div
                    className="d-flex align-items-center"
                    style={{
                      listStyle: "none",
                    }}
                  >
                    <MdPlace />
                    <label
                      style={{ fontWeight: "normal" }}
                      className="my-auto ml-0 mr-2"
                    >
                      Metro:
                    </label>
                    <MetroSelect
                      style={{ height: "45px" }}
                      value={metro || ""}
                      onChange={(event, newValue) =>
                        actions.metro.selectMetro(newValue)
                      }
                    />
                  </div>

                  <li style={{ listStyle: "none", padding: "0 10px" }}>
                    <button
                      style={{
                        background: "white",
                        borderColor: "#e6e6e6",
                        padding: "0 20px"
                      }}
                      className="btn btn__accent mr-0 ml-3"
                      onClick={() => actions.modal.openShiftTemplate()}
                    >
                      <IoIosAddCircle style={{ fontSize: "22px" }} />
                      New Shift Template
                    </button>
                  </li>

                  <button className="refresh_btn ml-3" onClick={() => refetch()}>
                    <Refresh className="refresh_icon" />
                  </button>
                </div>
              </HeaderTextLayout>

              {isLoading ? (
                <div className="loading-holder">
                  <CircularProgress className="loading-progress" />
                </div>
              ) : filterShiftTemplate && filterShiftTemplate.length ? (
                <div className="table-wrapper normal_table">
                  <Table
                    columns={columns}
                    items={filterShiftTemplate}
                    className="table_orders"
                    activeItem={activeItem}
                  />
                </div>
              ) : (
                <div className="no_results">No Results</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
