import React, { useState, useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { actions } from "../../redux/store/store";
import validator from "validator";
import {
  useResetPasswordMutation,
  useGetEmailByHashMutation,
} from "../../api/auth";
import brandLogo from "../../images/logo-better-truck.svg";

import "../../styles/_login.scss";

const validatorOptions = {
  minLength: 8,
  minLowercase: 1,
  minUppercase: 1,
  minNumbers: 1,
  minSymbols: 1,
  returnScore: true,
  pointsPerUnique: 3,
  pointsPerRepeat: 3,
  pointsForContainingLower: 10,
  pointsForContainingUpper: 10,
  pointsForContainingNumber: 10,
  pointsForContainingSymbol: 10,
};

export default function ResetPassword(props) {
  const { onStopUserQuery } = props;
  const { hash } = useParams();

  const [newPassword, setNewPassword] = useState("");
  const [passwordWarning, setPasswordWarning] = useState(0);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isPassword2Warning, setIsPassword2Warning] = useState(false);
  const [username, setUsername] = useState("");

  const [getEmailByHash] = useGetEmailByHashMutation();

  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [resetPassword, { isLoading }] = useResetPasswordMutation();

  useEffect(() => {
    const handleResetPasswordHash = async () => {
      if (typeof onStopUserQuery === "function") {
        onStopUserQuery(true);
      }
      if (hash) {
        let user = await getEmailByHash({ hash: hash });

        setUsername(
          user && user.data && user.data.email ? user.data.email : ""
        );
        user.error &&
          setError(user.error && user.error.data && user.error.data.message);
      }
    };

    handleResetPasswordHash();
  }, [onStopUserQuery, hash, getEmailByHash]);

  const performResetPassword = useCallback(
    async (e) => {
      e.preventDefault();

      setError("");
      const { data: user } = await resetPassword({
        newPassword,
        confirmPassword,
        hash,
      });
      if (user.status === 200) {
        actions.notifications.add({ message: user.message, status: "success" });
        navigate("/login");
      } else {
        if (user.status === 401 || user.originalStatus === 401) {
          setError(user.message);
        } else {
          setError("Something went wrong");
        }
      }
    },
    [newPassword, confirmPassword, hash, navigate, resetPassword]
  );

  const toLogin = (e) => {
    e.preventDefault();
    navigate("/login");
  };

  const handleNewPassword = (e) => {
    let val = e.target.value;
    if (!newPassword.includes(username)) {
      let score = validator.isStrongPassword(val, validatorOptions);
      setPasswordWarning(score);
    } else {
      setPasswordWarning(-1);
    }
    setNewPassword(val);
    val === confirmPassword
      ? setIsPassword2Warning(false)
      : setIsPassword2Warning(true);
    setNewPassword(e.target.value);
  };

  const handleChangePassword2 = (e) => {
    let val = e.target.value;
    val === newPassword
      ? setIsPassword2Warning(false)
      : setIsPassword2Warning(true);
    setConfirmPassword(val);
  };

  return (
    <div className="screen screen_flex">
      <div className="container container_contain-center loginmain">
        <img className="brandlogo" src={brandLogo} alt="" />
        <div className="brandHeading">
          <h5 style={{ width: "100%", paddingLeft: "17px" }}>Reset Password</h5>
        </div>
        <div className="login">
          <form>
            <div className="form__item">
              <label className="form__label" htmlFor="login">
                Email
              </label>
              <input
                id="login"
                className="form__input"
                type="text"
                value={username}
                onChange={() => { }}
                disabled
              />
            </div>
            <div className="form__item">
              <div className="form__msg">
                <span>the password must contain:</span>
                <ul>
                  <li>minimum of 8 non-repeating characters</li>
                  <li>At least one letter</li>
                  <li>At least one uppercase letter</li>
                  <li>At least one symbol</li>
                  <li>At least one digit</li>
                </ul>
                <span>the password must not contain</span>
                <ul>
                  <li>email</li>
                  <li>common words</li>
                  <li>common or weak passwords</li>
                </ul>
              </div>
              <label className="form__label" htmlFor="newPassword">
                New Password
              </label>
              <input
                id="newPassword"
                className="form__input" /*form__input-invalid <= class for invalid value*/
                type="password"
                value={newPassword}
                onChange={(e) => {
                  handleNewPassword(e);
                }}
                autoComplete="off"
              />
              {passwordWarning === -1 && (
                <div className="form__msg form__msg_invalid">
                  the password contains an email
                </div>
              )}
              {passwordWarning === 0 && (
                <div className="form__msg">please enter password</div>
              )}
              {passwordWarning > 0 && passwordWarning < 30 && (
                <div className="form__msg form__msg_invalid">
                  your password is very weak
                </div>
              )}
              {passwordWarning > 29 && passwordWarning < 52 && (
                <div className="form__msg form__msg_invalid">
                  your password is not strong enough
                </div>
              )}

              {passwordWarning > 50 && newPassword.length < 8 && (
                <div className="form__msg form__msg_invalid">
                  Please enter atlease 8 characters strong password
                </div>
              )}
              {passwordWarning > 51.5 && newPassword.length > 8 && (
                <div className="form__msg">great password</div>
              )}
            </div>
            <div className="form__item">
              <label className="form__label" htmlFor="confirmPassword">
                Confirm Password
              </label>
              <input
                id="confirmPassword"
                className="form__input" /*form__input-invalid <= class for invalid value*/
                type="password"
                value={confirmPassword}
                onChange={(e) => {
                  handleChangePassword2(e);
                }}
                autoComplete="off"
              />
              {isPassword2Warning && (
                <div className="form__msg form__msg_invalid">
                  passwords don&#39;t match
                </div>
              )}
            </div>
            <div className="form__item form__item_min-margin">
              <button
                className="btn btn_block btn_accent"
                disabled={
                  isLoading || passwordWarning < 52 || isPassword2Warning
                }
                onClick={performResetPassword}
              >
                Set Password
              </button>
            </div>
            {error && (
              <div className="form__item form__item_min-margin">
                <div className="form__msg form__msg_summary form__msg_invalid">
                  {error}
                </div>
              </div>
            )}
            <div className="login__link-holder">
              <a href="/login" onClick={toLogin}>
                Login
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
