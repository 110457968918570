import React, { useEffect, useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Box, CircularProgress, Drawer, FormControlLabel, InputLabel, ListItem, Modal, Paper, styled, Switch, Typography } from '@mui/material'
import "../driver/style.driver.scss"
import CloseIcon from '@mui/icons-material/Close';
import { BsFillFilterCircleFill } from 'react-icons/bs';
import { useDriverSavedFilterMutation, useGetSavedFilterDataQuery, useUpdateSavedFilterMutation } from '../../../../api/savedFilter';
import { useGetMetrosQuery } from '../../../../api/metro';
import { useSelector } from 'react-redux';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MdPlace } from "react-icons/md";
import MultiSelect from '../../../../constants/multiSelectDropdown';
import { driverArriva, shiftReportStatus } from '../../../../constants/menuLists';
import { useGetStoresQuery } from '../../../../api/stores';
import ReactSelect from 'react-select';
import moment from 'moment';
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CancelIcon from "@mui/icons-material/Cancel";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import BackspaceIcon from "@mui/icons-material/Backspace";
import { DateRangePicker } from 'react-date-range';
import { sendSuccessMessage } from '../../../../constants/extras/sendSuccessMsg';
import { sendErrorMessage } from '../../../../constants/extras/errorHandlers';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

const MuiAccordion = styled((props) => (
    <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:before': {
        display: 'none',
    },
}));

const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 6,
    '& .MuiSwitch-switchBase': {
        transitionDuration: '500ms',
    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 20,
        height: 20,
        margin: 0,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#ccd7dd' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 1000,
        }),
    },
}));

const MuiAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));


const MuiAccordionSummary = styled((props) => (
    <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : '#ccd7dd',

    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

function CustomShiftDrawer(props) {
    const { openDrawer, handleCloseDrawer, handleApplyFilter, handleCustomRange, handleReset, onResetClick, handleTimeRangeSelection,
        onSaveDataChange, obj } = props

    const {
        metros: metro,
        setMetros,
        hubs,
        setHubs,
        statusFilter,
        setStatusFilter,
        fromDate,
        toDate,
        isRejectShift,
        setIsRejectShift,
        isRejectReason,
        setIsRejectReason,
        isArriveToggle,
        setIsArriveToggle,
        showDateRangePicker,
        setShowDateRangePicker,
        setState,
        state,
        saveData,
        columndata,
        setColumnData,
        hideColumn,
        data,
        getID
    } = obj

    const styles = (width) => {
        return {
            placeholder: (base) => ({
                ...base,
                fontSize: "0.9rem",
                fontWeight: "normal"
            }),
            control: (styles) => ({
                ...styles,
                borderRadius: "2px",
                boxShadow: "none",
                width: width || "12rem",
                height: "2.8rem",
                "&:hover": {
                    borderColor: "#e3eaf3",
                },
                borderColor: "#e3eaf3",
                fontSize: "1rem",
            }),
        }
    }


    const currentUser = useSelector((state) => state.auth.currentUser)

    const { data: metros } = useGetMetrosQuery(null, {
        refetchOnMountOrArgChange: true,
    });
    const { data: stores, isFetching: storesLoadingStatus } = useGetStoresQuery(
        null,
        { refetchOnMountOrArgChange: false }
    );

    const { data: getsavedData, isFetching } = useGetSavedFilterDataQuery({ id: currentUser?._id, screen: "shift" }, {
        refetchOnMountOrArgChange: true,
        skip: !openDrawer
    })

    const [savedFilter, savedData] = useDriverSavedFilterMutation()
    const [updateSavedFilter, updateData] = useUpdateSavedFilterMutation()

    const [title, setTitle] = useState("");
    const [openModal, setOpenModal] = useState(false)
    const [isTitle, setIsTitle] = useState(false)

    useEffect(() => {
        if (saveData === "") {
            setColumnData(data)
        }
    }, [saveData])

    const filterData = () => {
        const metroData = metro.map(item => item.value)
        const status = statusFilter.map(item => item.value)
        const hub = hubs?.map(item => item.id)

        return {
            metro: metroData,
            status: status,
            hub: hub,
            shifFromDate: fromDate,
            shifToDate: toDate,
            hidecolumn: hideColumn,
            showcolumn: columndata,
            shiftLog: isRejectShift,
            shiftRejectReason: isRejectReason,
            isDriverArrived: isArriveToggle
        }
    }

    const handlesavedFilter = async () => {
        if (title) {
            setOpenModal(false);
            const isDriverArrivedValue = isArriveToggle ? isArriveToggle.value : null;

            const filterdata = filterData();
            filterdata.isDriverArrived = isDriverArrivedValue;

            const savedNewData = {
                screenname: "shift",
                filtername: title.trim(),
                filterdata: filterdata,
            };
            await savedFilter(savedNewData);
            setTitle("");
            if (!savedData.error) {
                sendSuccessMessage("Saved Successfully");
            }
        } else {
            setIsTitle(true);
        }
    };

    const saveFilter = () => {
        const isDateValue = fromDate || toDate
        const isDropdownValue = metro.length && metro || statusFilter.length && statusFilter || hubs

        if (isDateValue || isDropdownValue?.length) {
            setOpenModal(true)
        } else {
            sendErrorMessage("Please Select Filter First")
        }
    }

    const updateFilter = async () => {
        setOpenModal(false);

        const isDriverArrivedValue = isArriveToggle ? isArriveToggle.value : '';

        const filterdata = filterData();
        filterdata.isDriverArrived = isDriverArrivedValue;

        const savedNewData = {
            filtername: saveData.value,
            filterdata: filterdata,
        };

        const update = {
            id: getID,
            body: savedNewData,
        };

        await updateSavedFilter(update);

        if (!updateData.error) {
            sendSuccessMessage("Update Successfully");
        }
    };

    const onDragEnd = (result) => {
        const { source, destination } = result;

        if (!destination) return;

        if (destination.droppableId === source.droppableId && destination.index === source.index) return;

        let add,
            show = columndata,
            hide = hideColumn

        if (source.droppableId === "showColumn") {
            add = show[source.index];
            show.splice(source.index, 1)
        } else {
            add = hide[source.index];
            hide.splice(source.index, 1)
        }

        if (destination.droppableId === "showColumn") {
            show.splice(destination.index, 0, add)
        } else {
            hide.splice(destination.index, 0, add)
        }
    }

    return (
        <>
            <Drawer
                anchor='right'
                open={openDrawer}
                transitionDuration={{ enter: 1000, exit: 1000 }}
            >
                <Box className='main_contain' role="presentation" >
                    <div className="drawer_header">
                        <div className="d-flex">
                            <span className='mr-3' style={{ lineHeight: "2rem", fontSize: "2rem", color: "#4195f5" }}><BsFillFilterCircleFill /></span>
                            <Typography style={{ fontSize: '24px', fontWeight: '600' }}>
                                {'Advanced Search'}
                            </Typography>
                        </div>

                        <div style={{ display: "flex", alignItems: "center", gap: "20px" }} >
                            <div>
                                <ReactSelect
                                    options={getsavedData?.map((item) => {
                                        return {
                                            value: item?.filtername,
                                            label: item?.filtername,
                                        };
                                    })}
                                    isClearable={true}
                                    onChange={(item) => {
                                        onSaveDataChange(item)
                                    }}
                                    placeholder="Saved Filter"
                                    value={saveData}
                                    components={{
                                        IndicatorSeparator: () => null,
                                    }}
                                    styles={styles()}
                                />
                            </div>
                            <div style={{ cursor: 'pointer', color: "#adbfc7" }} onClick={() => handleCloseDrawer()}>
                                <CloseIcon fontSize='medium' />
                            </div >
                        </div>
                    </div>
                    {
                        isFetching ? <div style={{ position: "absolute", top: "50%", left: "50%" }}>
                            <CircularProgress className="loading-progress" />
                        </div> : (
                            <div style={{ margin: "100px 30px" }}>
                                <MuiAccordion className="mb-4" >
                                    <MuiAccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        className="panel1a-header"
                                    >
                                        <Typography>Metro/Hub </Typography>
                                    </MuiAccordionSummary>
                                    <MuiAccordionDetails>
                                        <div>
                                            <div className='mb-3'>
                                                <label style={{ fontWeight: "normal" }} className="m-0 mb-1">
                                                    <MdPlace /> Metro &nbsp;
                                                </label>
                                                <MultiSelect
                                                    placeholder="Select Metro"
                                                    value={metro}
                                                    itemData={metros?.map((item, i) => {
                                                        return {
                                                            value: item?.name ? item?.name : "-",
                                                            label: `${item?.name ? item?.name : ""}`,
                                                        };
                                                    })}
                                                    onChange={(value) => {
                                                        setMetros(value)
                                                    }}
                                                />
                                            </div>
                                            <div>
                                                <label style={{ fontWeight: "normal" }} className="m-0 mb-1" >
                                                    Hub
                                                </label>
                                                <MultiSelect
                                                    value={hubs}
                                                    placeholder="Select State"
                                                    itemData={stores?.map((item, i) => {
                                                        return {
                                                            value: item?.name ? item?.name : "-",
                                                            label: `${item?.name ? item?.name : ""}`,
                                                            id: `${item?._id ? item?._id : ""}`,
                                                        };
                                                    })}
                                                    isOptionSelected={(option, selectValue) => selectValue.some(i => i.id === option.id)}
                                                    onChange={(value) => {
                                                        setHubs(value)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </MuiAccordionDetails>
                                </MuiAccordion>

                                <MuiAccordion className="mb-4" >
                                    <MuiAccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        className="panel1a-header"
                                    >
                                        <Typography>Shift</Typography>
                                    </MuiAccordionSummary>
                                    <MuiAccordionDetails>
                                        <div>
                                            <div className="accordionDetails mt-2" style={{ justifyContent: "space-between" }}>
                                                <div style={{ width: "45%" }}>
                                                    <label className='m-0 mb-1' id="bg_checker" style={{ fontWeight: "normal" }}> Shift Date Range </label>
                                                    <DatePicker
                                                        handleCustomRange={handleCustomRange}
                                                        fromDate={fromDate}
                                                        toDate={toDate}
                                                        showDateRangePicker={showDateRangePicker}
                                                        onChange={(item) => {
                                                            setState([item.selection]);
                                                        }}
                                                        ranges={state}
                                                        cancelDatePicker={() => {
                                                            setShowDateRangePicker(false);
                                                        }}
                                                        handleTimeRangeSelection={handleTimeRangeSelection}
                                                        resetTimeRange={onResetClick}
                                                        sx={{ position: 'relative' }}
                                                        placeholder="Select Shift Date Range"
                                                    />
                                                </div>

                                                {/* <div className='toggle_box' style={{ height: "48px", width: "35%", marginTop: "22px", marginRight: "30px" }}>
                                                    <label className='m-0' style={{ fontWeight: "normal", color: "#838383", fontSize: "0.9rem" }}> Arrive at Hub </label>
                                                    <FormControlLabel
                                                        control={<Android12Switch checked={isArriveToggle} onChange={(e) => setIsArriveToggle(e?.target?.checked)} />}
                                                        style={{ marginTop: "8px" }}
                                                    />
                                                </div> */}

                                                <div style={{ width: "45%" }}>
                                                    <label style={{ fontWeight: "normal" }} className=" m-0 mb-1">
                                                        Arrive at Hub
                                                    </label>

                                                    <ReactSelect
                                                        options={driverArriva?.map(function (item) {
                                                            return {
                                                                value: item?.value ? item?.value : "-",
                                                                label: `${item?.title ? item?.title : ""}`,
                                                            }
                                                        })}
                                                        isClearable={true}
                                                        onChange={(item) => {
                                                            setIsArriveToggle(item)
                                                        }}
                                                        value={isArriveToggle}
                                                        components={{
                                                            IndicatorSeparator: () => null,
                                                        }}
                                                        styles={styles("100%")}
                                                        closeMenuOnSelect={true}
                                                        placeholder={`Select Driver Arrival`}
                                                    />
                                                </div>

                                            </div>
                                            <div className="accordionDetails mt-2" style={{ justifyContent: "space-between" }}>
                                                <div className='toggle_box' style={{ height: "48px", width: "45%", marginTop: "22px", }}>
                                                    <label className='m-0' style={{ fontWeight: "normal", color: "#838383", fontSize: "0.9rem" }}> Shift Reject Reason </label>
                                                    <FormControlLabel
                                                        control={<Android12Switch checked={isRejectReason} onChange={(e) => setIsRejectReason(e.target.checked)} />}
                                                        style={{ marginTop: "8px" }}
                                                    />
                                                </div>
                                                <div className='toggle_box' style={{ height: "48px", width: "45%", marginTop: "22px", }}>
                                                    <label className='m-0' style={{ fontWeight: "normal", color: "#838383", fontSize: "0.9rem" }}> Shift Log </label>
                                                    <FormControlLabel
                                                        control={<Android12Switch checked={isRejectShift} onChange={(e) => setIsRejectShift(e.target.checked)} />}
                                                        style={{ marginTop: "8px" }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="mt-2">
                                                <label style={{ fontWeight: "normal" }} className=" m-0 mb-1">
                                                    Status
                                                </label>
                                                <MultiSelect
                                                    placeholder="Select Status"
                                                    itemData={shiftReportStatus?.map((item, i) => {
                                                        return {
                                                            value: item?.value ? item?.value : "-",
                                                            label: `${item?.title ? item?.title : ""}`,
                                                        };
                                                    })}
                                                    value={statusFilter}
                                                    onChange={(value) => {
                                                        setStatusFilter(value);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </MuiAccordionDetails>
                                </MuiAccordion>
                                <MuiAccordion className="mb-4">
                                    <MuiAccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel4a-header"
                                    >
                                        <Typography>Column Settings</Typography>
                                    </MuiAccordionSummary>
                                    <MuiAccordionDetails>
                                        <DragDropContext onDragEnd={onDragEnd}>
                                            <div className="column_Container" >
                                                <div className='showColumn' >
                                                    <h4>Show Column</h4>
                                                    <Droppable droppableId={"showColumn"}>

                                                        {(provided, snapshot) => (
                                                            <div sx={{ textAlign: "center", height: "500px", }}
                                                                ref={provided.innerRef}
                                                                {...provided.droppableProps}
                                                                isDraggingOver={snapshot.isDraggingOver}
                                                            >
                                                                <div style={{ height: "450px", padding: "5px 30px", overflow: "auto" }}>
                                                                    {columndata.map((item, index) => (
                                                                        <Draggable draggableId={`${item}`} key={item} index={index}>
                                                                            {(provided, snapshot) => (
                                                                                <Paper
                                                                                    {...provided.draggableProps}
                                                                                    {...provided.dragHandleProps}
                                                                                    ref={provided.innerRef}
                                                                                    isDragging={snapshot.isDragging}
                                                                                    sx={{ margin: "10px 0", bgcolor: "lightgray", cursor: "pointer" }}
                                                                                >
                                                                                    <ListItem disablePadding sx={{ padding: "5px 10px" }}>
                                                                                        {item}
                                                                                    </ListItem>
                                                                                </Paper>
                                                                            )}
                                                                        </Draggable>
                                                                    ))}
                                                                    {provided.placeholder}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Droppable>
                                                </div>
                                                <div className='hideColumn' >
                                                    <h4>Hide Column</h4>
                                                    <Droppable droppableId="hideColumn">
                                                        {(provided, snapshot) => (
                                                            <div sx={{ textAlign: "center", height: "500px", width: "300px" }}
                                                                ref={provided.innerRef}
                                                                {...provided.droppableProps}
                                                                isDraggingOver={snapshot.isDraggingOver}
                                                            >
                                                                <div style={{ height: "450px", padding: "0px 30px", overflow: "auto" }}>
                                                                    {hideColumn?.map((item, index) => (
                                                                        <Draggable draggableId={`${item}`} key={item} index={index}>
                                                                            {(provided, snapshot) => (
                                                                                <Paper
                                                                                    {...provided.draggableProps}
                                                                                    {...provided.dragHandleProps}
                                                                                    ref={provided.innerRef}
                                                                                    isDragging={snapshot.isDragging}
                                                                                    sx={{ margin: "10px 0", bgcolor: "lightgray", cursor: "pointer" }}
                                                                                >
                                                                                    <ListItem disablePadding sx={{ padding: "5px 10px" }}>
                                                                                        {item}
                                                                                    </ListItem>
                                                                                </Paper>
                                                                            )}
                                                                        </Draggable>
                                                                    ))}
                                                                </div>
                                                                {provided.placeholder}
                                                            </div>
                                                        )}
                                                    </Droppable>
                                                </div>
                                            </div>
                                        </DragDropContext>
                                    </MuiAccordionDetails>
                                </MuiAccordion>
                            </div>
                        )
                    }
                    <div className="drawer_footer">
                        {!saveData ? <button className="btn mr-4 pl-4 pr-4" onClick={saveFilter}> Save Filter </button>
                            : <button className="btn mr-4 pl-4 pr-4" onClick={updateFilter}> Update Filter </button>}
                        <button className="btn mr-4 pl-4 pr-4" onClick={() => handleReset()}> Reset </button>
                        <button className="btn btn_accent mr-3 pl-4 pr-4" onClick={() => handleApplyFilter()}> Apply </button>
                    </div>
                </Box>

                <Modal
                    open={openModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <div className="modal filter_modal">
                        <div className="modal__header" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <h2 className="modal__title">Saved Filter</h2>
                            <div style={{ cursor: 'pointer' }} onClick={() => {
                                setOpenModal(false)
                                setIsTitle(false)
                                setTitle("")
                            }}>
                                <CloseIcon fontSize='medium' />
                            </div>
                        </div>
                        <div style={{ fontWeight: '600', padding: "30px" }}>
                            <InputLabel htmlFor="file">Filter Name : &nbsp;</InputLabel>
                            <input
                                className={`form__input`}
                                type="text"
                                id="file"
                                style={{ border: "1px solid #ccd7dd", borderRadius: "2px" }}
                                placeholder="Enter Filter Name"
                                onChange={(e) => {
                                    let data = e.target.value.trimStart()
                                    setTitle(data)
                                }}
                                value={title}
                            />
                            {isTitle && !title && <p className="mb-0 mt-1" style={{ color: "red", fontWeight: "normal" }}>*Please Enter Filter Name</p>}
                        </div>
                        <div className="modal__footer">
                            <button className="btn" onClick={handlesavedFilter}>
                                Save
                            </button>
                        </div>
                    </div>
                </Modal>
            </Drawer>
        </>
    )
}

export default CustomShiftDrawer

export const DatePicker = (props) => {
    const { handleCustomRange, fromDate, toDate, showDateRangePicker, onChange, ranges, handleTimeRangeSelection, cancelDatePicker,
        resetTimeRange, sx,minDate, maxDate, placeholder } = props

    const styles = {
        position: 'absolute',
        top: 37,
        right: 0,
        left: 0,
        zIndex: 1,
        p: 1,
        width: "103%"
    };
    return (
        <>
            <Box sx={sx}>
                <div onClick={handleCustomRange} className="form__input" style={{ borderRadius: "2px", border: "1px solid #e3eaf3", color: "#838383", fontSize: "0.9rem", display: "flex", justifyContent: "space-between" }}>
                    <span>
                        {fromDate ? `${moment(fromDate).format(
                            "MM-DD-yyyy"
                        )} TO ${moment(toDate).format("MM-DD-yyyy")}`
                            :  placeholder ? placeholder : "Select Date Range"}
                    </span>
                    <span className="pl-2">
                        {showDateRangePicker ? (
                            <KeyboardArrowUpIcon />
                        ) : (
                            <KeyboardArrowDownIcon />
                        )}
                    </span>
                </div>
                {showDateRangePicker && (
                    <Box sx={styles} className="dt__rng__pickr__cont">
                        <div className="dateRangePicker" style={{ right: "!important" }}>
                            <DateRangePicker
                                onChange={onChange}
                                showSelectionPreview={true}
                                moveRangeOnFirstSelection={false}
                                months={1}
                                ranges={ranges}
                                direction="horizontal"
                                minDate={minDate}
                                maxDate={maxDate}
                            />

                            <div className="button-container">
                                <button
                                    onClick={resetTimeRange}
                                    className="okButton clearButton"
                                >
                                    <span>
                                        <BackspaceIcon /> &nbsp;
                                        <strong>RESET</strong>
                                    </span>
                                </button>
                                <button
                                    onClick={cancelDatePicker}
                                    className="cancelButton"
                                >
                                    <span>
                                        <CancelIcon sx={{ width: "1.5rem", height: "1.5rem" }} />{" "}
                                        <strong>cancel</strong>
                                    </span>
                                </button>
                                <button
                                    onClick={handleTimeRangeSelection}
                                    className="okButton"
                                >
                                    <span>
                                        <ThumbUpAltIcon /> &nbsp;
                                        <strong>Done</strong>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </Box>
                )}
            </Box>
        </>
    )
}
