export const states = [
  {
    title: "Alabama",
    state: 'Alabama',
  },
  {
    title: "Alaska",
    state: 'Alaska',
  },
  {
    title: "American Samoa",
    state: 'American Samoa',
  },
  {
    title: "Arizona",
    state: 'Arizona',
  },
  {
    title: "Arkansas",
    state: 'Arkansas',
  },
  {
    title: "California",
    state: 'California',
  },
  {
    title: "Colorado",
    state: 'Colorado',
  },
  {
    title: "Connecticut",
    state: 'Connecticut',
  },
  {
    title: "Delaware",
    state: 'Delaware',
  },
  {
    title: "district of columbia ",
    state: 'district of columbia ',
  },
  {
    title: "Federated States Of Micronesia",
    state: 'Federated States Of Micronesia',
  },
  {
    title: "Palau",
    state: 'Palau',
  },
  {
    title: "Marshall Islands",
    state: 'Marshall Islands',
  },
  {
    title: "Florida",
    state: 'Florida',
  },
  {
    title: "Georgia",
    state: 'Georgia',
  },
  {
    title: "Guam",
    state: 'Guam',
  },
  {
    title: "Hawaii",
    state: 'Hawaii',
  },
  {
    title: "Idaho",
    state: 'Idaho',
  },
  {
    title: "Illinois",
    state: 'Illinois',
  },
  {
    title: "Indiana",
    state: 'Indiana',
  },
  {
    title: "Iowa",
    state: 'Iowa',
  },
  {
    title: "Kansas",
    state: 'Kansas',
  },
  {
    title: "Kentucky",
    state: 'Kentucky',
  },
  {
    title: "Louisiana",
    state: 'Louisiana',
  },
  {
    title: "Maine",
    state: 'Maine',
  },
  {
    title: "Maryland",
    state: 'Maryland',
  },
  {
    title: "Massachusetts",
    state: 'Massachusetts',
  },
  {
    title: "Michigan",
    state: 'Michigan',
  },
  {
    title: "Minnesota",
    state: 'Minnesota',
  },
  {
    title: "Mississippi",
    state: 'Mississippi',
  },
  {
    title: "Missouri",
    state: 'Missouri',
  },
  {
    title: "Montana",
    state: 'Montana',
  },
  {
    title: "Nebraska",
    state: 'Nebraska',
  },
  {
    title: "Nevada",
    state: 'Nevada',
  },
  {
    title: "New Hampshire",
    state: 'New Hampshire',
  },
  {
    title: "New Jersey",
    state: 'New Jersey',
  },
  {
    title: "New Mexico",
    state: 'New Mexico',
  },
  {
    title: "New York",
    state: 'New York',
  },
  {
    title: "North Carolina",
    state: 'North Carolina',
  },
  {
    title: "North Dakota",
    state: 'North Dakota',
  },
  {
    title: "Northern Mariana Islands",
    state: 'Northern Mariana Islands',
  },
  {
    title: "Ohio",
    state: 'Ohio',
  },
  {
    title: "Oklahoma",
    state: 'Oklahoma',
  },
  {
    title: "Oregon",
    state: 'Oregon',
  },
  {
    title: "Pennsylvania",
    state: 'Pennsylvania',
  },
  {
    title: "Puerto Rico",
    state: 'Puerto Rico',
  },
  {
    title: "Rhode Island",
    state: 'Rhode Island',
  },
  {
    title: "South Carolina",
    state: 'South Carolina',
  },
  {
    title: "South Dakota",
    state: 'South Dakota',
  },
  {
    title: "Tennessee",
    state: 'Tennessee',
  },
  {
    title: "Texas",
    state: 'Texas',
  },
  {
    title: "Utah",
    state: 'Utah',
  },
  {
    title: "Vermont",
    state: 'Vermont',
  },
  {
    title: "Virgin Islands",
    state: 'Virgin Islands',
  },
  {
    title: "Virginia",
    state: 'Virginia',
  },
  {
    title: "Washington",
    state: 'Washington',
  },
  {
    title: "West Virginia",
    state: 'West Virginia',
  },
  {
    title: "Wisconsin",
    state: 'Wisconsin',
  },
  {
    title: "Wyoming",
    state: 'Wyoming',
  },
];