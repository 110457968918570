import React, { useState, useCallback, useEffect } from "react";
import { Modal, CircularProgress } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import MetroSelect from "../metroSelect";
import { InputItem, ErrorMessage, Label } from "../Form";
import {
  useGetStoreQuery,
  useUpdateStoreMutation,
  useCreateStoreMutation,
} from "../../../api/stores";
import { actions } from "../../../redux/store/store";
import { checkForError } from "../../../constants/extras/errorHandlers";

import "./style.scss";
import Input from "react-phone-number-input/input";
import { formatPhoneNumber } from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";

const requiredMessage = "This field is required";
const validationSchema = Yup.object().shape({
  metro: Yup.string().required(requiredMessage),
  name: Yup.string()
    .test("len", requiredMessage, (value) => {
      return !!value.trim();
    })
    .required(requiredMessage),
  address: Yup.string()
    .test("len", requiredMessage, (value) => {
      return !!value.trim();
    })
    .required(requiredMessage),
  phone: Yup.string().required(requiredMessage),
  latitude: Yup.string("Please enter valid coordinate").required(
    requiredMessage
  ),
  longitude: Yup.string().required(requiredMessage),
});

const StoreModal = ({ id }) => {
  const title = id ? `Hub #${id}` : "New hub";

  const [isLoaded, setIsLoaded] = useState(false);
  const [valid, setIsValid] = useState(false);

  const { data: store, status: storeLoadingStatus } = useGetStoreQuery(id, {
    refetchOnMountOrArgChange: true,
    skip: !id,
  });

  const [update, { error: updateError, isLoading: isUpdating }] =
    useUpdateStoreMutation();
  const [create, { error: createError, isLoading: isCreating }] =
    useCreateStoreMutation();

  const [, setGeneralError] = useState(null);

  const form = useForm({
    defaultValues: { metro: "", latitude: "", longitude: "", isPublic: false },
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    setIsLoaded(["uninitialized", "fulfilled"].includes(storeLoadingStatus));
  }, [storeLoadingStatus]);

  useEffect(() => {
    const error = updateError || createError;
    setGeneralError(
      error
        ? {
          message:
            error.message ||
            error.error ||
            (error.data && error.data.message) ||
            error.data,
        }
        : null
    );
  }, [updateError, createError]);

  useEffect(() => {
    (async () => {
      if (!store) return;
      setIsValid(true);
      Object.entries(store).forEach(([name, value]) =>
        form.setValue(name, value)
      );

      let phoneNum = form.getValues("phone");
      !phoneNum.includes("+1") &&
        form.setValue("phone", "+1 ".concat(store.phone));
    })();
  }, [store, form]);

  const onSubmit = useCallback(
    async (data) => {
      data.phone = formatPhoneNumber(data.phone);
      const result = await (id ? update(data) : create(data));
      if (!result?.error && result?.data && result?.data?.metro) {
        actions.modal.closeStore();
        actions.notifications.add({
          message: `Store ${id ? "updated" : "created"} successfully`,
          status: "success",
        });
      } else {
        checkForError(result && result?.error);
      }
    },
    [id, create, update]
  );

  const onCancel = useCallback(() => {
    if (
      !form.formState.isDirty ||
      window.confirm("Do you want to close the dialog? Changes will be lost.")
    ) {
      actions.modal.closeStore();
    }
  }, [form.formState.isDirty]);

  const handleCoordinates = (e) => {
    let str = e.target.value;
    var rgx = /^[0-9!#$%^&*()_+\-=[\]{};':"\\|,.<>/?]*\.?[0-9]*$/;
    e.target.value = str.match(rgx);
  };

  return (
    <Modal
      open
      onClose={onCancel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div
        className={`modal ${isLoaded ? "after-loading" : "when-loading"}`}
        style={{ maxWidth: "50.875rem" }}
      >
        <div className="modal__header">
          <h2 className="modal__title">{title}</h2>
        </div>
        <div className="modal__body">
          {isLoaded ? (
            <form>
              <div className="form__item">
                <label className="form__label" htmlFor="metro-select">
                  Metro
                </label>
                <Controller
                  control={form.control}
                  name="metro"
                  rules={{ required: true }}
                  render={({
                    field: { onChange, value, ref },
                    fieldState: { error },
                  }) => (
                    <React.Fragment>
                      <MetroSelect
                        value={value}
                        onChange={(e, data) => onChange(data)}
                        ref={ref}
                        isError={error}
                        style={{ width: "100%" }}
                      />
                      <ErrorMessage error={error} />
                    </React.Fragment>
                  )}
                ></Controller>
              </div>
              <InputItem name="name" form={form} />
              <InputItem name="address" form={form} />

              <div className="mb-3">
                <label style={{ marginBottom: 0 }}>Phone</label>
                <Controller
                  name="phone"
                  control={form.control}
                  rules={{
                    required: true,
                    validate: (value) => isValidPhoneNumber(value),
                  }}
                  render={({
                    field: { onChange, value, ref },
                    fieldState: { error },
                  }) => (
                    <>
                      <Input
                        country="US"
                        value={value}
                        onChange={(data) => {
                          onChange(data);
                          if (data && isValidPhoneNumber(data)) {
                            setIsValid(true);
                          } else {
                            setIsValid(false);
                          }
                        }}
                        id="phone"
                        className="form__input"
                        ref={ref}
                      />
                      <ErrorMessage error={error} />
                    </>
                  )}
                />
              </div>
              <InputItem
                name="latitude"
                form={form}
                onInput={(e) => {
                  handleCoordinates(e);
                }}
              />
              <InputItem
                name="longitude"
                form={form}
                onInput={(e) => {
                  handleCoordinates(e);
                }}
              />
              <div className="mb-3">
                <Controller
                  name="isPublic"
                  control={form.control}
                  rules={{
                    required: true,
                    validate: (value) => isValidPhoneNumber(value),
                  }}
                  render={({
                    field: { onChange, value, ref },
                    fieldState: { error },
                  }) => (
                    <>
                      <input
                        type="checkbox"
                        name="isPublic"
                        id="isPublic"
                        checked={form.getValues("isPublic")}
                        onChange={(e) => onChange(e)}
                      />
                      <Label
                        title={"This will prevent this Hub from showing in the Need help Button in the BTShift app."}
                        htmlFor="isPublic"
                      />
                    </>
                  )}
                />
              </div>
            </form>
          ) : (
            <div className="loading-wrapper">
              <CircularProgress />
            </div>
          )}
        </div>
        <div className="modal__footer">
          <button className="btn" onClick={() => actions.modal.closeStore()}>
            Cancel
          </button>
          <button
            className="btn btn_accent"
            disabled={isUpdating || isCreating || !isLoaded || !valid}
            onClick={form.handleSubmit(onSubmit)}
          >
            OK
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default StoreModal;

