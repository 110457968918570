export const messageListOptionStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#4195F5" : "white",
    "&:hover": {
      background: "#4195F5",
      color: "#FFFFFF",
      transition: "all 0.8s",
    },
  }),
  control: (styles) => ({
    ...styles,
    border: "2px #dadada solid",
    borderRadius: "0.375rem",
    padding: "3px 0",
    boxShadow: "none",
    "&:hover": {
      borderColor: "#a0a293",
    },
  }),
  menu: (base) => ({
    ...base,
    borderRadius: 0,
    borderColor: "2px solid #dadada",
  }),
  menuList: (base) => ({
    ...base,
    cursor: "pointer",
  }),
  placeholder: (base) => ({
    ...base,
    fontSize: "16px",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";
    const color = "#707070";
    return { ...provided, transition, color, opacity };
  },
};
