import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuthInterceptor } from "./util";

export const emailApi = createApi({
  reducerPath: "emailApi",
  baseQuery: baseQueryWithAuthInterceptor({ baseUrl: "/api/emails/" }),
  tagTypes: ["Emails"],
  endpoints: (builder) => ({
    getEmails: builder.query({
      query: (body) => {
        let opts = {};
        if (body && body.metro) {
          opts.params = { metro: body.metro };
        }
        return {
          url: "",
          ...opts,
        };
      },
      providesTags: ["Emails"],
    }),
    createEmail: builder.mutation({
      query: (body) => ({
        url: "send",
        method: "POST",
        body,
      }),
      invalidatesTags: (result, error, arg) => !error && ["Emails"],
    }),
  }),
});

export const { useGetEmailsQuery, useCreateEmailMutation } = emailApi;
