import React, { Component, useState, useEffect } from "react";
import moment from "moment";
import cn from "classnames";
// import schedules from "../../../redux/constants/schedules";
import { TimeRanges } from "../../../util/time";

import "./style.scss";

window.moment = moment;

// const weekdays = moment.weekdaysShort();

const TimeRange = ({ from, to, isAvailable, isPreferred }) => {
  return (
    <div
      className={cn("time-range__span", {
        "time-range__span_active": isAvailable,
        // "time-range__span_favorite": isPreferred,
      })}
    >
      {isAvailable && (
        <React.Fragment>
          {from && (
            <React.Fragment>
              <div className="time-range__label time-range__label_from">
                {from}
              </div>
              <div className="time-range__tick time-range__tick_from" />
            </React.Fragment>
          )}
          {to && (
            <React.Fragment>
              <div className="time-range__label time-range__label_to">{to}</div>
              <div className="time-range__tick time-range__tick_to" />
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

const DaySchedule = ({ availableTime, preferredTime }) => {
  const [ranges, setRanges] = useState([]);
  useEffect(() => {
    const result = TimeRanges.map(({ from, to }, index) => ({
      from,
      to,
      isAvailable: availableTime?.includes(index),
      isPreferred: preferredTime?.includes(index),
    }));
    let prevI = null;
    result.forEach((r, i) => {
      if (
        prevI !== null &&
        prevI === i - 1 &&
        result[i - 1].isAvailable &&
        result[i].isAvailable
      ) {
        result[i - 1].to = null;
        result[i].from = null;
      }
      prevI = i;
    });
    setRanges(result);
  }, [availableTime]);
  return (
    <div className="time-range">
      {ranges.map(({ from, to, isAvailable, isPreferred }, index) => {
        return (
          <TimeRange
            from={from}
            to={to}
            isAvailable={isAvailable}
            isPreferred={isPreferred}
            key={index}
          />
        );
      })}
    </div>
  );
};

export default DaySchedule;
