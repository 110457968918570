import React, { useCallback, useEffect, useRef, useState } from 'react'
import { DateRangePicker } from 'react-date-range';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import HeaderTextLayout from '../../../components/shared/HeaderTextLayout';
import { actions } from '../../../redux/store/store';
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CancelIcon from "@mui/icons-material/Cancel";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import BackspaceIcon from "@mui/icons-material/Backspace";
import { createDate, DATE, DateCreater } from '../../../constants/variables/dates';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import Table from '../../../components/shared/Table';
import { CircularProgress, Fade, Menu, MenuItem } from '@mui/material';
import { getOperatorReport } from '../../../api/operator';
import ClearIcon from "@mui/icons-material/Clear";
import { IoSearchSharp } from "react-icons/io5";
import { Refresh } from '@mui/icons-material';
import { OperatorData, headers } from '../../../constants/csv/csvOperator';
import { dataExport, xlsxDataExport } from '../../../constants/extras/dataExport';
import { CSVLink } from 'react-csv';
import { useChangeRoleMutation } from "../../../api/admins";
import { sendSuccessMessage } from '../../../constants/extras/sendSuccessMsg';
import { sendErrorMessage } from '../../../constants/extras/errorHandlers';
import SwitchRoleConfirmation from '../../../components/common/SwitchRoleConfirmation';
import { exportMenu } from '../../../constants/variables/arrays';
import { PAGINATION } from '../../../constants/variables';

const { COMMON__LIMIT } = PAGINATION;

export default function Operator() {
    const isRefetch = useSelector((state) => state.refetch.value);
    const location = useLocation();
    const { pathname } = location;
    const wrapperRef = useRef(null);

    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(2);
    const [limit] = useState(COMMON__LIMIT);
    const [filteredOperator, setFilteredOperator] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [disable, setDisable] = useState(false);
    const [showDateRangePicker, setShowDateRangePicker] = useState(false);
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [state, setState] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: "selection",
        },
    ]);
    const [prevDate, setPrevDate] = useState(state);
    const [searchQuery, setSearchQuery] = useState("");
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [csvData, setCsvData] = useState(false);
    const csvInstance = useRef();
    const [loadingCsv, setLoading] = useState(false);
    const currentUser = useSelector((state) => state.auth.currentUser);
    const [changeRole] = useChangeRoleMutation();
    const [loadingRole, setLoadingRole] = useState(false)
    const [id, setId] = useState()
    const [switchRole, setSwitchRole] = useState([false, ""])


    useEffect(() => {
        actions.metro.selectMetro("");
        actions.refetch.setIsDataRefetch(true);
    }, [pathname]);

    useEffect(() => {
        if (isRefetch === false) return;
        setIsLoading(true);
        setHasMore(true);
        setFilteredOperator([]);
        setPage(1);

        (async () => {
            setDisable(true);
            const data = await getOperatorReport(
                1,
                limit,
                fromDate,
                toDate,
                searchQuery
            );
            setFilteredOperator(data);
            if (data && data?.length < limit) setHasMore(false);
            setPage(2);
            setDisable(false);
        })();
        actions.refetch.setIsDataRefetch(false);
        setIsLoading(false);

    }, [isRefetch]);

    const columns = [
        {
            name: "name",
            label: "Name",
            sortable: true,
            className: "w-auto pt-2 pb-2",
        },
        {
            name: "username", label: "Username", sortable: true, maxWidth: "180px",
        },
        {
            name: "phone",
            label: "Phone",
            sortable: true,
            className: "w-10 "
        },
        {
            name: "created",
            label: "Created",
            sortable: true,
            maxWidth: "170px",
            renderer: (item) => moment(item.created).format("MMM D, YYYY hh:mm A"),
        },
        {
            name: "lastLoginTime",
            label: "Last Login At",
            sortable: true,
            maxWidth: "170px",
            renderer: (item) => item.lastLoginTime && moment(item.lastLoginTime).format("LLL"),
        },
        currentUser && currentUser?.role === "root" ? {
            name: "operatortodriver",
            maxWidth: "100px",
            renderer: (item) => (
                <button className='btn btn_accent customized-details-btn' onClick={() => setSwitchRole([true, item._id])}>{(item._id === id && loadingRole) && <CircularProgress className="btn__progress" />}Change operator to driver</button>
            )
        } : { label: "HIDE" }
    ];

    const { M_D_Y } = DATE;
    const dateRange = `${fromDate
        ? `${createDate(fromDate, M_D_Y)} TO ${createDate(toDate, M_D_Y)}`
        : "Select Custom Date Range"
        }`;

    const isShowingDtRangePicker = showDateRangePicker ? (
        <KeyboardArrowUpIcon />
    ) : (
        <KeyboardArrowDownIcon />
    );

    const handleTimeRangeSelection = async () => {
        const { startDate, endDate } = state[0] || {};
        const { dt: strtDt } = new DateCreater(startDate);
        const { dt: endDt } = new DateCreater(endDate);
        setFromDate(strtDt);
        setToDate(endDt);
        setShowDateRangePicker(false);
        setPrevDate(state);
        actions.refetch.setIsDataRefetch(true);
    }

    const onCancelClick = () => {
        setShowDateRangePicker(false);
        setState(prevDate);
    };

    const onResetClick = async () => {
        setShowDateRangePicker(false);
        const currDt = [
            {
                startDate: new Date(),
                endDate: new Date(),
                key: "selection",
            },
        ]
        setFromDate("");
        setToDate("");
        setState(currDt);
        setPrevDate(currDt);
        actions.refetch.setIsDataRefetch(true);
    }

    const handleSearchingDriver = useCallback(
        async (c) => {
            const search = c === "CLEAR" ? "" : searchQuery;
            setFilteredOperator([]);
            setPage(1);
            setHasMore(true);
            setDisable(true);
            const data = await getOperatorReport(
                1,
                limit,
                fromDate,
                toDate,
                search
            );
            setDisable(false);
            setPage(2);
            if (data && data?.length < limit) setHasMore(false);
            setFilteredOperator(data);
        },
        [searchQuery, fromDate, toDate]
    );

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            handleSearchingDriver();
        }
    };

    const fetchMoreDrivers = async () => {
        setDisable(true);
        const data = await getOperatorReport(
            page,
            limit,
            fromDate,
            toDate,
            searchQuery
        );

        setDisable(false);
        setFilteredOperator([...filteredOperator, ...data]);
        if (data?.length === 0 || data?.length < limit)
            setHasMore(false);
        setPage(page + 1);
    };

    useEffect(() => {
        if (csvData && csvInstance.current && csvInstance.current.link) {
            setTimeout(() => {
                csvInstance.current.link.click();
                setCsvData(false);
            });
        }
    }, [csvData]);

    const downloadOperatorData = async () => {

        const data = await getOperatorReport(undefined, undefined, fromDate, toDate, searchQuery)
        if (data && Array.isArray(data) && data?.length > 0) {
            return data;
        }
        return;
    };

    const exportData = async (value) => {
        setAnchorEl(null)
        setLoading(true);
        const download = await downloadOperatorData()
        setLoading(false);

        const filename = "Operator.csv";
        const data = OperatorData(download)
        if (value === "csv") {
            const obj = dataExport(data, filename, headers)
            setCsvData(obj);
        } else if (value === "xlsx") {
            xlsxDataExport(data, "Operator")
        }
    }

    const handleSwitchRole = async (item) => {
        setLoadingRole(true)
        setId(item[0])
        let response = await changeRole({
            id: item[0],
            role: "user",
        });
        if (response && response.data) {
            sendSuccessMessage(`${response.data.role} role assigned successfully`)
            actions.refetch.setIsDataRefetch(true)
            setSwitchRole(false, "")
        } else {
            sendErrorMessage(`Something went wrong`)
            setSwitchRole(false, "")
        }
        setLoadingRole(false)

    }

    return (
        <>
            <SwitchRoleConfirmation switchRole={switchRole} setSwitchRole={setSwitchRole} handleSwitchRole={handleSwitchRole} name={"driver"} title={'Change operator to driver '} />
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="card">
                            <HeaderTextLayout
                                headerText="Operator List"
                                className="col-lg-2 col-md-2 main__driver"
                                data={
                                    filteredOperator && filteredOperator.length > 0
                                        ? `${filteredOperator.length} Operator Found`
                                        : ""
                                }
                                dataLength={
                                    Array.isArray(filteredOperator) ? filteredOperator.length : "0"
                                }
                            >
                                <div className="col-lg-10 col-md-10 col-sm-12 filter">
                                    <div className="filter__inner p-0 justify-content-end">
                                        <div className="filter__ctrls-holder filter__input-holder d-flex justify-content-end search__container">
                                            <div className="searchbar__container">
                                                <input
                                                    className="form__input customized__input"
                                                    value={searchQuery}
                                                    onChange={(e) => {
                                                        setSearchQuery(e.target.value);
                                                        if (e.target.value === "")
                                                            handleSearchingDriver("CLEAR");
                                                    }}
                                                    placeholder="Quick Search"
                                                    onKeyDown={handleKeyDown}
                                                    disabled={disable}
                                                />
                                                {searchQuery?.length > 0 && (
                                                    <ClearIcon
                                                        className="clear__btn filter__search-icon"
                                                        onClick={() => {
                                                            if (!disable) {
                                                                setSearchQuery("");
                                                                handleSearchingDriver("CLEAR");
                                                            }
                                                        }}
                                                        style={{
                                                            right: "45px",
                                                            top: "12px",
                                                        }}
                                                    />
                                                )}
                                                <button
                                                    disabled={searchQuery?.length <= 0}
                                                    className="search__button"
                                                    onClick={() => handleSearchingDriver()}
                                                >
                                                    <IoSearchSharp />
                                                </button>
                                            </div>
                                        </div>
                                        <div ref={wrapperRef}>
                                            <div
                                                onClick={() => {
                                                    !disable && setShowDateRangePicker(!showDateRangePicker);
                                                    setState(prevDate);
                                                }}
                                                className="form__input "
                                            >
                                                <span>{dateRange}</span>
                                                <span className="ml-2">{isShowingDtRangePicker}</span>
                                            </div>
                                            {showDateRangePicker && (
                                                <div className="mainDateRangePicker mt-3" style={{ right: 0 }}>
                                                    <DateRangePicker
                                                        onChange={(item) => setState([item.selection])}
                                                        months={1}
                                                        ranges={state}
                                                    />
                                                    <div className="button-container">
                                                        <button
                                                            onClick={onResetClick}
                                                            className="okButton clearButton"
                                                        >
                                                            <span>
                                                                <BackspaceIcon /> &nbsp;
                                                                <strong>RESET</strong>
                                                            </span>
                                                        </button>
                                                        <button onClick={onCancelClick} className="cancelButton">
                                                            <span>
                                                                <CancelIcon
                                                                    sx={{ width: "1.5rem", height: "1.5rem" }}
                                                                />
                                                                <strong>cancel</strong>
                                                            </span>
                                                        </button>
                                                        <button
                                                            onClick={handleTimeRangeSelection}
                                                            className="okButton"
                                                        >
                                                            <span>
                                                                <ThumbUpAltIcon /> &nbsp;
                                                                <strong>Done</strong>
                                                            </span>
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div>
                                            <button
                                                id="fade-button"
                                                className="btn btn_block btn_accent btn_export_15"
                                                onClick={(event) => setAnchorEl(event.currentTarget)}
                                                variant="contained"
                                                disabled={
                                                    loadingCsv || filteredOperator?.length === 0
                                                        ? true
                                                        : false
                                                }
                                            >
                                                {loadingCsv ? (
                                                    <div className="loading" style={{ color: "white" }}>
                                                        <span className="loading__text" style={{ color: "white" }}>
                                                            Exporting
                                                        </span>
                                                        ...
                                                    </div>
                                                ) : (
                                                    <> <span> Export <KeyboardArrowDownIcon className="mr-0" /></span> </>
                                                )}
                                            </button>
                                            <Menu
                                                id="fade-menu"
                                                MenuListProps={{
                                                    'aria-labelledby': 'fade-button',
                                                }}
                                                anchorEl={anchorEl}
                                                open={open}
                                                onClick={() => setAnchorEl(null)}
                                                TransitionComponent={Fade}
                                            >
                                                {exportMenu.map(({ value, name }) => (
                                                    <MenuItem key={name} value={name} onClick={() => exportData(value)} style={{ width: "100px" }}>
                                                        {name}
                                                    </MenuItem>

                                                ))}
                                            </Menu>
                                        </div>

                                        {csvData ? (
                                            <CSVLink
                                                data={csvData.data}
                                                headers={csvData.headers}
                                                filename={csvData.filename}
                                                ref={csvInstance}
                                            />
                                        ) : undefined}

                                        <button className="refresh_btn" onClick={() => actions.refetch.setIsDataRefetch(true)}>
                                            <Refresh className="refresh_icon" />
                                        </button>


                                    </div>
                                </div>
                            </HeaderTextLayout>

                            <InfiniteScroll
                                className='infinit_table'
                                dataLength={filteredOperator?.length}
                                next={fetchMoreDrivers}
                                hasMore={hasMore}
                                style={{
                                    paddingBottom: !hasMore ? "0px" : "10px",
                                }}
                                loader={
                                    <div
                                        className="d-flex justify-content-center align-items-center"
                                        style={{
                                            height:
                                                filteredOperator && filteredOperator?.length <= 0
                                                    ? "70vh"
                                                    : "auto",
                                        }}
                                    >
                                        <CircularProgress className="loading-progress" />
                                    </div>
                                }
                                height={"80vh"}
                                endMessage={
                                    filteredOperator &&
                                    filteredOperator?.length <= 0 && (
                                        <div
                                            style={{
                                                height: "70vh",
                                            }}
                                            className="d-flex justify-content-center align-items-center"
                                        >
                                            <p>
                                                <b>No Result Found</b>
                                            </p>
                                        </div>
                                    )
                                }
                            >
                                {isLoading ? (
                                    <div className="loading-holder">
                                        <CircularProgress className="loading-progress" />
                                    </div>
                                ) : (
                                    <Table
                                        columns={columns}
                                        items={filteredOperator}
                                        className="table_drivers"
                                        width="100%"
                                    />
                                )}
                            </InfiniteScroll>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}


