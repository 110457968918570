import { createApi } from "@reduxjs/toolkit/query/react";
import axios from "axios";
import { baseQueryWithAuthInterceptor } from "./util";
var qs = require('qs');

export const reportsApi = createApi({
  reducerPath: "reportsApi",
  baseQuery: baseQueryWithAuthInterceptor({ baseUrl: "/api/" }),
  tagTypes: ["Reports"],
  endpoints: (builder) => ({
    getTransactions: builder.query({
      query: (body) => {
        const opts = {};
        if (body && body.startDate && body.endDate) {
          opts.params = { startDate: body.startDate, endDate: body.endDate };
        }
        if (body && body.transactionId) {
          opts.params = { transactionId: body.transactionId };
        }
        return {
          url: "transactionReport",
          ...opts,
        };
      },
      providesTags: ["Reports"],
    }),
    getTransactionDrivers: builder.query({
      query: () => {
        return {
          url: "/transactionReport/drivers",
          method: "GET",
        };
      },
      providesTags: ["Drivers"],
    }),
    getEstimatedShiftPay: builder.query({
      query: () => {
        return {
          url: "shiftPayEstimation",
        };
      },
    }),
  }),
});

export const { useGetTransactionsQuery, useGetTransactionDriversQuery, useGetEstimatedShiftPayQuery } =
  reportsApi;

export const getTransactionsList = async (
  page,
  limit,
  startDate,
  endDate,
  search,
  isTotal,
  metroData,
  fromDateFilter,
  toDateFilter,
  adhocPayments,
  instantPayments,
  exceptionPayments,
  weeklyPayments,
  shiftPayments,
  shift,
  transactionStatus,
  driver,
  paymentMethod
) => {
  try {


    const response = await axios.get(
      `/api/transactionReport?page=${page ? page : ""}&limit=${limit ? limit : ""
      }&startDate=${startDate}&endDate=${endDate}&search=${search}&isTotal=${isTotal}&shiftFromDate=${fromDateFilter}&shiftToDate=${toDateFilter}&shift=${shift}
      &adhocPayments=${adhocPayments}&instantPayments=${instantPayments}&exceptionPayments=${exceptionPayments}&weeklyPayments=${weeklyPayments}&shiftPayments=${shiftPayments}&paymentMode=${paymentMethod?.value ? paymentMethod?.value : ""}`,
      {
        'params': { 'metro': metroData, 'status': transactionStatus?.length ? transactionStatus?.map(e => e?.value || '') : [], 'driver': driver?.length ? driver?.map(e => e?.value || '') : [] },
        'paramsSerializer': function (params) {
          return qs.stringify(params, { arrayFormat: 'repeat' })
        },
      }
    );
    if (response?.status === 200) return response?.data
      ;
    else return "Something went wrong";
  } catch (e) {
    return e.response.data
  }
};
