import { CircularProgress, Modal } from '@mui/material';
import React, { useCallback, useState } from 'react'
import { scheduleTime, sched_init } from '../../../constants/variables/arrays';
import { actions } from '../../../redux/store/store';
import { useEffect } from 'react';
import { useUpdateDriverMutation } from '../../../api/drivers';
import { sendSuccessMessage } from '../../../constants/extras/sendSuccessMsg';
import { checkForError } from '../../../constants/extras/errorHandlers';
import cn from "classnames";
import moment from 'moment';
import { Label } from '../Form';


function ScheduleModal(props) {
    const { id } = props;
    const driver = id?.driver
    const companyId = props && props?.id?.id && props?.id?.id;

    const [updateDriverMetro, { isLoading: isUpdatingDriver }] =
        useUpdateDriverMutation();

    const [days, setDays] = useState([])
    const [time, setTime] = useState([])
    const [timeAmount, setTimeAmount] = useState("")

    const weekdays = Array.apply(null, Array(7)).map(function (_, i) {
        return moment(i, "e")
            .startOf("week")
            .isoWeekday(i + 1)
            .format("ddd");
    });

    useEffect(() => {
        setDays(driver?.availableDays || []);
        setTime(driver?.availableTime || []);
        setTimeAmount(driver?.timeAmount || "")
    }, [])

    const onSubmit = useCallback(
        async () => {
            const params = {
                availableDays: days,
                availableTime: time,
                timeAmount: timeAmount,
                _id: companyId
            }
            let result = await updateDriverMetro(params);
            if (!result?.error) {
                sendSuccessMessage(`Schedule updated successfully`);
                actions.refetch.setIsDataRefetch(true);
            } else {
                checkForError(result);
            }
            actions.modal.closeSchedule();
        },
        [
            id,
            days,
            time,
            timeAmount,
            companyId,
            updateDriverMetro,
        ]
    );

    const onCancel = useCallback(() => {
        actions.modal.closeSchedule();
    }, []);

    const isDisbaled = (a, b) => {
        return (a.length == b.length) && a?.every((el) => {
            return b?.some((item) => item === el)
        })
    }

    return (
        <>
            <Modal
                open
                onClose={onCancel}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className={`modal after-loading`} style={{ maxWidth: "35.875rem" }}>
                    <div className="modal__header">
                        <h2 className="modal__title">{`Update Schedule`}</h2>
                    </div>
                    <div>
                        <div>
                            <Label title="Days" />
                            <div className="week-schedule">
                                {weekdays.map((elem, index) => (
                                    <div
                                        className={cn("week-schedule__day", {
                                            "week-schedule__day_active": days?.includes(index),
                                        })}
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            const check = days.includes(index)
                                            if (check) {
                                                setDays(days?.filter((elem) => elem !== index))
                                            } else {
                                                setDays([...days, index])
                                            }
                                        }}
                                        key={index}
                                    >
                                        {elem}
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="mt-3">
                            <Label title="Time" />
                            <div className="week-schedule" style={{ flexWrap: "wrap", rowGap: "5px" }}>
                                {scheduleTime.map((elem, index) => (
                                    <div
                                        className={cn("week-schedule__day", {
                                            "week-schedule__day_active": time?.includes(index),
                                        })}
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            const check = time.includes(index)
                                            if (check) {
                                                setTime(time?.filter((elem) => elem !== index))
                                            } else {
                                                setTime([...time, index])
                                            }
                                        }}
                                        key={index}
                                    >
                                        {elem}
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="mt-3">
                            <Label title="Time Amount" />
                            <div className="week-schedule">
                                {sched_init.map((elem, index) => (
                                    <div
                                        className={cn("week-schedule__day", {
                                            "week-schedule__day_active": timeAmount?.title === elem.title,
                                        })}
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            const check = timeAmount?.title === elem.title
                                            if (check) {
                                                setTimeAmount("")
                                            } else {
                                                setTimeAmount(elem)
                                            }
                                        }}
                                        key={index}
                                    >
                                        {elem?.title}
                                    </div>
                                ))}
                            </div>
                        </div>


                    </div>
                    <div className="modal__footer">
                        <button className="btn" onClick={() => actions.modal.closeSchedule()}>
                            Cancel
                        </button>
                        <div style={{position: 'relative'}}>
                            <button
                                className="btn btn_accent"
                                onClick={onSubmit}
                                disabled={isUpdatingDriver || !days?.length || !time?.length || !timeAmount?.title || 
                                    (isDisbaled(days, driver?.availableDays) && isDisbaled(time, driver?.availableTime) && timeAmount?.title === driver?.timeAmount?.title)
                                }
                            >
                                OK
                            </button>
                            {isUpdatingDriver && (
                                
                                    <CircularProgress size={18}
                                    sx={{
                                      color: '#fff',
                                      position: 'absolute',
                                      top: '50%',
                                      left: '83%',
                                      marginTop: '-8px',
                                      marginLeft: '-10px',
                                    }} />
                                
                            )}
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default ScheduleModal
