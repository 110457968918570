import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuthInterceptor } from "./util";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: baseQueryWithAuthInterceptor({ baseUrl: "/api/" }),
  tagTypes: ["CurrentUser"],
  endpoints: (builder) => ({
    getCurrentUser: builder.query({
      query: () => "user",
      providesTags: ["CurrentUser"],
    }),
    getInvitationCode: builder.query({
      query: () => "invitationCode",
    }),
    setInvitationCode: builder.mutation({
      query: (body) => ({
        url: "invitationCode",
        method: "POST",
        body,
      }),
    }),
    login: builder.mutation({
      query: (body) => ({
        url: "login",
        method: "POST",
        body,
      }),
      invalidatesTags: ["CurrentUser"],
    }),
    register: builder.mutation({
      query: (body) => ({
        url: "register",
        method: "POST",
        body,
      }),
    }),
    logout: builder.mutation({
      query: () => ({
        url: "logout",
        method: "POST",
      }),
    }),
    forgotPassword: builder.mutation({
      query: (body) => ({
        url: "forgotPassword",
        method: "POST",
        body,
      }),
      invalidatesTags: ["CurrentUser"],
    }),
    resetPassword: builder.mutation({
      query: (body) => ({
        url: `resetPassword/${body.hash}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["CurrentUser"],
    }),
    getEmailByHash: builder.mutation({
      query: (val) => ({
        url: `email/${val.hash}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetCurrentUserQuery,
  useGetInvitationCodeQuery,
  useSetInvitationCodeMutation,
  useLoginMutation,
  useRegisterMutation,
  useLogoutMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useGetEmailByHashMutation,
} = authApi;
