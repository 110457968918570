import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { CircularProgress } from "@mui/material";
import { useLocation } from "react-router-dom";

import Table from "../../../components/shared/Table";
import { actions } from "../../../redux/store/store";
import "../../../styles/_data.scss";
// import "./style.scss";
import HeaderTextLayout from "../../../components/shared/HeaderTextLayout";
import MultiSelect from "../../../constants/multiSelectDropdown";
import { useGetMetrosQuery } from "../../../api/metro";
import { MdPlace } from "react-icons/md";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CancelIcon from "@mui/icons-material/Cancel";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import BackspaceIcon from "@mui/icons-material/Backspace";
import {
  DateCreater,
  DATE,
  createDate,
} from "../../../constants/variables/dates";
import { DateRangePicker } from "react-date-range";
import { getPiplineStageReport } from "../../../api/piplineStage";
import PiplineStageChart from "../../../components/Chart/pipelineStage";
import moment from "moment";


export default function PiplineStage() {
  const isRefetch = useSelector((state) => state.refetch.value);
  const location = useLocation();
  const { pathname } = location;
  const wrapperRef = useRef(null);

  let d = new Date();
  d.setMonth(d.getMonth() - 1);

  const { data: metros } = useGetMetrosQuery(null, {
    refetchOnMountOrArgChange: true,
  });

  const [filteredPipline, setFilteredPipline] = useState([]);
  const [filteredAllPipline, setFilteredAllPipline] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [disable, setDisable] = useState(false);
  const [showDateRangePicker, setShowDateRangePicker] = useState(false);
  const [fromDate, setFromDate] = useState(moment(d).format("yyyy-MM-DD"));
  const [toDate, setToDate] = useState(moment().format("yyyy-MM-DD"));
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [prevDate, setPrevDate] = useState(state);
  const [metro, setMetro] = useState([])
  const [display, setDisplay] = useState([])

  useEffect(() => {
    let startD = `${moment(fromDate).format("MM-DD-yyyy")}`;
    let endD = `${moment(toDate).format("MM-DD-yyyy")}`;
    const date = [
      {
        startDate: new Date(startD),
        endDate: new Date(endD),
        key: "selection",
      },
    ]
    setState(date);
    setPrevDate(date);
  }, [fromDate]);

  useEffect(() => {
    const data = filteredPipline.map((item) => {
      return item.data.map((val) => {
        return {
          label: val.label,
          level: val.level
        }
      })
    })
    setDisplay(data)
  }, [filteredPipline])

  useEffect(() => {
    const allpipeline = filteredPipline[0]?.data || []
    const filterData = allpipeline?.filter((item) => item.level === 0)
    setFilteredAllPipline(filterData)
  }, [filteredPipline])

  useEffect(() => {
    actions.metro.selectMetro("");
    actions.refetch.setIsDataRefetch(true);
  }, [pathname]);

  const { M_D_Y } = DATE;

  const styles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#4195F5" : "white",
      paddingTop: "3px",
      paddingBottom: "0px",
      "&:hover": {
        background: "#4195F5",
        color: "#FFFFFF",
        transition: "all 0.8s",
      },
    }),
    control: (styles) => ({
      ...styles,
      borderRadius: "5px",
      padding: "3px 0",
      boxShadow: "none",
      "&:hover": {
        border: "2px solid #dadada",
      },
      border: "2px solid #dadada",
      width: "20rem",
      height: "2.9rem",
      overflowY: "auto",
      padding: 0,
      "@media only screen and (max-width: 1600px)": {
        width: "16rem",
      },
      "@media only screen and (max-width: 1400px)": {
        width: "15rem",
      },
    }),
  }

  const dateRange = `${fromDate
    ? `${createDate(fromDate, M_D_Y)} TO ${createDate(toDate, M_D_Y)}`
    : "Select Custom Date Range"
    }`;

  const isShowingDtRangePicker = showDateRangePicker ? (
    <KeyboardArrowUpIcon />
  ) : (
    <KeyboardArrowDownIcon />
  );

  const metroData = metro?.map(item => item.value)
  useEffect(() => {
    if (isRefetch === false) return;

    setFilteredPipline([]);
    (async () => {
      setDisable(true);
      setIsLoading(true);
      const data = await getPiplineStageReport(
        fromDate,
        toDate,
        metroData
      );
      setFilteredPipline(data.result);
      setDisable(false);
      setIsLoading(false);
    })();
    actions.refetch.setIsDataRefetch(false);

  }, [isRefetch]);

  useEffect(() => {
    if (isRefetch === false) return;
  }, [isRefetch]);

  const AllDatacolumns = [
    {
      name: "label",
      label: "Pipeline Stage",
      sortable: true,
      className: "pt-2 pb-2",
    },
    {
      name: "counts",
      label: "Count",
      sortable: true,
      renderer: (item) => `${item.counts}`
    },
    {
      name: "percentage",
      label: "Percentage",
      sortable: true,
    },
  ];

  const handleDisplayData = (item, title) => {
    return (
      item.data.map((data, i) => {
        return data.label === title && data.level === 0 && (
          <React.Fragment key={i}>
            <span>{data.counts}</span> <br />
            <span>{`(${data.percentage})`}</span>
          </React.Fragment>
        )
      })
    )
  }

  const hidecolumn = (item) => {
    const data = display.some((e) => {
      return e.some((y) => {
        return y.label === item && y.level === 0
      })
    })
    return data
  }

  const columns = [
    {
      name: "metro",
      label: "Metro",
      sortable: true,
    },
    hidecolumn("Created Driver") ? {
      name: "createdDriver",
      label: "Created Driver",
      sortable: true,
      className: " table__td_centered",
      renderer: (item) => {
        return handleDisplayData(item, "Created Driver")
      }
    } : { label: "HIDE" },
    hidecolumn("Onboarding Start") ? {
      name: "onboardingStart",
      label: "Onboarding Start",
      sortable: true,
      className: " table__td_centered",
      renderer: (item) => {
        return handleDisplayData(item, "Onboarding Start")
      }
    } : { label: "HIDE" },
    hidecolumn("Background Check Start") ? {
      name: "backgroundCheckStart",
      label: "Background Check Start",
      sortable: true,
      className: " table__td_centered",
      renderer: (item) => {
        return handleDisplayData(item, "Background Check Start")
      }
    } : { label: "HIDE" },
    hidecolumn("Background Check Complete") ? {
      name: "backgroundCheckComplete",
      label: "Background Check Complete",
      sortable: true,
      className: " table__td_centered",
      renderer: (item) => {
        return handleDisplayData(item, "Background Check Complete")
      }
    } : { label: "HIDE" },
    hidecolumn("Onboarding Finished Tax") ? {
      name: "onboardingFinishedTax",
      label: "Onboarding Finished Tax",
      sortable: true,
      className: " table__td_centered",
      renderer: (item) => {
        return handleDisplayData(item, "Onboarding Finished Tax")
      }
    } : { label: "HIDE" },
    hidecolumn("Onboarding Finished Insurance") ? {
      name: "onboardingFinishedInsurance",
      label: "Onboarding Finished Insurance",
      sortable: true,
      className: " table__td_centered",
      renderer: (item) => {
        return handleDisplayData(item, "Onboarding Finished Insurance")
      }
    } : { label: "HIDE" },
    hidecolumn("Onboarding Finished Vehicle") ? {
      name: "onboardingFinishedVehicle",
      label: "Onboarding Finished Vehicle",
      sortable: true,
      className: " table__td_centered",
      renderer: (item) => {
        return handleDisplayData(item, "Onboarding Finished Vehicle")
      }
    } : { label: "HIDE" },
    hidecolumn("Onboarding Finished Videos") ? {
      name: "onboardingFinishedVideos",
      label: "Onboarding Finished Videos",
      sortable: true,
      className: " table__td_centered",
      renderer: (item) => {
        return handleDisplayData(item, "Onboarding Finished Videos")
      }
    } : { label: "HIDE" },
    hidecolumn("Onboarding Finished DL") ? {
      name: "onboardingFinishedDL",
      label: "Onboarding Finished DL",
      sortable: true,
      className: " table__td_centered",
      renderer: (item) => {
        return handleDisplayData(item, "Onboarding Finished DL")
      }
    } : { label: "HIDE" },
    hidecolumn("Onboarding Finished BC") ? {
      name: "onboardingFinishedBC",
      label: "Onboarding Finished BC",
      sortable: true,
      className: " table__td_centered",
      renderer: (item) => {
        return handleDisplayData(item, "Onboarding Finished BC")
      }
    } : { label: "HIDE" },
    hidecolumn("Onboarding Complete") ? {
      name: "onboardingComplete",
      label: "Onboarding Complete",
      sortable: true,
      className: " table__td_centered",
      renderer: (item) => {
        return handleDisplayData(item, "Onboarding Complete")
      }
    } : { label: "HIDE" },
    hidecolumn("Background Check Submitted") ? {
      name: "backgroundCheckSubmitted",
      label: "Background Check Submitted",
      sortable: true,
      className: " table__td_centered",
      renderer: (item) => {
        return handleDisplayData(item, "Background Check Submitted")
      }
    } : { label: "HIDE" },
    hidecolumn("Background Check Not Submitted") ? {
      name: "backgroundCheckNotSubmitted",
      label: "Background Check Not Submitted",
      sortable: true,
      className: " table__td_centered",
      renderer: (item) => {
        return handleDisplayData(item, "Background Check Not Submitted")
      }
    } : { label: "HIDE" },
    hidecolumn("Approved Driver") ? {
      name: "approvedDriver",
      label: "Approved Driver",
      sortable: true,
      className: " table__td_centered",
      renderer: (item) => {
        return handleDisplayData(item, "Approved Driver")
      }
    } : "HIDE",
    hidecolumn("Background Check Pending") ? {
      name: "backgroundCheckPending",
      label: "Background Check Pending",
      sortable: true,
      className: " table__td_centered",
      renderer: (item) => {
        return handleDisplayData(item, "Background Check Pending")
      }
    } : { label: "HIDE" },
    hidecolumn("Background Check Consider") ? {
      name: "backgroundCheckConsider",
      label: "Background Check Consider",
      sortable: true,
      className: " table__td_centered",
      renderer: (item) => {
        return handleDisplayData(item, "Background Check Consider")
      }
    } : { label: "HIDE" },
    hidecolumn("Background Check Clear") ? {
      name: "backgroundCheckClear",
      label: "Background Check Clear",
      sortable: true,
      className: " table__td_centered",
      renderer: (item) => {
        return handleDisplayData(item, "Background Check Clear")
      }
    } : { label: "HIDE" },
    hidecolumn("Assigned") ? {
      name: "assigned",
      label: "Assigned",
      sortable: true,
      className: " table__td_centered",
      renderer: (item) => {
        return handleDisplayData(item, "Assigned")
      }
    } : { label: "HIDE" },
    hidecolumn("Intrested") ? {
      name: "intrested",
      label: "Intrested",
      sortable: true,
      className: " table__td_centered",
      renderer: (item) => {
        return handleDisplayData(item, "Intrested")
      }
    } : { label: "HIDE" },
    hidecolumn("Completed 1 Shift") ? {
      name: "completedOneShift",
      label: "Completed 1 Shift",
      sortable: true,
      className: " table__td_centered",
      renderer: (item) => {
        return handleDisplayData(item, "Completed 1 Shift")
      }
    } : { label: "HIDE" },
    hidecolumn("Completed 2 To 4 Shifts") ? {
      name: "CompletedTwotoFourShifts",
      label: "Completed 2 To 4 Shifts",
      sortable: true,
      className: " table__td_centered",
      renderer: (item) => {
        return handleDisplayData(item, "Completed 2 To 4 Shifts")
      }
    } : { label: "HIDE" },
    hidecolumn("Completed 5 Plus Shifts") ? {
      name: "CompletedFivePlusShifts",
      label: "Completed 5 Plus Shifts",
      sortable: true,
      className: " table__td_centered",
      renderer: (item) => {
        return handleDisplayData(item, "Completed 5 Plus Shifts")
      }
    } : { label: "HIDE" },
  ]

  const handleTimeRangeSelection = async () => {
    const { startDate, endDate } = state[0] || {};
    const { dt: strtDt } = new DateCreater(startDate);
    const { dt: endDt } = new DateCreater(endDate);
    setFromDate(strtDt);
    setToDate(endDt);
    setShowDateRangePicker(false);
    setPrevDate(state);
    actions.refetch.setIsDataRefetch(true);
  }

  const onCancelClick = () => {
    setShowDateRangePicker(false);
    setState(prevDate);
  };

  const onResetClick = async () => {
    setShowDateRangePicker(false);
    // const date = [{
    //   startDate: new Date(),
    //   endDate: new Date(),
    //   key: "selection",
    // }]
    setFromDate(moment(d).format("yyyy-MM-DD"));
    setToDate(moment().format("yyyy-MM-DD"));
    // setState(date);
    // setPrevDate(date)
    actions.refetch.setIsDataRefetch(true);
  };

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="card">
            <HeaderTextLayout
              headerText="Pipeline Stage Report"
              className="col-lg-2 col-md-2 main__driver"
              dataLength={0}
            >
              <div className="col-lg-10 col-md-10 col-sm-12 filter">
                <div className="filter__inner p-0 justify-content-end">
                  <div className="d-flex align-items-center mr-2">
                    <label style={{ fontWeight: "normal" }} className="m-0 mb-1">
                      <MdPlace /> Metro &nbsp;
                    </label>
                    <MultiSelect
                      placeholder="Select Metro"
                      value={metro}
                      styles={styles}
                      itemData={metros?.map((item, i) => {
                        return {
                          value: item?.name ? item?.name : "-",
                          label: `${item?.name ? item?.name : ""}`,
                        };
                      })}
                      onChange={(value) => {
                        setMetro(value)
                        actions.refetch.setIsDataRefetch(true);
                      }}
                    />
                  </div>
                  <div ref={wrapperRef}>
                    <div
                      onClick={() => {
                        !disable && setShowDateRangePicker(!showDateRangePicker);
                        setState(prevDate);
                      }}
                      className="form__input "
                    >
                      <div style={{position: 'relative'}}>
                      <span className="label-date-picker">Date</span>
                    </div>
                      <span>{dateRange}</span>
                      <span className="ml-2">{isShowingDtRangePicker}</span>
                    </div>
                    {showDateRangePicker && (
                      <div className="mainDateRangePicker mt-3" style={{ right: 0 }}>
                        <DateRangePicker
                          onChange={(item) => setState([item.selection])}
                          months={1}
                          ranges={state}
                        />
                        <div className="button-container">
                          <button
                            onClick={onResetClick}
                            className="okButton clearButton"
                          >
                            <span>
                              <BackspaceIcon /> &nbsp;
                              <strong>RESET</strong>
                            </span>
                          </button>
                          <button onClick={onCancelClick} className="cancelButton">
                            <span>
                              <CancelIcon
                                sx={{ width: "1.5rem", height: "1.5rem" }}
                              />
                              <strong>cancel</strong>
                            </span>
                          </button>
                          <button
                            onClick={handleTimeRangeSelection}
                            className="okButton"
                          >
                            <span>
                              <ThumbUpAltIcon /> &nbsp;
                              <strong>Done</strong>
                            </span>
                          </button>
                        </div>
                      </div>
                    )}
                  </div>

                </div>
              </div>
            </HeaderTextLayout>

            {isLoading ? (
              <div className="loading-holder">
                <CircularProgress className="loading-progress" />
              </div>
            ) : (
              <>
                <div style={{ height: "75vh", overflow: "auto" }}>
                  <div className="d-flex justify-content-center align-items-center" style={{ gap: "50px" }}>
                    <div className="col col-3" style={{ overflow: filteredPipline?.length > 1 && "auto" }}>
                      <Table
                        columns={AllDatacolumns}
                        items={filteredAllPipline}
                        className="table_drivers"
                        width="100%"
                      />
                      {
                        filteredPipline && filteredPipline?.length <= 0 && (
                          <div
                            style={{ height: "70vh" }}
                            className="d-flex justify-content-center align-items-center"
                          >
                            <p>
                              <b>No Result Found</b>
                            </p>
                          </div>
                        )
                      }
                    </div>
                    <div className="col col-8">
                      <PiplineStageChart filteredPipline={filteredPipline} />
                    </div>
                  </div>
                  <div className="mt-5" style={{ width: "100%" }}>
                    {
                      filteredPipline && filteredPipline?.length > 1 && (
                        <Table
                          columns={columns}
                          items={filteredPipline.filter((d, i) => {
                            return i !== 0
                          })}
                          className="table_drivers"
                          width="100%"
                        />
                      )
                    }
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
