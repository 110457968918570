import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuthInterceptor } from "./util";

export const shiftTemplateApi = createApi({
  reducerPath: "shiftTemplateApi",
  baseQuery: baseQueryWithAuthInterceptor({ baseUrl: "/api/shiftTemplates" }),
  tagTypes: ["shiftTemplate"],
  endpoints: (builder) => ({
    getShiftTemplates: builder.query({
      query: (body) => {
        const opts = {};

        if (body && body.ids) {
          opts.params = { ids: JSON.stringify(body.ids) };
        }
        return {
          url: "",
          ...opts,
        };
      },
      providesTags: ["shiftTemplate"],
    }),

    getShiftTemplate: builder.query({
      query: (id) => id,
      providesTags: ["shiftTemplate"],
    }),
    updateShiftTemplate: builder.mutation({
      query: (body) => ({
        url: body._id,
        method: "PATCH",
        body,
      }),
      invalidatesTags: (result, error, arg) =>
        !error && [{ type: "shiftTemplate", _id: result._id }],
    }),
    createShiftTemplate: builder.mutation({
      query: (body) => ({
        url: "",
        method: "POST",
        body,
      }),
      invalidatesTags: (result, error, arg) => !error && ["shiftTemplate"],
    }),
    changeStatus: builder.mutation({
      query: (body) => ({
        url: `${body._id}/${body.status}`,
        method: "POST",
        body,
      }),
      invalidatesTags: (result, error, arg) =>
        !error && [{ type: "shiftTemplate", _id: result._id }],
    }),
    removeShiftTemplate: builder.mutation({
      query: (id) => ({
        url: id,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => !error && ["shiftTemplate"],
    }),
  }),
});

export const {
  useGetShiftTemplatesQuery,
  useGetShiftTemplateQuery,
  useUpdateShiftTemplateMutation,
  useCreateShiftTemplateMutation,
  useChangeStatusMutation,
  useRemoveShiftTemplateMutation,
} = shiftTemplateApi;
