import React from "react";
import moment from "moment";

const Description = (props) => {
  return (
    <dl className="term term_inline">
      <dt className="term__title extra">{props.title}:</dt>
      <dd className="term__value extra">
        {props.description} {props.device}
      </dd>
    </dl>
  );
};

const DevieAndLoginInfo = (props) => {
  return (
    <div>
      <Description
        title="Last Login at"
        description={
          props?.order?.driver?.lastLoginTime
            ? moment(props?.order?.driver?.lastLoginTime).format("LLL")
            : ""
        }
        device={
          props?.order?.driver?.deviceInfo?.type
            ? `(${props?.order?.driver?.deviceInfo?.type})`
            : ""
        }
      />
    </div>
  );
};

export default DevieAndLoginInfo;
