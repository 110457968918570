import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

export const dataExport = (data, filename, headers) => {
    const obj = {
        headers: headers,
        data: data,
        filename: filename
    };
    return obj
}

export const xlsxDataExport = (data, filename) => {
    const fileType = "xlsx"
    const fileExtention = '.xlsx'

    const ws = XLSX.utils.json_to_sheet(data, filename)
    const wb = { Sheets: { "data": ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
    const newCsvData = new Blob([excelBuffer], { type: fileType })

    return FileSaver.saveAs(newCsvData, filename + fileExtention)
}