import { Modal } from '@mui/material';
import React, { useRef, useState } from 'react'
import { bulkOrderValidationSchema } from '../../../../constants/validaitonSchema';
import { createDate, DATE } from '../../../../constants/variables/dates';
import { actions } from '../../../../redux/store/store';
import RouteDateRange from '../RouteDateRange';
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useOutsideAlerter } from '../../../../constants/outsideAlert';
import Timepicker from '../../Timepicker';
import { Controller } from "react-hook-form";
import { API_SUCCESS } from '../../../../constants/variables/successMessage';
import { useUpdateBulkOrderMutation } from '../../../../api/orders';
import { sendSuccessMessage } from '../../../../constants/extras/sendSuccessMsg';
import { checkForError } from '../../../../constants/extras/errorHandlers';
import { ErrorMessage } from '../../Form';
import MetroSelect from '../../metroSelect';
import MultiSelect from '../../../../constants/multiSelectDropdown';
import { shiftTypeOption } from '../../../../constants/menuLists';
import { bulkUpdateShiftTypeReactSelectStyle } from '../../../../constants/multiSelectDropdown/multiSelectStyle';

function BulkOrderModal(props) {
    const { fromDate, toDate, metro, selectedRouteMethod } = props?.id || {};
    const initArr = [
        {
            startDate: new Date(fromDate),
            endDate: new Date(toDate),
            key: "selection",
        },
    ];
    const [showDateRangePicker, setShowDateRangePicker] = useState(false);
    const [state, setState] = useState(initArr);

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, setShowDateRangePicker);
    const { UPDATED } = API_SUCCESS.SHIFT;



    const [update, { isLoading: isUpdating }] = useUpdateBulkOrderMutation();

    const form = useForm({
        defaultValues: {
            metro: metro || '',
            startTime: '',
            updateTime: '',
            // routeMethod: selectedRouteMethod || '',
            shiftType: [],
        },
        resolver: yupResolver(bulkOrderValidationSchema),
    });

    const { control, handleSubmit, setValue, formState: { errors, error } } = form;

    const onCancel = () => {
        actions.modal.closeBulkOrderModal();
    };

    const handleCustomRange = () => setShowDateRangePicker(!showDateRangePicker);
    const handleCloseDtPickr = () => setShowDateRangePicker(false);

    const handleDataWithDtFormatting = (state) => {
        return {
            startDate: createDate(state[0]?.startDate, DATE.Y_M_D),
            endDate: createDate(state[0]?.endDate, DATE.Y_M_D),
        };
    };

    const onSubmit = async (data) => {
        const dateRangeData = handleDataWithDtFormatting(state);
        
        const formData = {
            fromDate: dateRangeData?.startDate,
            endDate: dateRangeData?.endDate,
            metro: data?.metro,
            // routeMethod: data?.routeMethod?.value,
            startTime: data?.startTime,
            update_startTime: data?.updateTime,
            shiftType: data.shiftType.map((item) => item.value),
        }

        const result = await update(formData);

        if (!result?.error && result?.data?.status === 200) {
            sendSuccessMessage(UPDATED);
            actions.refetch.setIsDataRefetch(true);
            actions.modal.closeBulkOrderModal();
        } else checkForError(result);

    };

    const DateRangeSelectProps = {
        form: form,
        showDateRangePicker,
        fromDate: state[0]?.startDate,
        toDate: state[0]?.endDate,
        state,
        className: "w-100",
        title: "Select Route Date*",
        formTitle: { fromDate: "fromDate", toDate: "toDate" },
        handleCloseDtPickr,
        handleCustomRange,
        handleChangeDtRange: (item) => setState(item),
    };

    const Label = ({ component, title, className, htmlFor, style }) => {
        const CustomizeComponent = component || "label";
        return (
            <CustomizeComponent
                style={style || null}
                className={`form__label ${className || ""}`}
                htmlFor={htmlFor || ""}
            >
                {title}
            </CustomizeComponent>
        );
    };

    const styles = {
        placeholder: (base) => ({
          ...base,
          fontSize: "14px",
          fontWeight: "normal"
        }),
        control: (styles) => ({
          ...styles,
          borderRadius: "5px",
          boxShadow: "none",
          minHeight: "2.8rem",
          "&:hover": {
            border: "2px solid #dadada",
          },
          border: "2px solid #dadada",
          fontSize: "13px",
        }),
        menu: (styles) => ({
            ...styles,
            zIndex: "10000",
          }),
      }

    return (
        <Modal
            open
            onClose={onCancel}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className={`modal after-loading`} style={{ width: "30rem" }}>
                <div className="modal__header">
                    <h2 className="modal__title">Bulk Shift Update</h2>
                </div>
                <div className="modal__body">
                    <div>
                        <RouteDateRange {...DateRangeSelectProps} />
                    </div>

                    <div className='d-flex flex-wrap justify-content-between' style={{paddingBottom: "0.625rem"}}>

                    <div style={{ width:"47%" }}>
                        <Label title={"Select Metro*"} />
                        <Controller
                            control={control}
                            name="metro"
                            render={({
                                field: { onChange, value, ref },
                                fieldState: { error },
                            }) => (
                                <React.Fragment>
                                    <MetroSelect
                                        value={value || ""}
                                        onChange={(e, data) => onChange(data)}
                                        ref={ref}
                                        style={{ width: "100%" }}
                                    />
                                    <ErrorMessage error={error} />
                                </React.Fragment>
                            )}
                        />
                    </div>

                    {/* <div style={{ width:"47%" }}>
                        <Label title={"Select Route Method"} />
                        <Controller
                            control={control}
                            name="routeMethod"
                            render={({
                                field: { onChange, value },
                                fieldState: { error },
                            }) => (
                                <ReactSelect
                                    options={shiftRouteSelectOption}
                                    isClearable={true}
                                    onChange={(item) => onChange(item)}
                                    placeholder="Select Route Method"
                                    value={value}
                                    components={{
                                        IndicatorSeparator: () => null,
                                    }}
                                    styles={styles}
                                />
                            )}
                        />
                        <ErrorMessage error={errors.routeMethod} />
                    </div> */}
                    <div style={{ width:"47%" }}>
                        <Label title={"Select Shift Type"} />
                        <Controller
                            control={control}
                            name="shiftType"
                            render={({
                                field: { onChange, value },
                                fieldState: { error },
                            }) => (
                                <MultiSelect
                                    placeholder="Select Shift Type"
                                    itemData={shiftTypeOption.map((item) => ({
                                        value: item.value,
                                        label: item.title,
                                    }))}
                                    styles={bulkUpdateShiftTypeReactSelectStyle}
                                    value={value}
                                    onChange={(selected) => onChange(selected || [])}
                                />
                            )}
                        />
                        <ErrorMessage error={errors.shiftType} />
                    </div>
                    </div>

                    <div className='d-flex flex-wrap justify-content-between'>
                        <div style={{ width:"47%" }}>
                            <Label title="Start Time:" />
                            <Controller
                                control={control}
                                name="startTime"
                                render={({
                                    field: { onChange, value },
                                    fieldState: { error },
                                }) => (
                                    <Timepicker
                                        value={value}
                                        updateTravelTime={(val) => setValue("startTime", val)}
                                        error={error}
                                    />
                                )}
                            />
                        </div>
                        <div style={{ width:"47%" }}>
                            <Label title="Update Time:" />
                            <Controller
                                control={control}
                                name="updateTime"
                                render={({
                                    field: { onChange, value },
                                    fieldState: { error },
                                }) => (
                                    <Timepicker
                                        value={value}
                                        updateTravelTime={(val) => setValue("updateTime", val)}
                                        error={error}
                                    />
                                )}
                            />
                        </div>
                    </div>

                    {errors.startTime || errors.updateTime && (
                        <ErrorMessage error={errors.startTime || errors.updateTime} />
                    )}


                </div>
                <div className="modal__footer">
                    <button className="btn" onClick={onCancel}>
                        Cancel
                    </button>
                    <button className="btn btn_accent" onClick={handleSubmit(onSubmit)} disabled={isUpdating}>
                        Update
                    </button>
                </div>
            </div>
        </Modal>
    )
}

export default BulkOrderModal


