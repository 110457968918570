import React, { useState } from "react";
import { NavItem } from "react-bootstrap";
import { useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";

import "./menu.scss";
import SidebarMenu from "./SidebarMenu";
import brandName from "../../images/logo-name.svg";
import { routesList } from "../../routers";
import btLogo from "../../images/bt-logo.svg"

const Menu = () => {
  const { pathname } = useLocation();
  const { role: userRole } = useSelector(
    (state) => state.auth.currentUser || {}
  );
  const [isOpen, setIsOpen] = useState("");

  return (
    ![
      "/login",
      "/register",
      "/confirmation",
      "/forgotPassword",
      "/resetPassword",
    ].includes(pathname) && (
      <aside
        className="main-sidebar"
        style={{
          backgroundColor: "#003878",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <span className="brand-link">
          <img src={brandName} className="brandlogosidebar px-2" alt="big_logo" />
          <img src={btLogo} className="brandlogosmall" alt="small_logo" />
        </span>

        <ul
          className="nav nav-pills flex-column"
          data-widget="treeview"
          role="menu"
          data-accordion="false"
        >
          {routesList.map((route, idx) => {
            const { name, path, subRoutes, icon, role } = route;
            if (role?.includes(userRole)) {
              if (subRoutes) {
                return (
                  <SidebarMenu
                    setIsOpen={setIsOpen}
                    route={route}
                    isOpen={isOpen}
                    key={name}
                    userRole={userRole}
                  />
                );
              }
              return (
                <NavItem
                  className="has-treeview menu-open"
                  key={idx}
                  style={{ whiteSpace: "nowrap" }}
                >
                  <NavLink
                    to={`/${path}`}
                    className={({ isActive }) =>
                      `nav_link ${isActive ? "navLink_active" : ""}`
                    }
                  >
                    <span className="nav-item nav__font">{icon}</span>
                    <p className="navLink_name">{name || path}</p>
                  </NavLink>
                </NavItem>
              );
            }
            return null;
          })}
        </ul>
      </aside>
    )
  );
};

export default Menu;
