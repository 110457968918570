import React from 'react'
import moment from 'moment';
import { CircularProgress, Modal, Rating } from '@mui/material'
import { actions } from '../../../../redux/store/store';
import Table from '../../Table';
import details_icon from "../../../../images/details.svg"
import "./style.scss"
import { useGetCoachingHistoryQuery } from '../../../../api/coaching';
import { handleStatusesBadge } from '../../../../constants/extras/statusesBadge';

function CoachingHistoryModal({ id }) {


    const { data: getData, isFetching } = useGetCoachingHistoryQuery(id?._id, {
        refetchOnMountOrArgChange: true,
    })

    const onCancel = () => {
        actions.modal.closeCoachingHistory();
    };

    const columns = [
        {
            name: "date",
            label: "Date of coach",
            sortable: true,
            maxWidth: "100px",
            className: "py-2",
            renderer: (item) => item?.date ? moment(item?.date).format("MM-DD-YYYY") : '-',
        },
        {
            name: "coachReason",
            label: "Coach reason",
            sortable: true,
            renderer: (item) => item?.coachReason?.reason || "-",
        },
        {
            name: "shiftDate",
            label: "Shift date and number",
            maxWidth: "150px",
            sortable: true,
            renderer: (item) =>
                `${item?.shiftDate ? moment(item?.shiftDate).format("MM-DD-YYYY") : ""} ${item?.shiftNumber ? `(${item?.shiftNumber})` : ""}`,
        },
        {
            name: "coachingType",
            label: "Coaching type",
            sortable: true,
        },
        {
            name: "coacher",
            label: "Coacher",
            sortable: true,
        },
        {
            name: "coachQualification",
            label: "Coach qualification",
            sortable: true,
            maxWidth: "120px",
            renderer: (item) => (
                <div className="rating_contain">
                    <Rating name="read-only" value={item?.coachQualification || 0} precision={0.5} readOnly />
                </div>
            ),
        },



        {
            name: "driverQualification",
            label: "Driver qualification",
            sortable: true,
            maxWidth: "120px",
            renderer: (item) => (
                <>
                    <div className="rating_contain">
                        <Rating name="read-only"
                            value={item?.status === "Pending" ? 0 : item?.driverQualification || item?.driverQualification}
                            precision={0.5} readOnly />
                    </div>
                </>
            ),
        },
        {
            name: "status",
            label: "Status",
            sortable: true,
            maxWidth: "100px",
            className: "table__td_centered",
            renderer: (item) => {
                return (
                    <span
                        className="textStyleForStatus"
                        style={handleStatusesBadge(item?.status.toLowerCase())}
                    >
                        {item?.status}
                    </span>
                );
            },
        },
        {
            name: "notes",
            label: "Notes",
            maxWidth: "200px",
            sortable: true,
        },
        {
            name: "edit",
            renderer: (item) => (
                <div
                    onClick={() => {
                        actions.modal.openCoaching(item?._id);
                    }}
                    style={{ fontSize: "85%", padding: "0 0.3rem", cursor: "pointer" }}
                >
                    <img src={details_icon} alt="" width="20px" />
                </div>
            ),
        },
    ]
    return (
        <>
            <Modal
                open
                onClose={onCancel}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div
                    className={`modal ${!isFetching ? "after-loading" : "when-loading"}`}
                    style={{ maxWidth: "85rem" }}
                >
                    <div className="modal__header d-flex justify-content-between">
                        <h2 className="modal__title">Coaching History</h2>

                        <button
                            className="btn btn_block btn_accent customized-details-btn w-5"
                            onClick={() => actions.modal.openCoaching({ driverId: id?._id })}
                            type="button"
                        >
                            Add
                        </button>

                    </div>
                    <div className="modal__body">
                        {!isFetching ? (
                            <React.Fragment>
                                {
                                    getData && getData?.length ? <div className="card" style={{ border: "1px solid #DADADA" }}>
                                        <div style={{ height: "30.3vh", overflow: "auto" }} >
                                            <Table
                                                columns={columns}
                                                items={getData?.length && getData || []}
                                            />
                                        </div>
                                    </div> : (
                                        <div className="no_results" style={{ minHeight: "30.3vh" }} >No Results</div>
                                    )
                                }

                            </React.Fragment>
                        ) : (
                            <div className="loading-wrapper">
                                <CircularProgress />
                            </div>
                        )}
                    </div>

                    <div className="modal__footer">
                        <button
                            className="btn btn_accent"
                            onClick={onCancel}
                        >
                            OK &nbsp;
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default CoachingHistoryModal
