import moment from "moment";
import { getRoute } from "../../util/time";
import { handleEstimatedShiftPay } from "../extras/handleShiftPayAndTime";
import { handleCurrency } from "../extras/handleNumbers";
import { maskingInfo } from "../../components/shared/helper";
import { convertToCapitalize } from "../extras/convertToCapitalize";
import { handlePaymentStatus } from "../extras/handlePaymentStatus";
import { statusList } from "../variables/arrays";
const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
const utc = moment().format().slice(19);

export const csvHeaders = [
     { label: "Metro", key: "metro" },
     { label: "Route Code", key: "routeCode" },
     { label: "Route Date", key: "dayDeliveryStart" },
     { label: "Starting Delivery Station", key: "fromName" },
     { label: "Start Time", key: "startTime" },
     { label: "Estimated Shift Pay", key: "estimatedShiftPayFrom" },
     { label: "Payout", key: "price" },
     { label: "Driver Name", key: "driverFirstName" },
     { label: "Driver Phone Number", key: "driverPhone" },
     { label: "Company", key: "company" },
     { label: "Method", key: "paymentmode"},
     { label: "Exception Note", key: "exceptionNote" },
     { label: "Status", key: "status" },
   ];

export const csvWeeklyPaymentsHeaders = [
     { label: "Metro", key: "metro" },
     { label: "Route Code", key: "routeCode" },
     { label: "Route Date", key: "dayDeliveryStart" },
     { label: "Starting Delivery Station", key: "fromName" },
     { label: "Start Time", key: "startTime" },
     { label: "Estimated Shift Pay", key: "estimatedShiftPayFrom" },
     { label: "Payout", key: "price" },
     { label: "Bonus", key: "bonusPrice" },
     { label: "Total Payout", key: "totalPrice" },
     { label: "Driver Name", key: "driverFirstName" },
     { label: "Driver Phone Number", key: "driverPhone" },
     { label: "Company", key: "company" },
     { label: "Method", key: "paymentmode"},
     { label: "Exception Note", key: "exceptionNote" },
     { label: "Status", key: "status" },
   ];


export const csvAdhocPaymentHeaders = [
  { label: "Date", key: "date" },
  { label: "Payee Name", key: "payeeName" },
  { label: "Amount", key: "amount" },
  { label: "Last Updated Status", key: "transactionStatus" },
  { label: "Method", key: "paymentmode"},
  { label: "Account Detail", key: "accountInfo-depositAccountNumber" },
  { label: "Notes", key: "note" },
];

function formatDate(date, format = "MM-DD-YYYY") {
  return moment(date).format(format);
}

export function paymentsData(filteredPaymentsData, paymentType) {
  const paymentsData = [];

  if (filteredPaymentsData && filteredPaymentsData.length) {
    for (let i = 0; i < filteredPaymentsData.length; i++) {
      const data = filteredPaymentsData[i];

      const {
        metro,
        routeCode,
        dayDeliveryStart,
        from,
        startTime,
        estimatedShiftPayFrom,
        estimatedShiftPayTo,
        price,
        bonusPrice,
        totalPrice,
        driver,
        exceptionNote,
        status,
        date,
        payeeName,
        amount,
        transactionStatus,
        description,
        accountInfo,
        note,
        isRequestedPayments,
        isWeeklyPayments,
        paymentmode
      } = data || {};

      paymentsData[i] =
        paymentType === "AdhocPayments"
          ? {
              date: formatDate(date),
              payeeName: payeeName || "",
              amount: amount ? `$${amount.toFixed(2)}` : "",
              transactionStatus: `${
                transactionStatus && transactionStatus === "approved"
                  ? "Waiting for approval"
                  : convertToCapitalize(transactionStatus)
              } ${description || ""}`,
              paymentmode: `${paymentmode || "DWOLLA"}`,
              "accountInfo-depositAccountNumber": maskingInfo(
                accountInfo.depositAccountNumber
              ),
              note: note,
            }
          : {
              metro: metro || "",
              routeCode: routeCode || "",
              dayDeliveryStart: formatDate(dayDeliveryStart),
              fromName: from?.name || "",
              startTime: getRoute(startTime),
              estimatedShiftPayFrom: handleEstimatedShiftPay(
                estimatedShiftPayFrom,
                estimatedShiftPayTo
              ),
              price: price ? `$${handleCurrency(price)}` : "",
              driverFirstName: `${driver?.firstName || ""} ${
                driver?.lastName || ""
              } `,
              driverPhone: driver?.phone || "",
              company: `${
                driver?.company?.companyCode ? driver?.company.companyCode : ""
              } ${
                driver?.company?.companyCode && driver?.company?.companyName
                  ? "-"
                  : ""
              } ${driver?.company ? driver?.company.companyName : ""}`,
              paymentmode: `${paymentmode || "DWOLLA"}`,
              exceptionNote: exceptionNote || "",
              status: `${status ? `${status === "approved" ? paymentType === "Weekly Payment" ? "Approved" : "Waiting for approval" : status} ${ (statusList.includes(status) && price) ? '- ' + handlePaymentStatus(status, price, isRequestedPayments, isWeeklyPayments) : ''}` : "-"}`
            };

      if (paymentType === "Weekly Payment") {
        paymentsData[i] = {
          ...paymentsData[i],
          bonusPrice: bonusPrice ? handleCurrency(bonusPrice) : "",
          totalPrice: totalPrice ? `$${handleCurrency(totalPrice)}` : "",
        };
      }
    }
  }
  paymentsData.unshift({
    [paymentType === "AdhocPayments" ? 'date' : 'metro']: `This report based on "${timeZone}  UTC ${utc}" Timezone `,
  });

  return paymentsData;
}
