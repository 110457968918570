import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
    Autocomplete,
    Box,
    CircularProgress,
    Modal, TextField,

} from "@mui/material";
import { actions } from "../../../../redux/store/store";
import { createDate, DATE } from "../../../../constants/variables/dates";
import { autocomplete } from "../../orderHelper/styles";
import { ErrorMessage, InputItem, Label } from "../../Form";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Calendar } from "react-date-range";
import { coachingStatus, typeOfCoaching } from "../../../../constants/variables/arrays";
import { addCoachingValidationSchema } from "../../../../constants/validaitonSchema";
import "./style.scss"
import { useCreateCoachingSessionsMutation, useGetAllCoachingReasonsQuery, useGetSingleCoachingQuery, useUpdateCoachingSessionsMutation, useUploadCoachingSessionsMutation } from "../../../../api/coaching";
import { sendSuccessMessage } from "../../../../constants/extras/sendSuccessMsg";
import Attechments from "./Attechments";
import { sendErrorMessage } from "../../../../constants/extras/errorHandlers";
import axios from "axios";

import { ref } from "yup";


const styles = {
    position: 'absolute',
    top: 37,
    right: 0,
    zIndex: 1,
    p: 1,
    width: "103%"
};

const CustomModal = ({ id }) => {

    const { data: getAllReasons } = useGetAllCoachingReasonsQuery("", {
        refetchOnMountOrArgChange: true,
    })

    const { data: getSingleData, isFetching } = useGetSingleCoachingQuery(id, {
        refetchOnMountOrArgChange: true,
        skip: id?.driverId
    })


    const [createCoaching] = useCreateCoachingSessionsMutation()
    const [updateCoaching] = useUpdateCoachingSessionsMutation()
    const [upload] = useUploadCoachingSessionsMutation()

    const [showDateRangePicker, setShowDateRangePicker] = useState(false);
    const [date, setDate] = useState(new Date())
    const [toDate, setToDate] = useState(date)
    const [prevDate, setPrevDate] = useState(date)
    const [showShiftRangePicker, setShowShiftRangePicker] = useState(false);
    const [shiftDate, setShiftDate] = useState(new Date())
    const [shiftToDate, setShiftToDate] = useState("")
    const [shiftPrevDate, setShiftPrevDate] = useState(shiftDate)
    const [isUpdatingDriver, setIsUpdatingDriver] = useState(false)
    const [attachment, setAttachment] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [disableField, setDisabledField] = useState(false)

    const form = useForm({
        defaultValues: {
            coachReason: null,
            shiftNumber: "",
            coachingType: null,
            status: null,
            notes: ""

        },
        resolver: yupResolver(addCoachingValidationSchema),
    });

    const attachmentImg = async (elem) => {
        if (elem?.includes("mp4")) {
            const arr = await axios.get(`/api/coachingSessions/debug/${elem}`);
            return arr?.data?.result?.url
        } else if (elem?.includes("pdf")) {
            const result = await fetch(`/api/coachingSessions/debug/${elem}`, { credentials: 'include' })
            return result.url
        }
    }


    const attachmentFile = async () => {
        const arr = getSingleData?.attachments?.map(async (elem) => {
            const isSeen = elem?.isSeen;
            elem = elem.url;
            const file = await attachmentImg(elem)
            if (elem?.includes("mp4")) return { type: "Video", isSeen: isSeen, file: file, prevFile: file };
            else if (elem?.includes("pdf")) return { type: "Document (PDF)", isSeen: isSeen, file: file, prevFile: file }
        })


        if (!id?.driverId) {
            setIsLoading(true)
            const linkArr = getSingleData?.links?.map((elem) => {
                const isSeen = elem?.isSeen;
                return ({ type: "Link", isSeen: isSeen, file: elem.url, prevFile: elem.url, _id: elem._id })
            })

            if (getSingleData?.attachments?.length && linkArr?.length) setAttachment([...await Promise.all(arr), ...linkArr])
            else if (getSingleData?.attachments?.length) setAttachment(await Promise.all(arr))
            else if (linkArr?.length) setAttachment(linkArr)
            setIsLoading(false)
        }
        else setAttachment([])
    }

    
    useEffect(() => {
        const coachType = typeOfCoaching?.filter((elem) => elem?.value === getSingleData?.coachingType)
        form.setValue("coachReason", !id?.driverId ? getSingleData?.coachReason : null)
        form.setValue("shiftNumber", !id?.driverId ? getSingleData?.shiftNumber : "")
        form.setValue("coachingType", !id?.driverId ? coachType[0] : null)
        form.setValue("status", !id?.driverId ? getSingleData?.status : null)
        form.setValue("notes", !id?.driverId ? getSingleData?.notes : "")
        getSingleData?.status === 'Done' && setDisabledField(true)
        setPrevDate(!id?.driverId ? new Date(getSingleData?.date) : new Date())
        setToDate(!id?.driverId ? new Date(getSingleData?.date) : new Date())
        setShiftPrevDate(!id?.driverId ? getSingleData?.shiftDate ? new Date(getSingleData?.shiftDate) : new Date() : new Date())
        setShiftToDate(!id?.driverId ? getSingleData?.shiftDate ? new Date(getSingleData?.shiftDate) : "" : "")
        attachmentFile()

    }, [id, getSingleData])

    const handleDateClose = () => {
        if(disableField) return;
        setShowDateRangePicker(!showDateRangePicker)
        setDate(prevDate)
    }

    const handleSelectDone = () => {
        const startDate = moment(date).format("yyyy-MM-DD");
        setToDate(startDate)
        setPrevDate(date)
        setShowDateRangePicker(false)
    }

    const handleShiftDateCloase = () => {
        if(disableField) return;
        setShowShiftRangePicker(!showShiftRangePicker)
        setShiftDate(shiftPrevDate)
    }

    const handleShiftSelectDone = () => {
        const startDate = moment(shiftDate).format("yyyy-MM-DD");
        setShiftToDate(startDate)
        setShiftPrevDate(shiftDate)
        setShowShiftRangePicker(false)
    }

    const handleResetShiftSelect = () => {
        setShiftToDate("")
        setShiftDate(new Date())
        setShiftPrevDate(new Date())
        setShowShiftRangePicker(false)
    }
    const onSubmit = async (data) => {


        const AllOptionSelected = attachment?.every((item) => {
            return item.type !== "" && item?.file !== "" && !item?.isVideo && !item?.isPdf && !item.isFile;
        });
        if (!AllOptionSelected) sendErrorMessage("Please select type and file")

        const attachmentData = attachment?.filter((elem) => elem?.type !== "Link").map((e) => e?.file)

        const getUpdateData = getSingleData?.attachments?.filter((elem) => {
            const foundAttachment = attachment?.find((item) => item.prevFile?.includes(elem.url));
            return elem?.type !== "Link" && foundAttachment;
        });

        const addNewUpdateData = attachment?.filter((item) => {
            return item?.type !== "" && !getSingleData?.attachments?.some((elem) => item.prevFile?.includes(elem))
        }).map((e) => e?.file)



        let link = attachment?.filter((e) => e?.type === "Link").map((e) => e)


        const uploadAttachment = {
            file: !id?.driverId ? addNewUpdateData : attachmentData
        }

        const params = {
            ...data,
            coachReason: data?.coachReason?._id,

            coachingType: data?.coachingType?.value,
            date: moment(toDate).format("yyyy-MM-DD"),
            shiftDate: shiftToDate ? moment(shiftToDate).format("yyyy-MM-DD") : "",


            links: link.map((e) => ({ url: e?.file, isSeen: e?.isSeen, _id: e?._id })),

            ...(id?.driverId && { "driver": id?.driverId }),

            ...(!id?.driverId && { "attachments": getUpdateData }),
            ...(getSingleData?.status === "Pending" && { driverQualification: 0 })
        }

        AllOptionSelected && setIsUpdatingDriver(true)

        const result = AllOptionSelected && await (!id?.driverId ? updateCoaching({ id, params }) : createCoaching(params));


        if (result?.data) {
            if (id?.driverId || addNewUpdateData?.length) {
                const getData = await upload({ id: result?.data?._id, params: uploadAttachment })
                if (getData?.data) {
                    sendSuccessMessage(!id?.driverId ? "Coaching updated successfully!!" : "Coaching created successfully!!");
                    actions.refetch.setIsDataRefetch(true);
                    actions.modal.closeCoaching();
                    setIsUpdatingDriver(false)
                } else {
                    sendErrorMessage("Something went wrong")
                    setIsUpdatingDriver(false)
                }
            } else {
                sendSuccessMessage(!id?.driverId ? "Coaching updated successfully!!" : "Coaching created successfully!!");
                actions.refetch.setIsDataRefetch(true);
                actions.modal.closeCoaching();
                setIsUpdatingDriver(false)
            }
        }

        if (!getSingleData?.driverQualification) {
            const CoachingId = await result?.data?._id;
            if (data?.status === "Done") {
                actions.modal.openRating(CoachingId);
            }
        } else if (getSingleData?.status === "Pending" && getSingleData?.driverQualification) {
            const CoachingId = await result?.data?._id;
            if (data?.status === "Done") {
                actions.modal.openRating(CoachingId);
            }
        }   
    }

    const onCancel = async () => {
        if (!getSingleData?.driverQualification) {
            const CoachingId = await getSingleData?._id;
            if (getSingleData?.status === "Done") {
                actions.modal.openRating(CoachingId);
            }
        }
        actions.modal.closeCoaching();
    };

    return (
        <>
            <Modal
                open
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div
                    className={`modal ${isLoading || isFetching ? "when-loading" : "after-loading"}`}
                >

                    <div className="modal__header">
                        <h2 className="modal__title">{!id?.driverId ? "Update Coaching" : `Add Coaching`}</h2>
                    </div>
                    <div className="modal__body">
                        {isLoading || isFetching ? (
                            <div className="loading-wrapper">
                                <CircularProgress />
                            </div>
                        ) : (
                            <React.Fragment>
                                <form>
                                    <div className="form__row" style={{ gap: "12px !important" }}>
                                        <div className="d-flex w-100" style={{ gap: "20px" }} >
                                            <div className="single-date-select" style={{ width: "50%" }} >
                                                <Label title="Date" />
                                                <Box sx={{ position: "relative" }}>
                                                    <div onClick={handleDateClose} className={`form__input d-flex justify-content-between`} disabled={disableField} >
                                                        <span>{createDate(toDate, DATE.M_D_Y)}</span>
                                                        <span className="pl-2"> {showDateRangePicker ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />} </span>
                                                    </div>
                                                    {showDateRangePicker && (
                                                        <Box sx={styles} className="dt__rng__pickr__cont">
                                                            <div className="dateRangePicker" style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px !important" }}>
                                                                <Calendar
                                                                    date={date}
                                                                    onChange={(data) => setDate(data)}
                                                                />
                                                                <div className=" date-range-button" >
                                                                    <button className="cancelButton" onClick={handleDateClose} >
                                                                        <strong>cancel</strong>
                                                                    </button>
                                                                    <button className="okButton" onClick={handleSelectDone} >
                                                                        <strong>Done</strong>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </Box>
                                                    )}
                                                </Box>
                                            </div>

                                            <div className="w-50">
                                                <Controller
                                                    control={form.control}
                                                    name="coachReason"
                                                    render={({
                                                        field: { onChange, value, ref },
                                                        fieldState: { error },
                                                    }) => (
                                                        <React.Fragment>
                                                            <Label title="Coach reason" />
                                                            <Autocomplete
                                                                options={getAllReasons || []}
                                                                sx={autocomplete}
                                                                value={value}
                                                                onChange={(value, data) => onChange(data)}
                                                                ref={ref}
                                                                getOptionLabel={(option) => option?.reason}
                                                                renderInput={(params) => (
                                                                    <TextField {...params} placeholder="Select Coach Reason" inputProps={{ ...params?.inputProps }} />
                                                                )}
                                                                isOptionEqualToValue={(option, value) => option?.reason === value?.reason}
                                                                disabled={disableField}
                                                            />
                                                            <ErrorMessage error={error} />
                                                        </React.Fragment>
                                                    )}
                                                />
                                            </div>
                                        </div>

                                        <div className="d-flex w-100 mt-2" style={{ gap: "20px" }} >
                                            <div className="single-date-select" style={{ width: "50%" }} >
                                                <Label title="Shift date" />
                                                <Box sx={{ position: "relative" }}>
                                                    <div onClick={handleShiftDateCloase} className={`form__input d-flex justify-content-between`} disabled={disableField}>
                                                        <span>{shiftToDate ?
                                                            createDate(shiftToDate, DATE.M_D_Y) :
                                                            <span className="shift_date-placeholder">Select Shift Date</span>}
                                                        </span>
                                                        <span className="pl-2"> {showShiftRangePicker ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />} </span>
                                                    </div>
                                                    {showShiftRangePicker && (
                                                        <Box sx={styles} className="dt__rng__pickr__cont">
                                                            <div className="dateRangePicker">
                                                                <Calendar
                                                                    date={shiftDate}
                                                                    onChange={(data) => setShiftDate(data)}
                                                                />
                                                                <div className=" date-range-button" >
                                                                    <button className="okButton clearButton" onClick={handleResetShiftSelect}>
                                                                        <strong>Reset</strong>
                                                                    </button>
                                                                    <button className="cancelButton" onClick={handleShiftDateCloase} >
                                                                        <strong>cancel</strong>
                                                                    </button>
                                                                    <button className="okButton" onClick={handleShiftSelectDone} >
                                                                        <strong>Done</strong>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </Box>
                                                    )}
                                                </Box>
                                            </div>
                                            <div className="w-50">
                                                <InputItem
                                                    title="Shift number"
                                                    name="shiftNumber"
                                                    form={form}
                                                    type="text"
                                                    required
                                                    pattern="[0-9]+"
                                                    placeholder="Enter Shift Number"
                                                    disabled={disableField}
                                                />
                                            </div>
                                        </div>

                                        <div className="d-flex w-100 mt-2" style={{ gap: "20px" }} >
                                            <div className="w-50">
                                                <Controller
                                                    control={form.control}
                                                    name="coachingType"
                                                    render={({
                                                        field: { onChange, value, ref },
                                                        fieldState: { error },
                                                    }) => (
                                                        <React.Fragment>
                                                            <Label title="Coaching type" />
                                                            <Autocomplete
                                                                options={typeOfCoaching}
                                                                sx={autocomplete}
                                                                value={value}
                                                                onChange={(value, data) => onChange(data)}
                                                                ref={ref}
                                                                getOptionLabel={(option) => option.title}
                                                                renderInput={(params) => (
                                                                    <TextField {...params} placeholder="Select Coaching Type" inputProps={{ ...params.inputProps }} />
                                                                )}
                                                                isOptionEqualToValue={(option, value) => option.title === value.title}
                                                                disabled={disableField}
                                                            />
                                                            <ErrorMessage error={error} />
                                                        </React.Fragment>
                                                    )}
                                                />
                                            </div>
                                            <div className="w-50">
                                                <Controller
                                                    control={form.control}
                                                    name="status"
                                                    render={({
                                                        field: { onChange, value, ref },
                                                        fieldState: { error },
                                                    }) => (
                                                        <React.Fragment>
                                                            <Label title="Status" />
                                                            <Autocomplete
                                                                options={coachingStatus}
                                                                sx={autocomplete}
                                                                value={value}
                                                                onChange={(value, data) => onChange(data)}
                                                                ref={ref}
                                                                getOptionLabel={(option) => option}
                                                                renderInput={(params) => (
                                                                    <TextField {...params} placeholder="Select Status" inputProps={{ ...params.inputProps }} />
                                                                )}
                                                                isOptionEqualToValue={(option, value) => option === value}
                                                                disabled={disableField}
                                                            />
                                                            <ErrorMessage error={error} />
                                                        </React.Fragment>
                                                    )}
                                                />
                                            </div>
                                        </div>

                                        <div className="mt-2 mb-3 inputData d-flex flex-column w-100 "  >
                                            <Controller
                                                control={form.control}
                                                name="notes"
                                                render={({
                                                    field: { onChange, value, ref },
                                                    fieldState: { error },
                                                }) => (
                                                    <React.Fragment>
                                                        <label >Notes:</label>
                                                        <textarea
                                                            type="text"
                                                            value={value}
                                                            onChange={(e) => onChange(e.target.value.trimStart())}
                                                            placeholder="Enter Notes"
                                                            className="textarea"
                                                            disabled={disableField}
                                                        />
                                                        <ErrorMessage error={error} />
                                                    </React.Fragment>
                                                )}
                                            />
                                        </div>

                                        <Attechments
                                            attachment={attachment}
                                            setAttachment={setAttachment}
                                            disableField={disableField}
                                            id={!id?.driverId && id || ""}
                                            isLoading={isLoading}
                                        />
                                    </div>
                                </form>
                            </React.Fragment>
                        )}
                    </div>

                    <div className="modal__footer">

                        <button className="btn" onClick={onCancel}>
                            Cancel
                        </button>
                        <button
                            className="btn btn_accent"
                            onClick={form.handleSubmit(onSubmit)}
                            disabled={isUpdatingDriver}
                            hidden={disableField}
                        >
                            OK &nbsp;
                            {isUpdatingDriver && (
                                <span>
                                    <CircularProgress size={20} style={{ color: "white" }} />
                                </span>
                            )}
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default CustomModal;